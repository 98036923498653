import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';

const Step1 = ({ istoggle, setToggle }) => {
    useEffect(() => {}, [istoggle])

    return (
        <>
            {istoggle ? (
                <div className='exchange-empty__stat px-3'>
                    <div className='exchange-stat__media'>
                        <svg width="107" height="107" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M90.546 15.5181C69.858 -5.17188 36.199 -5.17187 15.515 15.5131C-5.173 36.1981 -5.171 69.8581 15.517 90.5471C36.199 111.231 69.858 111.231 90.544 90.5431C111.23 69.8581 111.229 36.2001 90.546 15.5181ZM84.757 84.7581C67.263 102.252 38.797 102.254 21.302 84.7601C3.804 67.2631 3.806 38.7941 21.302 21.3001C38.796 3.80713 67.261 3.80513 84.759 21.3021C102.253 38.7961 102.251 67.2651 84.757 84.7581ZM77.017 74.0011C77.675 75.5221 76.975 77.2871 75.455 77.9441C73.934 78.6041 72.169 77.9021 71.511 76.3821C68.618 69.6931 61.781 65.3701 54.09 65.3701C46.222 65.3701 39.343 69.6891 36.568 76.3741C36.089 77.5281 34.972 78.2251 33.797 78.2251C33.413 78.2251 33.024 78.1511 32.647 77.9951C31.117 77.3591 30.392 75.6031 31.027 74.0741C34.737 65.1421 43.791 59.3711 54.09 59.3711C64.174 59.3711 73.174 65.1131 77.017 74.0011ZM33.24 38.6711C33.24 35.2471 36.017 32.4701 39.441 32.4701C42.864 32.4701 45.641 35.2461 45.641 38.6711C45.641 42.0971 42.864 44.8731 39.441 44.8731C36.017 44.8731 33.24 42.0971 33.24 38.6711ZM61.357 38.6711C61.357 35.2471 64.136 32.4701 67.56 32.4701C70.983 32.4701 73.76 35.2461 73.76 38.6711C73.76 42.0971 70.984 44.8731 67.56 44.8731C64.136 44.8731 61.357 42.0971 61.357 38.6711Z" fill="#C4CAD0"/>
                        </svg>
                    </div>
                    <div className='exchange-stat__details'>
                        <div className='exchange-stat__text'>Sorry your smartphone is not eligible for exchange</div>
                        <div className='exchange-stat__info'>Why is my phone not eligible?</div>
                    </div>
                </div>) :
                <div className=''>
                    <div className="exchange-data">
                        <h3 className="Exchangehead"> Does your phone Switch on? </h3>
                        <div className="filter-tab__lists">
                            <div className="filter-tab__flex changeexchange-div">
                                <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                                <label htmlFor="label" className="filter_exchange ml-2">Yes</label>
                            </div>
                            <div className="filter-tab__flex changeexchange-div">
                                <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />	
                                <label htmlFor="label" className="filter_exchange ml-2">No</label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="exchange-data">
                        <h3 className="Exchangehead">Is your phone able to make and receive calls?</h3>
                        <div className="filter-tab__lists">
                            <div className="filter-tab__flex changeexchange-div">
                                <Form.Check type="radio" name='radioCoupon1' aria-label="radio 1" />
                                <label htmlFor="label" className="filter_exchange ml-2">Yes</label>
                            </div>
                            <div className="filter-tab__flex changeexchange-div">
                                <Form.Check type="radio" name='radioCoupon1' aria-label="radio 1" onChange={()=>setToggle({toggle: !istoggle})} />
                                <label htmlFor="label" className="filter_exchange ml-2">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='mar-spacer h-13' />
        </>
    )
}

export default Step1