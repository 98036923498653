import React, { useEffect, useState } from 'react';
import ModalSimilarProducts from './ModalSimilarProducts';

const ModalOutOfStock = () => {
    const [modalShow, setModalShow] = useState(false);
	
    useEffect(() => {
        setTimeout(() => {
            setModalShow({ show: true });
        }, 20000);
    }, [])
    return (
        <>
            <ModalSimilarProducts
                show={modalShow}
                onHide={() => setModalShow(false)}
            /> 
        </>
    )
}

export default ModalOutOfStock