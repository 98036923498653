import React, { useState } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';
import PopularBlogTabs from './BlogTabsContent/PopularBlogTabs'
import BuyguideTab from "./BlogTabsContent/BuyguideTab";
import CategoaryTab from './BlogTabsContent/CategoaryTab'
import LatestTechTab from "./BlogTabsContent/LatestTechTab";

const BlogTabs = () => {
    const [key, setKey] = useState('home');

    return (
        <div>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="home" title="Popular">
                    <TabContent>
                        <PopularBlogTabs/>                        
                    </TabContent>
                </Tab>
                <Tab eventKey="profile" title="Buying Guide">
                    <TabContent>
                        <BuyguideTab/>
                        
                    </TabContent >
                </Tab>
                <Tab eventKey="contact" title="Categories">
                    <TabContent>
                        <CategoaryTab/>
                    </TabContent>
                </Tab>
                <Tab eventKey="Latest" title="Latest in Tech">
                    <TabContent>
                        <LatestTechTab/>
                    </TabContent>
                </Tab>
            </Tabs>
        </div>
    )
}

export default BlogTabs