import React from "react";
import { Router } from '@reach/router';
import { BrowserRouter } from 'react-router-dom';

//Screens
import Home from "./Containers/Home";
import Category from './Containers/Category';
import Brand from "./Containers/Brand";
import Categories from "./Containers/Categories";
import Brands from "./Containers/Brands";
import ProductDetails from "./Containers/Product/ProductDetails";
import WhySangeethaExplore from "./Components/Product/WhySangeethaExplore";
import ProductRenewed from "./Containers/Product/ProductRenewed";
import PreBooking from "./Containers/Product/PreBooking";
import ProductList from "./Containers/Product/ProductList";
import RenewedPrelaunchList from "./Containers/Product/RenewedPrelaunchList";
import CartDetails from "./Containers/Cart";
import WishlistDetails from "./Containers/Wishlist";
import Address from "./Containers/Address";
import AddAddress from "./Components/Address";
import InputSearch from "./Components/Address/InputSearch";
import AddAddressDetails from "./Components/Address/AddAddressDetails";
import OrderDetails from "./Components/Order/OrderDetails";
import OrderPickupDetails from "./Components/Order/OrderPickupDetails";
import OrderDeliveredDetails from "./Components/Order/OrderDeliveredDetails";
import NavSearchBar from "./Components/Header/NavSearchBar";
import SearchResult from "./Containers/SearchResult";
import Compare from "./Containers/Compare";
import Account from "./Containers/Account";
import Profile from "./Components/Account/Profile";
import AccountAddress from "./Components/Account/Address";
import Payments from "./Components/Account/Payments";
import MyCoupons from "./Components/Account/MyCoupons";

import Payment from "./Containers/Payment";
import BankOffersList from "./Components/Product/BankOffersList";
import BankOffersDetails from "./Components/Product/BankOffersDetails";
import ProductSpecifications from "./Components/Product/ProductSpecifications";
import ProductOutOfStock from "./Containers/Product/ProductOutOfStock";
import EMIListTab from "./Components/Product/EMIListTab";
import DIY from "./Containers/DIY";
import DIYLaptop from "./Containers/DIYLaptop";
import DIYHeadphone from "./Containers/DIYHeadphone";
import ResultList from "./Components/DIY/ResultList";
import StoreLocator from "./Containers/StoreLocator";
import StoreSearch from "./Components/StoreLocator/StoreSearch";

//Product details category pages
import ProductEarphone from "./Containers/Product/ProductEarphone";
import ProductSmartWatch from "./Containers/Product/ProductSmartWatch";
import ProductAccessories from "./Containers/Product/ProductAccessories";
import ProductSmartTv from "./Containers/Product/ProductSmartTv";
import ProductSpecificationsCategory from "./Components/Product/CategoryDetails/ProductSpecifications";
import SmartwatchSpecification from "./Components/Product/CategoryDetails/SmartwatchSpecification";
import SmartTvSpecification from "./Components/Product/CategoryDetails/Television_details/SmartTvSpecification";
import Order from "./Containers/Order/Order";
import OrderConfirmation from "./Containers/Order/OrderConfirmation";
import OrdersuccessfullDetails from "./Containers/Order/OrdersuccessfullDetails";
import OrderTrack from "./Containers/Order/OrderTrack";
import SmartWatchList from "./Containers/Product/SmartWatchList";
import MobileAccessoriesList from "./Containers/Product/MobileAccessoriesList";
import HeadPhoneList from "./Containers/Product/HeadPhoneList";

import OrderCancellationDetails from './Components/Ordercancellation/OrderCancellationDetails';
import OrderCancelled from './Components/Ordercancellation/OrderSuccessCancel'
import OrderCanceledDetails from './Components/Ordercancellation/OrderCanceledDetails';
import OrderDelay from './Components/Order/OrderDelay'

//Components
import Header from "./Components/Header";
import ScrollToTop from "./Components/Footer/ScrollToTop";

// orderstatus
import OrderStatus from './Components/Order/OrderStatus/OrderStatus';
import OrderDelivered from './Components/Order/OrderDelivered';
import OrderTrackerStatus from './Components/Order/OrderStatus/OrderTrackerStatus'
import Return from './Components/Help/Return'
import TermCondition from './Components/Help/TermCondition';
import PrivacyPolicy from './Components/Help/PrivacyPolicy';
import Faq from './Components/Help/Faq';
import Error404 from "./Containers/404";








//WebScreens
import WebHome from "./Web/Containers/Home";
import WebHeader from './Web/Components/Header';
import WebFooter from './Web/Components/Footer';
import WebCart from './Web/Containers/Cart';
import WebAddress from './Web/Containers/Address';
import WebProductList from './Web/Containers/ProductList';
import WebHeadphoneProductList from './Web/Containers/HeadphoneProductList';
import WebAccessoriesProductList from './Web/Containers/AccessoriesProductList';
import WebWatchProductList from './Web/Containers/WatchProductList';
import WebCategoryList from './Web/Containers/CategoryList';
import WebBrandList from './Web/Containers/BrandList';
import WebProductDetails from './Web/Containers/ProductDetails';
import WebEarphoneDetails from './Web/Containers/CategoryProductDetails/EarphoneDetails';
import WebAccessoriesDetails from './Web/Containers/CategoryProductDetails/AccessoriesDetails';
import WebSmartTvDetails from './Web/Containers/CategoryProductDetails/SmartTvDetails';
import WebWatchDetails from './Web/Containers/CategoryProductDetails/WatchDetails';
import WebOutOfStock from "./Web/Containers/CategoryProductDetails/OutOfStock";
import WebProtectionPlan from './Web/Components/ProductDetails/ProtectionPlans';
import WebProductRenewed from './Web/Containers/CategoryProductDetails/ProductRenewed';
import WebPreBooking from './Web/Containers/CategoryProductDetails/PreBooking';
import WebDIY from "./Web/Containers/DIY";
import WebDIYLaptop from "./Web/Containers/DIYLaptop";
import WebDIYHeadphone from "./Web/Containers/DIYHeadphone";
import WebDIYResult from './Web/Components/DIY/DIYResult';
import WebStoreLocator from './Web/Containers/StoreLocator';
import WebStoreSearch from './Web/Components/StoreLocator/StoreSearch';
import WebWishlistDetails from './Web/Containers/Wishlist';
import WebOrderConfirmation from './Web/Containers/OrderConfirmation';
import WebOrderDetails from './Web/Containers/OrderDetails';
import WebBlog from './Web/Containers/Blog'
import WebTerm from './Web/Containers/PolicyAndTerm'
import WebBlogDeatils from './Web/Components/Blog/BlogDeatils'
import WebCompare from "./Web/Containers/Compare";
import WebProfile from './Web/Containers/Profile';
import WebPayment from './Web/Containers/Payment';
import WebOrderReplacement from './Web/Components/Order/OrderReplacement';
import WebScrollToTop from "./Web/Components/Footer/ScrollToTop";
import WebVasList from './Web/Components/BrandList/VasList'





import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
console.log("Browser View",{BrowserView} );
console.log("Mobile View",{MobileView} );
console.log("isBrowser View",{isBrowser} );
console.log("isMobile View",{isMobile} );



const App = () => {
    if(isMobile){
        return (
       
            <div className="page-wrapper">
                <BrowserRouter>
                    <ScrollToTop />
                    <Header />
                    <Router>
                        <Home exact path="/" component={Home} />
                        <Category exact path='/Category' />
                        <Brand exact path='/Brand' />
                        <Categories exact path='/Categories' />
                        <Brands exact path='/Brands' />
                        <NavSearchBar exact path="/NavSearchBar" />
                        <SearchResult exact path="/SearchResult" />
                        <ProductDetails exact path="/ProductDetails" />
                        <WhySangeethaExplore exact path='/WhySangeethaExplore' />
                        <ProductRenewed exact path='/ProductRenewed' /> 
                        <PreBooking exact path='/PreBooking' />
                        <ProductList exact path="/ProductList" />
                        <RenewedPrelaunchList exact path='/RenewedPrelaunchList' />
                        <Compare exact path='/Compare' />
                        <CartDetails exact path="/Cart" />
                        <WishlistDetails exact path="/Wishlist" />
                        <Address exact path="/Address" />
                        <AddAddress exact path="/AddAddress" /> 
                        <InputSearch exact path="/InputSearch" />
                        <AddAddressDetails exact path="/AddAddressDetails" />
                        <Payment exact path="/Payment" />
                        <BankOffersList path="/BankOffersList" />
                        <BankOffersDetails path="/BankOffersDetails" />
                        <ProductSpecifications path="/ProductSpecifications" />
                        <ProductOutOfStock path="/ProductOutOfStock" />
                        <EMIListTab path="/EMIListTab" />
                        <Account exact path="/Account" />
                        <Profile exact path='/Account/Profile' />
                        <AccountAddress exact path='/Account/AccountAddress' />
                        <Payments exact path='/Account/Payments' />
                        <MyCoupons exact path='/Account/MyCoupons' />
                        <DIY exact path='/DIY' />
                        <DIYLaptop exact path='/DIYLaptop' />
                        <DIYHeadphone exact path='/DIYHeadphone' />
                        <ResultList exact path='/ResultList' />
                        <StoreLocator exact path='/StoreLocator' />
                        <StoreSearch exact path='/StoreSearch' />
                        
                        {/* //Product Category */}
                        <ProductEarphone path="/ProductEarphone"/>
                        <ProductSmartWatch path="/ProductSmartWatch" />
                        <ProductAccessories path="/ProductAccessories" />
                        <ProductSmartTv path="/ProductSmartTv" />
                        <ProductSpecificationsCategory path="/CategoryDetails/ProductSpecifications" />
                        <SmartwatchSpecification path="/CategoryDetails/SmartwatchSpecification" />
                        <SmartTvSpecification path="/CategoryDetails/Accessories/SmartTvSpecification" />
                        <Order path ="/Order" />
                        <OrderConfirmation exact path='/OrderConfirmation' />
                        <OrdersuccessfullDetails path ="/OrdersuccessfullDetails" />
                        <OrderTrack path ="/OrderTrack" />
                        <SmartWatchList exact path="/SmartWatchList" />
                        <MobileAccessoriesList exact path="/MobileAccessoriesList" />
                        <HeadPhoneList exact path="/HeadPhoneList" />
                        <OrderDetails exact path="/OrderDetails" />

                        <OrderPickupDetails exact path="/OrderPickupDetails" />
                        <OrderDeliveredDetails exact path="/OrderDeliveredDetails" />
                        <OrderDelay exact path='/OrderDelay'/>
                        {/* Order Cancellation */}
                        <OrderCancellationDetails exact path="/OrderCancellationDetails" />
                        <OrderCancelled exact path="/OrderCancelled"/>
                        <OrderCanceledDetails exact path='/OrderCanceledDetails'/>
                        {/* orderstatus */}                   
                        <OrderStatus exact path ='/OrderStatus'/>
                        <OrderDelivered exact path ='/OrderDelivered'/>
                        {/* orderReplacement sangeetha */}
                        <OrderTrackerStatus exact path="/OrderTrackerStatus"/>
                        <Return exact path ="/Return"/>
                        <TermCondition exact path ="/TermCondition"/>
                        <PrivacyPolicy exact path ="/PrivacyPolicy"/>
                        <Faq exact path ="/Faq"/>
                        <Error404 exact path="/404" />

                        

                        



                    </Router>
                    {/* <Router>
                    </Router> */}
                </BrowserRouter>
            </div>
            
        );
    } else {
        return (
            <>
                <div className="page-wrapper">
                    <BrowserRouter>
                        <WebScrollToTop />
                        <WebHeader />
                        <Router>
                            <WebHome exact path="/" component={Home} />
                            <WebCart exact path='/Cart' />
                            <WebAddress exact path='/Address' />
                            <WebProductList exact path='/ProductList' />
                            <WebHeadphoneProductList exact path='/HeadPhoneList' />
                            <WebAccessoriesProductList exact path='/MobileAccessoriesList' />
                            <WebWatchProductList exact path='/SmartWatchList' />
                            <WebCategoryList exact path='Categories' />
                            <WebBrandList exact path='/Brands' />
                            <WebProductDetails exact path='/ProductDetails' />
                            <WebEarphoneDetails exact path='/ProductEarphone' />
                            <WebAccessoriesDetails exact path='/ProductAccessories' />
                            <WebSmartTvDetails exact path='/ProductSmartTv' />
                            <WebWatchDetails exact path='/ProductSmartWatch' />
                            <WebOutOfStock exact path='/ProductOutOfStock' />
                            <WebProtectionPlan exact path='/ProtectionPlan' />
                            <WebProductRenewed exact path='/ProductRenewed' /> 
                            <WebPreBooking exact path='/PreBooking' />
                            <WebDIY exact path='/DIY' />
                            <WebDIYLaptop exact path='/DIYLaptop' />
                            <WebDIYHeadphone exact path='/DIYHeadphone' />
                            <WebDIYResult exact path='/ResultList' />
                            <WebStoreLocator exact path='/StoreLocator' />
                            <WebStoreSearch exact path='/StoreSearch' />
                            <WebWishlistDetails exact path="/Wishlist" />
                            <WebOrderConfirmation exact path='/OrderConfirmation' />
                            <WebOrderDetails exact path='/OrderDetails' />
                            <WebBlog exact path='/Blog' />
                            <WebBlogDeatils exact path='/BlogDeatils' />
                            <WebTerm exact path='/PolicyAndTerm' />
                            <WebProfile exact path='/Profile' />
                            <WebCompare exact path='/Compare' />
                            <WebPayment exact path='/Payment' />
                            <WebOrderReplacement exact path='/OrderReplacement' />
                            <WebVasList exact path='/VasList' />
                        </Router>
                        <WebFooter />
                    </BrowserRouter>
                </div>
            </>
        );
    }
    
};
export default App; 
