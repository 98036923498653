import React, { useState } from 'react';
import EmiModal from './EmiModal';

const EmiOptions = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='payment-offer-options'>
                <div className='payment-offer-content'>
                    <div className='payment-offer-icon'>
                        <span>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.3333 13.3333V26.6667C29.3333 27.0203 29.1928 27.3594 28.9428 27.6095C28.6927 27.8595 28.3536 28 28 28H3.99996C3.64634 28 3.3072 27.8595 3.05715 27.6095C2.8071 27.3594 2.66663 27.0203 2.66663 26.6667V13.3333H29.3333ZM29.3333 10.6667H2.66663V5.33333C2.66663 4.97971 2.8071 4.64057 3.05715 4.39052C3.3072 4.14048 3.64634 4 3.99996 4H28C28.3536 4 28.6927 4.14048 28.9428 4.39052C29.1928 4.64057 29.3333 4.97971 29.3333 5.33333V10.6667ZM20 21.3333V24H25.3333V21.3333H20Z" fill="#121415"/>
                            </svg>

                        </span>
                    </div>
                    <div className='payment-offer-info'>
                        <h6>EMI Options</h6>
                        <p className='details-text'>Starting from <span> ₹3,950/month</span></p>
                    </div>
                </div>
                <div className='payment-offer-link' onClick={() => setModalShow(true)}  role='button' aria-hidden="true">
                    View
                </div>
            </div> 
            <EmiModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />  
        </>
    )
}

export default EmiOptions