import React from 'react';

import callbackBanner from '../../Images/StoreLocator/Banner1.jpg';


const ReqCallbackBanner = () => {
    return (
        <>
            <section className="store_callback-banner mb-2">
                <div className='container'>
                    <div className='store-locator__container'>
                        <div className='mar-spacer h-2 mb-2' />
                        <img src={callbackBanner} className='img-fluid' alt='' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReqCallbackBanner