import React from 'react';
import addressCart from '../../../Images/product/addressCart.png';
import Headphones from '../../../Images/product/Headphones.png';
import PopSocket from '../../../Images/product/PopSocket.png';

const DeliveryEstimates = () => {
    return (
        <>
            <div className='cart-address__delivery'>
                <div className='address-este__delivery'>
                    <div className='address-este____media'>
                        <img src={addressCart} className='img img-fluid' alt=''/>
                    </div>
                    <div className='address-este__details'>
                        <h5>Galaxy Fold 5g</h5>
                        <p>Awesome White, 128Gb, 8Gb</p>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3332 5.33337H13.3332L15.3332 8.03737V12H13.9765C13.8973 12.5562 13.62 13.0652 13.1956 13.4333C12.7713 13.8015 12.2283 14.0042 11.6665 14.0042C11.1047 14.0042 10.5617 13.8015 10.1374 13.4333C9.71303 13.0652 9.43576 12.5562 9.3565 12H5.9765C5.89725 12.5562 5.61998 13.0652 5.19562 13.4333C4.77127 13.8015 4.22832 14.0042 3.6665 14.0042C3.10469 14.0042 2.56174 13.8015 2.13738 13.4333C1.71303 13.0652 1.43576 12.5562 1.3565 12H0.666504V4.00004C0.666504 3.82323 0.736742 3.65366 0.861766 3.52864C0.98679 3.40361 1.15636 3.33337 1.33317 3.33337H10.6665C10.8433 3.33337 11.0129 3.40361 11.1379 3.52864C11.2629 3.65366 11.3332 3.82323 11.3332 4.00004V5.33337ZM11.3332 6.66671V8.66671H13.9998V8.47671L12.6612 6.66671H11.3332Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-2">Delivery by &nbsp;</span>
                            <span className="label__similarProd">14 Jan, &nbsp;</span>
                            <span className="deliver-type text-uppercase">Free</span>
                        </div>
                    </div>
                    {/* For Mobile Phones */}
                    <div className='cart-delivery__service'>
                        <h6 className='mb-2'>You are eligible for our Free Delivery Services</h6>
                        <ul className='delivery-lists__service'>
                            <li className='delivery-list-service'>
                                <span>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 7V8C5.20435 8 4.44129 8.31607 3.87868 8.87868C3.31607 9.44129 3 10.2044 3 11H2C2 9.93913 2.42143 8.92172 3.17157 8.17157C3.92172 7.42143 4.93913 7 6 7ZM6 6.5C4.3425 6.5 3 5.1575 3 3.5C3 1.8425 4.3425 0.5 6 0.5C7.6575 0.5 9 1.8425 9 3.5C9 5.1575 7.6575 6.5 6 6.5ZM6 5.5C7.105 5.5 8 4.605 8 3.5C8 2.395 7.105 1.5 6 1.5C4.895 1.5 4 2.395 4 3.5C4 4.605 4.895 5.5 6 5.5ZM9 10.75L7.5305 11.5225L7.811 9.8865L6.6225 8.7275L8.2655 8.4885L9 7L9.735 8.4885L11.3775 8.7275L10.189 9.8865L10.469 11.5225L9 10.75Z" fill="white"/>
                                    </svg>
                                </span>
                                <label htmlFor="label">Delivery by expert.</label>
                            </li>
                            <li className='delivery-list-service'>
                                <span>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.7735 1.94311L6.17974 0.035833C6.06466 -0.0119326 5.93531 -0.0119561 5.82026 0.035833L1.22651 1.94311C1.05155 2.01576 0.9375 2.18658 0.9375 2.37602V4.69458C0.9375 7.88707 2.86709 10.7588 5.82286 11.9652C5.93639 12.0116 6.06359 12.0116 6.17714 11.9652C9.13287 10.7588 11.0625 7.88709 11.0625 4.69458V2.37602C11.0625 2.18658 10.9485 2.01576 10.7735 1.94311ZM10.125 4.69458C10.125 7.40852 8.53125 9.90689 6 11.0221C3.53639 9.93668 1.875 7.48207 1.875 4.69458V2.68896L6 0.976286L10.125 2.68896V4.69458Z" fill="white"/>
                                        <path d="M5.50498 6.34001L4.37998 5.34L3.75 5.89999L5.505 7.5L8.25 5.05999L7.62002 4.5L5.50498 6.34001Z" fill="white"/>
                                    </svg>
                                </span>
                                <label htmlFor="label">Tempered Glass Installation at home</label>
                            </li>
                            <li className='delivery-list-service'>
                                <span>
                                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8745 3.41118L8.87449 0.411123C8.70709 0.243776 8.43573 0.243827 8.26838 0.411224C8.18805 0.491582 8.14292 0.600518 8.1429 0.71415V1.99989H5.14284C4.90614 1.99989 4.71426 2.19177 4.71426 2.42847C4.71426 2.66518 4.90614 2.85706 5.14284 2.85706H8.57148C8.80819 2.85706 9.00007 2.66518 9.00007 2.42847V1.74872L10.9655 3.71418L9.00007 5.67965V4.99992C9.00007 4.76321 8.80819 4.57133 8.57148 4.57133H3.85711V3.2856C3.85706 3.04889 3.66513 2.85706 3.42845 2.85711C3.31484 2.85714 3.20588 2.90226 3.12552 2.98259L0.125491 5.98265C-0.0418303 6.15002 -0.0418303 6.42131 0.125491 6.58866L3.12555 9.58872C3.2059 9.6691 3.31489 9.71428 3.42855 9.7143C3.48487 9.71448 3.5407 9.7034 3.59268 9.68173C3.75279 9.61533 3.85716 9.45906 3.85711 9.28571V7.99998H6.85716C7.09387 7.99998 7.28575 7.8081 7.28575 7.57139C7.28575 7.33468 7.09387 7.1428 6.85716 7.1428H3.42855C3.19184 7.1428 2.99996 7.33468 2.99996 7.57139V8.25112L1.0345 6.28565L2.99996 4.32019V4.99992C2.99996 5.23662 3.19184 5.42851 3.42855 5.42851H8.1429V6.71424C8.14295 6.95095 8.33488 7.14278 8.57156 7.14273C8.68517 7.1427 8.79413 7.09758 8.87449 7.01724L11.8745 4.01718C12.0418 3.84981 12.0418 3.57853 11.8745 3.41118Z" fill="white"/>
                                    </svg>
                                </span>
                                <label htmlFor="label">Data Transfer at home</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='address-este__delivery'>
                    <div className='address-este____media'>
                        <img src={Headphones} className='img img-fluid' alt='' />
                    </div>
                    <div className='address-este__details'>
                        <h5>boAt Airdopes 131 Bluetooth Headset</h5>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3332 5.33337H13.3332L15.3332 8.03737V12H13.9765C13.8973 12.5562 13.62 13.0652 13.1956 13.4333C12.7713 13.8015 12.2283 14.0042 11.6665 14.0042C11.1047 14.0042 10.5617 13.8015 10.1374 13.4333C9.71303 13.0652 9.43576 12.5562 9.3565 12H5.9765C5.89725 12.5562 5.61998 13.0652 5.19562 13.4333C4.77127 13.8015 4.22832 14.0042 3.6665 14.0042C3.10469 14.0042 2.56174 13.8015 2.13738 13.4333C1.71303 13.0652 1.43576 12.5562 1.3565 12H0.666504V4.00004C0.666504 3.82323 0.736742 3.65366 0.861766 3.52864C0.98679 3.40361 1.15636 3.33337 1.33317 3.33337H10.6665C10.8433 3.33337 11.0129 3.40361 11.1379 3.52864C11.2629 3.65366 11.3332 3.82323 11.3332 4.00004V5.33337ZM11.3332 6.66671V8.66671H13.9998V8.47671L12.6612 6.66671H11.3332Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-2">Delivery by &nbsp;</span>
                            <span className="label__similarProd">14 Jan, &nbsp;</span>
                            <span className="deliver-type text-uppercase">Free</span>
                        </div>
                    </div>
                </div>

                <div className='address-este__delivery'>
                    <div className='address-este____media'>
                        <img src={PopSocket} className='img img-fluid' alt='' />
                    </div>
                    <div className='address-este__details'>
                        <h5>Zapvi Watercolor gradient Phone grip holder and stand</h5>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3332 5.33337H13.3332L15.3332 8.03737V12H13.9765C13.8973 12.5562 13.62 13.0652 13.1956 13.4333C12.7713 13.8015 12.2283 14.0042 11.6665 14.0042C11.1047 14.0042 10.5617 13.8015 10.1374 13.4333C9.71303 13.0652 9.43576 12.5562 9.3565 12H5.9765C5.89725 12.5562 5.61998 13.0652 5.19562 13.4333C4.77127 13.8015 4.22832 14.0042 3.6665 14.0042C3.10469 14.0042 2.56174 13.8015 2.13738 13.4333C1.71303 13.0652 1.43576 12.5562 1.3565 12H0.666504V4.00004C0.666504 3.82323 0.736742 3.65366 0.861766 3.52864C0.98679 3.40361 1.15636 3.33337 1.33317 3.33337H10.6665C10.8433 3.33337 11.0129 3.40361 11.1379 3.52864C11.2629 3.65366 11.3332 3.82323 11.3332 4.00004V5.33337ZM11.3332 6.66671V8.66671H13.9998V8.47671L12.6612 6.66671H11.3332Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-2">Delivery by &nbsp;</span>
                            <span className="label__similarProd">22 Jan, &nbsp;</span>
                            <span className="deliver-type text-uppercase">Free</span>
                        </div>
                    </div>
                </div>
            </div>
            <button type='button' className='btn btn-dark-custom w-100' disabled>
                <span className='my-1 d-flex justify-content-center'>Checkout</span>
            </button>
            <div className='mar-spacer h-3' />
        </>
    )
}

export default DeliveryEstimates