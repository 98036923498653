export const GET_PRODUCT_INFO = 'GET_PRODUCT_INFO';
export const ERROR_PRODUCT_INFO = 'ERROR_PRODUCT_INFO';
export const GET_PRODUCT_COLOR = 'GET_PRODUCT_COLOR';
export const ERROR_PRODUCT_COLOR = 'ERROR_PRODUCT_COLOR';


export const GET_HOME_INFO = 'GET_HOME_INFO';
export const ERROR_HOME_INFO = 'ERROR_HOME_INFO';
export const GET_HOME_BANNER = 'GET_HOME_BANNER';
export const GET_HOME_CATEGORY = 'GET_HOME_CATEGORY';

export const GET_HOME_PRODUCT_SLIDER_1 = 'GET_HOME_PRODUCT_SLIDER_1';
