import React from 'react';
import Modal from 'react-bootstrap/Modal';

const WhySangeethaModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom items-removed__modal'
            >
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <h2 className='notifyMe-heading mb-4'>Check Pincode</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100 mb-4'>
                        <div className='input-group-address'>
                            <div className='cart-address__wrap'>
                                <input type="number" className='form-control form-control-coupon' placeholder='Enter pin code here'/>
                                <button type='button' className='btn btn-coupon-absolute'>Check</button>
                            </div>
                        </div>
                    </div>                 
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WhySangeethaModal