import React from 'react';
// import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import '../Components/Policy/policy.css'
import ReturnRefund from '../Components/Policy/ReturnRefund';
import TermCondition from '../Components/Policy/TermCondition';
import PrivacyPolicy from '../Components/Policy/PrivacyPolicy';
import Faqs from '../Components/Policy/Faqs';


const PolicyAndTerm = () => {
    return (
        <>
            <div className="mar-spacer"></div>
            <div className='policy-main-wrapper'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className='policymaintabe-wrap'>
                        <div className="term-policy-left">
                            <Nav variant="pills" className="flex-column">
                                <div className="termrelated-heading">
                                Related 
                                </div>
                                <div className="h-1_1"/>
                                <Nav.Item className='term-tab'>
                                    <Nav.Link eventKey="first">Returns and Refunds</Nav.Link>
                                </Nav.Item>
                                <div className='mar-spacer h-2 mb-2'/>
                                <Nav.Item className='term-tab'>
                                    <Nav.Link eventKey="second">Terms and Conditions</Nav.Link>
                                </Nav.Item>
                                <div className='mar-spacer h-2 mb-2'/>

                                <Nav.Item className='term-tab'>
                                    <Nav.Link eventKey="third">Privacy Policy</Nav.Link>
                                </Nav.Item>
                                <div className='mar-spacer h-2 mb-2'/>

                                <Nav.Item className='term-tab'>
                                    <Nav.Link eventKey="fourth">FAQs</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className='term-policy-right'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <ReturnRefund/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <TermCondition/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <PrivacyPolicy/>                                
                                </Tab.Pane>  
                                <Tab.Pane eventKey="fourth">
                                    <Faqs/>                                
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default PolicyAndTerm