import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import Form from 'react-bootstrap/Form';
import Overview from '../Components/Compare/Overview';

import Iphone from '../Images/Compare/1_1.png'
import Samsung from '../Images/Compare/1_2.png';
import '../Components/Compare/Compare.css';

const Compare = () => {
    const [ compareStickyTop, setCompareStickyTop ] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); 

    const handleScroll = (evt) => {
        setCompareStickyTop(evt.target.scrollTop > 1000);
    };
    return (
        <>
            <div className='mar-spacer h-5 sticky-rmv' />
            <div className={`${compareStickyTop ? 'sticky-compare-header' : ''}`}>
                <div className='container'>
                    <div className='compare__heading sticky-rmv'>Compare</div>
                    <div className='mar-spacer h-5 sticky-rmv' />
                    <div className='compare-list__wrap'>
                        <div className='compare-list__row'>
                            <div className='compare-list__col'>
                                <div className='compare-media__wrap'>
                                    <img src={Iphone} className='img-fluid' alt='' />
                                </div>
                                <div className='compare-details__wrap mt-4'>
                                    <div className='compare_title'>Apple iPhone 13 Pro</div>
                                    <div className='compare_info_label'>Blue Void, 128GB, 8GB RAM</div>
                                    <div className='compare_price'>
                                        <div className='compare-new_price'>₹89,999</div>
                                    </div>
                                    <div className='compare_color'>
                                        <span className='compare_color_item' style={{ backgroundColor: '#AFA6DD'}}></span>
                                        <span className='compare_color_item' style={{ backgroundColor: '#A6DADD'}}></span>
                                        <span className='compare_color_item' style={{ backgroundColor: '#433F43'}}></span>
                                    </div>
                                    <div className='product-compare mt-2' aria-hidden="true">Change</div>
                                    <Link to='/ProductDetails' className='btn btn-dark-button w-100 mb-1 sticky-rmv'>View Product</Link>
                                </div>
                            </div>
                            <div className='compare-list__col ml-4'>
                                <div className='compare-media__wrap'>
                                    <img src={Samsung} className='img-fluid' alt='' />
                                </div>
                                <div className='compare-details__wrap mt-4'>
                                    <div className='compare_title'>Samsung Galaxy FoldZ 5G</div>
                                    <div className='compare_info_label'>Black, 128GB, 8GB RAM</div>
                                    <div className='compare_price'>
                                        <div className='compare-new_price'>₹81,999</div>
                                        <div className='compare-old_price'>₹83,559</div>
                                    </div>
                                    <div className='compare_color'>
                                        <span className='compare_color_item' style={{ backgroundColor: '#7990BC'}}></span>
                                        <span className='compare_color_item' style={{ backgroundColor: '#BC7979'}}></span>
                                        <span className='compare_color_item' style={{ backgroundColor: '#FBFCFF'}}></span>
                                        <span className='compare_color_item' style={{ backgroundColor: '#433F43'}}></span>
                                    </div>
                                    <div className='product-compare mt-2' aria-hidden="true">Change</div>
                                    <Link to='/ProductDetails' className='btn btn-dark-button w-100 mb-1 sticky-rmv'>View Product</Link>
                                </div>
                            </div>
                        </div> 
                        <div className='mar-spacer h-5 sticky-rmv' />
                        <div className='shopping-cart__gst'>
                            <p className='compare__heading sticky-add'>Compare</p>
                            <div className='flexible sticky-add' />
                            <Form.Check 
                                type='switch'
                                id='custom-switch'
                                label='Show only difference'
                                className='d-flex align-items-start custom-input-switch'
                            />
                        </div>
                        <div className='mar-spacer h-5 sticky-rmv' />
                    </div>
                </div>
            </div>
            <div className='container'>
                <Overview />
            </div>
        </>
    )
}

export default Compare
