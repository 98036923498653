import React from 'react'
import Carousel from "react-multi-carousel";
import ButtonGroup from '../Common/ButtonGroup';

import { productsData } from '../../Data';
import { Link } from '@reach/router';


const Sale = ({ wishlistActive, handleToggle }) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
            partialVisibilityGutter: 50
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            partialVisibilityGutter: 50
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30
        }
    };
    return (
        <>
            <Carousel  
                ssr              
                partialVisible={true}
                arrows={false}
                itemClass="sale__wrap"
                responsive={responsive}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
                draggable
                slidesToSlide={2}
                minimumTouchDrag={80}
                swipeable
            >   
                {productsData.map((RemommendProduct, idx) => {
                    return (
                        <Link to='/ProductDetails' className='product-carousel-wrapper' key={idx}>
                            {RemommendProduct.label ?
                                <div className='product-label-tag'>{RemommendProduct.label}</div> : ''
                            }
                            <button 
                                type='button' 
                                className={wishlistActive ? 'product-wishlist Active' : 'product-wishlist Inactive'}
                                onClick={handleToggle} 
                                aria-hidden="true" 
                            >
                                {wishlistActive ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.35164 10.6616C1.55948 9.73785 1.10148 8.57421 1.05146 7.35831C1.00143 6.14241 1.36231 4.94509 2.07592 3.95935C2.78952 2.9736 3.81427 2.25688 4.98499 1.9247C6.1557 1.59252 7.40417 1.66424 8.52914 2.12831H8.52748L4.99247 5.66498L6.17081 6.84331L9.70581 3.30831C10.7216 2.29242 12.0993 1.72166 13.5359 1.72158C14.9725 1.7215 16.3503 2.29211 17.3662 3.30789C18.3821 4.32367 18.9529 5.70141 18.953 7.13802C18.953 8.57462 18.3824 9.95242 17.3666 10.9683L10.0008 18.3333L2.34081 10.6733L2.35164 10.6616Z" fill="#E25100"/>
                                    </svg> :
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.8695 3.96415C18.7545 5.85415 18.8195 8.86415 17.0662 10.8275L9.9995 17.9042L2.9345 10.8275C1.18116 8.86415 1.247 5.84915 3.13116 3.96415C5.01866 2.07748 8.03783 2.01415 10.0012 3.77415C11.9587 2.01665 14.9837 2.07498 16.8695 3.96415ZM4.31033 5.14332C3.06866 6.38498 3.00616 8.37248 4.15033 9.68582L10.0003 15.545L15.8503 9.68665C16.9953 8.37248 16.9328 6.38748 15.6895 5.14165C14.4503 3.89998 12.4553 3.83998 11.1453 4.98665L7.64366 8.48915L6.4645 7.31082L8.81866 4.95498L8.75033 4.89748C7.43783 3.84332 5.5195 3.93332 4.31033 5.14332Z" fill="#6B7586"/>
                                    </svg>
                                }
                            </button>
                            <div className='carousel-media-wrapper d-flex justify-content-center'>
                                <img
                                    src={RemommendProduct.Image}
                                    alt={RemommendProduct.productName}
                                    className='img-fluid'
                                />
                            </div>
                            <div className='carousel-content-wrapper'>
                                <div className='carousel-product-brand mb-1'>{RemommendProduct.prductBrand}</div>
                                <div className='carousel-product-title mb-3'>{RemommendProduct.productName}</div>  
                                <div className='flexible'></div>
                                {RemommendProduct.productStorage.productColor ||  RemommendProduct.productStorage.ram || RemommendProduct.productStorage.Storage ?
                                    <div className='carousel-product-specifications mb-3'>
                                        {RemommendProduct.productColor ? 
                                            (<div className='carousel-specification'>
                                                <label htmlFor="label">Color</label>
                                                <span>{RemommendProduct.productColor}</span>
                                            </div>) : null
                                        }
                                        {RemommendProduct.productStorage.ram ?
                                            (<div className='carousel-specification'>
                                                <label htmlFor="label">RAM</label>
                                                <span>{RemommendProduct.productStorage.ram}</span>
                                            </div>) : null
                                        }   
                                        {RemommendProduct.productStorage.Storage ?                                         
                                            (<div className='carousel-specification'>
                                                <label htmlFor="label">Storage</label>
                                                <span>{RemommendProduct.productStorage.Storage}</span>
                                            </div>) : null
                                        }
                                    </div> : null
                                }
                                <div className='flexible'></div>
                                <div className="price-offer">
                                    <div className="new-price">₹43,659</div>
                                    <div className="old-price">₹45,659</div>
                                </div>
                                <button type='button' className='btn btn-outline w-100 mb-1'>Add to cart</button>
                            </div>
                        </Link>
                    );
                })}
            </Carousel>
        </>
    )
}

export default Sale;
