import React from 'react'

const SaveAddress = () => {
    return (
        <>
            <div className="Profileordertab_wrapper">
                <div className="profileorder-div">
                    <div className='order-divleft-box'>
                        <div className="order-l-img">
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg"><path d="M9.42564 14.9482C7.69714 14.7343 6.11724 13.8883 5.00706 12.5822C3.89688 11.276 3.33972 9.60778 3.44883 7.91648C3.55794 6.22518 4.32513 4.63775 5.59447 3.47682C6.86382 2.3159 8.54005 1.66861 10.2825 1.66651C12.0271 1.66524 13.7065 2.31095 14.9786 3.47207C16.2506 4.63319 17.0196 6.22233 17.1289 7.9157C17.2381 9.60908 16.6794 11.2792 15.5665 12.5859C14.4536 13.8925 12.8704 14.7373 11.1393 14.9482V16.6765C14.5204 16.7532 17.1372 17.279 17.1372 17.9165C17.1372 18.6065 14.068 19.1665 10.2825 19.1665C6.49695 19.1665 3.42773 18.6065 3.42773 17.9165C3.42773 17.279 6.04454 16.7532 9.42564 16.6765V14.9482ZM10.2825 13.3332C11.646 13.3332 12.9536 12.8064 13.9178 11.8687C14.8819 10.931 15.4236 9.65926 15.4236 8.33317C15.4236 7.00709 14.8819 5.73532 13.9178 4.79764C12.9536 3.85996 11.646 3.33317 10.2825 3.33317C8.91899 3.33317 7.61134 3.85996 6.64721 4.79764C5.68307 5.73532 5.14142 7.00709 5.14142 8.33317C5.14142 9.65926 5.68307 10.931 6.64721 11.8687C7.61134 12.8064 8.91899 13.3332 10.2825 13.3332ZM10.2825 9.99984C9.82799 9.99984 9.39211 9.82424 9.07073 9.51168C8.74935 9.19912 8.5688 8.7752 8.5688 8.33317C8.5688 7.89114 8.74935 7.46722 9.07073 7.15466C9.39211 6.8421 9.82799 6.66651 10.2825 6.66651C10.737 6.66651 11.1729 6.8421 11.4942 7.15466C11.8156 7.46722 11.9962 7.89114 11.9962 8.33317C11.9962 8.7752 11.8156 9.19912 11.4942 9.51168C11.1729 9.82424 10.737 9.99984 10.2825 9.99984Z" fill="#6B7586"/></svg>
                            </span>
                        </div>
                        <div className="order-profilemid-container">
                            <h4>Saved Address</h4>
                            <span>History of all your past orders </span>
                        </div>

                    </div>
                    
                    <div className="profileorder-arrow">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13.172 12.0002L8.222 7.05023L9.636 5.63623L16 12.0002L9.636 18.3642L8.222 16.9502L13.172 12.0002Z" fill="#fff"/></svg>
                        </span>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default SaveAddress