import React from 'react';
import Modal from 'react-bootstrap/Modal';

import OrderSuccess from '../../Images/Order/OrderSuccess.png'

const OrderSuccessModal = (props) => {	
    
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom modal-nfm-custom'
            >
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100' />
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center'>
                        <div className='mar-spacer h-4' />
                        <img src={OrderSuccess} className='img-fluid' alt='' />

                        <div className='order-success-info text-center'>
                            <h3 className='mb-2'>Order Successful</h3>
                            <p className='mb-2'>Happiness arriving at your doorstep soon!</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderSuccessModal