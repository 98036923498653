import React from 'react';
import '../Components/Blog/Blog.css';
import Blogsearch from '../Components/Blog/BlogInnerContent/Blogsearch';
import BlogShort from '../Components/Blog/BlogInnerContent/BlogShort';
import BlogTabs from '../Components/Blog/BlogTabs'

const Blog = () => {
    return (
        <>
            <div className="blog_wrapper">
                <div className="container">
                    <div className='store-locator__container'>
                        <div className='mar-spacer h-2 mb-2' />
                        <div className='top-head__wrap blog-top--flex'>
                            <h4 className='brand-heading'>Sangeetha Blogs</h4>
                            <div className="blog_right-search-sort">
                                <Blogsearch/>

                                <BlogShort/>
                            </div>               
                        </div>   
                        <div className="blog-categoary_tab">
                            <BlogTabs/>
                        </div>
                        
             
                    
                    </div>

                </div>
                
            </div>
	
        </>
    )
}

export default Blog