import { Link } from '@reach/router';
import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CartImg from '../../Images/Product/cart.png';
import { FaCheckCircle } from 'react-icons/fa';

const CartPanel = () => {
    return (
        <>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Shopping Cart</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className='Cart-details__wrapper d-flex w-100'>
                    <div className='pdp-cart__media'>
                        <img src={CartImg} className='img-fluid' alt='' />
                    </div>
                    <div className='pdp-cart__details'>
                        <h6 className='cart-pdp__heading mb-0'>Samsung Galaxy Z Fold 2 5G</h6>
                        <p className='cart-pdp__infoText'>Mystic Purple, 256GB + 8GB</p>
                        <div className='cart-qty__wrapper'>
                            <div className='cart-qty__count'>Qty : 1</div>
                        </div>
                        <div className="product-price mb-2">
                            <div className="new-price">₹43658</div>
                            <div className="old-price">₹45659</div>
                        </div>
                        <div className='product-offer-list pdp-cart__delivery mt-1'>
                            <div className='offer-icon'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 8H20L23 12.056V18H20.965C20.8461 18.8343 20.4302 19.5977 19.7937 20.1499C19.1571 20.7022 18.3427 21.0063 17.5 21.0063C16.6573 21.0063 15.8429 20.7022 15.2063 20.1499C14.5698 19.5977 14.1539 18.8343 14.035 18H8.965C8.84612 18.8343 8.43021 19.5977 7.79368 20.1499C7.15714 20.7022 6.34272 21.0063 5.5 21.0063C4.65728 21.0063 3.84286 20.7022 3.20632 20.1499C2.56979 19.5977 2.15388 18.8343 2.035 18H1V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H16C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6V8ZM17 10V13H21V12.715L18.992 10H17Z" fill="#C4CAD0"/>
                                </svg>
                            </div>
                            <div className='details-text'>Delivery in 2 Hrs. <span className=''>FREE</span></div>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-2' />
                <div className='addToCart__wrap'>
                    <div className='addToCart__section'>
                        <div className='addToCart__list'>
                            <div className='product-label-tag addToCart-label-tag'>Most Popular</div>
                            <div className='addToCart__head'>Money and Damage Protection (MAD) </div>
                            <ul className='addToCart__li'>
                                <li><span><FaCheckCircle /></span> Free repairs - just walk in at any Sangeetha store!</li>
                                <li><span><FaCheckCircle /></span> Replacement of damaged SKU at 50% cost</li>
                                <li><span><FaCheckCircle /></span> Full refund in case of no claims</li>
                            </ul>
                            <Link to='/ProtectionPlan' className='addToCart__link'>Know More</Link>
                            <div className='cart-horizontal-line m-0'></div>
                            <div className='addToCart-footer'>
                                <div className="addToCart-price">₹999</div>
                                <button type='button' className='btn btn-add-device'>Add</button>
                            </div>
                        </div>
                        <div className='addToCart__list'>
                            <div className='addToCart__head'>Price Protection Plan</div>
                            <ul className='addToCart__li'>
                                <li><span><FaCheckCircle /></span> Bought a phone today and price dropped after? We refund you the difference.</li>
                                <li><span><FaCheckCircle /></span> No questions asked redeem in store or online</li>
                            </ul>
                            <Link to='/ProtectionPlan' className='addToCart__link'>Know More</Link>
                            <div className='cart-horizontal-line m-0'></div>
                            <div className='addToCart-footer'>
                                <div className="addToCart-price">₹599</div>
                                <button type='button' className='btn btn-add-device'>Add</button>
                            </div>
                        </div>
                        <div className='addToCart__list'>
                            <div className='addToCart__head'>Assured Buyback</div>
                            <ul className='addToCart__li'>
                                <li><span><FaCheckCircle /></span> Get assured money back on your next puchase at Sangeetha</li>
                            </ul>
                            <Link to='/ProtectionPlan' className='addToCart__link'>Know More</Link>
                            <div className='cart-horizontal-line m-0'></div>
                            <div className='addToCart-footer'>
                                <div className="addToCart-price">₹599</div>
                                <button type='button' className='btn btn-add-device'>Add</button>
                            </div>
                        </div>
                        <div className="flexible"></div>
                    </div>
                </div>
            </Offcanvas.Body>
            <div className='cart-footer'>
                <Link to='/Cart' className='btn btn-dark-custom-outline w-100 mb-3'>Continue Shopping</Link>
                <Link to='/Cart' className='btn btn-dark-custom w-100'>View Cart (1)</Link>
            </div>
        </>
    )
}

export default CartPanel