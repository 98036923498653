import React, { useState } from 'react';
import CategoryBanner from '../Components/CategoryList/CategoryBanner';
import Categories from '../Components/CategoryList/Categories';
import CategoryCarousel from '../Components/CategoryList/CategoryCarousel';
import CategoryProduct from '../Components/CategoryList/CategoryProduct';
import BestGaming from '../Components/CategoryList/BestGaming';
import CategoryOffer from '../Components/CategoryList/CategoryOffer';
import PhoneOffersTab from '../Components/CategoryList/PhoneOffersTab';
import CategoryProductList from '../Components/CategoryList/CategoryProductList';

import '../Components/CategoryList/Category.css';
import offerBanner from '../Images/Home/offerBanner.jpg';
import offerBanner1 from '../Images/Brand/Banner.jpg'
import { Link } from '@reach/router';

const CategoryList = () => {
    const [isWishlist] = useState(true);
    return (
        <>
            <CategoryBanner />
            <div className='mar-spacer h-3' />
            <section className="brand__gallery category__padding">
                <div className='container'>
                    <CategoryCarousel />
                </div>
            </section>
            <section className="category__wrapper category__padding">
                <div className='container'>
                    <div className='category-head__wrap py-0'>
                        <h4 className="category-heading__wrap mb-0">Choose from Popular Brands</h4>
                    </div>
                    <div className='mar-spacer h-2 mb-2' />
                    <Categories />
                </div>
            </section>
            <section className="Model__wrapper category__padding">
                <div className='container'>
                    <div className='Model-head__wrap'>
                        <h4 className="Model-heading__wrap mb-0">New Launches</h4>
                    </div>
                    <CategoryProduct />
                </div>
            </section>
            <section className="Model__wrapper category__padding">
                <div className='container'>
                    <div className='position-relative category-brand-slider'>
                        <div className='Model-head__wrap'>
                            <h4 className="Model-heading__wrap mb-0">Best Gaming Phones</h4>
                        </div>
                        <BestGaming />
                    </div>
                </div>
            </section>
            <section className="section__wrapper category__padding">
                <div className='container d-flex'>
                    <Link to='/ProductDetails' className='offer-banner spacing-block position-relative'>
                        <img src={offerBanner} className='img-fluid w-100' alt='' />
                    </Link>
                </div>
            </section>
            <section className="Model__wrapper category__padding">
                <div className='container'>
                    <div className='position-relative category-brand-slider'>
                        <div className='Model-head__wrap'>
                            <h4 className="Model-heading__wrap mb-0">Best Gaming Phones</h4>
                        </div>
                        <CategoryOffer />
                    </div>
                </div>
            </section>
            <section className="section__wrapper category__padding">
                <div className='container d-flex'>
                    <Link to='/ProductDetails' className='offer-banner spacing-block position-relative'>
                        <img src={offerBanner1} className='img-fluid w-100' alt='' />
                    </Link>
                </div>
            </section>
            <section className="category__wrapper category__padding">
                <div className='container'>
                    <div className='category-head__wrap py-0'>
                        <h4 className="category-heading__wrap mb-0">Phones for You</h4>
                    </div>
                    <div className='mar-spacer h-1_5' />
                    <PhoneOffersTab />
                </div>
            </section>
            <section className="diy-home__wrapper diy-category__wrapper">
                <div className='container'>
                    <div className='suggestion-section'>
                        <h3 className='suggestion-heading mb-2'>Let us help you find your perfect phone</h3>
                        <p className='suggestion-info'>Select upto 3 activities you use your phone for</p>
                        <div className='btn-suggestion__wrap'>
                            <Link to='/' className='btn-suggestion btn-right-space active'>Performance</Link>
                            <Link to='/' className='btn-suggestion btn-right-space active'>Gaming</Link>
                            <Link to='/' className='btn-suggestion btn-right-space'>Photography</Link><br/>
                            <Link to='/' className='btn-suggestion btn-right-space'>Music</Link>
                            <Link to='/' className='btn-suggestion btn-right-space'>Mails</Link>
                            <Link to='/' className='btn-suggestion btn-right-space'>Online Classes</Link><br/>
                            <Link to='/' className='btn-suggestion btn-right-space'>Entertainment</Link>
                        </div>
                        <button type='button' className='btn btn-dark-custom btn__spacer-40'>Let’s Go!</button>
                    </div>
                </div>
            </section>
            <div className='mar-spacer h-5' />
            <section className="Model-list__wrapper category__padding">
                <div className='container'>
                    <div className='ModelList-head__wrap'>
                        <h4 className="ModelList-heading__wrap mb-0">Curated for you</h4>
                    </div>
                    <CategoryProductList isWishlist={isWishlist} />
                    <div className='mar-spacer h-1' />
                    <div className='d-flex align-items-center justify-content-center'>
                        <Link to='/ProductList' className='btn btn-dark-custom btn__spacer-60'>Show All (2,129)</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CategoryList