import React from 'react';
import { OffersList } from '../../Data'; 

const BankOffer = () => {
    return (
        <>
            <div className='bank-offer-wrapper emi-tab-content'>
                <div className='product-payment-offers'>  
                    {OffersList.map((OffersItems, idx) => {                      
                        return(
                            <div to='/BankOffersDetails' className='payment-offer-options' key={idx}>
                                <div className='payment-offer-content'>
                                    <div className='payment-offer-icon'>
                                        <span>
                                            <img src={OffersItems.Image} className='img im-fluid' alt='' />
                                        </span>
                                    </div>
                                    <div className='payment-offer-info'>
                                        <p className='payment-offer-details'>{OffersItems.Text}</p>
                                    </div>
                                </div>
                                <div className='highlight-body__link ml-2' role='button' aria-hidden="true">
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.4767 10.0006L7.35168 5.8756L8.53002 4.69727L13.8334 10.0006L8.53002 15.3039L7.35168 14.1256L11.4767 10.0006Z" fill="#121415"/>
                                    </svg>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default BankOffer