import { Link } from '@reach/router';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const MenuAccordion = () => {
    const [activeId, setActiveId] = useState();

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <>
            <Accordion className='footer-menu__accordion w-100' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                        <div className='payment-card__head m-0'>
                            <span>Sangeetha</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category active'>Why Sangeetha</Link>
                            <Link to='/' className='item-links__category'>Terms and Conditions </Link>
                            <Link to='/' className='item-links__category'>Privacy Policy</Link>
                            <Link to='/' className='item-links__category'>Returns and Refunds</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion className='footer-menu__accordion w-100' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                        <div className='payment-card__head m-0'>
                            <span>Shop By </span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/Categories' className='item-links__category active'>Categories</Link>
                            <Link to='/Brands ' className='item-links__category'>Brands </Link>
                            <Link to='/StoreLocator' className='item-links__category'>Find A Store</Link>
                            <Link to='/' className='item-links__category'>Home</Link>
                            <Link to='/' className='item-links__category'>New Arrivals</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion className='footer-menu__accordion w-100' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                        <div className='payment-card__head m-0'>
                            <span>Help</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <Link to='/' className='item-links__category active'>My Orders</Link>
                            <Link to='/Faq' className='item-links__category'>FAQ</Link>
                            <Link to='/Return' className='item-links__category'>Returns</Link>
                            <Link to='/PrivacyPolicy' className='item-links__category'>PrivacyPolicy</Link>
                            <Link to='/TermCondition' className='item-links__category'>Terms & Conditions</Link>

                            <Link to='/' className='item-links__category'>Payments</Link>
                            <Link to='/' className='item-links__category'>Customer Care</Link>
                            <Link to='Account/MyCoupons' className='item-links__category'>Coupons</Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default MenuAccordion