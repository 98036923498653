import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import { Dropdown, Nav } from 'react-bootstrap';
import HeaderSearch from './HeaderSearch';
import logo from '../../Images/sangeethaLogo.svg';
import user from '../../Images/user.svg';
import banner from '../../Images/navigation/offer-banner.jpg'
import './header.css'
import { Link } from '@reach/router';
import Login from '../Login/Index';

const Index = () => {
    const [ isUser ] = useState(false);
    const [ CartAdded ] = useState(true);
    const [ WishlistAdded ] = useState(true);
    const [modalShow, setModalShow] = useState(false);
	
    return (
        <>
            <Navbar collapseOnSelect expand="lg" className='navbar-dark header-top'  variant="dark" sticky='top'>
                <div className='container'>
                    <div className='header-navigation'>
                        <Link to='/' className='navbar-brand p-0' aria-label="logo">
                            <img src={logo} className='img-fluid' alt='' height='36' width='128' />
                        </Link>
                        <Navbar className='nav-menu__wrap'>
                            <Nav className='me-auto align-items-center'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="warning" id="dropdown-mega-menu" className='mega-menu__dropdown py-3'>
                                        <span>All Categories</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className='menu__wrapper'>
                                            <div className='mega-menu-heading mt-2'>popular Categories</div>
                                            <ul className='cd-dropdown-content'>
                                                <li className="mega-menu-li has-menu brands-menu">
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Top Brands</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                    <ul className='secondary-menu'>
                                                        <div className='secondary-menu__wrap'>
                                                            <div className='secondary-menu__flex'>
                                                                <ul className='secondary-menu__section'>
                                                                    <li className='secondary-menu-link'>Apple</li>
                                                                    <li className='secondary-menu-link'>Bajaj</li>
                                                                    <li className='secondary-menu-link'>Oppo</li>
                                                                    <li className='secondary-menu-link'>Panasonic</li>
                                                                    <li className='secondary-menu-link'>Blue Star</li>
                                                                    <li className='secondary-menu-link'>Phillips</li>
                                                                    <li className='secondary-menu-link'>Boat</li>
                                                                    <li className='secondary-menu-link'>Realme</li>
                                                                    <li className='secondary-menu-link'>Bosch</li>
                                                                    <li className='secondary-menu-link'>Samsung</li>
                                                                    <li className='secondary-menu-link'>Canon</li>
                                                                    <li className='secondary-menu-link'>Skullcandy</li>
                                                                    <li className='secondary-menu-link'>Daikin</li>
                                                                    <li className='secondary-menu-link'>Xiaomi</li>
                                                                    <li className='secondary-menu-link'>Dell</li>
                                                                    <li className='secondary-menu-link'>Eleon</li>
                                                                    <li className='secondary-menu-link'>Faber</li>
                                                                    <li className='secondary-menu-link'>Haier</li>
                                                                    <li className='secondary-menu-link'>Havells</li>
                                                                    <li className='secondary-menu-link'>Hitachi</li>
                                                                    <li className='secondary-menu-link'>LG</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </li>
                                                <li className="mega-menu-li has-menu">
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Best Deals</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                    <ul className='secondary-menu'>
                                                        <div className='secondary-menu__wrap'>
                                                            <div className='secondary-menu__flex'>
                                                                <ul className='secondary-menu__section'>
                                                                    <li className='secondary-menu-link'>Exchange Offers</li>
                                                                    <li className='secondary-menu-link'>Clearance Sale</li>
                                                                    <li className='secondary-menu-link'>Flash Picks</li>
                                                                    <li className='secondary-menu-link'>Cashbacks</li>
                                                                    <li className='secondary-menu-link'>Upto 25% Off</li>
                                                                    <li className='secondary-menu-link'>Upto 50% Off</li>
                                                                    <li className='secondary-menu-link'>Trending</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </li>
                                                <li className='mega-menu-li has-menu has-offers'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Smart Phone</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                    <ul className='secondary-menu'>
                                                        <div className='secondary-menu__wrap'>
                                                            <div className='secondary-menu__flex'>
                                                                <ul className='secondary-menu__section'>
                                                                    <li className='secondary-menu-heading'>Top Brands</li>
                                                                    <li className='secondary-menu-link'>Apple</li>
                                                                    <li className='secondary-menu-link'>Asus</li>
                                                                    <li className='secondary-menu-link'>MI</li>
                                                                    <li className='secondary-menu-link'>Realme</li>
                                                                    <li className='secondary-menu-link'>Samsung</li>
                                                                    <li className='secondary-menu-link'>Vivo</li>
                                                                    <li className='secondary-menu-link'>Infinix</li>
                                                                    <li className='secondary-menu-link'>Oneplus</li>
                                                                    <li className='secondary-menu-heading'>Shop by Feature</li>
                                                                    <li className='secondary-menu-link'>5G Phones</li>
                                                                    <li className='secondary-menu-link'>Gaming Phones</li>
                                                                    <li className='secondary-menu-link'>
                                                                        <Link to='/' className='secondary-menu-link__item'>Selfies Phones</Link>
                                                                    </li>
                                                                    <li className='secondary-menu-link'>
                                                                        <Link to='/' className='secondary-menu-link__item'>Long Battery Life</Link>
                                                                    </li>
                                                                </ul>
                                                                <ul className='secondary-menu__section'>
                                                                    <li className='secondary-menu-heading'>Shop by Price</li>
                                                                    <li className='secondary-menu-link'>Under ₹10,000</li>
                                                                    <li className='secondary-menu-link'>₹10,000 - ₹15,000</li>
                                                                    <li className='secondary-menu-link'>₹15,000  -  ₹20,000</li>
                                                                    <li className='secondary-menu-link'>₹20,000 - ₹30,000</li>
                                                                    <li className='secondary-menu-link'>₹30,000 - ₹40,000</li>
                                                                    <li className='secondary-menu-link'>₹40,000 - ₹50,000</li>
                                                                    <li className='secondary-menu-link'>Above ₹50,001</li>
                                                                    <li className='secondary-menu-heading'>Top Deals</li>
                                                                    <li className='secondary-menu-link'>New Launches</li>
                                                                    <li className='secondary-menu-link'>Cashback offers</li>
                                                                    <li className='secondary-menu-link'>Exchange offer</li>
                                                                    <li className='secondary-menu-link'>
                                                                        <Link to='/' className='secondary-menu-link__item'>Long Battery Life</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className='secondary-menu__offers'>
                                                                <div className='secondary-menu-heading'>Deal of the day!</div>
                                                                <img src={banner} className='img-fluid w-100' alt='' />
                                                                <div className='menu-offer-details mt-3'>
                                                                    <h3 className='mb-0'>Oneplus Nord 2 5G</h3>
                                                                    <div className='banner-price'>
                                                                        <span className='price-new'>₹70,559</span>
                                                                        <span className='price-old ml-2'>₹78,559</span>
                                                                    </div>
                                                                    <Link to='/' className='btn btn-outline w-100'>Buy Now</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </li>
                                                <li className='mega-menu-li has-menu'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Laptops</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                    <ul className='secondary-menu'>
                                                        <div className='secondary-menu__wrap'>
                                                            <div className='secondary-menu__flex'>
                                                                <ul className='secondary-menu__section'>
                                                                    <li className='secondary-menu-heading'>Top Brands</li>
                                                                    <li className='secondary-menu-link'>Apple</li>
                                                                </ul>
                                                                <ul className='secondary-menu__section'>
                                                                    <li className='secondary-menu-heading'>Top Brands</li>
                                                                    <li className='secondary-menu-link'>Apple</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </li>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Watches</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Earphones & Headphones</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>TV</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Camera</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Fitness</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                            <div className='mega-menu-heading'>popular Categories</div>
                                            <ul className='cd-dropdown-content'>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Air Quality Managers</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Air Quality Managers</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                                <li className='mega-menu-li'>
                                                    <Link to='/' className='menu-list-wrap' aria-label="menu">
                                                        <span>Air Quality Managers</span>
                                                        <span className='is-hidden'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75733L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <HeaderSearch />
                            </Nav>
                            <Nav className='align-items-center justify-content-start'>
                                <Nav.Link href="/" aria-label="Wishlist">
                                    {WishlistAdded ? 
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.82109 12.7942C1.8705 11.6856 1.32089 10.2892 1.26087 8.83017C1.20084 7.37109 1.63389 5.9343 2.49022 4.75141C3.34654 3.56852 4.57625 2.70845 5.98111 2.30983C7.38596 1.91122 8.88413 1.99729 10.2341 2.55417H10.2321L5.99009 6.79817L7.40409 8.21217L11.6461 3.97017C12.865 2.7511 14.5183 2.06618 16.2422 2.06609C17.9662 2.06599 19.6195 2.75073 20.8386 3.96967C22.0577 5.1886 22.7426 6.84188 22.7427 8.56581C22.7428 10.2897 22.058 11.9431 20.8391 13.1622L12.0001 22.0002L2.80809 12.8082L2.82109 12.7942Z" fill="#EAECF0"/>
                                            </svg>
                                            <span className='item-added'>4</span>
                                        </span> :
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.1792 12.794L21.1922 12.808L12.0002 22L2.80821 12.808L2.82121 12.794C1.75378 11.5495 1.19605 9.94767 1.2598 8.30938C1.32355 6.67108 2.00406 5.11739 3.16497 3.95965C4.32587 2.80191 5.88141 2.12564 7.51987 2.06636C9.15834 2.00708 10.7587 2.56917 12.0002 3.63999C13.2417 2.56917 14.8421 2.00708 16.4806 2.06636C18.119 2.12564 19.6746 2.80191 20.8355 3.95965C21.9964 5.11739 22.6769 6.67108 22.7406 8.30938C22.8044 9.94767 22.2466 11.5495 21.1792 12.794V12.794ZM4.57521 5.38299C4.15734 5.80086 3.82587 6.29693 3.59972 6.8429C3.37357 7.38887 3.25717 7.97404 3.25717 8.56499C3.25717 9.15594 3.37357 9.74111 3.59972 10.2871C3.82587 10.833 4.15734 11.3291 4.57521 11.747L12.0002 19.172L19.4252 11.747C20.2691 10.9031 20.7432 9.75847 20.7432 8.56499C20.7432 7.37151 20.2691 6.22691 19.4252 5.38299C18.5813 4.53907 17.4367 4.06496 16.2432 4.06496C15.0497 4.06496 13.9051 4.53907 13.0612 5.38299L8.81821 9.62599L7.40421 8.20999L10.5662 5.04799C9.70143 4.35733 8.61246 4.00957 7.50743 4.07118C6.40241 4.1328 5.35885 4.59947 4.57621 5.38199L4.57521 5.38299Z" fill="#EAECF0"/>
                                        </svg>
                                    }
                                </Nav.Link>
                                <Nav.Link href="/Cart" aria-label="Cart">
                                    {CartAdded ?
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 9H19.938L20.438 7H8V5H21.72C21.872 5 22.022 5.03466 22.1586 5.10134C22.2952 5.16801 22.4148 5.26495 22.5083 5.38479C22.6019 5.50462 22.6668 5.6442 22.6983 5.79291C22.7298 5.94162 22.7269 6.09555 22.69 6.243L20.19 16.243C20.1358 16.4592 20.011 16.6512 19.8352 16.7883C19.6595 16.9255 19.4429 17 19.22 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V4H2V2H5C5.26522 2 5.51957 2.10536 5.70711 2.29289C5.89464 2.48043 6 2.73478 6 3V9ZM6 23C5.46957 23 4.96086 22.7893 4.58579 22.4142C4.21071 22.0391 4 21.5304 4 21C4 20.4696 4.21071 19.9609 4.58579 19.5858C4.96086 19.2107 5.46957 19 6 19C6.53043 19 7.03914 19.2107 7.41421 19.5858C7.78929 19.9609 8 20.4696 8 21C8 21.5304 7.78929 22.0391 7.41421 22.4142C7.03914 22.7893 6.53043 23 6 23ZM18 23C17.4696 23 16.9609 22.7893 16.5858 22.4142C16.2107 22.0391 16 21.5304 16 21C16 20.4696 16.2107 19.9609 16.5858 19.5858C16.9609 19.2107 17.4696 19 18 19C18.5304 19 19.0391 19.2107 19.4142 19.5858C19.7893 19.9609 20 20.4696 20 21C20 21.5304 19.7893 22.0391 19.4142 22.4142C19.0391 22.7893 18.5304 23 18 23Z" fill="#EAECF0"/>
                                            </svg>
                                            <span className='item-added'>4</span>
                                        </span> :
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 16V4H2V2H5C5.26522 2 5.51957 2.10536 5.70711 2.29289C5.89464 2.48043 6 2.73478 6 3V15H18.438L20.438 7H8V5H21.72C21.872 5 22.022 5.03466 22.1586 5.10134C22.2952 5.16801 22.4148 5.26495 22.5083 5.38479C22.6019 5.50462 22.6668 5.6442 22.6983 5.79291C22.7298 5.94162 22.7269 6.09555 22.69 6.243L20.19 16.243C20.1358 16.4592 20.011 16.6512 19.8352 16.7883C19.6595 16.9255 19.4429 17 19.22 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16ZM6 23C5.46957 23 4.96086 22.7893 4.58579 22.4142C4.21071 22.0391 4 21.5304 4 21C4 20.4696 4.21071 19.9609 4.58579 19.5858C4.96086 19.2107 5.46957 19 6 19C6.53043 19 7.03914 19.2107 7.41421 19.5858C7.78929 19.9609 8 20.4696 8 21C8 21.5304 7.78929 22.0391 7.41421 22.4142C7.03914 22.7893 6.53043 23 6 23ZM18 23C17.4696 23 16.9609 22.7893 16.5858 22.4142C16.2107 22.0391 16 21.5304 16 21C16 20.4696 16.2107 19.9609 16.5858 19.5858C16.9609 19.2107 17.4696 19 18 19C18.5304 19 19.0391 19.2107 19.4142 19.5858C19.7893 19.9609 20 20.4696 20 21C20 21.5304 19.7893 22.0391 19.4142 22.4142C19.0391 22.7893 18.5304 23 18 23Z" fill="#EAECF0"/>
                                        </svg>
                                    }
                                </Nav.Link>
                                {isUser ?
                                    <Dropdown>
                                        <Dropdown.Toggle 
                                            variant="success" 
                                            id="dropdown-basic" 
                                            className='login-dropdown__wrap login-dropdown' 
                                            type="button"
                                            aria-label="Profile"
                                        >
                                            <img src={user} className='img-fluid' alt='' width='36' height='36' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className='product-specifications'>
                                                <div className='account-lists'>
                                                    <Link to='/Order' className='account-list' aria-label="menu">
                                                        <div className='account-media'>
                                                            <span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.57071 2.5H17.9939C18.2212 2.5 18.4391 2.5878 18.5998 2.74408C18.7605 2.90036 18.8508 3.11232 18.8508 3.33333V16.6667C18.8508 16.8877 18.7605 17.0996 18.5998 17.2559C18.4391 17.4122 18.2212 17.5 17.9939 17.5H2.57071C2.34346 17.5 2.12552 17.4122 1.96483 17.2559C1.80414 17.0996 1.71387 16.8877 1.71387 16.6667V3.33333C1.71387 3.11232 1.80414 2.90036 1.96483 2.74408C2.12552 2.5878 2.34346 2.5 2.57071 2.5ZM7.71178 10C7.71178 10.663 7.9826 11.2989 8.46467 11.7678C8.94674 12.2366 9.60056 12.5 10.2823 12.5C10.9641 12.5 11.6179 12.2366 12.1 11.7678C12.582 11.2989 12.8528 10.663 12.8528 10H17.1371V4.16667H3.42756V10H7.71178Z" fill="#121415"/>
                                                                </svg>
                                                            </span>
                                                            <div className='d-flex flex-column flex-nowrap ml-1_5'>
                                                                <div className='account-list-title text-dark'>Order</div>
                                                                <div className='account-list-label'>Track your orders</div>
                                                            </div>
                                                        </div>
                                                        <div className='account-link'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                    <Link to='/Account/Profile' className='account-list' aria-label="menu">
                                                        <div className='account-media'>
                                                            <span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.2822 14.1667C13.42 14.1667 16.1644 15.4792 17.6571 17.4376L16.0788 18.1634C14.8637 16.7634 12.7216 15.8334 10.2822 15.8334C7.84277 15.8334 5.70066 16.7634 4.48565 18.1634L2.9082 17.4367C4.40083 15.4784 7.14444 14.1667 10.2822 14.1667ZM10.2822 1.66675C11.4185 1.66675 12.5082 2.10573 13.3116 2.88714C14.1151 3.66854 14.5664 4.72835 14.5664 5.83342V8.33342C14.5664 9.43848 14.1151 10.4983 13.3116 11.2797C12.5082 12.0611 11.4185 12.5001 10.2822 12.5001C9.14596 12.5001 8.05625 12.0611 7.2528 11.2797C6.44936 10.4983 5.99798 9.43848 5.99798 8.33342V5.83342C5.99798 4.72835 6.44936 3.66854 7.2528 2.88714C8.05625 2.10573 9.14596 1.66675 10.2822 1.66675Z" fill="#121415"/>
                                                                </svg>
                                                            </span>
                                                            <div className='d-flex flex-column flex-nowrap ml-1_5'>
                                                                <div className='account-list-title text-dark'>Personal Details</div>
                                                                <div className='account-list-label'>Name, Email, Phone Number</div>
                                                            </div>
                                                        </div>
                                                        <div className='account-link'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                    <Link to='/Account/AccountAddress' className='account-list' aria-label="menu">
                                                        <div className='account-media'>
                                                            <span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.42516 14.9484C7.69665 14.7345 6.11676 13.8885 5.00657 12.5824C3.89639 11.2763 3.33923 9.60803 3.44834 7.91673C3.55745 6.22542 4.32465 4.63799 5.59399 3.47707C6.86333 2.31614 8.53956 1.66885 10.282 1.66675C12.0266 1.66549 13.706 2.3112 14.9781 3.47231C16.2502 4.63343 17.0192 6.22258 17.1284 7.91595C17.2377 9.60932 16.6789 11.2795 15.566 12.5861C14.4532 13.8928 12.8699 14.7376 11.1388 14.9484V16.6767C14.52 16.7534 17.1368 17.2792 17.1368 17.9167C17.1368 18.6068 14.0675 19.1667 10.282 19.1667C6.49646 19.1667 3.42725 18.6068 3.42725 17.9167C3.42725 17.2792 6.04405 16.7534 9.42516 16.6767V14.9484ZM10.282 10.0001C10.7365 10.0001 11.1724 9.82449 11.4938 9.51193C11.8151 9.19937 11.9957 8.77544 11.9957 8.33342C11.9957 7.89139 11.8151 7.46747 11.4938 7.15491C11.1724 6.84235 10.7365 6.66675 10.282 6.66675C9.8275 6.66675 9.39162 6.84235 9.07024 7.15491C8.74886 7.46747 8.56831 7.89139 8.56831 8.33342C8.56831 8.77544 8.74886 9.19937 9.07024 9.51193C9.39162 9.82449 9.8275 10.0001 10.282 10.0001Z" fill="#121415"/>
                                                                </svg>
                                                            </span>
                                                            <div className='d-flex flex-column flex-nowrap ml-1_5'>
                                                                <div className='account-list-title text-dark'>Saved Address</div>
                                                                <div className='account-list-label'>History of all your past orders </div>
                                                            </div>
                                                        </div>
                                                        <div className='account-link'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                    <Link to='/Account/Payments' className='account-list' aria-label="menu">
                                                        <div className='account-media'>
                                                            <span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.91348 2H18.3367C18.5639 2 18.7819 2.0878 18.9426 2.24408C19.1033 2.40036 19.1935 2.61232 19.1935 2.83333V16.1667C19.1935 16.3877 19.1033 16.5996 18.9426 16.7559C18.7819 16.9122 18.5639 17 18.3367 17H2.91348C2.68624 17 2.46829 16.9122 2.3076 16.7559C2.14691 16.5996 2.05664 16.3877 2.05664 16.1667V2.83333C2.05664 2.61232 2.14691 2.40036 2.3076 2.24408C2.46829 2.0878 2.68624 2 2.91348 2ZM17.4798 8.66667H3.77033V15.3333H17.4798V8.66667ZM17.4798 7V3.66667H3.77033V7H17.4798ZM12.3388 12H15.7661V13.6667H12.3388V12Z" fill="#121415"/>
                                                                </svg>
                                                            </span>
                                                            <div className='d-flex flex-column flex-nowrap ml-1_5'>
                                                                <div className='account-list-title text-dark'>Saved Payment Methods</div>
                                                                <div className='account-list-label'>Add your card  for faster checkout</div>
                                                            </div>
                                                        </div>
                                                        <div className='account-link'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                    <Link to='/Account/MyCoupons' className='account-list' aria-label="menu">
                                                        <div className='account-media'>
                                                            <span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_3053_53095)">
                                                                        <path d="M2.05664 8.41667V3.83333C2.05664 3.61232 2.14691 3.40036 2.3076 3.24408C2.46829 3.0878 2.68624 3 2.91348 3H18.3367C18.5639 3 18.7819 3.0878 18.9426 3.24408C19.1033 3.40036 19.1935 3.61232 19.1935 3.83333V8.41667C18.6254 8.41667 18.0805 8.63616 17.6788 9.02686C17.2771 9.41756 17.0514 9.94747 17.0514 10.5C17.0514 11.0525 17.2771 11.5824 17.6788 11.9731C18.0805 12.3638 18.6254 12.5833 19.1935 12.5833V17.1667C19.1935 17.3877 19.1033 17.5996 18.9426 17.7559C18.7819 17.9122 18.5639 18 18.3367 18H2.91348C2.68624 18 2.46829 17.9122 2.3076 17.7559C2.14691 17.5996 2.05664 17.3877 2.05664 17.1667V12.5833C2.62476 12.5833 3.16962 12.3638 3.57134 11.9731C3.97307 11.5824 4.19875 11.0525 4.19875 10.5C4.19875 9.94747 3.97307 9.41756 3.57134 9.02686C3.16962 8.63616 2.62476 8.41667 2.05664 8.41667ZM12.3388 4.66667H3.77033V7.14C4.414 7.45025 4.95575 7.92921 5.3345 8.52288C5.71326 9.11656 5.91395 9.80133 5.91395 10.5C5.91395 11.1987 5.71326 11.8834 5.3345 12.4771C4.95575 13.0708 4.414 13.5498 3.77033 13.86V16.3333H12.3388V4.66667ZM14.0525 4.66667V16.3333H17.4798V13.86C16.8362 13.5498 16.2944 13.0708 15.9157 12.4771C15.5369 11.8834 15.3362 11.1987 15.3362 10.5C15.3362 9.80133 15.5369 9.11656 15.9157 8.52288C16.2944 7.92921 16.8362 7.45025 17.4798 7.14V4.66667H14.0525Z" fill="#121415"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_3053_53095">
                                                                            <rect width="20.5643" height="20" fill="white"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                            <div className='d-flex flex-column flex-nowrap ml-1_5'>
                                                                <div className='account-list-title text-dark'>My Coupons</div>
                                                                <div className='account-list-label'>Manage coupons for additional discounts</div>
                                                            </div>
                                                        </div>
                                                        <div className='account-link'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='mar-spacer bg-theme' />
                                            <div className='product-specifications'>
                                                <div className='account-lists'>
                                                    <div className='account-list'>
                                                        <div className='account-media'>
                                                            <span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18.8508 14.1684C18.8504 15.308 18.4498 16.4134 17.7155 17.3014C16.9811 18.1895 15.9569 18.807 14.8124 19.0517L14.2658 17.4567C14.7664 17.3766 15.2425 17.1894 15.6597 16.9087C16.0769 16.6279 16.4249 16.2607 16.6787 15.8334H14.5665C14.112 15.8334 13.6762 15.6578 13.3548 15.3453C13.0334 15.0327 12.8528 14.6088 12.8528 14.1667V10.8334C12.8528 10.3914 13.0334 9.96746 13.3548 9.6549C13.6762 9.34234 14.112 9.16675 14.5665 9.16675H17.0839C16.8748 7.55583 16.0685 6.07448 14.8162 5.00064C13.564 3.92681 11.9518 3.33431 10.2823 3.33431C8.61279 3.33431 7.00065 3.92681 5.74838 5.00064C4.49611 6.07448 3.68978 7.55583 3.48068 9.16675H5.99809C6.45259 9.16675 6.88847 9.34234 7.20985 9.6549C7.53123 9.96746 7.71178 10.3914 7.71178 10.8334V14.1667C7.71178 14.6088 7.53123 15.0327 7.20985 15.3453C6.88847 15.6578 6.45259 15.8334 5.99809 15.8334H3.42756C2.97306 15.8334 2.53717 15.6578 2.21579 15.3453C1.89442 15.0327 1.71387 14.6088 1.71387 14.1667V10.0001C1.71387 5.39758 5.54996 1.66675 10.2823 1.66675C15.0147 1.66675 18.8508 5.39758 18.8508 10.0001V14.1684Z" fill="#121415"/>
                                                                </svg>
                                                            </span>
                                                            <div className='d-flex flex-column flex-nowrap ml-1_5'>
                                                                <div className='account-list-title'>Help and Support</div>
                                                                <div className='account-list-label'>Manage coupons for additional discounts</div>
                                                            </div>
                                                        </div>
                                                        <div className='account-link'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className='account-list'>
                                                        <div className='account-media'>
                                                            <span>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.2844 12.5001V14.1667C4.28426 14.5872 4.44755 14.9922 4.74152 15.3005C5.0355 15.6089 5.43844 15.7977 5.86956 15.8292L5.99809 15.8334H8.56862V17.5001H5.99809C5.08909 17.5001 4.21732 17.1489 3.57457 16.5238C2.93181 15.8986 2.57071 15.0508 2.57071 14.1667V12.5001H4.2844ZM15.4234 8.33341L19.1935 17.5001H17.347L16.3179 15.0001H12.8134L11.7861 17.5001H9.94043L13.7097 8.33341H15.4234ZM14.5665 10.7376L13.498 13.3334H15.6333L14.5665 10.7376ZM6.85493 1.66675V3.33341H10.2823V9.16675H6.85493V11.6667H5.14124V9.16675H1.71387V3.33341H5.14124V1.66675H6.85493ZM14.5665 2.50008C15.4755 2.50008 16.3473 2.85127 16.9901 3.47639C17.6328 4.10151 17.9939 4.94936 17.9939 5.83341V7.50008H16.2802V5.83341C16.2802 5.39139 16.0997 4.96746 15.7783 4.6549C15.4569 4.34234 15.021 4.16675 14.5665 4.16675H11.996V2.50008H14.5665ZM5.14124 5.00008H3.42756V7.50008H5.14124V5.00008ZM8.56862 5.00008H6.85493V7.50008H8.56862V5.00008Z" fill="#121415"/>
                                                                </svg>
                                                            </span>
                                                            <div className='d-flex flex-column flex-nowrap ml-1_5'>
                                                                <div className='account-list-title'>Change Prefered Language</div>
                                                                <div className='account-list-label'>English</div>
                                                            </div>
                                                        </div>
                                                        <div className='account-link'>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-x-16'>
                                                <button type='button' className='btn btn-outOfStock-custom w-100'>Log Out</button>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown> : 
                                    <>
                                        <Nav.Link href="#action3" className='login-dropdown' onClick={() => setModalShow(true)}>
                                            <img src={user} className='img-fluid' alt='' width='36' height='36' />
                                            <span className='ml-2'>LogIn/Sigup</span>
                                        </Nav.Link> 
                                        <Login 
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                        />
                                    </>
                                }
                            </Nav>
                        </Navbar>
                    </div>
                </div>
            </Navbar>
        </>
    )
}

export default Index