import React from 'react'

const Installation = () => {
    return (
        <>
            <div className='payment-offer-options'>
                <div className='payment-offer-content'>
                    <div className='payment-offer-info m-0'>
                        <h6>Installation and Demo</h6>
                        <p className='details-text'>Will be scheduled at your convenience.<span> FREE</span></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Installation