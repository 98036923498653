import React, {useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CartPanel from './CartPanel';

const CartOffcanvas = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' className='btn btn-dark-custom col' onClick={handleShow}>
                <span className='d-flex align-items-center justify-content-center'>
                    <span className='d-flex'>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.89995 11.0992V3.89922H2.69995V2.69922H4.49995C4.65908 2.69922 4.81169 2.76243 4.92422 2.87495C5.03674 2.98748 5.09995 3.14009 5.09995 3.29922V10.4992H12.5628L13.7628 5.69922H6.29995V4.49922H14.532C14.6232 4.49922 14.7132 4.52002 14.7951 4.56002C14.8771 4.60003 14.9488 4.65819 15.005 4.73009C15.0611 4.80199 15.1 4.88574 15.1189 4.97497C15.1378 5.06419 15.1361 5.15655 15.114 5.24502L13.614 11.245C13.5815 11.3748 13.5065 11.4899 13.4011 11.5722C13.2956 11.6545 13.1657 11.6992 13.032 11.6992H4.49995C4.34082 11.6992 4.18821 11.636 4.07569 11.5235C3.96317 11.411 3.89995 11.2583 3.89995 11.0992ZM5.09995 15.2992C4.78169 15.2992 4.47647 15.1728 4.25142 14.9477C4.02638 14.7227 3.89995 14.4175 3.89995 14.0992C3.89995 13.781 4.02638 13.4757 4.25142 13.2507C4.47647 13.0256 4.78169 12.8992 5.09995 12.8992C5.41821 12.8992 5.72344 13.0256 5.94848 13.2507C6.17352 13.4757 6.29995 13.781 6.29995 14.0992C6.29995 14.4175 6.17352 14.7227 5.94848 14.9477C5.72344 15.1728 5.41821 15.2992 5.09995 15.2992ZM12.3 15.2992C11.9817 15.2992 11.6765 15.1728 11.4514 14.9477C11.2264 14.7227 11.1 14.4175 11.1 14.0992C11.1 13.781 11.2264 13.4757 11.4514 13.2507C11.6765 13.0256 11.9817 12.8992 12.3 12.8992C12.6182 12.8992 12.9234 13.0256 13.1485 13.2507C13.3735 13.4757 13.5 13.781 13.5 14.0992C13.5 14.4175 13.3735 14.7227 13.1485 14.9477C12.9234 15.1728 12.6182 15.2992 12.3 15.2992Z" fill="white"/>
                        </svg>
                    </span> 
                    <span className='ml-1'>Add to Cart</span>
                </span>
            </button>

            <Offcanvas show={show} onHide={handleClose} className='cart-pdp-offcanvas'>
                <CartPanel  />
            </Offcanvas>
        </>
    )
}

export default CartOffcanvas