import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import loginlogo from '../../Images/logo-icon.png';
import './Login.css';
import { Link } from '@reach/router';

const Login = (props) => {
    const [ isLogin, setIsLogin ] = useState(true);
    
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100' />
                </Modal.Header>
                <Modal.Body>
                    <div className='Login-wrapper'>
                        <div className="Login-logo mb-4">
                            <img src={loginlogo} alt="" className='first_logo' />
                        </div>
                        {isLogin ?
                            <div className='login-textbox__wrapper' style={{ display: isLogin ? 'block' : 'none' }}>
                                <h5 className='login-heading mb-4'>Welcome to Sangeetha Mobiles</h5>
                                <p className='Login-info mb-4'>Please enter your mobile number</p>
                                <div className='loginIn_wrap'>
                                    <div className='cart-address__wrap'>
                                        <input  type='number' className='form-control form-control-cart' placeholder='Mobile Number'  maxLength="10" />
                                        <span className='absolute-login-input'>+91</span> 
                                    </div>
                                    <span className='login-error'>Please enter a valid mobile number (10 digits)</span>
                                </div>
                                <div className='mar-spacer h-4' />
                                <div className="login-footer">
                                    <div className="footer-links mb-0 login-footerlink">
                                        <span className='fontcolor-light'>By continuing,</span>{' '}
                                        <span className='fontcolor-light'>I agree to the{' '}
                                            <Link to='/' className='Linkpolicy'>Privacy Policy</Link>  
                                        </span>
                                    </div>
                                    <div className="footer-links login-footerlink"> 
                                        <span className='fontcolor-light'>and{' '}
                                            <Link to='/' className='Linkpolicy'>Terms & Conditions</Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="continue-login_btn mt-4">
                                    <button type='button' className='btn btn-dark-custom w-100' onClick={() => setIsLogin(false)}>Login</button>
                                </div>  
                            </div> :
                            <div className='login-otp__wrapper' style={{ display: isLogin ? 'none' : 'block' }}>
                                <h5 className='login-heading mb-4'>Login with OTP</h5>
                                <p className='Login-info mb-4'>Please enter OTP sent to 78*****389 
                                    <button type='button' className='change-login-number'>Change</button>
                                </p>
                                <div className='loginIn_wrap'>
                                    <div className='cart-address__wrap '>
                                        <form action="" className='otp_set'>
                                            <input type='number' className='form-control otp_input' maxLength="1" />
                                            <input type='number' className='form-control otp_input' maxLength="1" />
                                            <input type='number' className='form-control otp_input' maxLength="1" />
                                            <input type='number' className='form-control otp_input' maxLength="1"/>
                                        </form>
                                        <span className='login-error justify-content-center'>Please enter a valid OTP (4 digits)</span>
                                    </div>
                                    <div className="resend-otp text-center">
                                        <button type='button' className='Resend_OtpLink'>Resend OTP</button>
                                    </div>
                                </div>
                                <div className='mar-spacer h-4' />
                                <div className="login-footer">
                                    <div className="footer-links mb-0 login-footerlink">
                                        <span className='fontcolor-light'>By continuing,</span>{' '}
                                        <span className='fontcolor-light'>I agree to the{' '}
                                            <Link to='/' className='Linkpolicy'>Privacy Policy</Link>  
                                        </span>
                                    </div>
                                    <div className="footer-links login-footerlink"> 
                                        <span className='fontcolor-light'>and{' '}
                                            <Link to='/' className='Linkpolicy'>Terms & Conditions</Link>
                                        </span>
                                    </div>
                                </div>
                                <div className="continue-login_btn mt-4">
                                    <button type='button' className='btn btn-dark-custom w-100'>Verify</button>
                                </div> 
                            </div>
                        }                     
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Login