import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import specific from '../../Images/product/wishlist.png'

const RemoveCartItems = ({ ...props }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    return (
        <>  
            <button type='button' className='btn btn-remove btn-cart-h' onClick={handleShow}>
                <span>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.266 4.33341V11.8001C11.266 11.9415 11.2098 12.0772 11.1098 12.1772C11.0098 12.2772 10.8741 12.3334 10.7327 12.3334H3.26602C3.12457 12.3334 2.98891 12.2772 2.88889 12.1772C2.78887 12.0772 2.73268 11.9415 2.73268 11.8001V4.33341H1.66602V3.26675H12.3327V4.33341H11.266ZM3.79935 4.33341V11.2667H10.1993V4.33341H3.79935ZM4.33268 1.66675H9.66602V2.73341H4.33268V1.66675ZM6.46602 5.93341H7.53268V9.66675H6.46602V5.93341Z" fill="#121415"/>
                    </svg>                                        
                </span>
                <span className='ml-1'>Remove</span>
            </button>
            
            <Offcanvas show={show} onHide={handleClose} {...props} className='notify-me__offCanvas-'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Remove from Cart? </Offcanvas.Title>
                </Offcanvas.Header>
                <div className='cart-horizontal-line m-0'></div>
                <Offcanvas.Body>
                    <div className='similar-product__wrap w-100'>                        
                        <div className='specific-wrapper__list'>
                            <div className='specific-wrapper__media'>
                                <img src={specific} className='img img-fluid' alt='' />
                            </div>
                            <div className='specific-wrapper__content'>
                                <h4>Samsung Galaxy Z Fold 2 5G</h4>
                                <p>Mystic Purple, 256GB + 8GB</p>
                                <div className="price-offer justify-content-start">
                                    <div className="new-price">₹19,999</div>
                                    <div className="old-price">₹22,999</div>
                                </div>
                            </div>
                        </div>
                        <div className='cart-btn-wrappper'>
                            <button type='button' className='btn btn-block btn-cart-outline w-100'>
                                <span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.266 4.33341V11.8001C11.266 11.9415 11.2098 12.0772 11.1098 12.1772C11.0098 12.2772 10.8741 12.3334 10.7327 12.3334H3.26602C3.12457 12.3334 2.98891 12.2772 2.88889 12.1772C2.78887 12.0772 2.73268 11.9415 2.73268 11.8001V4.33341H1.66602V3.26675H12.3327V4.33341H11.266ZM3.79935 4.33341V11.2667H10.1993V4.33341H3.79935ZM4.33268 1.66675H9.66602V2.73341H4.33268V1.66675ZM6.46602 5.93341H7.53268V9.66675H6.46602V5.93341Z" fill="#121415"/>
                                    </svg>                                        
                                </span>
                                <span className='ml-1'>Remove</span>
                            </button>
                            <button type='button' className='btn btn-block btn-cart text-nowrap w-100 ml-2'>
                                <span>
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6198 8.52959L14.6285 8.53893L8.50047 14.6669L2.37247 8.53893L2.38113 8.52959C1.66951 7.69996 1.2977 6.63205 1.34019 5.53985C1.38269 4.44765 1.83636 3.41186 2.6103 2.64003C3.38424 1.8682 4.42127 1.41736 5.51357 1.37784C6.60588 1.33832 7.67278 1.71304 8.50047 2.42693C9.32816 1.71304 10.3951 1.33832 11.4874 1.37784C12.5797 1.41736 13.6167 1.8682 14.3906 2.64003C15.1646 3.41186 15.6182 4.44765 15.6607 5.53985C15.7032 6.63205 15.3314 7.69996 14.6198 8.52959V8.52959ZM3.55047 3.58893C3.27189 3.8675 3.05091 4.19822 2.90014 4.5622C2.74937 4.92618 2.67177 5.31629 2.67177 5.71026C2.67177 6.10423 2.74937 6.49434 2.90014 6.85832C3.05091 7.2223 3.27189 7.55302 3.55047 7.83159L8.50047 12.7816L13.4505 7.83159C14.0131 7.26898 14.3292 6.50591 14.3292 5.71026C14.3292 4.9146 14.0131 4.15154 13.4505 3.58893C12.8879 3.02631 12.1248 2.71024 11.3291 2.71024C10.5335 2.71024 9.77041 3.02631 9.2078 3.58893L6.37913 6.41759L5.43647 5.47359L7.54447 3.36559C6.96795 2.90515 6.24196 2.67331 5.50528 2.71439C4.7686 2.75546 4.07289 3.06658 3.55113 3.58826L3.55047 3.58893Z" fill="white"/>
                                    </svg>                                       
                                </span>
                                <span className='ml-1'>Move to wishlist</span>
                            </button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RemoveCartItems