import { Link } from '@reach/router'
import React from 'react'

const RenewedInfo = () => {
    return (
        <>
            <div className='renewed-info'>
                <div className='renewed_line d-flex flex-column align-items-start'>
                    <div className='product-label-tag tag-flex-light position-static'>
                        <span className='tag-flex_light d-flex align-items-center'>
                            <span className='d-flex'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.19925 1.16617C9.08227 1.21086 9.01081 1.30288 9.01081 1.40541V2.018C5.27467 2.15733 2.28577 4.66022 2.28577 7.71523C2.28577 8.13063 2.34102 8.54339 2.45146 8.93774C2.48395 9.05607 2.61716 9.14282 2.76662 9.14282H2.76986C2.92254 9.14018 3.0525 9.05607 3.085 8.93512C3.69576 6.66096 6.15511 4.98623 9.01081 4.86792V5.46736C9.01081 5.56989 9.08227 5.66455 9.19925 5.7066C9.31619 5.74867 9.4494 5.73289 9.54687 5.66717L12.4578 3.63225C12.5293 3.58231 12.5715 3.51132 12.5715 3.43244C12.5715 3.3562 12.5293 3.28521 12.4578 3.23527L9.54687 1.20559C9.4494 1.13987 9.31293 1.12409 9.19925 1.16617Z" fill="white"/>
                                    <path d="M6.79865 10.2918C6.68491 10.2497 6.54842 10.2655 6.45092 10.3338L3.53906 12.3667C3.46758 12.4167 3.42859 12.4903 3.42859 12.5666C3.42859 12.6429 3.46758 12.7165 3.53906 12.7664L6.45419 14.7941C6.51268 14.8361 6.58741 14.8572 6.6654 14.8572C6.7109 14.8572 6.7564 14.8493 6.79865 14.8335C6.91566 14.7914 6.99039 14.6968 6.99039 14.5942V13.9814C10.7277 13.8447 13.7143 11.3411 13.7143 8.28256C13.7143 7.86704 13.6591 7.45679 13.5518 7.06232C13.5161 6.94135 13.3861 6.85718 13.2333 6.85718C13.0806 6.85718 12.9473 6.94397 12.9148 7.06494C12.3071 9.33976 9.84375 11.0123 6.99039 11.1307V10.5311C6.99039 10.4285 6.91566 10.3365 6.79865 10.2918Z" fill="white"/>
                                </svg>
                            </span>
                            <span className='ml-1'>Refurbished</span>
                        </span>
                        <span className='tag-flex_dark'>Superb</span>
                    </div>
                    <div className='renewed_details'>Fully Functional | 1 year Warranty | Upto 5 minor scratches | No dents | All accessories included with the box</div>
                    <Link to='/' className='learn_more_link'>Learn More</Link>
                </div>
            </div>
        </>
    )
}

export default RenewedInfo