import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const FaqAccordion = () => {
    const [activeId, setActiveId] = useState();

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <div>
            <div className='cancellation__accordion faq-accordion'>
                <Accordion className='filter__accordion accordion-plus' defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                        <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                            <div className='filter-card__head m-0'>
                                <span>Lorem ipsum dolor sit amet</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="product-policy__wrap">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion className='filter__accordion accordion-plus' defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                        <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                            <div className='filter-card__head m-0'>
                                <span>Lorem ipsum dolor sit amet</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="product-policy__wrap">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion className='filter__accordion accordion-plus' defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                        <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                            <div className='filter-card__head m-0'>
                                <span>Lorem ipsum dolor sit amet</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="product-policy__wrap">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion className='filter__accordion accordion-plus' defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                        <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                            <div className='filter-card__head m-0'>
                                <span>Lorem ipsum dolor sit amet</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="product-policy__wrap">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default FaqAccordion