import React from 'react';
import Highlights1 from '../../Images/Product/Highlights1.png';
import Highlights2 from '../../Images/Product/Highlights2.png';
import Highlights3 from '../../Images/Product/Highlights3.png';
import Highlights4 from '../../Images/Product/Highlights4.png';

const Highlights = () => {
    return (
        <>
            <div className='highlight-grid'>
                <div className='highlight-col'>
                    <img src={Highlights1} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights2} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights3} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights4} className='img-fluid' alt='' />
                </div>
            </div>
        </>
    )
}

export default Highlights