import React from 'react'

const ItemQty = () => {
    return (
        <>
            <div className='item-qty__wrap'>
                <div className='item-overflow__scroll'>
                    <div className='item-qty__item'>1</div>
                    <div className='item-qty__item'>2</div>
                    <div className='item-qty__item'>3</div>
                    <div className='item-qty__item'>4</div>
                    <div className='item-qty__item'>5</div>
                </div>
            </div>
        </>
    )
}

export default ItemQty