import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ItemsRemoved from './ItemsRemoved';
import ItemQty from './ItemQty';

import shoppingCart from '../../../Images/product/shoppingCart.png';
import  productImg from '../../../Images/product/productImg.png';
import shoppingCart2 from '../../../Images/CategoryDetails/Cart/iphone13-pro.png'

const CartItems = () => {
    const [show, setShow] = useState(false);
    const [ showSavedItems, setShowSavedItems ] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseSavedItems = () => setShowSavedItems(false);
    const handleShowSavedItems = () => setShowSavedItems(true);

    return (
        <>
            <div className='shopping-cart___wrap'>
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <img src={shoppingCart2} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>IPhone 13 Pro</h4>
                        <p>
                            <span>Purple </span>,
                            <span>256GB </span> + 
                            <span> 8GB</span>
                        </p>
                        <div className='shopping-cart__qty'>
                            <div className='d-flex align-items-center'>
                                <span>Qty :</span> 1
                                <span className='ml-1 d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0006 10.9766L14.1256 6.85156L15.3039 8.0299L10.0006 13.3332L4.69727 8.0299L5.8756 6.85156L10.0006 10.9766Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <ItemQty />
                        </div>
                        <div className='price-offer addToCart-offcanvas'>
                            <div className='new-price'>₹79,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span className='d-flex'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 8H20L23 12.056V18H20.965C20.8461 18.8343 20.4302 19.5977 19.7937 20.1499C19.1571 20.7022 18.3427 21.0063 17.5 21.0063C16.6573 21.0063 15.8429 20.7022 15.2063 20.1499C14.5698 19.5977 14.1539 18.8343 14.035 18H8.965C8.84612 18.8343 8.43021 19.5977 7.79368 20.1499C7.15714 20.7022 6.34272 21.0063 5.5 21.0063C4.65728 21.0063 3.84286 20.7022 3.20632 20.1499C2.56979 19.5977 2.15388 18.8343 2.035 18H1V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H16C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6V8ZM17 10V13H21V12.715L18.992 10H17Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                            <span className="label__similarProd">Monday, 24 Jan.</span>
                        </div>
                    </div>
                </div>
                <div className='shopping-cart__vas'>
                    <div className='shopping-vas__info'>
                        <div className='shopping-vas__media'>
                            <span>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" fill="white"/>
                                    <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415"/>
                                </svg>
                            </span>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='shopping-vas__head mb-1'>Money and Damage Protection (MAD) </div>
                            <div className='shopping-vas__label d-flex'>
                                <span>1 Year Protection</span>
                                <div className='price-offer mb-0'>
                                    <div className='new-price'>₹19,999</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='shopping-vas__close d-flex'  onClick={handleShow} role='button' aria-hidden="true">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#98A0AE"/>
                            <path d="M8.00048 7.05732L11.3005 3.75732L12.2431 4.69999L8.94315 7.99999L12.2431 11.3L11.3005 12.2427L8.00048 8.94266L4.70048 12.2427L3.75781 11.3L7.05781 7.99999L3.75781 4.69999L4.70048 3.75732L8.00048 7.05732Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8 5V13.4C12.8 13.5591 12.7368 13.7117 12.6243 13.8243C12.5117 13.9368 12.3591 14 12.2 14H3.8C3.64087 14 3.48826 13.9368 3.37574 13.8243C3.26321 13.7117 3.2 13.5591 3.2 13.4V5H2V3.8H14V5H12.8ZM4.4 5V12.8H11.6V5H4.4ZM5 2H11V3.2H5V2ZM7.4 6.8H8.6V11H7.4V6.8Z" fill="#121415"/>
                            </svg>                                    
                        </span>
                        <span className='ml-1'>Remove</span>
                    </button>
                    <button type='button' className='btn btn-save-for-later btn-cart-h ml-2' onClick={handleShowSavedItems}>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                            </svg>                                      
                        </span>
                        <span className='ml-1'>Save For Later</span>
                    </button>
                </div>
                <div className='mar-spacer h-3' />
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <img src={shoppingCart} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>Samsung Galaxy Z Fold 2 5G</h4>
                        <p>
                            <span>Mystic Purple</span>,
                            <span>256GB</span> + 
                            <span> 8GB</span>
                        </p>
                        <div className='shopping-cart__qty'>
                            <div className='d-flex align-items-center'>
                                <span>Qty :</span> 1
                                <span className='ml-1 d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0006 10.9766L14.1256 6.85156L15.3039 8.0299L10.0006 13.3332L4.69727 8.0299L5.8756 6.85156L10.0006 10.9766Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <ItemQty />
                        </div>
                        <div className='price-offer addToCart-offcanvas'>
                            <div className='new-price'>₹19,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span className='d-flex'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 8H20L23 12.056V18H20.965C20.8461 18.8343 20.4302 19.5977 19.7937 20.1499C19.1571 20.7022 18.3427 21.0063 17.5 21.0063C16.6573 21.0063 15.8429 20.7022 15.2063 20.1499C14.5698 19.5977 14.1539 18.8343 14.035 18H8.965C8.84612 18.8343 8.43021 19.5977 7.79368 20.1499C7.15714 20.7022 6.34272 21.0063 5.5 21.0063C4.65728 21.0063 3.84286 20.7022 3.20632 20.1499C2.56979 19.5977 2.15388 18.8343 2.035 18H1V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H16C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6V8ZM17 10V13H21V12.715L18.992 10H17Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                            <span className="label__similarProd">Monday, 24 Jan.</span>
                            <span className="deliver-type text-uppercase ml-1">Free</span>
                        </div>
                    </div>
                </div>
                <div className='shopping-cart__vas'>
                    <div className='shopping-vas__info'>
                        <div className='shopping-vas__media'>
                            <span>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" fill="white"/>
                                    <path d="M15.783 14.826L24 13L32.217 14.826C32.4391 14.8754 32.6377 14.999 32.78 15.1764C32.9224 15.3538 33 15.5745 33 15.802V25.789C32.9999 26.7767 32.756 27.7492 32.2899 28.62C31.8238 29.4908 31.1499 30.2331 30.328 30.781L24 35L17.672 30.781C16.8503 30.2332 16.1765 29.4911 15.7103 28.6205C15.2442 27.7498 15.0002 26.7776 15 25.79V15.802C15 15.5745 15.0776 15.3538 15.22 15.1764C15.3623 14.999 15.5609 14.8754 15.783 14.826ZM25 22V17L20 24H23V29L28 22H25Z" fill="#121415"/>
                                </svg>
                            </span>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='shopping-vas__head mb-1'>Money and Damage Protection (MAD) </div>
                            <div className='shopping-vas__label d-flex'>
                                <span>1 Year Protection</span>
                                <div className='price-offer mb-0'>
                                    <div className='new-price'>₹19,999</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='shopping-vas__add d-flex'>
                        <button type='button' className='btn btn-add'>Add</button>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8 5V13.4C12.8 13.5591 12.7368 13.7117 12.6243 13.8243C12.5117 13.9368 12.3591 14 12.2 14H3.8C3.64087 14 3.48826 13.9368 3.37574 13.8243C3.26321 13.7117 3.2 13.5591 3.2 13.4V5H2V3.8H14V5H12.8ZM4.4 5V12.8H11.6V5H4.4ZM5 2H11V3.2H5V2ZM7.4 6.8H8.6V11H7.4V6.8Z" fill="#121415"/>
                            </svg>                                    
                        </span>
                        <span className='ml-1'>Remove</span>
                    </button>
                    <button type='button' className='btn btn-save-for-later btn-cart-h ml-2'>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                            </svg>                                      
                        </span>
                        <span className='ml-1'>Save For Later</span>
                    </button>
                </div>
                <div className='mar-spacer h-3' />
            </div>

            <div className='shopping-cart___wrap'>
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <span className='few-left-tag'>
                            <span className='d-flex'>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 5.5H10.5L6 12V7.5H2.5L7 1V5.5Z" fill="white"/>
                                </svg>
                            </span>
                            <span className='ml-1'>Only few left </span>
                        </span>
                        <img src={productImg} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>IPhone 13 Pro</h4>
                        <p>
                            <span>Purple</span>,
                            <span>256GB</span> + 
                            <span> 8GB</span>
                        </p>
                        <div className='shopping-cart__qty'>
                            <div className='d-flex align-items-center'>
                                <span>Qty :</span> 1
                                <span className='ml-1 d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0006 10.9766L14.1256 6.85156L15.3039 8.0299L10.0006 13.3332L4.69727 8.0299L5.8756 6.85156L10.0006 10.9766Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <ItemQty />
                        </div>
                        <div className='price-offer addToCart-offcanvas'>
                            <div className='new-price'>₹19,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span className='d-flex'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 8H20L23 12.056V18H20.965C20.8461 18.8343 20.4302 19.5977 19.7937 20.1499C19.1571 20.7022 18.3427 21.0063 17.5 21.0063C16.6573 21.0063 15.8429 20.7022 15.2063 20.1499C14.5698 19.5977 14.1539 18.8343 14.035 18H8.965C8.84612 18.8343 8.43021 19.5977 7.79368 20.1499C7.15714 20.7022 6.34272 21.0063 5.5 21.0063C4.65728 21.0063 3.84286 20.7022 3.20632 20.1499C2.56979 19.5977 2.15388 18.8343 2.035 18H1V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H16C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6V8ZM17 10V13H21V12.715L18.992 10H17Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery in &nbsp;</span>
                            <span className="label__similarProd">2 Hrs.</span>
                            <span className="deliver-type text-uppercase ml-1">Free</span>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8 5V13.4C12.8 13.5591 12.7368 13.7117 12.6243 13.8243C12.5117 13.9368 12.3591 14 12.2 14H3.8C3.64087 14 3.48826 13.9368 3.37574 13.8243C3.26321 13.7117 3.2 13.5591 3.2 13.4V5H2V3.8H14V5H12.8ZM4.4 5V12.8H11.6V5H4.4ZM5 2H11V3.2H5V2ZM7.4 6.8H8.6V11H7.4V6.8Z" fill="#121415"/>
                            </svg>                                    
                        </span>
                        <span className='ml-1'>Remove</span>
                    </button>
                    <button type='button' className='btn btn-save-for-later btn-cart-h ml-2'>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.53333 2.40002H12C12.1414 2.40002 12.2771 2.45621 12.3771 2.55623C12.4771 2.65625 12.5333 2.79191 12.5333 2.93336V13.143C12.5334 13.1906 12.5207 13.2375 12.4965 13.2786C12.4723 13.3197 12.4375 13.3535 12.3958 13.3766C12.3541 13.3997 12.3069 13.4112 12.2593 13.4099C12.2116 13.4085 12.1652 13.3945 12.1248 13.3691L8.26667 10.9494L4.40853 13.3686C4.3682 13.3939 4.32181 13.408 4.27419 13.4093C4.22657 13.4107 4.17946 13.3992 4.13776 13.3762C4.09606 13.3532 4.06128 13.3194 4.03706 13.2784C4.01283 13.2374 4.00004 13.1906 4 13.143V2.93336C4 2.79191 4.05619 2.65625 4.15621 2.55623C4.25623 2.45621 4.39188 2.40002 4.53333 2.40002ZM11.4667 3.46669H5.06667V11.6971L8.26667 9.69122L11.4667 11.6971V3.46669Z" fill="#121415"/>
                            </svg>                                      
                        </span>
                        <span className='ml-1'>Save For Later</span>
                    </button>
                </div>
                <div className='mar-spacer h-5' />
            </div>

            <Offcanvas show={show} onHide={handleClose} className='shopping-vas__removed'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex remove-link__head align-items-center'>
                        <span className='d-flex'>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.1525 2.85467L10 1.33301L16.8475 2.85467C17.0326 2.89581 17.198 2.99881 17.3167 3.14668C17.4353 3.29454 17.5 3.47844 17.5 3.66801V11.9905C17.4999 12.8136 17.2967 13.624 16.9082 14.3497C16.5198 15.0754 15.9582 15.694 15.2733 16.1505L10 19.6663L4.72667 16.1505C4.04189 15.694 3.48038 15.0756 3.09196 14.35C2.70353 13.6245 2.5002 12.8143 2.5 11.9913V3.66801C2.50003 3.47844 2.5647 3.29454 2.68332 3.14668C2.80195 2.99881 2.96745 2.89581 3.1525 2.85467ZM10.8333 8.83301V4.66634L6.66667 10.4997H9.16667V14.6663L13.3333 8.83301H10.8333Z" fill="white"/>
                            </svg>
                        </span>
                        <span className='ml-2'>Damage Protection Removed</span>
                    </div>
                    <div className='remove-link'>Undo</div>
                </div>
            </Offcanvas>

            <Offcanvas show={showSavedItems} onHide={handleCloseSavedItems} className='shopping-vas__removed'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex remove-link__head align-items-center'>
                        <span>Items moved to save for later</span>
                    </div>
                    <div className='remove-link' onClick={() => setModalShow(true)} role='button' aria-hidden="true">More Details</div>
                </div>
            </Offcanvas> 

            <ItemsRemoved 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default CartItems