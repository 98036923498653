import React from 'react'
import FaqAccordion from './FaqAccordion'

const Faqs = () => {
    return (
        <div>
            <div className="retunrefund-text-wrapper">
                <div className="retunrefund-heading-main">
                FAQs
                </div>
                <div className='mar-spacer'/>
               
                <FaqAccordion/>

              
                
             


                <div className='hi-100px'/>
            </div>
        </div>
    )
}

export default Faqs