import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import hdfc from '../../Images/Payment/HDFC.png';
import SBI from '../../Images/Payment/SBI.png';
import GPAY from '../../Images/Payment/GPAY.png';
import VisaLogo from '../../Images/Payment/VisaLogo.png';
import MasterLogo from '../../Images/Payment/MasterLogo.png';

const PaymentMethods = () => { 
    const [ istoggle, setToggle ] = useState(false);
    useEffect(() => {}, [istoggle])
    return (
        <>
            <div className='cart-payment__lists'>
                <div className='cart-payment__media'>
                    <img src={hdfc} className='img img-fluid' alt='' />
                </div>
                <div className='cart-payment__method'>
                    <div className='cart-payment__details'>
                        <div className='cart-payment__name'>HDFC Debit Card</div>
                        <div className='cart-payment__num'>
                            <span className='cart-payment-card_name'>Master Card </span>
                            <span className='cart-payment-card_num'>****2386</span>
                            <span className='cart-payment-card_icon'>
                                <img src={VisaLogo} className='img img-fluid' alt='' />
                            </span>
                        </div>
                        {istoggle ? (
                            <div className='mt-3'>
                                <div className='form-group-custom mb-1 col-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="CVV"
                                    >
                                        <Form.Control type="text" className='form-control form-control-custom text-center' placeholder='CVV' />
                                    </FloatingLabel>
                                </div>
                            </div>) :
                            <div className='cart-payment__offer'>Get 50% off on HDFC Debit Card</div>
                        }
                    </div>
                    <div className='cart-payment__btn'>
                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" onChange={()=>setToggle({toggle: !istoggle})} /> 
                    </div>
                </div>
            </div>

            <div className='cart-payment__lists'>
                <div className='cart-payment__media'>
                    <img src={SBI} className='img img-fluid' alt='' />
                </div>
                <div className='cart-payment__method'>
                    <div className='cart-payment__details'>
                        <div className='cart-payment__name'>SBI Debit Card</div>
                        <div className='cart-payment__num'>
                            <span className='cart-payment-card_name'>VISA Card </span>
                            <span className='cart-payment-card_num'>****2386</span>
                            <span className='cart-payment-card_icon'>
                                <img src={MasterLogo} className='img img-fluid' alt='' />
                            </span>
                        </div>
                    </div>
                    <div className='cart-payment__btn'>
                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                    </div>
                </div>
            </div>

            <div className='cart-payment__lists'>
                <div className='cart-payment__media'>
                    <img src={GPAY} className='img img-fluid' alt='' />
                </div>
                <div className='cart-payment__method'>
                    <div className='cart-payment__details'>
                        <div className='cart-payment__name'>Google Pay</div>
                    </div>
                    <div className='cart-payment__btn'>
                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentMethods