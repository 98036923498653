import React from 'react';
import Modal from 'react-bootstrap/Modal';
import EMIListTab from './EMIListTab';

const EmiModal = (props) => {
    // const handleChange = (newValue) => {
    //     setShowBankOffers(newValue);
    // }
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <h2 className='modal-heading mb-4'>EMI Options</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EMIListTab />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EmiModal