import React from 'react';

import Banner from '../../Images/Brand/Banner.png';

const BrandBanner = () => {
    return (
        <>
            <div className='brand-banner__wrap'
                style={{ 
                    backgroundImage:`url(${Banner})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom center',
                    backgroundSize: 'inherit',
                    padding: '116px 16px',
                    backgroundColor: '#32293B',
                    color: '#fff'
                }}
            >
                <div className='container'>
                    <div className='brand-banner__content'>
                        <h1 className='brand-banner__head'>Apple</h1>
                        <p className='brand-banner__info'>Upto 40% off on branded phones</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandBanner