import React from 'react';
import Form from 'react-bootstrap/Form';
//import WalletOffersList from './WalletOffersList';

import OlaMoney from '../../Images/Payment/1_4.png';
import Payzapp from '../../Images/Payment/1_2.png';
import Mobikiwik from '../../Images/Payment/1_1.png';
import FreeCharge from '../../Images/Payment/1_3.png';

const WalletsPayment = ({ setToggle }) => {

    return (
        <>
            <div className='upi-payment-list__wrap'>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={Mobikiwik} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>Mobikiwik</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                    <div className='cart-payment__offer mt-2'>Get 50% off on HDFC Debit Card</div>
                </div>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={Payzapp} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>Payzapp</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={FreeCharge} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>FreeCharge</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={OlaMoney} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>OlaMoney</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WalletsPayment