import React,{ useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';

const OrderReplaceMentTrackStrap = ({...props}) => {
    const [show, setShow] = useState(false);
    // const [nonsangeethatrackStrap ]=useState(2);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [exceptdeliverystrap]=useState(true)



    return (
        <>
            <button type='button' className='btn btn-dark-button btn-space bnt-h' onClick={handleShow}>Track</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Track Shipment</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {exceptdeliverystrap?    
                        <div className="trackshipment_canva-Model expected">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para expected-para'>Expected delivery by</p>
                                <h4 className='trackshipment_canva-msg expected-para'>Tomorrow  </h4>
                            </div>
                        </div>:
                       
                        <div className="trackshipment_canva-Model">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para '>Replacement Delivered on   </p>
                                <h4 className='trackshipment_canva-msg'>Wed, 6th Jan</h4>
                            </div>
                        </div> 
                    }

                    <div className="mar-spacer h-1"></div>                    
                    <div className="order_tracking-shipiment-strapper">
                        <ul className="StepProgress">
                            <li className="StepProgress-item is-done line-going">
                                <strong className={`  ${exceptdeliverystrap===1 ?'orderplace ':'orderplace going_on'}`}>Replacement requested</strong>
                                <span className='tracking_timing'>At 3:45pm, Today</span>
                            </li>
                            <li className= 'StepProgress-item is-done line-going'>
                                <strong className='orderplace going_on'>Pickup Scheduled</strong>
                                <span className='tracking_timing'>Pick up expected by Today</span>
                            </li>
                            
                            <li className='StepProgress-item  is-done line-going'>
                                <strong className='orderplace going_on'>Item is on the way to the store</strong>
                            </li>
                                   
                            <li className='StepProgress-item is-done line-going'>
                                <strong className='orderplace going_on'>Received at Sangeetha store</strong>
                                <span className='tracking_timing'>Expected Delivery by Today</span>
                            </li>
                                 
                            <li className='StepProgress-item is-done line-going'>
                                <strong className='orderplace going_on'>Verification Pending</strong>
                                <span className='tracking_timing'>Expected Verification by Tomorrow</span>
                            </li>                       
                            
                            <li className='StepProgress-item is-done line-going'>
                                <strong className='orderplace going_on'>Verification Successful</strong>
                                <span className='tracking_timing'>11pm Today</span>
                            </li> 
                            <li className='StepProgress-item is-done'>
                                <strong className='orderplace going_on'>Replacement is on the way</strong>
                                <span className='tracking_timing'>11pm Today</span>
                            </li>
                        
                            <li className='StepProgress-item'>
                                <strong>Replacement Delivery pending</strong>
                                <span className='tracking_timing'>Expected delivery by Today</span>
                            </li> 

                                   


                            
                            

                                   
                          

                            
                        </ul>
                        <div className="mar-spacer h-1_2"></div>

                    </div>                                     
                     

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default OrderReplaceMentTrackStrap