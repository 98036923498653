import React from 'react'
import Carousel from "react-multi-carousel";
import Banner1 from '../../Images/Category/1.jpg';
import Banner2 from '../../Images/Category/2.jpg';

const CategoryProduct = () => {
    const productsData = [
        {
            "_id": "1",
            "Image": Banner1,
        }, {
            "_id": "2",
            "Image": Banner2,
        }, {
            "_id": "3",
            "Image": Banner1
        }, {
            "_id": "4",
            "Image": Banner2
        },
    ];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
            partialVisibilityGutter: 70
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 3,
            partialVisibilityGutter: 70
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 3,
            partialVisibilityGutter: 50
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="models-carousel"
                responsive={responsive}
                swipeable
            >   
                {productsData.map((Data, idx) => {
                    return (
                        <div className='product-carousel-wrapper' key={idx}>
                            <div className='carousel-media-wrapper d-flex justify-content-center'>
                                <img
                                    draggable={true}
                                    src={Data.Image}
                                    alt=''
                                    className='img-fluid'
                                />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default CategoryProduct