import React from 'react'
import PopularBlogContent from '../BlogInnerContent/PopularBlogContent'

const PopularBlogTabs = () => {
    return (
        <>
            <div className="blog-filter-tab_wrapper blog-mr-t">
                <div className="filter_heading">
                    Filter by topic
                </div>
                <div className="product-cat-tags">
                    <div className="product-tag_label">Sangeetha Mobiles</div>
                    <div className="product-tag_label active">Oppo 
                        <span role="button">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.00042 6.17516L9.88792 3.28766L10.7128 4.11249L7.82525 6.99999L10.7128 9.88749L9.88792 10.7123L7.00042 7.82482L4.11292 10.7123L3.28809 9.88749L6.17559 6.99999L3.28809 4.11249L4.11292 3.28766L7.00042 6.17516Z" fill="white"/></svg>
                        </span>
                    </div>
                    <div className="product-tag_label">Vivo</div>
                    <div className="product-tag_label">Apple</div>
                    <div className="product-tag_label">Smartphones</div>
                    <div className="product-tag_label">Buying guide</div>
                    <div className="product-tag_label">Laptops</div>
                    <div className="product-tag_label">Camera</div>
                    <div className="product-tag_label">AC</div>
                    <div className="product-tag_label">5G</div>
                    <div className="product-tag_label">OnePlus</div>
                </div>
                <div className="popular-blog_contents">
                    <div className='mar-spacer h-1_2 mb-2' />

                    <PopularBlogContent/>
                </div>
            </div>
        </>
    )
}

export default PopularBlogTabs