import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import NetBankingModalList from './NetBankingModalList';
import HDFCLogo from '../../../Images/product/emi/HDFCLogo.png';
import IciciLogo from '../../../Images/product/emi/IciciLogo.png';
import AxisLogo from '../../../Images/product/emi/AxisLogo.png';
import SBI from '../../../Images/product/emi/SBI.png';

const NetBanking = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='upi-payment-list__wrap'>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={AxisLogo} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>Axis Bank</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                    <div className='cart-payment__offer mt-2'>Get 50% off on HDFC Debit Card</div>
                </div>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={IciciLogo} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>ICICI Bank</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={SBI} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>SBI Bank</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex align-items-center'>
                            <div className='upi-payment__media'>
                                <img src={HDFCLogo} className='img img-fluid' alt='' />
                            </div>
                            <span className='upi-payment__text'>HDFC Bank</span>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" defaultChecked />
                        </div>
                    </div>
                    <div className='upi-payment__add'>
                        <div className="cart-address__wrap pl-0 pb-0">
                            {/* {['bottom'].map((placement, idx) => (
                                <NetBankingModalList key={idx} placement={placement} name={placement} />
                            ))}  */}
                        </div>     
                    </div>
                </div>
            </div>
            <div className='upi-payment__add'>
                <div className='cart-address__wrap pl-0 pb-0'>
                    <div className='select-modal-dropdown' onClick={() => setModalShow(true)} aria-hidden="true">
                        <span>Drop Down</span>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0013 11.6663L6.66797 8.33301H13.3346L10.0013 11.6663Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>

            <NetBankingModalList
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default NetBanking