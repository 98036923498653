import React from 'react'
// import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
const Blogsearch = () => {
    return (
        <div>
            
            <Dropdown className='blog_search'>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="search-wrapper">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875V15.875Z" fill="#6B7586"/>
                            </svg>
                        </span>
                        <p className='Searc-mute'>Search</p>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown>                        
                        <div className="blogsearch_main">
                            <button className='search_btn_blog' type='button'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z" fill="#98A0AE"/></svg>
                            </button>
                            <input type="text" placeholder='Search by keyword, brand, product' className='input-here-blog'/>
                            <button className='blog_search-close' type='button'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9997 10.586L16.9497 5.63599L18.3637 7.04999L13.4137 12L18.3637 16.95L16.9497 18.364L11.9997 13.414L7.04974 18.364L5.63574 16.95L10.5857 12L5.63574 7.04999L7.04974 5.63599L11.9997 10.586Z" fill="#6B7586"/></svg>
                            </button>

                        </div>
                    </Dropdown>
                 
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default Blogsearch