import React from 'react';
import { CardlessEMIData } from '../../Data'; 

const CardlessList = () => {
    return (
        <>
            <div className='bank-offer-wrapper emi-tab-content'>
                <div className='product-payment-offers'>  
                    {CardlessEMIData.map((OffersItems, idx) => {                      
                        return(
                            <div to='/BankOffersDetails' className='payment-offer-options' key={idx}>
                                <div className='payment-offer-content w-100'>
                                    <span className='d-flex'>
                                        <img src={OffersItems.Img} className='img im-fluid' alt='' />
                                    </span>
                                    <div className='payment-offer-info'>
                                        <p className='emi-offer-title m-0'>{OffersItems.Title}</p>
                                        <p className='emi-offer-details m-0'>{OffersItems.EMIInfo}</p>
                                    </div>
                                </div>
                                <div className='highlight-body__link'>Apply</div>
                            </div>
                        );
                    })}
                </div>
            </div> 
        </>
    )
}

export default CardlessList