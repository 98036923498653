import { Link } from '@reach/router';
import React from 'react';

const Step2 = () => {
    return (
        <>
            <div className='step2__wrap'>
                <div className='step2-title__wrap mb-4'>Please enter OTP sent to 78*****389.
                    <Link to='/' className='step2-link__wrap'>Change</Link>
                </div>
                <div className='loginIn_wrap'>
                    <div className='cart-address__wrap mb-4'>
                        <form action="" className='otp_set ml-0'>
                            <input type='number' className='form-control otp_input' maxLength="1" />
                            <input type='number' className='form-control otp_input' maxLength="1" />
                            <input type='number' className='form-control otp_input' maxLength="1" />
                            <input type='number' className='form-control otp_input' maxLength="1"/>
                        </form>
                        <span className='login-error justify-content-start'>Please enter a valid OTP (4 digits)</span>
                    </div>
                    <div className="resend-otp mb-4">
                        <button type='button' className='Resend_OtpLink'>Resend OTP</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step2