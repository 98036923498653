import React from 'react'

const ChangeLanguage = () => {
    return (
        <>
            <div className="Profileordertab_wrapper">
                <div className="profileorder-div">
                    <div className='order-divleft-box'>
                        <div className="order-l-img">
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg"><g><path d="M4.2844 12.4998V14.1665C4.28426 14.587 4.44755 14.992 4.74152 15.3003C5.0355 15.6086 5.43844 15.7975 5.86956 15.829L5.99809 15.8332H8.56862V17.4998H5.99809C5.08909 17.4998 4.21732 17.1486 3.57457 16.5235C2.93181 15.8984 2.57071 15.0506 2.57071 14.1665V12.4998H4.2844ZM15.4234 8.33317L19.1935 17.4998H17.347L16.3179 14.9998H12.8134L11.7861 17.4998H9.94043L13.7097 8.33317H15.4234ZM14.5665 10.7373L13.498 13.3332H15.6333L14.5665 10.7373ZM6.85493 1.6665V3.33317H10.2823V9.1665H6.85493V11.6665H5.14124V9.1665H1.71387V3.33317H5.14124V1.6665H6.85493ZM14.5665 2.49984C15.4755 2.49984 16.3473 2.85103 16.9901 3.47615C17.6328 4.10127 17.9939 4.94912 17.9939 5.83317V7.49984H16.2802V5.83317C16.2802 5.39114 16.0997 4.96722 15.7783 4.65466C15.4569 4.3421 15.021 4.1665 14.5665 4.1665H11.996V2.49984H14.5665ZM5.14124 4.99984H3.42756V7.49984H5.14124V4.99984ZM8.56862 4.99984H6.85493V7.49984H8.56862V4.99984Z" fill="#6B7586"/></g><defs><clipPath id="clip0_1353_36455"><rect width="20.5643" height="20" fill="white"/></clipPath></defs></svg>
                            </span>
                        </div>
                        <div className="order-profilemid-container">
                            <h4>Change Prefered Language</h4>
                            <span>English </span>
                        </div>

                    </div>
                    
                    <div className="profileorder-arrow">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.172 12.0002L8.222 7.05023L9.636 5.63623L16 12.0002L9.636 18.3642L8.222 16.9502L13.172 12.0002Z" fill="#ffff"/></svg>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangeLanguage