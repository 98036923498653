import React,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const PersonalDetailsright = () => {
    const [modalShow, setModalShow] = useState(false);
    const [invalidno]=useState(false)

    const [updateNo,setUpdateno]=useState(true)
    const[errormail]=useState(false)

    return (
        <>
            <div className='order-success__wrapper'>
                <div className='container'>
                    <div className='order-confirmation m-0'>
                        <div className="profile-details-wrapper-right"> 
                            <div className="address-input-width">
                                <div className='form-group-custom'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Mobile Number*"
                                    >
                                        <Form.Control type="number" className='form-control form-control-custom' placeholder='Mobile Number*' defaultValue="9836644212" />
                                        <span className='Check-number-input'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z" fill="#278C4C"/></svg>
                                        </span>
                                        <button className='mobileno-chnagebtn' onClick={() => setModalShow(true)} >Change</button>
                                    </FloatingLabel>
                                </div>
                                <div className='form-group-custom'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Full Name"
                                    >
                                        <Form.Control type="text" className='form-control form-control-custom' placeholder='Full Name' defaultValue="" />
                                    </FloatingLabel>
                                </div>
                                <div className='form-group-custom'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                    >
                                        <Form.Control type="text" className='form-control form-control-custom' placeholder='Email' defaultValue="" />
                                        {errormail?
                                            <p className='error-formail'>Please enter a valid email ID</p>:""}
                                    </FloatingLabel>
                                </div>
                                <div className='form-group-custom'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Date Of Birth"
                                    >
                                        <Form.Control type="date" className='form-control form-control-custom' placeholder='Date Of Birth' defaultValue="" />
                                    </FloatingLabel>
                                </div>
                            </div>                                                  
                        
                            
                            <button type="button" className="btn btn-dark-custom btn-padding" disabled ><span className="my-1 d-flex justify-content-center">Save Changes</span></button>
                        </div> 
                        <Modal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className='modal-md-custom items-removed__modal border-header-re'
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter" className='w-100 gst-title'>
                                    {updateNo?
                                        <h2 className='notifyMe-heading mb-4'style={{ display: updateNo ? 'block' : 'none' }}>Update your mobile number</h2>:
                                        <h2 className='notifyMe-heading mb-4'>Enter OTP</h2>}

                                    
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {updateNo?
                                    <div className='login-textbox__wrapper'style={{ display: updateNo ? 'block' : 'none' }}>
                                        <p className='enter-urmobileno'>Please enter the new mobile number</p>
                                        <div className='cart-gift__wrap'>
                                            <div className='form-group-custom'>                                         
                                                <div className='loginIn_wrap'>
                                                    <div className='cart-address__wrap'>
                                                        <input  type='number' className='form-control form-control-cart' placeholder='Mobile Number'  maxLength="10" />
                                                        <span className='absolute-login-input'>+91</span> 
                                                    </div>
                                                    {invalidno?
                                                        <span className='login-error'>Please enter a valid mobile number (10 digits)</span>:""}
                                                </div>
                                            </div>                                     
                                        </div>                                        
                                        <div className="continue-login_btn">
                                            <button type='button' className='btn btn-dark-custom w-100' onClick={() => setUpdateno(false)} >Continue</button>
                                        </div>  
                                    </div>  :

                                    <div className='login-otp__wrapper' style={{ display: updateNo ? 'none' : 'block' }}>
                                       
                                        <p className='Login-info mb-4 text-dark'>Please enter OTP sent to 78*****389 
                                            <button type='button' className='change-login-number'>Change</button>
                                        </p>
                                        <div className='loginIn_wrap'>
                                            <div className='cart-address__wrap '>
                                                <form action="" className='otp_set'>
                                                    <input type='number' className='form-control otp_input' maxLength="1" />
                                                    <input type='number' className='form-control otp_input' maxLength="1" />
                                                    <input type='number' className='form-control otp_input' maxLength="1" />
                                                    <input type='number' className='form-control otp_input' maxLength="1"/>
                                                </form>
                                                <span className='login-error justify-content-center'>Please enter a valid OTP (4 digits)</span>
                                            </div>
                                            <div className="resend-otp text-center">
                                                <button type='button' className='Resend_OtpLink'>Resend OTP</button>
                                            </div>
                                        </div>
                                        <div className='mar-spacer' />
                                        <div className="continue-login_btn">
                                            <button type='button' className='btn btn-dark-custom w-100'>Verify</button>
                                        </div> 
                                    </div>
                                }              
                            </Modal.Body>
                        </Modal>

                        

                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalDetailsright;