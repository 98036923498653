import { Link } from '@reach/router';
import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Wishlist = ({ wishlistActive, handleToggle }) => {
    const [ isWishlist ] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='product__icons'>
                <button type='button' className='product-share'>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0762 17.271L8.9662 14.484C8.47569 14.9709 7.85201 15.3017 7.17379 15.4346C6.49557 15.5676 5.79316 15.4968 5.15513 15.2311C4.51709 14.9654 3.97199 14.5168 3.58855 13.9418C3.20511 13.3668 3.00049 12.6911 3.00049 12C3.00049 11.3089 3.20511 10.6332 3.58855 10.0582C3.97199 9.48317 4.51709 9.03455 5.15513 8.76889C5.79316 8.50322 6.49557 8.43241 7.17379 8.56536C7.85201 8.69832 8.47569 9.02909 8.9662 9.51599L14.0762 6.72899C13.9009 5.90672 14.0275 5.04885 14.4328 4.31224C14.8381 3.57563 15.4949 3.00952 16.2833 2.71742C17.0717 2.42532 17.9388 2.42676 18.7262 2.72147C19.5136 3.01619 20.1686 3.58448 20.5714 4.32243C20.9742 5.06037 21.098 5.91866 20.92 6.74034C20.742 7.56202 20.2742 8.29218 19.6021 8.79729C18.93 9.3024 18.0986 9.54871 17.2598 9.49119C16.4211 9.43366 15.631 9.07613 15.0342 8.48399L9.9242 11.271C10.0261 11.7516 10.0261 12.2483 9.9242 12.729L15.0342 15.516C15.631 14.9238 16.4211 14.5663 17.2598 14.5088C18.0986 14.4513 18.93 14.6976 19.6021 15.2027C20.2742 15.7078 20.742 16.438 20.92 17.2596C21.098 18.0813 20.9742 18.9396 20.5714 19.6776C20.1686 20.4155 19.5136 20.9838 18.7262 21.2785C17.9388 21.5732 17.0717 21.5747 16.2833 21.2826C15.4949 20.9905 14.8381 20.4243 14.4328 19.6877C14.0275 18.9511 13.9009 18.0933 14.0762 17.271Z" fill="#121415"/>
                    </svg>
                </button>
                <button type='button' className='product-wishlist' onClick={handleShow} >
                    <div onClick={handleToggle} role='button' aria-hidden="true">
                        {wishlistActive ?
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.82097 12.7937C1.87038 11.6851 1.32077 10.2888 1.26075 8.82968C1.20072 7.3706 1.63377 5.93381 2.4901 4.75092C3.34642 3.56803 4.57613 2.70796 5.98098 2.30934C7.38584 1.91073 8.884 1.9968 10.234 2.55368H10.232L5.98997 6.79768L7.40397 8.21168L11.646 3.96968C12.8649 2.75061 14.5182 2.06569 16.2421 2.0656C17.966 2.06551 19.6194 2.75024 20.8385 3.96918C22.0575 5.18811 22.7425 6.8414 22.7425 8.56532C22.7426 10.2893 22.0579 11.9426 20.839 13.1617L12 21.9997L2.80797 12.8077L2.82097 12.7937Z" fill="#E25100"/>
                            </svg> :
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.1539 12.0356L19.1654 12.048L10.9948 20.2187L2.8241 12.048L2.83565 12.0356C1.88682 10.9294 1.39107 9.50549 1.44773 8.04923C1.5044 6.59296 2.10929 5.21191 3.14121 4.1828C4.17313 3.1537 5.55583 2.55257 7.01224 2.49987C8.46865 2.44718 9.89118 2.94682 10.9948 3.89866C12.0984 2.94682 13.5209 2.44718 14.9773 2.49987C16.4337 2.55257 17.8164 3.1537 18.8483 4.1828C19.8802 5.21191 20.4851 6.59296 20.5418 8.04923C20.5985 9.50549 20.1027 10.9294 19.1539 12.0356ZM4.39476 5.448C4.02332 5.81943 3.72868 6.26039 3.52766 6.74569C3.32664 7.231 3.22317 7.75115 3.22317 8.27644C3.22317 8.80173 3.32664 9.32188 3.52766 9.80719C3.72868 10.2925 4.02332 10.7334 4.39476 11.1049L10.9948 17.7049L17.5948 11.1049C18.3449 10.3547 18.7663 9.33731 18.7663 8.27644C18.7663 7.21557 18.3449 6.19815 17.5948 5.448C16.8446 4.69784 15.8272 4.27642 14.7663 4.27642C13.7054 4.27642 12.688 4.69784 11.9379 5.448L8.16632 9.21955L6.90943 7.96088L9.7201 5.15022C8.9514 4.53629 7.98342 4.22717 7.00118 4.28194C6.01894 4.33671 5.09133 4.75153 4.39565 5.44711L4.39476 5.448Z" fill="#121415"/>
                            </svg>
                        }
                    </div>
                </button>
            </div>

            <Offcanvas show={show} onHide={handleClose} className='shopping-vas__removed'>
                {isWishlist ?
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex remove-link__head align-items-center'>
                            <span className='d-flex'>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.35085 11.1619C1.55869 10.2381 1.10068 9.07446 1.05066 7.85856C1.00064 6.64265 1.36152 5.44533 2.07512 4.45959C2.78873 3.47385 3.81348 2.75712 4.98419 2.42494C6.15491 2.09276 7.40338 2.16449 8.52835 2.62855H8.52668L4.99168 6.16522L6.17001 7.34355L9.70502 3.80855C10.7208 2.79266 12.0985 2.2219 13.5351 2.22182C14.9717 2.22174 16.3495 2.79236 17.3654 3.80814C18.3813 4.82392 18.9521 6.20165 18.9522 7.63826C18.9522 9.07487 18.3816 10.4527 17.3658 11.4686L10 18.8336L2.34002 11.1736L2.35085 11.1619Z" fill="white"/>
                                </svg>
                            </span>
                            <span className='ml-2'>Added to Wishlist</span>
                        </div>
                        <Link to='/' className='remove-link'>View Wishlist</Link>
                    </div> :
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex remove-link__head align-items-center'>
                            <span className='d-flex'>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.1667 3.83317H18.3334V5.49984H16.6667V17.9998C16.6667 18.2209 16.5789 18.4328 16.4226 18.5891C16.2663 18.7454 16.0544 18.8332 15.8334 18.8332H4.16669C3.94567 18.8332 3.73371 18.7454 3.57743 18.5891C3.42115 18.4328 3.33335 18.2209 3.33335 17.9998V5.49984H1.66669V3.83317H5.83335V2.1665H14.1667V3.83317ZM7.50002 7.99984V14.6665H9.16669V7.99984H7.50002ZM10.8334 7.99984V14.6665H12.5V7.99984H10.8334Z" fill="white"/>
                                </svg>
                            </span>
                            <span className='ml-2'>Removed from Wishlist</span>
                        </div>
                        <div className='remove-link'>Undo</div>
                    </div>
                }
            </Offcanvas>
        </>
    )
}

export default Wishlist