import { Link } from '@reach/router';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import React from 'react';
import './footer.css';

const index = () => {
    return (
        <>
            <footer className='page-footer'>
                <div className='footer-padding'>
                    <div className='container'>
                        <div className='page-footer-row'>
                            <div className='page-footer-grid d-flex '>
                                <div className='page-footer-col'>
                                    <h5 className='footer-heading'>Sangeetha</h5>
                                    <div className='footer-link__wrap'>
                                        <Link to='/' className='footer-link'>Why Sangeetha</Link>
                                        <Link to='/' className='footer-link'>Terms and Conditions</Link>
                                        <Link to='/' className='footer-link'>Privacy Policy</Link>
                                        <Link to='/' className='footer-link'>Returns and Refunds</Link>
                                    </div>
                                </div>
                                <div className='page-footer-col'>
                                    <h5 className='footer-heading'>Shop By</h5>
                                    <div className='footer-link__wrap'>
                                        <Link to='/' className='footer-link'>Categories</Link>
                                        <Link to='/' className='footer-link'>Brands</Link>
                                        <Link to='/' className='footer-link'>Find a Store</Link>
                                        <Link to='/' className='footer-link'>Home</Link>
                                        <Link to='/' className='footer-link'>New Arrivals</Link>
                                    </div>
                                </div>
                                <div className='page-footer-col'>
                                    <h5 className='footer-heading'>Help</h5>
                                    <div className='footer-link__wrap'>
                                        <Link to='/' className='footer-link'>My Orders</Link>
                                        <Link to='/' className='footer-link'>FAQ</Link>
                                        <Link to='/' className='footer-link'>Returns</Link>
                                        <Link to='/' className='footer-link'>Cancellations</Link>
                                        <Link to='/' className='footer-link'>Payments</Link>
                                        <Link to='/' className='footer-link'>Customer Care</Link>
                                        <Link to='/' className='footer-link'>Coupons</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='page-footer-social'>
                                <div className='page-footer-social-col'>
                                    <h5 className='footer-heading'>Follow Us On</h5>
                                    <div className='footer__social'>
                                        <div className='social__wrap'>
                                            <FaFacebookF />
                                        </div>
                                        <div className='social__wrap'>
                                            <FaInstagram />
                                        </div>
                                        <div className='social__wrap'>
                                            <FaTwitter />
                                        </div>
                                        <div className='social__wrap'>
                                            <FaYoutube />
                                        </div>
                                    </div>
                                    <div className='mar-spacer' />
                                    <h6 className='footer-heading-h6'>Language</h6>
                                    <div className='footer__language'>
                                        <div className='lang__wrap'>Hindi</div>
                                        <div className='lang__wrap'>English</div>
                                        <div className='lang__wrap'>Kannada</div>
                                        <div className='lang__wrap'>Tamil</div>
                                        <div className='lang__wrap'>Malayalam</div>
                                        <div className='lang__wrap'>Telugu</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-copyright'>Copyright 2022 © Sangeetha Mobiles. All Rights Reserved.</div>
            </footer>
        </>
    )
}

export default index