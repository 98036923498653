import React from 'react'
import Carousel from "react-multi-carousel";
import ButtonGroup from '../Common/ButtonGroup';
import Banner1 from '../../Images/Home/HotestDeals/1.jpg';
import Banner2 from '../../Images/Home/HotestDeals/2.jpg';
import { Link } from '@reach/router';

const HottestDeals = () => {
    const productsData = [
        {
            "_id": "1",
            "Image": Banner1,
        }, {
            "_id": "2",
            "Image": Banner2,
        }, {
            "_id": "3",
            "Image": Banner1
        }, {
            "_id": "4",
            "Image": Banner2
        },
    ];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            partialVisibilityGutter: 50
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 2,
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1200, min: 464 },
            items: 2,
            partialVisibilityGutter: 0
        }
    };

    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="deals-carousel"
                responsive={responsive}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
                swipeable
            >   
                {productsData.map((Data, idx) => {
                    return (
                        <Link to='/ProductDetails' className='product-carousel-wrapper' key={idx}>
                            <div className='carousel-media-wrapper d-flex justify-content-center'>
                                <img
                                    draggable={true}
                                    src={Data.Image}
                                    alt=''
                                    className='img-fluid'
                                />
                            </div>
                        </Link>
                    );
                })}
            </Carousel>
        </>
    )
}

export default HottestDeals