import { Link } from '@reach/router'
import React from 'react'

const HomeDIY = () => {
    return (
        <>
            <div className='btn-suggestion__wrap spacing-block'>
                <Link to='/' className='flex-auto'>
                    <div className='btn-suggestion'>Buy Now</div>
                </Link>
                <Link to='/' className='flex-auto'>
                    <div className='btn-suggestion'>Smart Watch</div>
                </Link>
                <Link to='/' className='flex-auto'>
                    <div className='btn-suggestion'>Earphones</div>
                </Link>
                <Link to='/' className='flex-auto'>
                    <div className='btn-suggestion'>Speakers</div>
                </Link>
                <Link to='/' className='flex-auto'>
                    <div className='btn-suggestion'>Security Cameras</div>
                </Link>
                <Link to='/' className='flex-auto'>
                    <div className='btn-suggestion'>Lights</div>
                </Link>
            </div>
        </>
    )
}

export default HomeDIY