import React from 'react';
import Carousel from "react-multi-carousel";
import { Link } from '@reach/router';
import Img1 from '../../Images/Home/exclusive/1.jpg';
import Img2 from '../../Images/Home/exclusive/2.jpg';

const Exclusive = () => {
    const ExclusiveData = [
        {   
            _Id: 1,
            Image: Img2
        }, {   
            _Id: 2,
            Image: Img1
        }
    ];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 0
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={false}
                arrows={false}
                itemClass="carousel-image-item"
                responsive={responsive}
            >   
                {ExclusiveData.map((Data) => {
                    return (
                        <Link to='/ProductDetails' className='exclusive-items-wrapper' key={Data._Id}>
                            <div className='exclusive-content-wrapper'>
                                <img src={Data.Image} className='img-fluid w-100' alt='' />
                            </div>
                        </Link>
                    )
                })}
            </Carousel>
        </>
    )
}

export default Exclusive
