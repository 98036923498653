import React from 'react';
import Sale from '../Home/Sale';
import RelatedCategory from '../ProductDetails/RelatedCategory';
import emptyWishlist from '../../Images/Wishlist/empty.png';

const WishlistEmptyState = () => {
    return (
        <>
            <div className='cartEmpty__wrap'>
                <div className='cart-empty__wrap'>
                    <div className='cart-empty__media'>
                        <img src={emptyWishlist} className='img img-fluid' alt='' />
                    </div>
                    <div className='cart-empty__info'>
                        <h5>Your Wishlist is empty</h5>
                        <p>Save gadgets that you like in your wishlist and revive them anytime!</p>
                    </div>
                    <button type='button' className='btn btn-dark-custom btn-dark-custom__empty'>Start Shopping</button>
                </div>
            </div>

            <div className='pdp-product-recommend'>
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <h4 className='pdp-heading--text'>Recently Viewed Items</h4>
                        <Sale />
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-5 mt-5' />
            <div className='pdp-related-category'>
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <h4 className='pdp-heading--text'>Related Categories</h4>
                        <RelatedCategory />
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-5 mt-5' />
        </>
    )
}

export default WishlistEmptyState