import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

const SortFilter = () => {
    const [ BankOffers ] = useState([
        { _Id: 1, code: '1', title: 'Relevance'},
        { _Id: 2, code: '2', title: 'Recommended'},
        { _Id: 3, code: '3', title: 'Price: High to Low High to Low'},
        { _Id: 4, code: '4', title: 'Price: Low to High'}
    ]);
    let chosenValue = BankOffers[0]
    const [toggleContents, setToggleContents] = useState(chosenValue.title);
    // eslint-disable-next-line no-unused-vars
    const [ selectedFilter, setSelectedFilter ] = useState();
    return (
        <>
            <div className='sort-by-filter'>
                <Form>
                    <Dropdown
                        onSelect={eventKey => {
                            const {title } = BankOffers.find(({ code }) => eventKey === code);
            
                            setSelectedFilter(eventKey);
                            setToggleContents(
                                <div className='upi-payment__list'>
                                    <div className='d-flex align-items-center'>
                                        <span className='upi-payment__text'>{title}</span>
                                    </div>                                    
                                </div>
                            );
                        }}
                        className="d-flex"
                    >
                        <Dropdown.Toggle id="dropdown-autoclose-true" className='dropdown-toggle-box'>
                            <div className='d-flex'>
                                <span className='sort-by__label'>Sort by : {' '}</span>
                                {toggleContents}
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className=''>
                            {BankOffers.map((SelectData) => (
                                <Dropdown.Item key={SelectData.code} eventKey={SelectData.code} className='p-2'>
                                    <div className='upi-payment__list'>
                                        <div className='d-flex align-items-center'>
                                            <span className='upi-payment__text'>{SelectData.title}</span>
                                        </div>                                    
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form>
            </div>
        </>
    )
}

export default SortFilter