import React, { useState } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form'

import TabContent from 'react-bootstrap/TabContent';
import CardlessEMI from "./CardlessEMI";
//import DebitEmi from './DebitEmi';
import CreditEmi from './CreditEmi';

const EMIListTab = () => {
    const [key, setKey] = useState('creditEmi');

    return (
        <>  
            <div className='phone-offers__wrap'>
                <div className="product-details__tabs emi-list__tabs">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="d-flex justify-content-center flex-nowrap emi-tabs"
                    >
                        <Tab eventKey="creditEmi" title="Credit EMI">
                            <TabContent>
                                <div className="tab-specific px-0 mt-1 pb-0">
                                    <div className='mar-spacer h-1_5' />
                                    <div className="tab-specific debitcard">
                                        <div className="nocostemi-option">
                                            <p>View only ‘No Cost EMI’ options</p>
                                            <Form>
                                                <Form.Check 
                                                    type="switch"
                                                    id="custom-switch"                                                
                                                />                                         
                                            </Form>

                                        

                                            {/* <input type="check" /> */}
                                            {/* <BootstrapSwitchButton checked={true} onstyle="dark" /> */}
                                        </div>
                                        <CreditEmi/>
                                    
                                    </div>
                                </div>
                            </TabContent>
                        </Tab>
                        <Tab eventKey="debitEmi" title="Debit EMI">
                            <TabContent>
                                <div className="tab-specific px-0 mt-1 pb-0">
                                    <CreditEmi/>
                                </div>
                            </TabContent >
                        </Tab>
                        <Tab eventKey="CardlessEmi" title="Cardless EMI">
                            <TabContent>
                                <div className="tab-specific px-0 mt-1 pb-0">
                                    <div className='mar-spacer h-1_5' />
                                    <CardlessEMI />
                                </div>
                            </TabContent>
                        </Tab>
                    </Tabs>  
                </div> 
            </div>     
        </>
    )
}

export default EMIListTab