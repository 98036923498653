import { Link } from '@reach/router';
import React, {useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

import img1 from '../../Images/Order/1.png';
import img2 from '../../Images/Order/2.png';

const TrackPanel = () => {
    const [show, setShow] = useState(false); 

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button type='button' className='btn btn-dark-custom btn-track' onClick={handleShow}>Track</button> 
            <Offcanvas show={show} onHide={handleClose} className='cart-pdp-offcanvas address-padding'>
                <Offcanvas.Header closeButton>
                    <div className="offcanvas-title-track">
					Track Shipment
                    </div>
                
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <div className=''>
                        <div className='order-track__content'>
                            <div className='orders_wrap'>
                                <div className='orders-list_wrap d-flex align-items-start'>
                                    <div className='orders-media__wrap'>
                                        <img src={img1} className='img-fluid' alt='' />
                                        <div className='orders__tag'>
                                            <div className='orders_content'>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='orders-media__wrap'>
                                        <img src={img2} className='img-fluid' alt='' />
                                    </div>
                                </div>
                                <Link to='/OrderDetails' className='d-flex'>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>

                        <div className="trackshipment_canva-Model expected mt-4">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para mb-0'>Expected delivery by
                                    <span className='expected-para'>Wed, 6th Jan</span>
                                </p>
                            </div>
                        </div>

                        <div className="order_tracking-shipiment-strapper mt-4">
                            <div className="stepper trackingstrappper">
                                <div className="d-flex mb-1">
                                    <div className="starpper_round-linemain">
                                        <div className="circleroundedstraper circle-check"></div>
                                        <div className="line line-dark"></div>
                                    </div>
                                    <div className="stapper_leftpadding"><strong className="strapperhead">Order Placed</strong>
                                        <span className="tracking_timing">At 3:45pm, Today</span></div>
                                </div>
                                <div className="d-flex mb-1">
                                    <div className="starpper_round-linemain">
                                        <div className="circleroundedstraper circle-check"></div>
                                        <div className="line"></div>
                                    </div>
                                    <div className="stapper_leftpadding"><strong className="strapperhead">Order is on the way</strong></div>
									
                                </div>
                                <div className="d-flex mb-1">
                                    <div className="starpper_round-linemain">
                                        <div className="circleroundedstraper"></div>
                                        <div className="line"></div>
                                    </div>
                                    <div className="stapper_leftpadding"><strong className="strapperhead">Order is on the way</strong>
                                        <span className="tracking_timing">Delhivery : 3477KL67</span>
                                        <div className='track-details__link'>Show details</div>
                                    </div>
                                </div>
                                <div className="d-flex mb-1">
                                    <div className="starpper_round-linemain">
                                        <div className="circleroundedstraper"></div>
                                        {/* <div className="line"></div> */}
                                    </div>
                                    <div className="stapper_leftpadding"><strong className="strapperhead">Verification Successful</strong>
                                        <span className="tracking_timing">Expected Delivery by Wed, 6th Jan</span></div>
                                </div>
                            </div>
                            <div className="mar-spacer h-1_2"></div>
                        </div>

                        
                    </div>                   
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default TrackPanel