import React from 'react';
import Modal from 'react-bootstrap/Modal';
import canara from '../../Images/Payment/Banks/1.png';
import Catholic from '../../Images/Payment/Banks/2.png';
import City from '../../Images/Payment/Banks/3.png';
import Corporation from '../../Images/Payment/Banks/4.png';
import DCB from '../../Images/Payment/Banks/5.png';
import Deutsche from '../../Images/Payment/Banks/6.png';
import Dhanlaxmi from '../../Images/Payment/Banks/7.png';

const NetBankingModalList = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom items-removed__modal'
            >
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 gst-title-'>
                        <h2 className='notifyMe-heading mb-4'>Other Banks</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='input-group-banks input-group-location mb-4'>
                        <input type='number' className='form-control form-control-cart' placeholder='Search for banks' />
                        <span className='form-control-search d-flex'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.0257 13.8475L18.5948 17.4158L17.4157 18.595L13.8473 15.0258C12.5196 16.0901 10.8682 16.669 9.1665 16.6666C5.0265 16.6666 1.6665 13.3066 1.6665 9.16663C1.6665 5.02663 5.0265 1.66663 9.1665 1.66663C13.3065 1.66663 16.6665 5.02663 16.6665 9.16663C16.6689 10.8683 16.09 12.5197 15.0257 13.8475ZM13.354 13.2291C14.4116 12.1415 15.0022 10.6837 14.9998 9.16663C14.9998 5.94329 12.389 3.33329 9.1665 3.33329C5.94317 3.33329 3.33317 5.94329 3.33317 9.16663C3.33317 12.3891 5.94317 15 9.1665 15C10.6835 15.0023 12.1414 14.4117 13.229 13.3541L13.354 13.2291Z" fill="#98A0AE"/>
                            </svg>
                        </span>
                    </div>
                    <div className='bank-list__wrap'>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap justify-content-start align-items-center'>
                                <div className='upi-payment__media m-0'>
                                    <img src={canara} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Canara Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap justify-content-start align-items-center'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Catholic} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Catholic Syrian Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap justify-content-start align-items-center'>
                                <div className='upi-payment__media m-0'>
                                    <img src={City} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>City Union Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap justify-content-start align-items-center'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Corporation} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Corporation Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap justify-content-start align-items-center'>
                                <div className='upi-payment__media m-0'>
                                    <img src={DCB} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>DCB BANK Personal</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap justify-content-start align-items-center'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Deutsche} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Deutsche Bank</span>
                            </div>
                        </div>
                        <div className='upi-payment__list'>
                            <div className='upi-payment__wrap justify-content-start align-items-center'>
                                <div className='upi-payment__media m-0'>
                                    <img src={Dhanlaxmi} className='img img-fluid' alt='' />
                                </div>
                                <span className='upi-payment__text ml-2'>Dhanlaxmi Bank</span>
                            </div>
                        </div>
                    </div>            
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NetBankingModalList
