import React from 'react';
import Sale from '../Home/Sale';
import RelatedCategory from '../ProductDetails/RelatedCategory';
import emptyCart from '../../Images/Cart/empty.png';

const CartEmptyState = () => {
    return (
        <>

            <div className='cartEmpty__wrap'>
                <div className='cart-empty__wrap'>
                    <div className='cart-empty__media'>
                        <img src={emptyCart} className='img img-fluid' alt='' />
                    </div>
                    <div className='cart-empty__info'>
                        <h5>Smart gadgets are just a step away</h5>
                        <p>Your cart is empty. Let’s add some items</p>
                    </div>
                    <button type='button' className='btn btn-dark-custom btn-dark-custom__empty'>Add Items from wishlist</button>
                </div>
            </div>

            <div className='pdp-product-recommend'>
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <h4 className='pdp-heading--text'>Recently Viewed Items</h4>
                        <Sale />
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-5 mt-5' />
            <div className='pdp-related-category'>
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <h4 className='pdp-heading--text'>Related Categories</h4>
                        <RelatedCategory />
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-5 mt-5' />
        </>
    )
}

export default CartEmptyState