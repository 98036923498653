import React from 'react';
import Carousel from "react-multi-carousel";
import CustomDot from '../Product/CustomDot';
import { Link } from '@reach/router';
import {useSelector} from 'react-redux';
// import HomeBanner1 from '../../Images/Home/homeBanner.png';

const HomeGallery = () => {
    const homeBanner = useSelector(state => state.homeDetails.homeBanner);
    // const {homeDetails} = homeDetailsData;
    // console.log("Child:",homeBanner)
    // const homeBanner = [];
    if(homeBanner){
        return (
            <>
                {/* {homeBanner && homeBanner.map((dataList) => (
                    <img src={dataList.image} className='img img-fluid w-100' alt='' key={dataList.placeholder_id} />
                ))} */}
    
                <Carousel
                    partialVisible={false}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="carouser-container"
                    customDot={<CustomDot />}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 1
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 1
                        }
                    }}
                    showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                
                
                    {homeBanner && homeBanner.map((dataList) => (
                        <div className='home-banner-slider' key={dataList.placeholder_id}>
                            <div className='home-gallery-section'>
                                <div className='banner-info__wrap'>
                                    <h2 className='banner-title'>Samsung A32 5G</h2>
                                    <h4 className='banner-info-title'>Up to 25% on Oneplus Phones</h4>
                                    <div className='banner-info-text'>*T&C Applied</div>
                                    <div className='home-banner-btn'>
                                        <Link to='/Cart' className='btn btn-home-banner'>Buy Now</Link>
                                    </div>
                                </div>
                            </div>
                            <img src={dataList.image} className='img img-fluid w-100' alt='' />
                        </div>
                    ))}
                    {/* <div className='home-banner-slider'>
                        <div className='home-gallery-section'>
                            <div className='banner-info__wrap'>
                                <h2 className='banner-title'>Samsung A32 5G</h2>
                                <h4 className='banner-info-title'>Up to 25% on Oneplus Phones</h4>
                                <div className='banner-info-text'>*T&C Applied</div>
                                <div className='home-banner-btn'>
                                    <Link to='/Cart' className='btn btn-home-banner'>Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                    </div>
                    <div className='home-banner-slider'>
                        <div className='home-gallery-section'>
                            <div className='banner-info__wrap'>
                                <h2 className='banner-title'>Samsung A32 5G</h2>
                                <h4 className='banner-info-title'>Up to 25% on Oneplus Phones</h4>
                                <div className='banner-info-text'>*T&C Applied</div>
                                <div className='home-banner-btn'>
                                    <Link to='/Cart' className='btn btn-home-banner'>Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                    </div>
                    <div className='home-banner-slider'>
                        <div className='home-gallery-section'>
                            <div className='banner-info__wrap'>
                                <h2 className='banner-title'>Samsung A32 5G</h2>
                                <h4 className='banner-info-title'>Up to 25% on Oneplus Phones</h4>
                                <div className='banner-info-text'>*T&C Applied</div>
                                <div className='home-banner-btn'>
                                    <Link to='/Cart' className='btn btn-home-banner'>Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                    </div>
                    <div className='home-banner-slider'>
                        <div className='home-gallery-section'>
                            <div className='banner-info__wrap'>
                                <h2 className='banner-title'>Samsung A32 5G</h2>
                                <h4 className='banner-info-title'>Up to 25% on Oneplus Phones</h4>
                                <div className='banner-info-text'>*T&C Applied</div>
                                <div className='home-banner-btn'>
                                    <Link to='/Cart' className='btn btn-home-banner'>Buy Now</Link>
                                </div>
                            </div>
                        </div>
                        <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                    </div> */}
                </Carousel>
    
            </>
        )
    }
}

export default HomeGallery