import React from 'react'

const ProfileOrderTab = () => {
    return (
        <> 
            <div className="Profileordertab_wrapper">
                <div className="profileorder-div">
                    <div className='order-divleft-box'>
                        <div className="order-l-img">
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg"><g> <path d="M2.57071 2.5H17.9939C18.2212 2.5 18.4391 2.5878 18.5998 2.74408C18.7605 2.90036 18.8508 3.11232 18.8508 3.33333V16.6667C18.8508 16.8877 18.7605 17.0996 18.5998 17.2559C18.4391 17.4122 18.2212 17.5 17.9939 17.5H2.57071C2.34346 17.5 2.12552 17.4122 1.96483 17.2559C1.80414 17.0996 1.71387 16.8877 1.71387 16.6667V3.33333C1.71387 3.11232 1.80414 2.90036 1.96483 2.74408C2.12552 2.5878 2.34346 2.5 2.57071 2.5ZM7.71178 10C7.71178 10.663 7.9826 11.2989 8.46467 11.7678C8.94674 12.2366 9.60056 12.5 10.2823 12.5C10.9641 12.5 11.6179 12.2366 12.1 11.7678C12.582 11.2989 12.8528 10.663 12.8528 10H17.1371V4.16667H3.42756V10H7.71178Z" fill="#6B7586"/></g><defs><clipPath id="clip0_1353_36386"><rect width="20.5643" height="20" fill="white"/></clipPath></defs></svg>
                            </span>
                        </div>
                        <div className="order-profilemid-container">
                            <h4>Orders</h4>
                            <span>Track your orders</span>
                        </div>

                    </div>
                    
                    <div className="profileorder-arrow">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M13.172 12.0002L8.222 7.05023L9.636 5.63623L16 12.0002L9.636 18.3642L8.222 16.9502L13.172 12.0002Z" fill="#fff"/></svg>
                        </span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProfileOrderTab