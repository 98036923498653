import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import ArrowBtn from './ArrowBtn';
import OfferModal from './OfferModal';
import { BankOfferData } from '../../Data';

const Offers = () => {
    const [modalShow, setModalShow] = useState(false);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 2,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 2,
            partialVisibilityGutter: 0
        }
    };
    return (
        <>
            <div className='position-relative'>
                <Carousel                
                    partialVisible={true}
                    arrows={false}
                    itemClass="image-item"
                    responsive={responsive}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ArrowBtn />}
                    swipeable
                >   
                    {BankOfferData.map((BankOffer) => {
                        return (
                            <div className='offer-box product-offer-wrapper- offer-box- why-buy- m-l-16' key={BankOffer._id}>
                                <div className='card__wrap'>
                                    <h5>{BankOffer.Title}</h5>
                                    <p className='mb-0 offer-box-info'>{BankOffer.OfferDetails}</p>
                                    <div className='flexible'></div>
                                    <div className='offer-link' onClick={() => setModalShow(true)} role='button' aria-hidden="true">View 4 Offers</div>
                                    {/* <Link to={`/BankOffersList/${BankOffer._id}`}>View</Link> */}
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
            <OfferModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />  
        </>
    )
}

export default Offers