import React, { useEffect, useState } from 'react';
import ProductGallery from '../../Components/ProductDetails/ProductGallery';
import ProductInfo from '../../Components/ProductDetails/ProductInfo';
import KeyFeatures from '../../Components/ProductDetails/KeyFeatures';
import ProductColors from '../../Components/ProductDetails/ProductColors';
import Offers from '../../Components/ProductDetails/Offers';
import Location from '../../Components/ProductDetails/Location';
import EmiOptions from '../../Components/ProductDetails/EmiOptions';
import Specifications from '../../Components/ProductDetails/Specifications';
import CancellationPolicy from '../../Components/ProductDetails/CancellationPolicy';
import WatchBuyTogether from '../../Components/ProductDetails/CategoryInfo/WatchBuyTogether';
import WhySangeetha from '../../Components/ProductDetails/WhySangeetha';
import EarphoneHighlights from '../../Components/ProductDetails/CategoryInfo/EarphoneHighlights';
import ReviewVideo from '../../Components/ProductDetails/Review';
import Sale from '../../Components/Home/Sale';
import RelatedCategory from '../../Components/ProductDetails/RelatedCategory';
import { EarphoneImageGallery, EarphoneSpecifications, EarphoneReview, productsEarphoneInfo } from '../../Data';

import '../../Components/ProductDetails/ProductDetails.css';

const EarphoneDetails = () => {
    const [ productData, setProductData ] = useState([]);
    const [ specificData, setData ] = useState([]);
    const [ genaralData, setgenaralData ] = useState([]);
    const [ reviewData, setReviewData ] = useState([]);

    console.log('Result main', specificData);

    useEffect(() => {
        setProductData(productsEarphoneInfo[0])
        setData(EarphoneSpecifications[0].Specific)
        setgenaralData(EarphoneSpecifications[1].General)
        setReviewData(EarphoneReview[0])
    }, []);
    return (
        <>
            <div className='product-details-wrapper Earphone_wrapper'>
                <div className='container'>
                    <div className='pdp-container'>
                        <div className='product-gallery-container'>
                            <div className='sticky-container'>
                                <ProductGallery DataList={EarphoneImageGallery} productData={productData}/>                            
                            </div>
                        </div>
                        <div className='product-details-container'>
                            <div className='pdp-info'>
                                <ProductInfo productData={productData}/>
                            </div>
                            <div className='pdp-key-features'>
                                <KeyFeatures />
                            </div>
                            <div className='mar-spacer h-2' />
                            <div className='pdp-colors'>
                                <h4 className='pdp-heading'>Colors</h4>
                                <ProductColors />
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='product-policies'>
                                <div className='days-policies border-r1'>
                                    <span className='policies-icon'>
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.50065 14.6654C4.81865 14.6654 1.83398 11.6807 1.83398 7.9987C1.83398 4.3167 4.81865 1.33203 8.50065 1.33203C12.1827 1.33203 15.1673 4.3167 15.1673 7.9987C15.1673 11.6807 12.1827 14.6654 8.50065 14.6654ZM8.50065 13.332C9.91514 13.332 11.2717 12.7701 12.2719 11.7699C13.2721 10.7697 13.834 9.41319 13.834 7.9987C13.834 6.58421 13.2721 5.22766 12.2719 4.22746C11.2717 3.22727 9.91514 2.66536 8.50065 2.66536C7.08616 2.66536 5.72961 3.22727 4.72941 4.22746C3.72922 5.22766 3.16732 6.58421 3.16732 7.9987C3.16732 9.41319 3.72922 10.7697 4.72941 11.7699C5.72961 12.7701 7.08616 13.332 8.50065 13.332ZM5.16732 8.66536H11.1673V9.9987H8.50065V11.9987L5.16732 8.66536ZM8.50065 5.9987V3.9987L11.834 7.33203H5.83398V5.9987H8.50065Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span className='policies-label'>7 days Return</span>
                                </div>
                                <div className='days-policies'>
                                    <span className='policies-icon'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00065 2H14.0007C14.1775 2 14.347 2.07024 14.4721 2.19526C14.5971 2.32029 14.6673 2.48986 14.6673 2.66667V13.3333C14.6673 13.5101 14.5971 13.6797 14.4721 13.8047C14.347 13.9298 14.1775 14 14.0007 14H2.00065C1.82384 14 1.65427 13.9298 1.52925 13.8047C1.40422 13.6797 1.33398 13.5101 1.33398 13.3333V2.66667C1.33398 2.48986 1.40422 2.32029 1.52925 2.19526C1.65427 2.07024 1.82384 2 2.00065 2ZM13.334 7.33333H2.66732V12.6667H13.334V7.33333ZM13.334 6V3.33333H2.66732V6H13.334ZM9.33398 10H12.0007V11.3333H9.33398V10Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span className='policies-label'>Doorstep EMI</span>
                                </div>
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='pdp-Offers'>
                                <h4 className='pdp-heading'>Offers</h4>
                                <Offers />
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='product-payment-offers'>
                                <div className='pdp-location'>
                                    <Location />
                                </div>
                                <div className='pdp-emi'>
                                    <EmiOptions />
                                </div>
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='pdp-specifications'>
                                <h4 className='pdp-heading'>Specifications</h4>
                                <Specifications specificData={specificData} genaralData={genaralData} />
                            </div>
                            <div className='mar-spacer h-4' />
                            <CancellationPolicy />
                            <div className='product-buy-together specification-head'>
                                <h4 className='pdp-heading'>Buy together, save more</h4>
                                <WatchBuyTogether />
                            </div>
                            <div className='border-divider' />
                            <div className='mar-spacer h-3' />
                        </div>
                    </div>
                </div>
                <div className='pdp-details__container'>
                    <div className='container'>
                        <div className='why-sangeetha__bg'>
                            <h4 className='pdp-info-heading text-center'>Why Buy From Sangeetha?</h4>
                            <div className='h-1_1 mar-spacer' />
                            <div className='why-sangeetha__flex'>
                                <WhySangeetha />
                            </div>
                        </div>
                    </div>
                </div>                
                <div className='mar-spacer h-5'></div>
                <section className='pdp-highlights'>
                    <h4 className='pdp-info-heading text-center'>Highlights</h4>
                    <EarphoneHighlights />
                </section>
                <div className='mar-spacer h-5'></div>
                <section className='pdp-review'>
                    <div className='container'>
                        <h4 className='pdp-heading text-center'>Watch Galaxy Fold’s Expert Review</h4>
                        <div className='mar-spacer h-1_1'></div>
                        <ReviewVideo reviewData={reviewData} />
                    </div>
                </section>
                <div className='space-top-160 pdp-product-recommend'>
                    <div className='container'>
                        <h4 className='pdp-heading'>Sangeetha Recommends</h4>
                        <div className='spacing-block position-relative'>
                            <Sale />
                        </div>
                    </div>
                </div>
                <div className='space-top-160 pdp-product-recommend'>
                    <div className='container'>
                        <h4 className='pdp-heading'>Sangeetha Recommends</h4>
                        <div className='spacing-block position-relative'>
                            <Sale />
                        </div>
                    </div>
                </div>
                <div className='space-top-160 pdp-related-category'>
                    <div className='container'>
                        <div className='spacing-block position-relative'>
                            <h4 className='pdp-catg-heading'>Related Categories</h4>
                            <RelatedCategory />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EarphoneDetails