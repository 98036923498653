import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AddressPanel from './AddressPanel';
import addressempty from '../../Images/Address/noaddress.png'

const AddressList = () => {
    const [show, setShow] = useState(false);
    //const [modalShow, setModalShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // address is not there
    const[noaddress]=useState(true)
    return (
        <>
            <div className='cart-address__wrap mb-4'>
                <div className='cart-address__default'>
                    {noaddress?
                        <div className='logged-default__list border-0 mb-4'>
                            <div className='logged-default__label mb-2'>
                                <span>Rohan Nanda <span className='default__address'>(Default)</span></span>
                                <span className="btn btn-tag">Home</span>
                            </div>
                            <div className='cart-address__section'>
                                <div className='loggedIn-address__label mb-2'>
                                        103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                </div>
                                <div className='loggedIn-address__contact'>
                                    <label htmlFor="label">Mobile: </label>{' '}
                                    <span className='contact-number'>7382901648</span>
                                </div>
                            </div>
                        </div>:
                        
                        <div className='logged-default__list border-0 mb-4'>
                            <div className="nooaddress-erroeimg">
                                <img src={addressempty} alt="Addressempty" />
                            </div>
                        </div>}
                    {noaddress?    
                        <div className='cart-address-btn__wrap'>
                            <button type='button' onClick={handleShow} className='btn btn-dark-custom-outline outline-custom py-2 w-100'>Change or add address</button>
                        </div>:

                        <div className='cart-address-btn__wrap'>
                            <button type='button' onClick={handleShow} className='btn btn-dark-custom-outline outline-custom py-2 w-100'>Add address</button>
                        </div>}
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose} className='cart-pdp-offcanvas address-offcanvas'>
                <AddressPanel  />
            </Offcanvas>
        </>
    )
}

export default AddressList