import React from 'react'
// import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form'


const BlogShort = () => {
    return (
        <div>
         
            <Dropdown className='m_left-34 blog_short'>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="search-wrapper ">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8H8.001L8 20H6V8H2L7 3L12 8ZM22 16L17 21L12 16H16V4H18V16H22Z" fill="#6B7586"/>
                            </svg>
                        </span>
                        <p className='Searc-mute'>Sort</p>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown>
                        <div className="blog_sort-container">
                            <div className="sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Date: New to Old</label>
                            </div>
                            <div className="sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Date: Old to New</label>
                            </div>  <div className="sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Popularity</label>
                            </div>  <div className="sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='filter_sort'>Most Read</label>
                            </div>

                        </div>
                    </Dropdown>
                  
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default BlogShort