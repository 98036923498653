import React from 'react';
import { Link } from '@reach/router';
import '../../../Components/Order/Order.css'
import img1 from '../../../Images/Order/1.png';
import img2 from '../../../Images/Order/2.png';
import img3 from '../../../Images/Order/3.png';
import img4 from '../../../Images/Order/4.png';

const TrackOrder = () => {

    return (
        <div>
            <div className='order-success__wrapper'>
                <div className='container'>
                    <div className='order-confirmation m-0'>
                        <div className='profile-order-banner d-flex align-items-center'>
                            <span className=''>Recent</span>
                        </div>                      
                        <div className='order-track__wrap'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Order is on the way</h6>
                                    <p className='order-placed__info mb-0'>Arriving by 4:20 pm, Today</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="bg-theme"></div>
                        <div className='order-track__wrap'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Order placed</h6>
                                    <p className='order-placed__info mb-0'>Expected delivery by Wed, 6th Jan</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                       
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                       
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="h-1_5"/>
                        <div className='profile-order-banner d-flex align-items-center'>
                            <span className=''>3 months older</span>
                        </div>
                        <div className='order-track__wrap'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Replacement Requested</h6>
                                    <p className='order-placed__info mb-0'>Usually takes 7-8 days for replacement</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="bg-theme"/>
                        <div className='order-track__wrap'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Replacement Requested</h6>
                                    <p className='order-placed__info mb-0'>Usually takes 7-8 days for replacement</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='profile-order-banner d-flex align-items-center mt-3'>
                            <span className=''>6 months older</span>
                        </div>
                        <div className='order-track__wrap'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Replacement Requested</h6>
                                    <p className='order-placed__info mb-0'>Usually takes 7-8 days for replacement</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="bg-theme"/>


				
                        <div className='order-track__wrap order-track__wrap2'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Cancelled</h6>
                                    <p className='order-placed__info mb-0'>Refund of ₹19,999 has been initiated</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='order-track__wrap order-track__wrap2'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Delivered</h6>
                                    <p className='order-placed__info error-danger mb-0'>1 Item requested for replacement</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='order-track__wrap'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                              
                                <div className='d-flex flex-column'>
                                    <div className="delayed-order">
                                        <span>
                                        Delayed
                                        </span>
                                    </div>
                                    <h6 className='order-placer__heading mb-1'>Replacement Requested</h6>
                                    <p className='order-placed__info mb-0'>Usually takes 7-8 days for replacement</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackOrder