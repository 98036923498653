import React from 'react'

const SavePayment = () => {
    return (
        <div>
            <div className="Profileordertab_wrapper">
                <div className="profileorder-div">
                    <div className='order-divleft-box'>
                        <div className="order-l-img">
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg"><g><path d="M2.91348 2H18.3367C18.5639 2 18.7819 2.0878 18.9426 2.24408C19.1033 2.40036 19.1935 2.61232 19.1935 2.83333V16.1667C19.1935 16.3877 19.1033 16.5996 18.9426 16.7559C18.7819 16.9122 18.5639 17 18.3367 17H2.91348C2.68624 17 2.46829 16.9122 2.3076 16.7559C2.14691 16.5996 2.05664 16.3877 2.05664 16.1667V2.83333C2.05664 2.61232 2.14691 2.40036 2.3076 2.24408C2.46829 2.0878 2.68624 2 2.91348 2ZM17.4798 8.66667H3.77033V15.3333H17.4798V8.66667ZM17.4798 7V3.66667H3.77033V7H17.4798ZM12.3388 12H15.7661V13.6667H12.3388V12Z" fill="#6B7586"/></g><defs><clipPath id="clip0_1353_36420"><rect width="20.5643" height="20" fill="white"/></clipPath></defs></svg>
                            </span>
                        </div>
                        <div className="order-profilemid-container">
                            <h4>Saved Payment Methods</h4>
                            <span>Add your card  for faster checkout </span>
                        </div>

                    </div>
                    
                    <div className="profileorder-arrow">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M13.172 12.0002L8.222 7.05023L9.636 5.63623L16 12.0002L9.636 18.3642L8.222 16.9502L13.172 12.0002Z" fill="#fff"/></svg>
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default SavePayment