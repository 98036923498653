import React from 'react'

const PersonalDetails = () => {
    return (
        <>
            <div className="Profileordertab_wrapper">
                <div className="profileorder-div">
                    <div className='order-divleft-box'>
                        <div className="order-l-img">
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg"><path d="M3.42773 18.3335C3.42773 16.5654 4.14993 14.8697 5.43545 13.6195C6.72096 12.3692 8.46449 11.6668 10.2825 11.6668C12.1005 11.6668 13.844 12.3692 15.1295 13.6195C16.415 14.8697 17.1372 16.5654 17.1372 18.3335H15.4236C15.4236 17.0074 14.8819 15.7356 13.9178 14.798C12.9536 13.8603 11.646 13.3335 10.2825 13.3335C8.91899 13.3335 7.61134 13.8603 6.64721 14.798C5.68307 15.7356 5.14142 17.0074 5.14142 18.3335H3.42773ZM10.2825 10.8335C7.44205 10.8335 5.14142 8.596 5.14142 5.8335C5.14142 3.071 7.44205 0.833496 10.2825 0.833496C13.1229 0.833496 15.4236 3.071 15.4236 5.8335C15.4236 8.596 13.1229 10.8335 10.2825 10.8335ZM10.2825 9.16683C12.1761 9.16683 13.7099 7.67516 13.7099 5.8335C13.7099 3.99183 12.1761 2.50016 10.2825 2.50016C8.38886 2.50016 6.85511 3.99183 6.85511 5.8335C6.85511 7.67516 8.38886 9.16683 10.2825 9.16683Z" fill="#98A0AE"/></svg>
                            </span>
                        </div>
                        <div className="order-profilemid-container">
                            <h4>Personal Details</h4>
                            <span>Name, Email, Phone Number</span>
                        </div>

                    </div>
                    
                    <div className="profileorder-arrow">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M13.172 12.0002L8.222 7.05023L9.636 5.63623L16 12.0002L9.636 18.3642L8.222 16.9502L13.172 12.0002Z" fill="#fff"/></svg>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalDetails