import React from 'react';
import './help.css';

const Return = () => {
    return (
        <>
            <div className='mar-spacer'/>
            <div className="help-wrapper">
                <div className="return-wrap">
                    <h2 className="returnhead">
                    What is Sangeetha’s Return Policy?
                    </h2>
                    <div className="return-despo">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>
                    <div className="h-1_1"/>
                    <h4 className='retunsec-head'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h4>
                    <div className="return-despo">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>
                    <div className='h-1_1'/>

                    <div className='help-devider'/>
                    <div className='h-1_1'/>

                    <h2 className="returnhead mb-2">
                    What is Sangeetha’s Refund Policy?
                    </h2>
                    <div className="return-despo ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>

                </div>

            </div>

        </>
    )
}

export default Return