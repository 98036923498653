import React from 'react';
import { Link } from '@reach/router';
import user from '../Images/user.png';

const Account = () => {
    
    return (
        <>
            <div className='account-profile'>
                <div className='d-flex align-items-center'>
                    <div className='profile-picture'>
                        <img src={user} className='img img-fluid' alt='' />
                    </div>
                    <div className='profile-details'>
                        <h3>Sangeetha User</h3>
                        <div>+91 7839391109</div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
            </div>
            <div className='mar-spacer h-1' />
            <div className='product-specifications'>
                <div className='account-lists'>
                    <div className='account-list'>
                        <div className='account-media'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.57071 2.5H17.9939C18.2212 2.5 18.4391 2.5878 18.5998 2.74408C18.7605 2.90036 18.8508 3.11232 18.8508 3.33333V16.6667C18.8508 16.8877 18.7605 17.0996 18.5998 17.2559C18.4391 17.4122 18.2212 17.5 17.9939 17.5H2.57071C2.34346 17.5 2.12552 17.4122 1.96483 17.2559C1.80414 17.0996 1.71387 16.8877 1.71387 16.6667V3.33333C1.71387 3.11232 1.80414 2.90036 1.96483 2.74408C2.12552 2.5878 2.34346 2.5 2.57071 2.5ZM7.71178 10C7.71178 10.663 7.9826 11.2989 8.46467 11.7678C8.94674 12.2366 9.60056 12.5 10.2823 12.5C10.9641 12.5 11.6179 12.2366 12.1 11.7678C12.582 11.2989 12.8528 10.663 12.8528 10H17.1371V4.16667H3.42756V10H7.71178Z" fill="#121415"/>
                                </svg>
                            </span>
                            <div className='d-flex flex-column flex-nowrap ml-4'>
                                <div className='account-list-title'>Order</div>
                                <div className='account-list-label'>Track your orders</div>
                            </div>
                        </div>
                        <Link to='/Order' className='account-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='account-list'>
                        <div className='account-media'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2822 14.1667C13.42 14.1667 16.1644 15.4792 17.6571 17.4376L16.0788 18.1634C14.8637 16.7634 12.7216 15.8334 10.2822 15.8334C7.84277 15.8334 5.70066 16.7634 4.48565 18.1634L2.9082 17.4367C4.40083 15.4784 7.14444 14.1667 10.2822 14.1667ZM10.2822 1.66675C11.4185 1.66675 12.5082 2.10573 13.3116 2.88714C14.1151 3.66854 14.5664 4.72835 14.5664 5.83342V8.33342C14.5664 9.43848 14.1151 10.4983 13.3116 11.2797C12.5082 12.0611 11.4185 12.5001 10.2822 12.5001C9.14596 12.5001 8.05625 12.0611 7.2528 11.2797C6.44936 10.4983 5.99798 9.43848 5.99798 8.33342V5.83342C5.99798 4.72835 6.44936 3.66854 7.2528 2.88714C8.05625 2.10573 9.14596 1.66675 10.2822 1.66675Z" fill="#121415"/>
                                </svg>
                            </span>
                            <div className='d-flex flex-column flex-nowrap ml-4'>
                                <div className='account-list-title'>Personal Details</div>
                                <div className='account-list-label'>Name, Email, Phone Number</div>
                            </div>
                        </div>
                        <Link className='account-link' to='/Account/Profile'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='account-list'>
                        <div className='account-media'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.42516 14.9484C7.69665 14.7345 6.11676 13.8885 5.00657 12.5824C3.89639 11.2763 3.33923 9.60803 3.44834 7.91673C3.55745 6.22542 4.32465 4.63799 5.59399 3.47707C6.86333 2.31614 8.53956 1.66885 10.282 1.66675C12.0266 1.66549 13.706 2.3112 14.9781 3.47231C16.2502 4.63343 17.0192 6.22258 17.1284 7.91595C17.2377 9.60932 16.6789 11.2795 15.566 12.5861C14.4532 13.8928 12.8699 14.7376 11.1388 14.9484V16.6767C14.52 16.7534 17.1368 17.2792 17.1368 17.9167C17.1368 18.6068 14.0675 19.1667 10.282 19.1667C6.49646 19.1667 3.42725 18.6068 3.42725 17.9167C3.42725 17.2792 6.04405 16.7534 9.42516 16.6767V14.9484ZM10.282 10.0001C10.7365 10.0001 11.1724 9.82449 11.4938 9.51193C11.8151 9.19937 11.9957 8.77544 11.9957 8.33342C11.9957 7.89139 11.8151 7.46747 11.4938 7.15491C11.1724 6.84235 10.7365 6.66675 10.282 6.66675C9.8275 6.66675 9.39162 6.84235 9.07024 7.15491C8.74886 7.46747 8.56831 7.89139 8.56831 8.33342C8.56831 8.77544 8.74886 9.19937 9.07024 9.51193C9.39162 9.82449 9.8275 10.0001 10.282 10.0001Z" fill="#121415"/>
                                </svg>
                            </span>
                            <div className='d-flex flex-column flex-nowrap ml-4'>
                                <div className='account-list-title'>Saved Address</div>
                                <div className='account-list-label'>History of all your past orders </div>
                            </div>
                        </div>
                        <Link className='account-link' to='/Account/AccountAddress'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='account-list'>
                        <div className='account-media'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.91348 2H18.3367C18.5639 2 18.7819 2.0878 18.9426 2.24408C19.1033 2.40036 19.1935 2.61232 19.1935 2.83333V16.1667C19.1935 16.3877 19.1033 16.5996 18.9426 16.7559C18.7819 16.9122 18.5639 17 18.3367 17H2.91348C2.68624 17 2.46829 16.9122 2.3076 16.7559C2.14691 16.5996 2.05664 16.3877 2.05664 16.1667V2.83333C2.05664 2.61232 2.14691 2.40036 2.3076 2.24408C2.46829 2.0878 2.68624 2 2.91348 2ZM17.4798 8.66667H3.77033V15.3333H17.4798V8.66667ZM17.4798 7V3.66667H3.77033V7H17.4798ZM12.3388 12H15.7661V13.6667H12.3388V12Z" fill="#121415"/>
                                </svg>
                            </span>
                            <div className='d-flex flex-column flex-nowrap ml-4'>
                                <div className='account-list-title'>Saved Payment Methods</div>
                                <div className='account-list-label'>Add your card  for faster checkout</div>
                            </div>
                        </div>
                        <Link className='account-link' to='/Account/Payments'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                            </svg>
                        </Link>
                    </div>
                    <div className='account-list'>
                        <div className='account-media'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3053_53095)">
                                        <path d="M2.05664 8.41667V3.83333C2.05664 3.61232 2.14691 3.40036 2.3076 3.24408C2.46829 3.0878 2.68624 3 2.91348 3H18.3367C18.5639 3 18.7819 3.0878 18.9426 3.24408C19.1033 3.40036 19.1935 3.61232 19.1935 3.83333V8.41667C18.6254 8.41667 18.0805 8.63616 17.6788 9.02686C17.2771 9.41756 17.0514 9.94747 17.0514 10.5C17.0514 11.0525 17.2771 11.5824 17.6788 11.9731C18.0805 12.3638 18.6254 12.5833 19.1935 12.5833V17.1667C19.1935 17.3877 19.1033 17.5996 18.9426 17.7559C18.7819 17.9122 18.5639 18 18.3367 18H2.91348C2.68624 18 2.46829 17.9122 2.3076 17.7559C2.14691 17.5996 2.05664 17.3877 2.05664 17.1667V12.5833C2.62476 12.5833 3.16962 12.3638 3.57134 11.9731C3.97307 11.5824 4.19875 11.0525 4.19875 10.5C4.19875 9.94747 3.97307 9.41756 3.57134 9.02686C3.16962 8.63616 2.62476 8.41667 2.05664 8.41667ZM12.3388 4.66667H3.77033V7.14C4.414 7.45025 4.95575 7.92921 5.3345 8.52288C5.71326 9.11656 5.91395 9.80133 5.91395 10.5C5.91395 11.1987 5.71326 11.8834 5.3345 12.4771C4.95575 13.0708 4.414 13.5498 3.77033 13.86V16.3333H12.3388V4.66667ZM14.0525 4.66667V16.3333H17.4798V13.86C16.8362 13.5498 16.2944 13.0708 15.9157 12.4771C15.5369 11.8834 15.3362 11.1987 15.3362 10.5C15.3362 9.80133 15.5369 9.11656 15.9157 8.52288C16.2944 7.92921 16.8362 7.45025 17.4798 7.14V4.66667H14.0525Z" fill="#121415"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3053_53095">
                                            <rect width="20.5643" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <div className='d-flex flex-column flex-nowrap ml-4'>
                                <div className='account-list-title'>My Coupons</div>
                                <div className='account-list-label'>Manage coupons for additional discounts</div>
                            </div>
                        </div>
                        <Link to='/Account/MyCoupons' className='account-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-1' />
            <div className='mar-spacer bg-theme' />
            <div className='mar-spacer h-1' />
            <div className='product-specifications'>
                <div className='account-lists'>
                    <div className='account-list'>
                        <div className='account-media'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.8508 14.1684C18.8504 15.308 18.4498 16.4134 17.7155 17.3014C16.9811 18.1895 15.9569 18.807 14.8124 19.0517L14.2658 17.4567C14.7664 17.3766 15.2425 17.1894 15.6597 16.9087C16.0769 16.6279 16.4249 16.2607 16.6787 15.8334H14.5665C14.112 15.8334 13.6762 15.6578 13.3548 15.3453C13.0334 15.0327 12.8528 14.6088 12.8528 14.1667V10.8334C12.8528 10.3914 13.0334 9.96746 13.3548 9.6549C13.6762 9.34234 14.112 9.16675 14.5665 9.16675H17.0839C16.8748 7.55583 16.0685 6.07448 14.8162 5.00064C13.564 3.92681 11.9518 3.33431 10.2823 3.33431C8.61279 3.33431 7.00065 3.92681 5.74838 5.00064C4.49611 6.07448 3.68978 7.55583 3.48068 9.16675H5.99809C6.45259 9.16675 6.88847 9.34234 7.20985 9.6549C7.53123 9.96746 7.71178 10.3914 7.71178 10.8334V14.1667C7.71178 14.6088 7.53123 15.0327 7.20985 15.3453C6.88847 15.6578 6.45259 15.8334 5.99809 15.8334H3.42756C2.97306 15.8334 2.53717 15.6578 2.21579 15.3453C1.89442 15.0327 1.71387 14.6088 1.71387 14.1667V10.0001C1.71387 5.39758 5.54996 1.66675 10.2823 1.66675C15.0147 1.66675 18.8508 5.39758 18.8508 10.0001V14.1684Z" fill="#121415"/>
                                </svg>
                            </span>
                            <div className='d-flex flex-column flex-nowrap ml-4'>
                                <div className='account-list-title'>Help and Support</div>
                                <div className='account-list-label'>Manage coupons for additional discounts</div>
                            </div>
                        </div>
                        <div className='account-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                            </svg>
                        </div>
                    </div>
                    <div className='account-list'>
                        <div className='account-media'>
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.2844 12.5001V14.1667C4.28426 14.5872 4.44755 14.9922 4.74152 15.3005C5.0355 15.6089 5.43844 15.7977 5.86956 15.8292L5.99809 15.8334H8.56862V17.5001H5.99809C5.08909 17.5001 4.21732 17.1489 3.57457 16.5238C2.93181 15.8986 2.57071 15.0508 2.57071 14.1667V12.5001H4.2844ZM15.4234 8.33341L19.1935 17.5001H17.347L16.3179 15.0001H12.8134L11.7861 17.5001H9.94043L13.7097 8.33341H15.4234ZM14.5665 10.7376L13.498 13.3334H15.6333L14.5665 10.7376ZM6.85493 1.66675V3.33341H10.2823V9.16675H6.85493V11.6667H5.14124V9.16675H1.71387V3.33341H5.14124V1.66675H6.85493ZM14.5665 2.50008C15.4755 2.50008 16.3473 2.85127 16.9901 3.47639C17.6328 4.10151 17.9939 4.94936 17.9939 5.83341V7.50008H16.2802V5.83341C16.2802 5.39139 16.0997 4.96746 15.7783 4.6549C15.4569 4.34234 15.021 4.16675 14.5665 4.16675H11.996V2.50008H14.5665ZM5.14124 5.00008H3.42756V7.50008H5.14124V5.00008ZM8.56862 5.00008H6.85493V7.50008H8.56862V5.00008Z" fill="#121415"/>
                                </svg>
                            </span>
                            <div className='d-flex flex-column flex-nowrap ml-4'>
                                <div className='account-list-title'>Change Prefered Language</div>
                                <div className='account-list-label'>English</div>
                            </div>
                        </div>
                        <div className='account-link'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.172 12L8.222 7.04999L9.636 5.63599L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="#121415"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-1' />
            <div className='p-x-16'>
                <button type='button' className='btn btn-outOfStock-custom w-100'>Log Out</button>
            </div>
            <div className='mar-spacer h-1_2' />
        </>
    )
}

export default Account