import React from 'react'
import hdfc from '../../../Images/Profile/hdfc.png'
import Gpay from '../../../Images/Profile/Gpay.png'
import Sbi from '../../../Images/Profile/SBI.png'

import masterCard from '../../../Images/Profile/MasterCardLogo.png'

const SavePaymentright = () => {
    return (
        <>
            <div className='order-success__wrapper'>
                <div className='container'>
                    <div className='order-confirmation m-0'>
                        <div className="address-wrap address-wrap-padding">
                            <div className="addnewcard-btn">
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 9V5H10.3333V9H14.3333V10.3333H10.3333V14.3333H9V10.3333H5V9H9Z" fill="#121415"/></svg>
                                </span>
                                <button className='btn-addcard'>Add New Card</button>
                            </div>
                            <div className="mar-spacer"/>
                            <div className="savecart-txt">
                            saved Cards
                            </div>
                            <div className="mar-spacer"/>

                            <div className="card-deatils-area-main">
                                <div className="add-card-area">
                                    <div className="card-bank-img">
                                        <img src={hdfc} alt="" />
                                    </div>
                                    <div className="card-details-area">
                                        <div className="card-titel">HDFC Debit Card</div>
                                        <div className="card-number-area">
                                            <span className='master-card'>Master Card </span>
                                            <div className="card-no-img">
                                                <span>****2386</span> 
                                                <img src={masterCard} alt="card" />
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                                <div className="card-remove_button">
                                    <button className='remove-btn-border'>Remove</button>
                                </div>
                            </div>
                            <div className="mar-spacer"/>

                            <div className="card-deatils-area-main">
                                <div className="add-card-area">
                                    <div className="card-bank-img">
                                        <img src={Sbi} alt="" />
                                    </div>
                                    <div className="card-details-area">
                                        <div className="card-titel">SBI Debit Card</div>
                                        <div className="card-number-area">
                                            <span className='master-card'>Master Card </span>
                                            <div className="card-no-img">
                                                <span>****2386</span> 
                                                <img src={masterCard} alt="card" />
                                            </div>                                   
                                        </div>
                                    </div>
                                </div>
                                <div className="card-remove_button">
                                    <button className='remove-btn-border'>Remove</button>
                                </div>
                            </div>
                            <div className="h-4"/>
                            <div className="addnewcard-btn">
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 9V5H10.3333V9H14.3333V10.3333H10.3333V14.3333H9V10.3333H5V9H9Z" fill="#121415"/></svg>
                                </span>
                                <button className='btn-addcard'>Add New Walllet</button>
                            </div>
                            <div className="mar-spacer"/>
                            <div className="savecart-txt">
                            saved Cards
                            </div>
                            <div className="mar-spacer"/>

                            <div className="card-deatils-area-main">
                                <div className="add-card-area">
                                    <div className="card-bank-img">
                                        <img src={Gpay} alt="" />
                                    </div>
                                    <div className="card-details-area">
                                        <div className="card-titel">Google Pay</div>
                                        <div className="card-number-area">
                                            <span className='master-card'>nanda.rohan@okicici </span>
                                                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="card-remove_button">
                                    <button className='remove-btn-border'>Remove</button>
                                </div>
                            </div>

                        </div>
                        

                    </div>
                </div>
            </div>
        
        </>
    )
}

export default SavePaymentright