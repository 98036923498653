import React, { useState } from 'react';
import SangeethaRecommends from './SangeethaRecommends';
import TrackPanel from './TrackPanel';
import img1 from '../../Images/Order/1.png';
import img2 from '../../Images/Order/2.png';
import img3 from '../../Images/Order/3.png';
import img4 from '../../Images/Order/4.png';
import Img11 from '../../Images/1_1.png';
import Img22 from '../../Images/2_2.png';
import Img33 from '../../Images/3_3.png';

import { Link } from '@reach/router';

const OrderReplacement = () => {
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    }
    return (
        <>
            <div className='order-details__wrap'>
                <div className='container'>
                    <div className='order--container'>
                        <div className='order-detail__section'>
                            <div className='Order_Id'>
                                <div className='order-id-txt'>
                                    ORDER ID:
                                    <span> 983729203490</span>
                                </div>
                            </div>
                            <div className='order-este__delivery'>
                                <div className='order-este__details d-flex align-items-center'>
                                    <div className='address-este___media'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16 30C8.268 30 2 23.732 2 16C2 8.268 8.268 2 16 2C23.732 2 30 8.268 30 16C30 23.732 23.732 30 16 30Z" fill="black"/>
                                            <path d="M19.71 6.29004L18.29 7.71004L20.59 10H10C8.89544 10 8 10.8955 8 12V15H10V12H20.59L18.3 14.29L19.71 15.7L23.71 11.7C24.0977 11.31 24.0977 10.6801 23.71 10.29L19.71 6.29004Z" fill="black"/>
                                            <path d="M22 20H11.41L13.7 17.71L12.29 16.3L8.29002 20.3C7.90227 20.6901 7.90227 21.32 8.29002 21.71L12.29 25.71L13.7 24.3L11.41 22H22C23.1046 22 24 21.1046 24 20V17H22V20Z" fill="black"/>
                                        </svg>

                                    </div>
                                    <div className='address-este____content ml-3'>
                                        <h5 className='mb-0'>Replacement Requested</h5>
                                        <div className="order_deliverTo__info">
                                            <span className="order_info__similarProd">Usually taked 7-8 days for replacement</span>
                                        </div>
                                    </div>
                                </div>
                                <TrackPanel />
                            </div>
                            <div className='item-include'>
                                <h6 className='item-inc__heading'>Item requested for replacement</h6>
                                <div className='item-inc__wrap'>
                                    <div className='order-este__include d-flex align-items-center'>
                                        <div className='orderdetails-este____media'>
                                            <img src={img2} className='img img-fluid' alt='' />
                                        </div>
                                        <div className='order-include__details ml-3'>
                                            <h5 className='mb-0'>Samsung Galaxy Z Fold 2 5G</h5>
                                            <div className="order_deliverTo__info mb-2">
                                                <span className="order_info__similarProd">Mystic Purple  &nbsp;</span>
                                                <span className="order_info__similarProd">, 256GB + 8GB &nbsp;</span>
                                            </div>
                                            <div className='order-qty'>Qty : 1 </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className="order_details-Model">
                                <div className='d-flex flex-column'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.455 15L1 18.5V3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V15H5.455ZM4.763 13H16V4H3V14.385L4.763 13ZM8 17H18.237L20 18.385V8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V22.5L17.545 19H9C8.73478 19 8.48043 18.8946 8.29289 18.7071C8.10536 18.5196 8 18.2652 8 18V17Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <h4 className='Order_Pending-msg mb-0 ml-1_5'>Need help with order?</h4>
                                    </div>
                                    <p className='order-cancellation-txt mb-0'>Order cancellation window open till the order is dispatched</p>
                                </div>
                                <button type="button" className="btn px-4 btn-dark-custom-outline btn-contact">Contact Support</button>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className='cart-address__default order_details-Model'>
                                <div className='d-flex flex-column'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 23.7279L5.636 17.3639C4.37734 16.1052 3.52019 14.5016 3.17293 12.7558C2.82567 11.0099 3.00391 9.20035 3.6851 7.55582C4.36629 5.91129 5.51984 4.50569 6.99988 3.51677C8.47992 2.52784 10.22 2 12 2C13.78 2 15.5201 2.52784 17.0001 3.51677C18.4802 4.50569 19.6337 5.91129 20.3149 7.55582C20.9961 9.20035 21.1743 11.0099 20.8271 12.7558C20.4798 14.5016 19.6227 16.1052 18.364 17.3639L12 23.7279ZM16.95 15.9499C17.9289 14.9709 18.5955 13.7236 18.8656 12.3658C19.1356 11.0079 18.9969 9.60052 18.4671 8.32148C17.9373 7.04244 17.04 5.94923 15.8889 5.18009C14.7378 4.41095 13.3844 4.00043 12 4.00043C10.6156 4.00043 9.26222 4.41095 8.11109 5.18009C6.95996 5.94923 6.06275 7.04244 5.53292 8.32148C5.00308 9.60052 4.86442 11.0079 5.13445 12.3658C5.40449 13.7236 6.07111 14.9709 7.05 15.9499L12 20.8999L16.95 15.9499ZM12 12.9999C11.4696 12.9999 10.9609 12.7892 10.5858 12.4141C10.2107 12.0391 10 11.5304 10 10.9999C10 10.4695 10.2107 9.96078 10.5858 9.58571C10.9609 9.21064 11.4696 8.99992 12 8.99992C12.5304 8.99992 13.0391 9.21064 13.4142 9.58571C13.7893 9.96078 14 10.4695 14 10.9999C14 11.5304 13.7893 12.0391 13.4142 12.4141C13.0391 12.7892 12.5304 12.9999 12 12.9999Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <h4 className='Order_Pending-msg mb-0 ml-1_5'>Delivery Address</h4>
                                    </div>
                                    <div className='logged-default__list border-0'>
                                        <div className='logged-default__label mb-2'>
                                            <span>Rohan Nanda <span className='default__address'>(Default)</span></span>
                                            <span className="btn btn-tag">Home</span>
                                        </div>
                                        <div className='cart-address__section'>
                                            <div className='loggedIn-address__label mb-2'>
                                        103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                            </div>
                                            <div className='loggedIn-address__contact'>
                                                <label htmlFor="label">Mobile: </label>{' '}
                                                <span className='contact-number'>7382901648</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className="order_details-Model">
                                <div className='d-flex flex-column'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <h4 className='Order_Pending-msg mb-0'>Reason for Replacement</h4>
                                    </div>
                                    <div className='d-flex flex-column replaceFlex--title'>
                                        <div className='replace--title mb-2'>I ordered a different Product/Variant/Color</div>
                                        <div className='replace--info'>I had ordered a Starry black Iphone 6s while what I have received is a Mystic blue Iphone 6s.</div>
                                    </div>

                                </div>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className="order_details-Model">
                                <div className='d-flex flex-column'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <h4 className='Order_Pending-msg mb-0'>How does replacement works?</h4>
                                    </div>

                                    <div className='work-structure_flex mt-4'>
                                        <div className='work-structure_wrap'>
                                            <div className='work-structure_media'>
                                                <img src={Img11} className='img-fluid' alt='' />
                                            </div>
                                            <div className='work-structure_details'>
                                                <div className='work-structure_title text-center'>Delivery executive will pickup the order from the selected address</div>
                                            </div>
                                        </div>
                                        <div className='work-structure_wrap'>
                                            <div className='work-structure_media'>
                                                <div className='group-img'>
                                                    <img src={Img22} className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                            <div className='work-structure_details'>
                                                <div className='work-structure_title text-center'>The order will be verified at the Sangeetha store</div>
                                            </div>
                                        </div>
                                        <div className='work-structure_wrap'>
                                            <div className='work-structure_media'>
                                                <div className='group-img'>
                                                    <img src={Img33} className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                            <div className='work-structure_details'>
                                                <div className='work-structure_title text-center'>After verification is successful a replaced product will be send back to the selected address</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className="order_details-Model">
                                <div className='d-flex flex-column w-100'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <h4 className='Order_Pending-msg mb-0'>Original Order</h4>
                                    </div>
                                    <div className='order-track__content mt-4'>
                                        <div className='orders_wrap'>
                                            <div className='orders-list_wrap d-flex align-items-start'>
                                                <div className='orders-media__wrap'>
                                                    <img src={img1} className='img-fluid' alt='' />
                                                    <div className='orders__tag'>
                                                        <div className='orders_content'>
                                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='orders-media__wrap'>
                                                    <img src={img2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='orders-media__wrap'>
                                                    <img src={img3} className='img-fluid' alt='' />
                                                </div>
                                                <div className='orders-media__wrap'>
                                                    <img src={img4} className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                            <Link to='/OrderDetails' className='d-flex'>
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='order-summary__section'>
                            <div className='payment-order__summary'>
                                <div className='cart-order__summary'>
                                    <h4 className='heading-order__summary'>Order Summary</h4>
                                    <div className='order-summary__table'>
                                        <div className='order-summary__body'>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Samsung Galaxy Z Fold 2 5G</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹19,999</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Money and Damage Protection</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹999</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Discount</label>
                                                </div>
                                                <div className='order-summary__col details-text'>
                                                    <span>- ₹400</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row order-summary--dashed-border'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Watercolor gradient Phone grip holder...</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹1,999</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Applied Coupon</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>- ₹500</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Delivery Charges</label>
                                                </div>
                                                <div className='order-summary__col details-text'>
                                                    <span className='text-uppercase'>Free</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-summary__footer'>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col flex-column d-flex'>
                                                    <span>Total Amount</span>
                                                    <span className='excl-txt'>(Inclusive of all taxes)</span>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹22,097</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type='button' className='btn btn-dark-custom-outline btn-dark-custom-summary w-100 d-flex justify-content-center align-items-center mt-4'>
                                <span className='d-flex'>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3333 8.33358H15.5L10.5 13.3336L5.50001 8.33358H9.66667V2.50024H11.3333V8.33358ZM3.83334 15.8336H17.1667V10.0002H18.8333V16.6669C18.8333 16.8879 18.7455 17.0999 18.5893 17.2562C18.433 17.4124 18.221 17.5002 18 17.5002H3.00001C2.77899 17.5002 2.56703 17.4124 2.41075 17.2562C2.25447 17.0999 2.16667 16.8879 2.16667 16.6669V10.0002H3.83334V15.8336Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className='ml-2'>Download Invoice</span>
                            </button>
                        </div>
                    </div>

                    <div className='cart-accessories'>
                        <div className='container'>     
                            <div className='cart-accessories__carousel mb-0'>
                                <div className='h-5 mar-spacer' />
                                <div className='top-head__wrap'>
                                    <h4 className='order-heading__wrap mb-0'>Sangeetha Recommends</h4>
                                </div>
                                
                                <SangeethaRecommends wishlistActive={wishlistActive} handleToggle={handleToggle} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderReplacement