import React from 'react'

const HelpSuport = () => {
    return (
        <>
            <div className="Profileordertab_wrapper">
                <div className="profileorder-div">
                    <div className='order-divleft-box'>
                        <div className="order-l-img">
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg"><g><path d="M18.8508 14.1682C18.8504 15.3078 18.4498 16.4131 17.7155 17.3012C16.9811 18.1892 15.9569 18.8068 14.8124 19.0515L14.2658 17.4565C14.7664 17.3763 15.2425 17.1891 15.6597 16.9084C16.0769 16.6277 16.4249 16.2605 16.6787 15.8332H14.5665C14.112 15.8332 13.6762 15.6576 13.3548 15.345C13.0334 15.0325 12.8528 14.6085 12.8528 14.1665V10.8332C12.8528 10.3911 13.0334 9.96722 13.3548 9.65466C13.6762 9.3421 14.112 9.1665 14.5665 9.1665H17.0839C16.8748 7.55558 16.0685 6.07423 14.8162 5.0004C13.564 3.92657 11.9518 3.33407 10.2823 3.33407C8.61279 3.33407 7.00065 3.92657 5.74838 5.0004C4.49611 6.07423 3.68978 7.55558 3.48068 9.1665H5.99809C6.45259 9.1665 6.88847 9.3421 7.20985 9.65466C7.53123 9.96722 7.71178 10.3911 7.71178 10.8332V14.1665C7.71178 14.6085 7.53123 15.0325 7.20985 15.345C6.88847 15.6576 6.45259 15.8332 5.99809 15.8332H3.42756C2.97306 15.8332 2.53717 15.6576 2.21579 15.345C1.89442 15.0325 1.71387 14.6085 1.71387 14.1665V9.99984C1.71387 5.39734 5.54996 1.6665 10.2823 1.6665C15.0147 1.6665 18.8508 5.39734 18.8508 9.99984V14.1682Z" fill="#6B7586"/></g><defs><clipPath id="clip0_1353_36444"><rect width="20.5643" height="20" fill="white"/></clipPath></defs></svg>
                            </span>
                        </div>
                        <div className="order-profilemid-container">
                            <h4>Help and Support</h4>
                            <span>Manage coupons for additional discounts </span>
                        </div>

                    </div>
                    
                    <div className="profileorder-arrow">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.172 12.0002L8.222 7.05023L9.636 5.63623L16 12.0002L9.636 18.3642L8.222 16.9502L13.172 12.0002Z" fill="#fff"/></svg>
                        </span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HelpSuport