import React from 'react'

const ProductVariants = () => {
    return (
        <>
            <div className='product-models-box'>
                <div className='product-modelsFrame active'>
                    <span>6GB + 128GB</span>
                </div>
                <div className='product-modelsFrame'>
                    <span>6GB + 128GB</span>
                </div>
                <div className='product-modelsFrame out-of-stock'>
                    <span>6GB + 128GB</span>
                </div>
            </div>
        </>
    )
}

export default ProductVariants