import React from 'react';
import Form from 'react-bootstrap/Form';

import Apple from '../../../../Images/brands/1.svg';
import OnePlus from '../../../../Images/brands/2.svg';
import Samsung from '../../../../Images/brands/3.svg';
import MI from '../../../../Images/brands/4.svg';
import Vivo from '../../../../Images/brands/5.svg';
import Google from '../../../../Images/brands/6.svg';
import Huawei from '../../../../Images/brands/7.svg';
import LG from '../../../../Images/brands/8.svg';

const Step2 = () => {

    return (
        <>
            <div className="exchange-data all-brands">
                <div className=''>
                    <h3 className="Exchange-head"> Tell us your phone&apos;s brand </h3>
                    <div className='header-home__search'>
                        <form className="form">
                            <div className="form-group">
                                <div className="input-icon">
                                    <input type="text" className="bannerInput-search" placeholder="Search for brands"/>
                                    <span className="bannerSearch-icon d-flex">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z" fill="#C4CAD0"/>
                                        </svg>              
                                    </span>
                                </div>                                            
                            </div>
                        </form>
                    </div>
                </div>
                <div className='exchange-brand__lists'>
                    <div className='exchange_heading col-12'>Offers on Brands</div>
                    <div className='exchange_list'>
                        <img src={Apple} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={OnePlus} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={Samsung} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={MI} className='img-fluid' alt='' />
                    </div>
                    <div className='exchange_list'>
                        <img src={Vivo} className='img-fluid' alt='' />
                    </div>
                </div>
                <div className='exchange-brand__lists bg-white'>
                    <div className='exchange_heading col-12'>All Brands</div>
                    <div className='exchange_flex'>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" defaultChecked/>
                            <label htmlFor="label" className="filter_exchange ml-1_5">
                                <img src={Google} className='img-fluid' alt='' />
                                <span className='ml-1_5'>Google</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange ml-1_5">
                                <img src={Huawei} className='img-fluid' alt='' />
                                <span className='ml-1_5'>Huawei</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange ml-1_5">
                                <img src={LG} className='img-fluid' alt='' />
                                <span className='ml-1_5'>LG</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step2