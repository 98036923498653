import React from 'react'
import Highlights1 from '../../../Images/Product/Highlights5.png';

const WatchHighlights = () => {
    return (
        <>
            <div className='highlight-grid highlight-grid-1'>
                <div className='highlight-col'>
                    <img src={Highlights1} className='img-fluid' alt='' />
                </div>
            </div>
        </>
    )
}

export default WatchHighlights