import { combineReducers,createStore } from 'redux'
import productReducer from './productReducer'
import homeReducer from './homeReducer'

export default combineReducers({
    productDetails: productReducer,
    homeDetails:homeReducer
})

// convert object to string and store in localStorage
function saveToLocalStorage(state) {
    try {
        const serialisedState = JSON.stringify(state);
        localStorage.setItem("home_page", serialisedState);
    } catch (e) {
        console.warn(e);
    }
}

// invalid output must be undefined
function loadFromLocalStorage() {
    try {
        const serialisedState = localStorage.getItem("home_page");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

const store = createStore(homeReducer, loadFromLocalStorage());

// listen for store changes and use saveToLocalStorage to
// save them to localStorage
store.subscribe(() => saveToLocalStorage(store.getState()));