import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MasterLogo from '../../../Images/product/MasterLogo.png';

const CardPayment = () => {
    return (
        <>
            <div className='paymrny-card-form__details address-form__details p-0'>
                <div className='form-group-custom'>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Card Number"
                        className='error-state'
                    >
                        <Form.Control type="text" className='form-control form-control-custom' placeholder='Card Number' defaultValue='5601 1032 6234 2343' />
                        <span className='floating-icon-logo' style={ {'display': 'flex'}}>
                            <img src={MasterLogo} className='img img-fluid' alt='' />
                        </span>
                        <span>Please enter a valid card number.</span>
                    </FloatingLabel>
                </div>
                <div className='form-group-custom'>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Name on Card"
                    >
                        <Form.Control type="text" className='form-control form-control-custom' placeholder='Name on Card' defaultValue='Rohan' />
                    </FloatingLabel>
                </div>
                <div className='d-flex'>
                    <div className='form-group-custom col'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Valid Thru (MM/YY)"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='Valid Thru (MM/YY)' />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom col ml-4'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="***"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='***' />
                            <span className='floating-icon'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.9987 14.6668C4.3167 14.6668 1.33203 11.6822 1.33203 8.00016C1.33203 4.31816 4.3167 1.3335 7.9987 1.3335C11.6807 1.3335 14.6654 4.31816 14.6654 8.00016C14.6654 11.6822 11.6807 14.6668 7.9987 14.6668ZM7.9987 13.3335C9.41319 13.3335 10.7697 12.7716 11.7699 11.7714C12.7701 10.7712 13.332 9.41465 13.332 8.00016C13.332 6.58567 12.7701 5.22912 11.7699 4.22893C10.7697 3.22873 9.41319 2.66683 7.9987 2.66683C6.58421 2.66683 5.22766 3.22873 4.22746 4.22893C3.22727 5.22912 2.66536 6.58567 2.66536 8.00016C2.66536 9.41465 3.22727 10.7712 4.22746 11.7714C5.22766 12.7716 6.58421 13.3335 7.9987 13.3335ZM7.33203 4.66683H8.66536V6.00016H7.33203V4.66683ZM7.33203 7.3335H8.66536V11.3335H7.33203V7.3335Z" fill="#121415"/>
                                </svg>
                            </span>
                        </FloatingLabel>
                    </div>
                </div>
                <div className='filter-tab__list border-0 p-0'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" />
                        <label htmlFor="label" className='filter_price'>Save this card for faster payments</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardPayment