import React from 'react';
import { Link } from '@reach/router';
import HowExchangeWorks from './HowExchangeWorks';

import coupon from '../../../../Images/coupon/coupon.svg';
//import MyCouponDetails from '../../../Account/MyCouponDetails';

import Banner2 from '../../../Images/Product/Exchange/banner2.png';
import coupon1 from '../../../Images/Product/Exchange/coupon1.png';

const Step7 = () => {
    return (
        <>
            <div className=''>
                <div className='exchange__result'>
                    <div className='exchange__content'>
                        <div className='exchange__greet'><span className='font-500'>Hurray!</span> Your phone’s total exchange value is</div>

                        <div className='exchange__price'>₹21,000</div>
                        <div className='d-flex align-items-center'>
                            <div className='exchange-device__info'>
                                <label htmlFor='label'>Device Value</label>{''}:{''}
                                <span> ₹20,000</span>
                            </div>
                            <div className='exchange-device__info ml-2_5'>
                                <label htmlFor='label'>Device Value</label>{''}:{''}
                                <span> ₹20,000</span>
                            </div>
                        </div>
                    </div>
                    <div className='exchange__media'>
                        <img src={coupon} className='img-fluid' alt='' />
                    </div>
                </div>
            </div>
            <div className='exchange__val'>
                <div className='exchange-label__val'>
                    <div className='d-flex flex-column'>
                        <span>Your Device for Exchange</span>
                        <p className='mb-0'>OnePlus Nord 6GB + 128GB</p>
                    </div>
                    <Link to='/' className='exchange_link'>Change</Link>
                </div>
            </div>
            <div className=''>
                <div className='deviderclass-border' />
                <div className=''>
                    <HowExchangeWorks />
                </div>
                <div className='deviderclass-border' />
                <div className='exchange-bg__lists'>
                    <div className='font-500 mb-2 exchange-lists__heading mb-3'>Old phone will be accepted only if</div>
                    <ul>
                        <li>Phone must be in a working condition</li>
                        <li>During pick up, IMEI number must match the one submitted</li>
                        <li>iCloud or screen lock needs to be disabled</li>
                        <li>Phone must be free from physical defects like cracks, dents, or scratches on the body</li>
                        <li>Phone’s screen must be working, and free from damages</li>
                    </ul>
                </div>
                <div className='deviderclass-border' />
                <div className='coupon-card__wrapper'>
                    <img src={Banner2} className='img-fluid w-100 coupon-img' alt='' />
                    <div className='coupon-card__bg'>
                        <div className='coupon-card__content'>
                            <div className='coupon-card__content'>
                                <h5>Yay! ₹3000 Off on Sangeetha <br/>Exchange Bonus</h5>
                                <hr className='hr-coupon' />
                                <div className='coupon-card__info'>VALIDITY: only on current purchase</div>
                                <button type='button' className='btn coupon-btn btn-dark-custom'>Learn More</button>
                            </div>
                        </div>
                        <div className='coupon-card__media'>
                            <img src={coupon1} className='img img-fluid' alt='' />
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-2' />
                <div className='px-3-'>
                    <div className='exchange-bg__storage exchange-bg__tip'>
                        <div className='font-500 mb-2 exchange-bg__title'>Exchange Tip</div>
                        <span className='mb-0'>If your exchange is rejected, you may still pay the pending discount amount at the time of delivery and receive your new phone</span>
                    </div>
                </div>
                
            </div>
            <div className='mar-spacer h-3 mt-3' />
        </>
    )
}

export default Step7