import { Link } from '@reach/router';
import React from 'react';
import Error404Img from '../Images/404.svg';

const Error404 = () => {
    return (
        <>
            <div className="Order_Confirm">
                <div className="orderconfirm_img">
                    <div className='mar-spacer h-3 mt-3' />
                    <div className='error-head mb-4'>Error Code: 404</div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img src={Error404Img} alt="" className='img-fluid'/>
                    </div>

                    <div className="order_confirm-heading px-3">
                        <h2 className='error-heading mb-2 text-dark'>Whoops!</h2>
                        <p className='error_para px-3'>We can’t seem to find the page you are looking for</p>
                        <div className='mar-spacer h-1' />
                        <Link to='/' className='btn btn-dark-custom w-100'>Go to Home</Link>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Error404