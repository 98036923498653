import React from 'react';
//import { Link } from '@reach/router';
import Carousel from 'react-multi-carousel';
import CustomDot from '../Common/CustomDot';
import HomeBanner1 from '../../Images/Home/homeBanner.jpg';

const HomeGallery = () => {

    return (
        <>    
            <Carousel
                partialVisible={false}
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="carouser-container"
                customDot={<CustomDot />}
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                    }
                }}
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                <div className='home-banner-slider'>
                    <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                </div>
                <div className='home-banner-slider'>
                    <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                </div>
                <div className='home-banner-slider'>
                    <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                </div>
                <div className='home-banner-slider'>
                    <img src={HomeBanner1} className='img img-fluid w-100' alt='' />
                </div>
            </Carousel>
    
        </>
    )
}

export default HomeGallery