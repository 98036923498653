import React, { useEffect, useState } from 'react';
import ProductGallery from '../../Components/ProductDetails/ProductGallery';
import ProductInfo from '../../Components/ProductDetails/ProductInfo';
import KeyFeatures from '../../Components/ProductDetails/KeyFeatures';
import ProductColors from '../../Components/ProductDetails/ProductColors';
import ProductVariants from '../../Components/ProductDetails/ProductVariants';
import Location from '../../Components/ProductDetails/Location';
import Specifications from '../../Components/ProductDetails/Specifications';
import CancellationPolicy from '../../Components/ProductDetails/CancellationPolicy';
import WhySangeetha from '../../Components/ProductDetails/WhySangeetha';
import ProductBox from '../../Components/ProductDetails/ProductBox';
import Highlights from '../../Components/ProductDetails/Highlights';
import ReviewVideo from '../../Components/ProductDetails/Review';
import Sale from '../../Components/Home/Sale';
import ModalOutOfStock from '../../Components/ProductDetails/ModalOutOfStock';
import { ImageGallery, ProductSpecifications, ProductReview, outOfStockInfo } from '../../Data';

import '../../Components/ProductDetails/ProductDetails.css';

const OutOfStock = () => {
    const [ productData, setProductData ] = useState([]);
    const [ specificData, setData ] = useState([]);
    const [ genaralData, setgenaralData ] = useState([]);
    const [ reviewData, setReviewData ] = useState([]);

    useEffect(() => {
        setProductData(outOfStockInfo[0])
        setData(ProductSpecifications[0].Specific)
        setgenaralData(ProductSpecifications[1].General)
        setReviewData(ProductReview[0])
    }, []);
    return (
        <>
            <div className='product-details-wrapper'>
                <div className='container'>
                    <div className='pdp-container'>
                        <div className='product-gallery-container'>
                            <div className='sticky-container'>
                                <ProductGallery DataList={ImageGallery} productData={productData} />                            
                            </div>
                        </div>
                        <div className='product-details-container'>
                            <div className='pdp-info'>
                                <ProductInfo productData={productData}/>
                            </div>
                            <div className='pdp-key-features'>
                                <KeyFeatures />
                            </div>
                            <div className='mar-spacer h-2' />
                            <div className='pdp-colors'>
                                <h4 className='pdp-heading'>Colors</h4>
                                <ProductColors />
                            </div>
                            <div className='border-divider' />
                            <div className='pdp-variants'>
                                <h4 className='pdp-heading'>RAM + Storage</h4>
                                <ProductVariants />
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='product-payment-offers'>
                                <div className='pdp-location'>
                                    <Location />
                                </div>
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='pdp-specifications'>
                                <h4 className='pdp-heading'>Specifications</h4>
                                <Specifications specificData={specificData} genaralData={genaralData} />
                            </div>
                            <div className='mar-spacer h-4' />
                            <CancellationPolicy />
                            <div className='mar-spacer h-3' />
                        </div>
                    </div>
                </div>
                <div className='pdp-details__container'>
                    <div className='container'>
                        <div className='why-sangeetha__bg'>
                            <h4 className='pdp-info-heading text-center'>Why Buy From Sangeetha?</h4>
                            <div className='h-1_1 mar-spacer' />
                            <div className='why-sangeetha__flex'>
                                <WhySangeetha />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pdp-product-box space-top-160'>
                    <div className='container'>
                        <h4 className='pdp-info-heading text-center'>What’s In The Box?</h4>
                        <p className='pdp-info-text text-center'>Samsung Galaxy Fold, Type C Cable, Pin</p>
                        <div className='product-box__wrap d-flex align-items-center justify-content-center'>
                            <ProductBox />
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-5'></div>
                <section className='pdp-highlights'>
                    <h4 className='pdp-info-heading text-center'>Highlights</h4>
                    <Highlights />
                </section>
                <div className='mar-spacer h-5'></div>
                <section className='pdp-review'>
                    <div className='container'>
                        <h4 className='pdp-heading text-center'>Watch Galaxy Fold’s Expert Review</h4>
                        <div className='mar-spacer h-1_1'></div>
                        <ReviewVideo reviewData={reviewData} />
                    </div>
                </section>
                <div className='space-top-160 pdp-product-recommend'>
                    <div className='container'>
                        <h4 className='pdp-heading'>Sangeetha Recommends</h4>
                        <div className='spacing-block position-relative'>
                            <Sale />
                        </div>
                    </div>
                </div>
                <ModalOutOfStock />
            </div>
        </>
    )
}

export default OutOfStock