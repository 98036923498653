import { Link } from '@reach/router';
import React from 'react';
import BrandOne from '../../Images/Home/Brands/1.png';
import BrandTwo from '../../Images/Home/Brands/2.png';
import BrandThree from '../../Images/Home/Brands/3.png';
import BrandFour from '../../Images/Home/Brands/4.png';
import BrandFive from '../../Images/Home/Brands/5.png';
import BrandSix from '../../Images/Home/Brands/7.png';
import BrandSeven from '../../Images/Home/Brands/8.png';
import BrandEight from '../../Images/Home/Brands/9.png';
 
const TopBrands = () => {
    const TopBrandsData = [
        {   
            _Id: 1,
            Image: BrandOne
        }, {   
            _Id: 2,
            Image: BrandTwo
        }, {   
            _Id: 3,
            Image: BrandThree
        }, {   
            _Id: 4,
            Image: BrandFour
        }, {   
            _Id: 5,
            Image: BrandFive
        }, {   
            _Id: 6,
            Image: BrandSix
        }, {   
            _Id: 7,
            Image: BrandSeven
        }, {   
            _Id: 8,
            Image: BrandEight
        }, {   
            _Id: 9,
            Image: BrandThree
        }, {   
            _Id: 10,
            Image: BrandThree
        }
    ];
    return (
        <>       
            <div className='brand-items-wrapper spacing-block'>
                {TopBrandsData.map((Data) => {
                    return (
                        <div className='brand-content-wrapper' key={Data._Id}>
                            <Link to='/Brands' className="brand-img">
                                <img src={Data.Image} className="img-fluid brand-image-style" alt="Apple"/>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default TopBrands
