import React,{ useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from '@reach/router';
const NonTrackshipMentstatus = ({...props}) => {
    const [show, setShow] = useState(false);
    // const [nonsangeethatrackStrap ]=useState(2);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [exceptdeliverystrap]=useState(false)
    const [expected]=useState(false)

    const [deliverOn]=useState(true)


    // const
    return (
        <>
            <button type='button' className='btn btn-dark-button btn-space bnt-h' onClick={handleShow}>Track</button>

            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Track Shipment</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {expected?

                        <div className="trackshipment_canva-Model expected">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para expected-para'>Expected delivery by</p>
                                <h4 className='trackshipment_canva-msg expected-para'>Wed, 6th Jan  </h4>
                            </div>
                        </div>
                        :""}
                    
                     
                         
                    {deliverOn?   
                        <div className="trackshipment_canva-Model">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para'>Delivered on  </p>
                                <h4 className='trackshipment_canva-msg'>Wed, 6th Jan, 2022 - 3:45pm</h4>
                            </div>
                        </div>:
                    
                    
                        <div className="trackshipment_canva-Model failed">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para failed'>Delivery failed  </p>
                                <h4 className='trackshipment_canva-msg blacked'>We will reattempt the delivery tomorrow</h4>
                            </div>
                        </div> 
                    }
                    

                    <div className="mar-spacer h-1"></div>                    
                    <div className="order_tracking-shipiment-strapper">
                        <ul className="StepProgress">
                            <li className="StepProgress-item is-done line-going">
                                <strong className='orderplace going_on'>Order Placed</strong>
                                <span className='tracking_timing'>Wed, 1 Jan, 2022 - 3:45pm</span>
                            </li>
                            <li className='StepProgress-item is-done'>
                                <strong className='orderplace going_on'>Order is on the way</strong>
                                <span className='tracking_timing'>Thursday, 2 Jan, 2022</span>
                                <Link to="/" className='StaperShow-details'>Show details</Link>  

                            </li>
                            {exceptdeliverystrap ?
                                <li className='StepProgress-item is-done'>
                                    <strong>Delivererd</strong>
                                    <span className='tracking_timing'>Delivered on Wed, 6th Jan, 2022 - 3:45pm</span>
                                </li>:
                                <li className={`StepProgress-item`}>
                                    <strong className='stapertxr'>Delivery Pending</strong>
                                    <span className='tracking_timing'>Delhivery : 3477KL67</span>
                                </li>

                            }
                        </ul>
                        <div className="mar-spacer h-1_2"></div>

                    </div>                                     
                     

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default NonTrackshipMentstatus