import React from 'react';
import AxisIcon from '../../Images/Product/offers/AxisIcon-1.png'

const BankOffers = () => {
    return (
        <>	
            <div className='bank-offer-wrapper'>
                <div className='bank-offer-media'>
                    <img src={AxisIcon} className='img-fluid' alt='' />
                </div>
                <div className='mar-spacer h-1_2'></div>
                <div className='bank-offer-details'>
                    <h3>5% Cashback on SANGEETHA AXIS Bank Credit Card on orders above ₹1800 </h3>
                    <div className='bank-offer__wrap'>
                        <div className='bank-offer__details'>
                            <div className='bank-offer__heading'>Duration</div>
                            <div className='bank-offer__text'>1st Feb, 2022 (00:00 Hours) to 28th Feb, 2022</div>
                        </div>
                        <div className='bank-offer__details'>
                            <div className='bank-offer__heading'>Conditions to avail the offer</div>
                            <ul>
                                <li>Minimum Cart Value- INR 5,000</li>
                                <li>Max. Discount would be applicable as per the below table</li>
                            </ul>
                        </div>
                        <div className='bank-offer__details'>
                            <div className='bank-offer__heading'>How do I check the eligibility of the product?</div>
                            <div className='bank-offer__text'>Offer is applicable across Large Appliances, Electronics, 
                                            Furniture & Mobiles category products. However, the offer will not apply to the purchase of select products. 
                                            Please check if the offer is listed on the product page, before making a purchase.  </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BankOffers