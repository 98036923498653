import React from 'react';
import Modal from 'react-bootstrap/Modal';
import NFM from '../../Images/Product/NFM.png';

import { NFMListData } from '../../Data';
import { Link } from '@reach/router';

const ModalSimilarProducts = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-lg-custom modal-nfm-custom'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <div className='notify-me__wrap'>
                            <div className='notify-me__media'>
                                <img src={NFM} className='img-fluid' alt='' />
                            </div>
                            <div className='notify-me__content'>
                                <h4 className='notify-me__head mb-0'>Samsung Galaxy Z Fold 5G (Awesome White, 128GB, 1TB)</h4>
                                <p className='notify-me__text'>Is out of stock</p>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='nfm-heading__wrap sale__wrap'>
                        <div className='nfm-heading-section'>
                            <h5 className='nfm-heading'>Similar Products</h5>
                            <Link to='/ProductList' className='nfm-link'>Explore More</Link>
                        </div>
                        <div className='nfm-out-of-stock'>
                            {NFMListData.slice(0, 3).map((RemommendProduct, idx) => {
                                return (
                                    <div className='product-carousel-wrapr' key={idx}>
                                        {RemommendProduct.label ?
                                            <div className='product-label-tag'>{RemommendProduct.label}</div> : ''
                                        }
                                        <div className='carousel-media-wrapper d-flex justify-content-center'>
                                            <img
                                                src={RemommendProduct.Image}
                                                alt={RemommendProduct.productName}
                                                className='img-fluid'
                                            />
                                        </div>
                                        <div className='carousel-content-wrapper'>
                                            <div className='carousel-product-title'>{RemommendProduct.productName}</div>  
                                            {RemommendProduct.productStorage.productColor ||  RemommendProduct.productStorage.ram || RemommendProduct.productStorage.ram ?
                                                <p className='product-muted mb-2'>
                                                    {RemommendProduct.productColor}, {' '}
                                                    {RemommendProduct.productStorage.Storage} + {' '}
                                                    {RemommendProduct.productStorage.ram}
                                                </p> : null
                                            }
                                            <div className='flexible'></div>
                                            <div className='product-offer mb-3'>
                                                {RemommendProduct.BrandOfferList.List1.length > 0 ?
                                                    <div className='product-offer-list'>
                                                        <div className='offer-icon'>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.416 7.64418L18.2496 7.64418L18.2496 13.4778L16.5832 13.4778L16.5826 10.4885L8.8215 18.2508L7.64299 17.0723L15.4047 9.31059L12.416 9.31059L12.416 7.64418ZM1.75043 6.52459L3.41685 6.52459L3.41685 9.5133L11.1785 1.75162L12.357 2.93013L4.59536 10.6918L7.58406 10.6918L7.58406 12.3582L1.75043 12.3582L1.75043 6.52459Z" fill="#121415"/>
                                                            </svg>

                                                        </div>
                                                        <div className='offer-label'>{RemommendProduct.BrandOfferList.List1}</div>
                                                    </div> : null
                                                }	
                                                {RemommendProduct.BrandOfferList.List2.length > 0 ?
                                                    <div className='product-offer-list'>
                                                        <div className='offer-icon'>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M17.4994 2.5H10.1427L1.66602 10.9767L9.02268 18.3333L17.4994 9.85667V2.5ZM14.5827 4.16667C15.2727 4.16667 15.8327 4.72667 15.8327 5.41667C15.8327 6.10667 15.2727 6.66667 14.5827 6.66667C13.8927 6.66667 13.3327 6.10667 13.3327 5.41667C13.3327 4.72667 13.8927 4.16667 14.5827 4.16667Z" fill="#121415"/>
                                                            </svg>


                                                        </div>
                                                        <div className='offer-label'>{RemommendProduct.BrandOfferList.List2}</div>
                                                    </div> : null
                                                }
                                            </div>
                                            <div className='flexible'></div>
                                            <div className="price-offer">
                                                <div className="new-price">₹43,659</div>
                                                <div className="old-price">₹45,659</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalSimilarProducts