import React from 'react'
import Carousel from "react-multi-carousel";
import CategoryButtonGroup from '../Common/CategoryButtonGroup';
import { Link } from '@reach/router';

import { productCategoryData } from '../../Data';

const CategoryOffer = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
            partialVisibilityGutter: 50
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            partialVisibilityGutter: 50
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item best-offer-phone__wrap"
                responsive={responsive}
                renderButtonGroupOutside={true}
                customButtonGroup={<CategoryButtonGroup />}
                swipeable
            >   
                {productCategoryData.map((Data, idx) => {
                    return (
                        <Link to='/ProductDetails' className='product-carousel-wrapper' key={idx}>
                            {Data.label ?
                                <div className='product-label-tag'>{Data.label}</div> : ''
                            }
                            
                            <div className='carousel-media-wrapper'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={Data.Image}
                                    alt={Data.productName}
                                    className='img'
                                />
                            </div>
                            <div className='carousel-content-wrapper'>
                                <div className='carousel-product-title'>{Data.productName}</div>  
                                <div className='carousel-product-brand'>{Data.prductDesc}</div>
                                <div className='flexible'></div>
                                <div className="price-offer">
                                    <div className="new-price">₹43,659</div>
                                    <div className="old-price">₹45,659</div>
                                </div>
                                <div className="price-offer__tags">
                                    <div className='offer-label__tags'>Photography</div>
                                    <div className='offer-label__tags'>Fast Charge</div>
                                    <div className='offer-label__tags'>Gaming</div>
                                    <div className='offer-label__tags'>Wireless Charge</div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </Carousel>
        </>
    )
}

export default CategoryOffer