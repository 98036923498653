import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';


import AddressEmpty from '../../Images/Address/empty.svg'
import AddressmapDeatils from './AddressmapDeatils';

const AddressPanel = () => {
    const [ isAddress ] = useState(false);
    const [searchfocous,setSearchfocous]  = useState(true);
    // locationmap
    const [livelocateradd,setLivelocateradd]=useState(true);
    const [searchTermLocate,setSearchTermLocate] = useState("");
    const handleChange = (e) => {
        setSearchTermLocate(e.target.value);
    };
    const showLoactionmap=()=>{
        setLivelocateradd(false)
    }


    const SearchLocation =[
        {
            id:1,
            LocateTitle:'The Royal Orchard Bengaluru',
            LicateAddreess:'Major district road 40, Manikaran road, Bengaluru, Ka..',
            distance: '30 m'
        },
        {
            id:1,
            LocateTitle:'Hotel Royal Orchard Bengaluru',
            LicateAddreess:'Gadherni road, Shimsa village, Bengaluru, Karanataka...',
            distance: '30 m'
        },  {
            id:1,
            LocateTitle:'The Royal Orchard Home Stay',
            LicateAddreess:'Gadherni road, Shimsa village, Bengaluru, Karanataka...',
            distance: '30 m'
        },
    ]
    const results = !searchTermLocate
        ? SearchLocation
        : SearchLocation.filter(person =>
            person.LocateTitle.toLowerCase().includes(searchTermLocate.toLocaleLowerCase())
        );


    return (
        <>
            <div className="address-locator-head">
                <Offcanvas.Header closeButton className='border-0 pb-0 '>
                    {livelocateradd?
                        <Offcanvas.Title>Change or add Address</Offcanvas.Title>:
                        <Offcanvas.Title>
                            <div className="addaddress-backarrow-wrappwer">
                                <span>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.4373 14.6667H26.6667V17.3333H10.4373L17.5893 24.4853L15.704 26.3707L5.33334 16L15.704 5.62933L17.5893 7.51467L10.4373 14.6667Z" fill="#121415"/></svg>
                                </span>
                                <h4>Add Address</h4>
                            </div>
                        </Offcanvas.Title>} 
                    {livelocateradd?
                        <div className='w-100 mt-4'>
                            <div className='input-group-address'>
                                <div className='cart-address__wrap'>
                                    <input type="text" className='form-control form-control-coupon' placeholder='Search for drop location' onFocus={()=>setSearchfocous(false)}  defaultValue={searchTermLocate}  
                                        onChange={handleChange}    

                                    />
                                    <span className='d-flex search-icon__address'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.0259 13.8475L18.5951 17.4158L17.4159 18.595L13.8476 15.0258C12.5199 16.0902 10.8684 16.6691 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16666C1.66675 5.02666 5.02675 1.66666 9.16675 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6692 10.8683 16.0903 12.5198 15.0259 13.8475ZM13.3542 13.2292C14.4118 12.1416 15.0025 10.6837 15.0001 9.16666C15.0001 5.94333 12.3892 3.33333 9.16675 3.33333C5.94341 3.33333 3.33341 5.94333 3.33341 9.16666C3.33341 12.3892 5.94341 15 9.16675 15C10.6838 15.0024 12.1417 14.4118 13.2292 13.3542L13.3542 13.2292Z" fill="#121415"/>
                                        </svg>
                                    </span>
                                    <span className='search-cancel-btn'  style={{ display: searchfocous ? 'none' : 'block' }}  role="button" onClick={() => setSearchTermLocate(() => "")} aria-hidden="true">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 18.3333C5.39752 18.3333 1.66669 14.6025 1.66669 10C1.66669 5.3975 5.39752 1.66666 10 1.66666C14.6025 1.66666 18.3334 5.3975 18.3334 10C18.3334 14.6025 14.6025 18.3333 10 18.3333ZM10 16.6667C11.7681 16.6667 13.4638 15.9643 14.7141 14.714C15.9643 13.4638 16.6667 11.7681 16.6667 10C16.6667 8.23189 15.9643 6.5362 14.7141 5.28595C13.4638 4.03571 11.7681 3.33333 10 3.33333C8.23191 3.33333 6.53622 4.03571 5.28598 5.28595C4.03573 6.5362 3.33335 8.23189 3.33335 10C3.33335 11.7681 4.03573 13.4638 5.28598 14.714C6.53622 15.9643 8.23191 16.6667 10 16.6667ZM10 8.82166L12.3567 6.46416L13.5359 7.64333L11.1784 10L13.5359 12.3567L12.3567 13.5358L10 11.1783L7.64335 13.5358L6.46419 12.3567L8.82169 10L6.46419 7.64333L7.64335 6.46416L10 8.82166Z" fill="#121415"/></svg>
                                    </span>
                                </div>
                            </div>
                            {searchTermLocate.length >1?
                                (<div className="addressinputSearchWrapper">
                                    {results.map(item=> (
                                        <div className="searchitem-wrap" key={item._id}>
                                            <div className="left-address">
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 13.9333L11.3 10.6333C11.9526 9.98067 12.397 9.14915 12.577 8.24392C12.7571 7.33869 12.6646 6.4004 12.3114 5.54771C11.9582 4.69501 11.36 3.96621 10.5926 3.45345C9.82519 2.94069 8.92296 2.66701 8 2.66701C7.07704 2.66701 6.17481 2.94069 5.40739 3.45345C4.63997 3.96621 4.04183 4.69501 3.68861 5.54771C3.33539 6.4004 3.24294 7.33869 3.42297 8.24392C3.603 9.14915 4.04741 9.98067 4.7 10.6333L8 13.9333ZM8 15.8187L3.75734 11.576C2.91823 10.7369 2.34679 9.66779 2.11529 8.5039C1.88378 7.34002 2.0026 6.13362 2.45673 5.03727C2.91086 3.94092 3.6799 3.00385 4.66659 2.34457C5.65328 1.68528 6.81332 1.33339 8 1.33339C9.18669 1.33339 10.3467 1.68528 11.3334 2.34457C12.3201 3.00385 13.0891 3.94092 13.5433 5.03727C13.9974 6.13362 14.1162 7.34002 13.8847 8.5039C13.6532 9.66779 13.0818 10.7369 12.2427 11.576L8 15.8187ZM8 8.66667C8.35362 8.66667 8.69276 8.52619 8.94281 8.27615C9.19286 8.0261 9.33334 7.68696 9.33334 7.33334C9.33334 6.97971 9.19286 6.64058 8.94281 6.39053C8.69276 6.14048 8.35362 6 8 6C7.64638 6 7.30724 6.14048 7.05719 6.39053C6.80715 6.64058 6.66667 6.97971 6.66667 7.33334C6.66667 7.68696 6.80715 8.0261 7.05719 8.27615C7.30724 8.52619 7.64638 8.66667 8 8.66667ZM8 10C7.29276 10 6.61448 9.71905 6.11438 9.21896C5.61429 8.71886 5.33334 8.04058 5.33334 7.33334C5.33334 6.62609 5.61429 5.94782 6.11438 5.44772C6.61448 4.94762 7.29276 4.66667 8 4.66667C8.70725 4.66667 9.38552 4.94762 9.88562 5.44772C10.3857 5.94782 10.6667 6.62609 10.6667 7.33334C10.6667 8.04058 10.3857 8.71886 9.88562 9.21896C9.38552 9.71905 8.70725 10 8 10Z" fill="#98A0AE"/></svg>
                                                </span>
                                                <div className="distance-counter">{item.distance}</div>
                                            </div>
                                            <div className="right-addresstxt">
                                                <h5 className='address-location-heading'>{item.LocateTitle}</h5>
                                                <span className='address-location-text'>{item.LicateAddreess}</span>
                                            </div>
                                        </div>

                                    ))}
                                
                                
                                </div>):""}
                            <div style={{ display: searchfocous ? 'block' : 'none' }}   >
                                <div className='cart-address__location  curser-point' onClick={showLoactionmap}  role="button" aria-hidden="true" >
                                    <span className='d-flex'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.16659 0.833328V2.54882C5.67135 2.92905 2.92897 5.67143 2.54875 9.16666H0.833252V10.8333H2.54875C2.92897 14.3286 5.67135 17.0709 9.16659 17.4512V19.1667H10.8333V17.4512C14.3285 17.0709 17.0709 14.3286 17.4511 10.8333H19.1666V9.16666H17.4511C17.0709 5.67143 14.3285 2.92905 10.8333 2.54882V0.833328H9.16659ZM9.99992 4.16666C13.2499 4.16666 15.8333 6.75 15.8333 10C15.8333 13.25 13.2499 15.8333 9.99992 15.8333C6.74992 15.8333 4.16659 13.25 4.16659 10C4.16659 6.75 6.74992 4.16666 9.99992 4.16666ZM9.99992 7.5C9.33688 7.5 8.70099 7.76339 8.23215 8.23223C7.76331 8.70107 7.49992 9.33696 7.49992 10C7.49992 10.663 7.76331 11.2989 8.23215 11.7678C8.70099 12.2366 9.33688 12.5 9.99992 12.5C10.663 12.5 11.2988 12.2366 11.7677 11.7678C12.2365 11.2989 12.4999 10.663 12.4999 10C12.4999 9.33696 12.2365 8.70107 11.7677 8.23223C11.2988 7.76339 10.663 7.5 9.99992 7.5Z" fill="#00AA88"/>
                                        </svg>
                                    </span>
                                    <label htmlFor="infoText">Use your current location</label>
                                </div>
                                <div className="mar-spacer"/>

                            </div>
                        </div>:""  
                    }
                </Offcanvas.Header>
            </div>
            {livelocateradd?
                <div className='mar-spacer bg-grey' style={{ display: searchfocous ? 'block' : 'none' }} />:""}
            {livelocateradd?
                <Offcanvas.Body style={{ display: searchfocous ? 'block' : 'none' }}>
                    <div className='address-head__wrapper'>
                        <h6 className='top-head__wrapper mb-0'>Saved Address</h6>
                    </div>
                    {isAddress ?
                        <div className='address-empty'>
                            <div className='mar-spacer h-1_5' />
                            <img src={AddressEmpty} className='img-fluid m-auto d-block' alt='' />
                            <p className='text-center'>You haven’t added any address yet</p>
                        </div> :
                        <div className='d-flex flex-column w-100 cart-address__normal'>
                            <div className='d-flex align-items-start cart-address__lists'>
                                <div className='coupon-list__input mt-1 address-radio'>
                                    <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" checked />
                                </div>
                                <div className='logged-default__list border-0 mb-4 ml-4'>
                                    <div className='logged-default__label mb-2'>
                                        <span>Rohan Nanda <span className='default__address'>(Default)</span></span>
                                        <span className="btn btn-tag">Home</span>
                                    </div>
                                    <div className='cart-address__section'>
                                        <div className='loggedIn-address__label mb-2'>
                                            103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                        </div>
                                        <div className='loggedIn-address__contact'>
                                            <label htmlFor="label">Mobile: </label>{' '}
                                            <span className='contact-number'>7382901648</span>
                                        </div>
                                    </div>
                                    <div className='cart-address-btn__wrap mt-3'>
                                        <button type='button' className='btn btn-outline px-4'>Edit</button>
                                        <button type='button' className='btn btn-outline ml-3 px-4'>Remove</button>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-start cart-address__lists'>
                                <div className='coupon-list__input mt-1 address-radio'>
                                    <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                </div>
                                <div className='logged-default__list border-0 mb-4 ml-4'>
                                    <div className='logged-default__label mb-2'>
                                        <span>User Name </span>
                                    </div>
                                    <div className='cart-address__section'>

                                        <div className='loggedIn-address__label mb-2'>
                                            103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                        </div>
                                        <div className='loggedIn-address__contact'>
                                            <label htmlFor="label">Mobile: </label>{' '}
                                            <span className='contact-number'>7382901648</span>
                                        </div>
                                    </div>
                                    {/* <div className='cart-address-btn__wrap mt-3'>

                                        <button type='button' className='btn btn-outline px-4'>Edit</button>
                                        <button type='button' className='btn btn-outline ml-3 px-4'>Remove</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className='d-flex align-items-start cart-address__lists'>
                                <div className='coupon-list__input mt-1 address-radio'>
                                    <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                </div>
                                <div className='logged-default__list border-0 mb-4 ml-4'>
                                    <div className='logged-default__label mb-2'>
                                        <span>Kartik Gupta </span>
                                    </div>
                                    <div className='cart-address__section'>
                                        <div className='loggedIn-address__label mb-2'>
                                            103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                        </div>
                                        <div className='loggedIn-address__contact'>
                                            <label htmlFor="label">Mobile: </label>{' '}
                                            <span className='contact-number'>7382901648</span>
                                        </div>
                                    </div>
                                    {/* <div className='cart-address-btn__wrap mt-3'>
                                        <button type='button' className='btn btn-outline px-4'>Edit</button>
                                        <button type='button' className='btn btn-outline ml-3 px-4'>Remove</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    }
                  
                </Offcanvas.Body>:
                <div className="address-locationmapwrapper">
                    <Offcanvas.Body>
                        <AddressmapDeatils/>
                    </Offcanvas.Body>

                </div>
               
            }

        </>
    )
}

export default AddressPanel