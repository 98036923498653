import React from 'react';
import Highlights1 from '../../../Images/Product/Highlights6.png';
import Highlights2 from '../../../Images/Product/Highlights7.png';
import Highlights3 from '../../../Images/Product/Highlights8.png';
import Highlights4 from '../../../Images/Product/Highlights9.png';
import Highlights5 from '../../../Images/Product/Highlights10.png';
import Highlights6 from '../../../Images/Product/Highlights11.png';

const EarphoneHighlights = () => {
    return (
        <>
            <div className='highlight-grid'>
                <div className='highlight-col'>
                    <img src={Highlights1} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights2} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights3} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights4} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights5} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights6} className='img-fluid' alt='' />
                </div>
            </div>
        </>
    )
}

export default EarphoneHighlights