import React from 'react'
import blog6 from '../../../Images/Blog/blog6.png';
import blog2 from '../../../Images/Blog/blog2.png';
import blog3 from '../../../Images/Blog/blog3.png';
import { Link } from '@reach/router';


const BlogCategoryContent = () => {
    const BlogProduct=[
        {
            id:1,
            Image:blog6 ,
            date:'1 Sept, 2021',
            Title: 'Beat the Heat: AC & Fridge',
            Description: 'Samsung is one of the leading smartphones selling South Korean companies and the    worlds highest producer of electronic items. This company is one of the top producers of smartphones apart from a wi..',

            
        },
        {
            id:1,
            Image:blog2 ,
            date:'1 Sept, 2021',
            Title: '5G : All you need to know',
            Description: 'Choosing the right features to truly fit their needs can be tricky for many consumers, so let’s break down what features you absolutely need based on your usage —',

        },
        {
            id:1,
            Image:blog3 ,
            date:'1 Sept, 2021',
            Title: 'Meta : The next big thing',
            Description: 'Are you looking forward to flaunting a cool new phone this summer? Well, your wait is over!',

        }
    ]
    return (
        <>
            <Link to='/BlogDeatils'>
                {BlogProduct.map(Data => (
                    <div className='popular-blog_content' key={Data._id}>
                        <div className="blog-tags_danger">
                            {Data.tag}
                        </div>
                        <div className="popular_blog-img">
                            
                            <img src={Data.Image} alt="Blog-Img"  className='img-fluid'/>
                        </div>
                        <div className="blog-contentpart">
                            <span className="blogdate">
                                {Data.date}
                            </span>
                            <div className="blog-titel-content">
                                {Data.Title}

                            </div>
                            <div className="blog-summery-text">
                                <p>{Data.Description}</p>
                            </div>
                            <div className="product-cat-tags blog-cate-tag">
                                <div className="product-tag_label">Sangeetha Mobiles</div>
                                <div className="product-tag_label">Samsung</div>
                            </div>
                        </div>
                    </div>
                ))}

            </Link>
            
        </>
    )
}

export default BlogCategoryContent