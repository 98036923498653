import React, { useState } from 'react';
import { Link } from "@reach/router";
import Modal from 'react-bootstrap/Modal';
import Step1 from './BajajSteps/Step1';
import Step2 from './BajajSteps/Step2';

const BajajModalList = (props) => {
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        username: "",
        nationality: "",
        other: "",
    });

    const stepsTitle = ["1/2", "2/2"];
 
    const PageDisplay = () => {
        if (page === 0) {
            return <Step1 formData={formData} setFormData={setFormData} />;
        } else {
            return <Step2 formData={formData} setFormData={setFormData} />;
        }
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom items-removed__modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 gst-title-'>
                        <h2 className='notifyMe-heading mb-4'>Bajaj NoCost Emi</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="wizard-steps wizard-steps__exchange- mt-2 p-0">
                        <div className="form">
                            {/* {page === stepsTitle.length - 1 ? '' :  */}
                            <div className="sticky-progress">
                                <div className="progress-bar">
                                    <div
                                        className="progress-bar__line"
                                        style={{ width: page === 0 ? "50%" : "100%" }}
                                        // style={{ width: page === 0 ? "16.66%" : page === 1 ? "33.32%" : page === 2 ? "49.99%" : page === 3 ? "66.65%" : page === 4 ? "83.31%" : page === 5 ? "100%" : "0%" }}
                                    /> 
                                </div>
                                <div className="header">
                                    <div className="steps-bar">Step <span>{stepsTitle[page]}</span></div>
                                </div>
                            </div>
                            
                            <div className="form-container">
                                <div className="body">{PageDisplay()}</div>
						
                                <div className="steps-footer d-flex align-items-center justify-content-between">
                                    <button
                                        className='btn btn-dark-custom-outline col-flex mr-2_5'
                                        disabled={page === 0}
                                        onClick={() => {
                                            setPage((currPage) => currPage - 1);
                                        }}
                                    >Previous</button>
                                    <Link
                                        className={page >= 1 ? 'btn btn-place-order btn-dark-custom col-flex text-nowrap' : 'btn btn-place-order btn-dark-custom col-flex'}
                                        to={page === stepsTitle.length - 1 ? '/Payment' : '' }
                                        onClick={() => {
                                            if (page === stepsTitle.length - 1) {
                                                console.log(formData);
                                            } else {
                                                setPage((currPage) => currPage + 1);
                                            }
                                        }}
                                    >
                                        {page === stepsTitle.length - 1 ? 'Add Card' : 'Next'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>  
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BajajModalList