import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const CancellationPolicy = () => {
    const [activeId, setActiveId] = useState();

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <div className='cancellation__accordion'>
                <Accordion className='filter__accordion accordion-plus w-100' defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                        <Accordion.Header onClick={() => toggleActive('0')} className='px-0'>
                            <div className='filter-card__head m-0'>
                                <span>Cancellation & Return Policy</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="product-policy__wrap">
                                <ul className="product-policy__list">
                                    <li>7 days return policy</li>
                                    <li>Free standard delivery on all orders and free return within 7 days of your order delivery date.</li>
                                </ul>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    )
}

export default CancellationPolicy