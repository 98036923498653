import React, { useState } from 'react';
import Address from './Address';

const Location = () => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>                     
            <div className='payment-offer-options'>
                <div className='payment-offer-content'>
                    <div className='payment-offer-icon'>
                        <span>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.6667 23.9186C11.977 23.5764 9.51851 22.2228 7.79096 20.133C6.0634 18.0432 5.19641 15.374 5.3662 12.6679C5.53599 9.96185 6.72981 7.42196 8.70503 5.56448C10.6803 3.707 13.2886 2.67133 16 2.66797C18.7148 2.66595 21.3281 3.69909 23.3076 5.55688C25.2871 7.41466 26.4837 9.9573 26.6537 12.6667C26.8237 15.3761 25.9543 18.0483 24.2225 20.139C22.4908 22.2296 20.0271 23.5813 17.3334 23.9186V26.684C22.5947 26.8066 26.6667 27.648 26.6667 28.668C26.6667 29.772 21.8907 30.668 16 30.668C10.1094 30.668 5.33337 29.772 5.33337 28.668C5.33337 27.648 9.40537 26.8066 14.6667 26.684V23.9186ZM16 16.0013C16.7073 16.0013 17.3856 15.7204 17.8857 15.2203C18.3858 14.7202 18.6667 14.0419 18.6667 13.3346C18.6667 12.6274 18.3858 11.9491 17.8857 11.449C17.3856 10.9489 16.7073 10.668 16 10.668C15.2928 10.668 14.6145 10.9489 14.1144 11.449C13.6143 11.9491 13.3334 12.6274 13.3334 13.3346C13.3334 14.0419 13.6143 14.7202 14.1144 15.2203C14.6145 15.7204 15.2928 16.0013 16 16.0013Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                    <div className='payment-offer-info'>
                        <h6>Deliver To 462026</h6>
                        <p className='details-text'>Get this product by Tomorrow.  <span> FREE</span></p>
                    </div>
                </div>
                <div className='payment-offer-link' onClick={() => setModalShow(true)} role='button' aria-hidden="true">
                    Change
                </div> 
            </div>     
            
            <Address
                show={modalShow}
                onHide={() => setModalShow(false)}
            />    
        </>
    )
}

export default Location