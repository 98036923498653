import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import CardPayment from './CardPayment';
import UPIPayment from './UPIPayment';
import NetBanking from './NetBanking';
import CardlessEmiPayment from './CardlessEmiPayment';
import WalletsPayment from './WalletsPayment';
import CODPayment from './CODPayment';

import upiLogo from '../../Images/Payment/upi.svg';

const PaymentAccordion = () => { 
    const [ istoggle, setToggle ] = useState(false);
    const [activeId, setActiveId] = useState('5');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <Accordion className='cart-payment__accordion' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Credit/Debit Card</span>
                            <span className='card-info__detail'>Mastercard, VISA, RuPay & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CardPayment />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')}>
                        <span>
                            <img src={upiLogo} className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head'>
                            <span>UPI</span>
                            <span className='card-info__detail'>Google Pay, Amazon Pay, PhonePe & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='product-highlight__otherInfo'>
                            <UPIPayment />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')}>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM4 8.236V9H20V8.236L12 4.236L4 8.236ZM12 8C11.7348 8 11.4804 7.89464 11.2929 7.70711C11.1054 7.51957 11 7.26522 11 7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7C13 7.26522 12.8946 7.51957 12.7071 7.70711C12.5196 7.89464 12.2652 8 12 8Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Netbanking</span>
                            <span className='card-info__detail'>SBI, HDFC, Axis & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <NetBanking />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className={activeId === '3' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('3')}>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z" fill="#121415"/>
                                <path d="M12.9653 10.5322C12.9005 11.2125 12.6667 11.7619 12.2639 12.1805C11.8611 12.5992 11.2569 12.8765 10.4514 13.0126L13.4583 17H12L9 13.0361L9.13889 11.8901H9.77778C10.0046 11.8901 10.2037 11.8796 10.375 11.8587C10.5463 11.8378 10.6944 11.809 10.8194 11.7724C10.9491 11.7305 11.0602 11.6808 11.1528 11.6232C11.2454 11.5604 11.331 11.4898 11.4097 11.4113C11.6134 11.1915 11.7454 10.8985 11.8056 10.5322H9V9.38619H11.7917C11.7546 9.1821 11.6944 9.00942 11.6111 8.86813C11.5324 8.72161 11.4282 8.59079 11.2986 8.47567C11.169 8.36054 10.9977 8.27682 10.7847 8.22449C10.5764 8.17216 10.2963 8.146 9.94444 8.146H9V7H15V8.146H12.3333C12.4954 8.27682 12.6296 8.4495 12.7361 8.66405C12.8426 8.8786 12.9167 9.11931 12.9583 9.38619H15V10.5322H12.9653Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>EMI & Cardless EMI</span>
                            <span className='card-info__detail'>LazyPay, Zebpay & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CardlessEmiPayment />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className={activeId === '4' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('4')}>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 7V5H4V19H20V17H12C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7H20ZM3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM13 9V15H20V9H13ZM15 11H18V13H15V11Z" fill="#121415"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Wallets</span> 
                            <span className='card-info__detail'>Paytm, Amazon & more</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <WalletsPayment istoggle={istoggle} setToggle={setToggle} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className={activeId === '5' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('5')}>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 19.9999C20 20.2651 19.8946 20.5195 19.7071 20.707C19.5196 20.8946 19.2652 20.9999 19 20.9999H5C4.73478 20.9999 4.48043 20.8946 4.29289 20.707C4.10536 20.5195 4 20.2651 4 19.9999V10.9999H1L11.327 1.61192C11.5111 1.4444 11.7511 1.35156 12 1.35156C12.2489 1.35156 12.4889 1.4444 12.673 1.61192L23 10.9999H20V19.9999ZM18 18.9999V9.15692L12 3.70292L6 9.15692V18.9999H18Z" fill="#121415"/>
                                <path d="M11.7309 10.3362H9H8.95V10.3862V11.5322V11.5822H9H11.7459C11.6831 11.9133 11.5583 12.1772 11.3737 12.3766C11.2977 12.4523 11.215 12.5206 11.1255 12.5813C11.0374 12.6359 10.9307 12.6838 10.8047 12.7246C10.6832 12.7602 10.538 12.7884 10.3689 12.8091C10.2002 12.8297 10.0032 12.8401 9.77778 12.8401H9.13889H9.09458L9.08925 12.8841L8.95036 14.0301L8.94793 14.0502L8.96013 14.0663L11.9601 18.0302L11.9751 18.05H12H13.4583H13.5587L13.4983 17.9699L10.5405 14.0476C11.3126 13.9058 11.901 13.6298 12.2999 13.2152C12.7028 12.7965 12.9388 12.2509 13.0105 11.5822H15H15.05V11.5322V10.3862V10.3362H15H13.0008C12.9574 10.0816 12.8842 9.85002 12.7809 9.64182C12.6941 9.46684 12.5887 9.318 12.4645 9.196H15H15.05V9.146V8V7.95H15H9H8.95V8V9.146V9.196H9H9.94444C10.2944 9.196 10.5699 9.22209 10.7725 9.27298L10.7728 9.27305C10.9799 9.32394 11.1433 9.40457 11.2654 9.51305C11.3908 9.62444 11.4913 9.75067 11.5671 9.89179L11.567 9.89181L11.568 9.89353C11.6397 10.0151 11.6943 10.1623 11.7309 10.3362Z" fill="#121415" stroke="#121415" strokeWidth="0.1"/>
                            </svg>
                        </span>
                        <div className='payment-card__head'>
                            <span>Cash on Delivery</span>
                            <span className='card-info__detail'>Pay at home</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CODPayment />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default PaymentAccordion