import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ItemsRemoved = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom items-removed__modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <h2 className='notifyMe-heading mb-4'>Items removed from cart</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='login-textbox__wrapper'>
                        <div className='text-left notify-me-text'>
                            <p className='mb-0'>The previous items from your cart have been moved to Save for Later as you have selected an exchange offer for the current product.</p>
                        </div>
                        <div className='mar-spacer h-4' />
                            
                        <div className="continue-login_btn">
                            <button type='button' className='btn btn-dark-custom w-100'>Continue</button>
                        </div>  
                    </div>                  
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ItemsRemoved