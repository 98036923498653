import React,{useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';


const Faq = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <div className="h-2"/>
            <div className="help-wrapper">
                <div className="faq-wrap">
                    <Accordion defaultActiveKey={activeId}>
                        <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                            <Accordion.Header onClick={() => toggleActive('0')}>Lorem ipsum dolor sit amet</Accordion.Header>
                            <Accordion.Body>
                                <p className='faq-bofy-txt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                            <Accordion.Header onClick={() => toggleActive('1')}>Lorem ipsum dolor sit amet</Accordion.Header>
                            <Accordion.Body>
                                <p className='faq-bofy-txt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                                
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                            <Accordion.Header onClick={() => toggleActive('2')}>Lorem ipsum dolor sit amet</Accordion.Header>
                            <Accordion.Body>
                                <p className='faq-bofy-txt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
               

            </div>
        </>
    )
}

export default Faq