import React from 'react'
import { Link } from "@reach/router";
// import catg1 from '../../Images/Home/catg/catg-1.png';
// import catg2 from '../../Images/Home/catg/catg-2.png';
// import catg3 from '../../Images/Home/catg/catg-3.png';
// import catg4 from '../../Images/Home/catg/catg-4.png';
// import catg5 from '../../Images/Home/catg/catg-5.png';
// import catg6 from '../../Images/Home/catg/catg-6.png';
// import catg7 from '../../Images/Home/catg/catg-7.png';
// import catg8 from '../../Images/Home/catg/catg-8.png';
import {useSelector} from 'react-redux';

const ShopByCatg = () => {
    const homeCategory = useSelector(state => state.homeDetails.homeCategory);
    const homeCategoryList = useSelector(state => state.homeDetails.homeCategory.content);
    // const homeCategory = '';
    // const homeCategoryList = '';

    console.log(homeCategory);
    if(homeCategory){
        return (
            <>
                <h4 className="catg-heading">{homeCategory.title}</h4>
                <div className="category__wrap category__grid p-x-16">
                    {homeCategoryList && homeCategoryList.map((dataList) => (
                        <div className="category-list__wrap w-auto" key={dataList.category_id}>
                            <div className="catg-item-wrapper">
                                <div className="catg-img-wrapper">
                                    <img src={dataList.image} className="img-fluid" alt=""  />
                                </div>
                                <Link to='/' className="catg-content-wrapper">
                                    <span>{dataList.title}</span>
                                </Link>
                            </div>
                        </div>
                    ))}
                
                    {/* <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg1} className="img-fluid" alt=""/>
                            </div>
                            <Link to='/' className="catg-content-wrapper">
                                <span>Phone</span>
                            </Link>
                        </div>
                    </div>
                    <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg2} className="img-fluid" alt=""/>
                            </div>
                            <div className="catg-content-wrapper">
                                <span>Accessories</span>
                            </div>
                        </div>
                    </div>
                    <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg3} className="img-fluid" alt=""/>
                            </div>
                            <div className="catg-content-wrapper">
                                <span>Earphone</span>
                            </div>{" "}
                        </div>
                    </div>
                    <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg4} className="img-fluid" alt=""/>
                            </div>
                            <div className="catg-content-wrapper">
                                <span>Smart Watches</span>
                            </div>
                        </div>
                    </div>
                    <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg5} className="img-fluid" alt=""/>
                            </div>
                            <div className="catg-content-wrapper">
                                <span>Cameras</span>
                            </div>
                        </div>
                    </div>
                    <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg6} className="img-fluid" alt=""/>
                            </div>
                            <div className="catg-content-wrapper">
                                <span>Laptops</span>
                            </div>
                        </div>
                    </div>
                    <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg7} className="img-fluid" alt=""/>
                            </div>
                            <div className="catg-content-wrapper">
                                <span>Laptops</span>
                            </div>
                        </div>
                    </div>
                    <div className="category-list__wrap w-auto">
                        <div className="catg-item-wrapper">
                            <div className="catg-img-wrapper">
                                <img src={catg8} className="img-fluid" alt=""/>
                            </div>
                            <div className="catg-content-wrapper">
                                <span>Laptops</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="view-all-link">
                    <Link to="/Categories" className="link-text">{homeCategory.view_all_text ? homeCategory.view_all_text: ''}</Link>
                </div>                            
            </>
        )
    }

    return (
        <>
        </>
    )
    
}

export default ShopByCatg;
