import React from 'react';

import img1 from '../../Images/DIY/2_1.jpg'
import img2 from '../../Images/DIY/2_2.jpg'
import img3 from '../../Images/DIY/3_3.jpg'

const Step2 = ({ formData, setFormData }) => {
    const availableCategories = [
        {
            __id : 1,
            name: 'Large Screen',
            status: 'unchecked',
            size: '3.2” - 4.5”',
            Image: img1
        }, {
            __id : 2,
            name: 'Medium Screen',
            status: 'checked',
            size: '4.5” - 5.4”',
            Image: img2
        }, {
            __id : 3,
            name: 'Small Screen',
            status: 'unchecked',
            size: '3.2” - 4.5”',
            Image: img3
        }
    ];
    return (
        <>
            <div className="sign-up-container">
                <div className="exchange-data">
                    <div className="mar-spacer h-4" />
                    <h3 className='steps__head'>Which Screen size do you prefer?</h3>
                    <p className='steps__info'>Select upto 2 options from below :  </p>
                    <div className="mar-spacer h-4" />
                    <div className='filter-tab__lists'>     
                        {availableCategories.map((Data) => (
                            <div className={Data.status === 'checked' ? 'filter-tab__flex changeexchange-div active' : 'filter-tab__flex changeexchange-div'} key={Data.__id} style={{ height: '245px'}}>
                                <div className='steps__media' style={{ width: '87px', height: 'auto'}}>
                                    <img src={Data.Image} className='img-fluid' alt={Data.name} />
                                </div>
                                <div className='flexible' />
                                <div className='steps__content'>
                                    <label htmlFor="label" className='filter_exchange laptop_screen'>{Data.name}</label>
                                    <p className='filter_exchange--size mb-0'>{Data.size}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                
            </div>
        </>
    )
}

export default Step2
