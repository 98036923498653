import React, { useState } from 'react';
import WhySangeethaModal from './WhySangeethaModal';

import img1 from '../../Images/Home/1.svg';
import img2 from '../../Images/Home/2.svg';
import img3 from '../../Images/Home/3.svg';
import img4 from '../../Images/Home/4.svg';
import img5 from '../../Images/Home/5.svg';

const WhySangeetha = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='why-sangeetha__wrap'>
                <div className='why-sangeetha__head text-center'>
                    <h4>Why Buy From Sangeetha</h4>
                    <p>A unique delivery experience with Sangeetha</p>
                </div>
                <div className='mar-spacer h-3' />
                <div className='d-flex why-sangeetha--lists'>
                    <div className='why-sangeetha--list'>
                        <div className='why-sangeetha--media'>
                            <img src={img1} className='img-fluid' alt='' />
                        </div>
                        <div className='why-sangeetha--info'>2 Hour Delivery</div>
                    </div>
                    <div className='why-sangeetha--list'>
                        <div className='why-sangeetha--media'>
                            <img src={img2} className='img-fluid' alt='' />
                        </div>
                        <div className='why-sangeetha--info'>Services at your doorstep</div>
                    </div>
                    <div className='why-sangeetha--list'>
                        <div className='why-sangeetha--media'>
                            <img src={img3} className='img-fluid' alt='' />
                        </div>
                        <div className='why-sangeetha--info'>Expert Guidance</div>
                    </div>
                    <div className='why-sangeetha--list'>
                        <div className='why-sangeetha--media'>
                            <img src={img4} className='img-fluid' alt='' />
                        </div>
                        <div className='why-sangeetha--info'>700 + Stores</div>
                    </div>
                    <div className='why-sangeetha--list'>
                        <div className='why-sangeetha--media'>
                            <img src={img5} className='img-fluid' alt='' />
                        </div>
                        <div className='why-sangeetha--info'>Post Sales Support</div>
                    </div>
                </div>
                <div className='mar-spacer h-3' />
                <div className='why-sangeetha__location'>
                    <span className='d-flex'>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.864 17.3639L12.5 23.7279L6.136 17.3639C4.87734 16.1052 4.02019 14.5016 3.67293 12.7558C3.32567 11.0099 3.50391 9.20035 4.1851 7.55582C4.86629 5.91129 6.01984 4.50569 7.49988 3.51677C8.97992 2.52784 10.72 2 12.5 2C14.28 2 16.0201 2.52784 17.5001 3.51677C18.9802 4.50569 20.1337 5.91129 20.8149 7.55582C21.4961 9.20035 21.6743 11.0099 21.3271 12.7558C20.9798 14.5016 20.1227 16.1052 18.864 17.3639ZM12.5 12.9999C13.0304 12.9999 13.5391 12.7892 13.9142 12.4141C14.2893 12.0391 14.5 11.5304 14.5 10.9999C14.5 10.4695 14.2893 9.96078 13.9142 9.58571C13.5391 9.21064 13.0304 8.99992 12.5 8.99992C11.9696 8.99992 11.4609 9.21064 11.0858 9.58571C10.7107 9.96078 10.5 10.4695 10.5 10.9999C10.5 11.5304 10.7107 12.0391 11.0858 12.4141C11.4609 12.7892 11.9696 12.9999 12.5 12.9999Z" fill="#121415"/>
                        </svg>
                    </span>
                    <span className='ml-1_5'>
                        <span>Delivery</span>{' '}560017
                    </span>
                    <span className='ml-1_5 why-sangeetha__link' onClick={() => setModalShow(true)} role='button' aria-hidden="true">Edit</span>
                </div>
            </div>

            <WhySangeethaModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default WhySangeetha