import React from 'react';
import Form from 'react-bootstrap/Form';

const Step3 = () => {
    return (
        <>
            <div className="exchange-data- all-brands">
                <div className=''>
                    <h3 className="Exchange-head">Tell us your phone&apos;s Model</h3>
                    <div className='header-home__search'>
                        <form className="form">
                            <div className="form-group">
                                <div className="input-icon">
                                    <input type="text" className="bannerInput-search" placeholder="Search for models"/>
                                    <span className="bannerSearch-icon d-flex">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.0257 13.8478L18.5948 17.4162L17.4157 18.5953L13.8473 15.0262C12.5196 16.0905 10.8682 16.6694 9.1665 16.667C5.0265 16.667 1.6665 13.307 1.6665 9.16699C1.6665 5.02699 5.0265 1.66699 9.1665 1.66699C13.3065 1.66699 16.6665 5.02699 16.6665 9.16699C16.6689 10.8687 16.09 12.5201 15.0257 13.8478ZM13.354 13.2295C14.4116 12.1419 15.0022 10.684 14.9998 9.16699C14.9998 5.94366 12.389 3.33366 9.1665 3.33366C5.94317 3.33366 3.33317 5.94366 3.33317 9.16699C3.33317 12.3895 5.94317 15.0003 9.1665 15.0003C10.6835 15.0027 12.1414 14.4121 13.229 13.3545L13.354 13.2295Z" fill="#121415"/>
                                        </svg>               
                                    </span>
                                </div>                                            
                            </div>
                        </form>
                    </div>
                </div>
                <div className='exchange-brand__lists bg-white'>
                    <div className='exchange_heading col-12'>All Models</div>
                    <div className='exchange_flex'>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" defaultChecked/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>iPhone 13 Pro</span>
                                <span className='exchange-tag'>Upto ₹3,000 bonus</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>iPhone 13</span>
                                <span className='exchange-tag'>Upto ₹3,000 bonus</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>iPhone 12 Pro</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>iPhone 12 Mini</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>iPhone X</span>
                            </label>
                        </div>
                        <div className="filter-tab__flex changeexchange-div">
                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"/>
                            <label htmlFor="label" className="filter_exchange col d-flex align-items-center justify-content-between">
                                <span className='ml-2'>iPhone Xs</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step3