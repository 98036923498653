import React from 'react';
import Modal from 'react-bootstrap/Modal';

const PlansTermsCondition = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <h2 className='modal-heading mb-4'>
                            <span className='ml-1_5'>Terms and Conditions</span>
                        </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='bank-offer-wrapper'>
                        <div className='bank-offer-details'>
                            <div className='bank-offer__wrap'>
                                <div className='bank-offer__details'>
                                    <div className='addToCart__head d-flex align-items-center justify-content-between p-0 m-0'>
                                        <div className=''>
                                            <span>
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_121_25492)">
                                                        <path d="M13.8367 11.585H13.8483L19.565 9.63666C19.5884 9.53166 19.5884 9.42666 19.5884 9.33333C19.5884 9.22833 19.5767 9.135 19.5417 9.04166C19.4484 8.77333 19.2734 8.48166 19.0284 8.27166V5.71666C19.0284 3.82667 18.3517 3.08 17.6517 2.64833C17.29 1.55167 15.785 0 12.8333 0C9.33335 0 6.69668 3.465 6.69668 5.71666C6.69668 6.65 6.66168 7.385 6.62668 7.945C6.62668 8.06166 6.61501 8.16666 6.61501 8.26C6.35835 8.49333 6.18335 8.80833 6.10168 9.11166C6.09001 9.18166 6.07835 9.25166 6.07835 9.33333C6.07835 10.2433 6.59168 11.5617 6.66168 11.7133C6.73168 11.9117 6.88335 12.075 7.08168 12.1683C7.09335 12.215 7.10501 12.285 7.10501 12.425C7.10501 13.6617 8.16668 14.8283 8.75001 15.3883C8.69168 16.6717 8.33001 17.5583 7.81668 17.78L3.24334 19.2967C1.94834 19.7283 0.98001 20.7783 0.641676 22.1083L0.0233425 24.5817C-0.0349908 24.8383 0.0233425 25.1183 0.186676 25.3283C0.350009 25.5383 0.606676 25.6667 0.87501 25.6667H13.9533C13.6033 25.2233 13.2767 24.7333 12.9733 24.2083C12.2033 22.8433 11.6667 21.1867 11.6667 19.3083V14.63C11.6667 13.2533 12.5417 12.04 13.8367 11.585Z" fill="black"/>
                                                        <path d="M27.4062 13.7969L21.2812 11.7133C21.0992 11.6503 20.9008 11.6503 20.7188 11.7133L14.5938 13.7969C14.238 13.9171 14 14.2496 14 14.6253V19.3129C14 25.0343 20.3863 27.8156 20.6582 27.9311C20.8775 28.0233 21.1225 28.0233 21.3418 27.9311C21.6137 27.8144 28 25.0343 28 19.3129V14.6253C28 14.2496 27.762 13.9171 27.4062 13.7969ZM24.8337 18.2209L21.6253 22.3043C21.217 22.8258 20.4517 22.9016 19.9488 22.4699L17.9072 20.7199C17.4172 20.3011 17.36 19.5638 17.7812 19.0749C18.2 18.5861 18.9373 18.5301 19.425 18.9489L20.5427 19.9068L22.9985 16.7813C23.3963 16.2749 24.129 16.1851 24.6365 16.5853C25.144 16.9808 25.2327 17.7146 24.8337 18.2209Z" fill="black"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_121_25492">
                                                            <rect width="28" height="28" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </span>
                                            <span className='ml-3'>Assured Buyback</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='bank-offer__details'>
                                    <div className='bank-offer__heading'>Duration</div>
                                    <div className='bank-offer__text'>12 Months </div>
                                </div>
                                <div className='bank-offer__details'>
                                    <div className='bank-offer__heading'>Conditions to avail the offer</div>
                                    <ul>
                                        <li>Minimum Cart Value- INR 5,000</li>
                                        <li>Max. Discount would be applicable as per the below table</li>
                                    </ul>
                                </div>
                                <div className='bank-offer__details'>
                                    <div className='bank-offer__heading'>How do I check the eligibility of the product?</div>
                                    <div className='bank-offer__text'>Offer is applicable across Large Appliances, Electronics, 
									Furniture & Mobiles category products. However, the offer will not apply to the purchase of select products. 
									Please check if the offer is listed on the product page, before making a purchase.  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PlansTermsCondition