import React from 'react';
//import { ProductSpecifications } from '../../Data';

const Specifications = ({ genaralData, specificData }) => { 

    const handleReadMore = () => {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");
      
        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "More Specifications"; 
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Less Specifications"; 
            moreText.style.display = "inline";
        }
    }

    return (
        <>
            <div className='product-specification__wrap' style={{padding: specificData.length > 0 ? '' : '0'}}>
                {specificData.map(Specification => (
                    <div className='specification__list' key={Specification._id}>
                        <div className='specification-list__icon'>
                            <img src={Specification.Image} className='img img-fluid' alt={Specification.Name} />
                        </div>
                        <div className='specification-list__content'>
                            <label>{Specification.label}</label>
                            <span>{Specification.Name}</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className='product-specification__otherInfo'>
                <h6 className='specification-info__head'>General</h6>
                <div className='specification-info__table'>
                    <table className='table specification-striped__table mb-0'>
                        <tbody>
                            <tr>
                                <th width='37%'>In The Box</th>
                                <td>{genaralData.InBox}</td>
                            </tr>
                            <tr>
                                <th width='37%'>Model Number</th>
                                <td>{genaralData.ModelNumber}</td>
                            </tr>
                            <tr>
                                <th width='37%'>Color</th>
                                <td>{genaralData.Color}</td>
                            </tr>
                            <tr>
                                <th width='37%'>Browse Type</th>
                                <td>{genaralData.designedFor}</td>
                            </tr>
                            <tr>
                                <th width='37%'>SIM Type</th>
                                <td>{genaralData.WaterResistant}</td>
                            </tr>
                            <tr>
                                <th width='37%'>Hybrid Sim Slot</th>
                                <td>{genaralData.SweatResistant}</td>
                            </tr>
                            <tr>
                                <th width='37%'>Touchscreen</th>
                                <td>{genaralData.OtherFeatures}</td>
                            </tr>
                            <tr>
                                <th width='37%'>OTG Compatible</th>
                                <td>{genaralData.OS}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <span id="dots"></span>
            <span id="more">
                <div className='product-specification__otherInfo'>
                    <h6 className='specification-info__head'>Products Details</h6>
                    <div className='specification-info__table'>
                        <table className='table specification-striped__table mb-0'>
                            <tbody>
                                <tr>
                                    <th width='37%'>Sweat Prood </th>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Foldable/Collapsible</th>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Deep Bass</th>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Designed For</th>
                                    <td>Apple, Android, Windows</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Other Features</th>
                                    <td>Upto 12 hours playback with a charging case (Up-to 4x Charge), Type-C Charging Port, Insta wake N’ Pair </td>
                                </tr>
                                <tr>
                                    <th width='37%'>Headphone Driver</th>
                                    <td>13mm </td>
                                </tr>
                                <tr>
                                    <th width='37%'>With Microphone</th>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='product-specification__otherInfo'>
                    <h6 className='specification-info__head'>Connectivity Features</h6>
                    <div className='specification-info__table'>
                        <table className='table specification-striped__table mb-0'>
                            <tbody>
                                <tr>
                                    <th width='37%'>Bluetooth Version</th>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Bluetooth Range</th>
                                    <td>10 m </td>
                                </tr>
                                <tr>
                                    <th width='37%'>Charging Time</th>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Play Time </th>
                                    <td>15 hours</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Standby Time</th>
                                    <td>100 hours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='product-specification__otherInfo'>
                    <h6 className='specification-info__head'>Warranty</h6>
                    <div className='specification-info__table'>
                        <table className='table specification-striped__table mb-0'>
                            <tbody>
                                <tr>
                                    <th width='37%'>Domestic Warranty</th>
                                    <td>1 Year</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Warranty Summary</th>
                                    <td>1 year warranty </td>
                                </tr>
                                <tr>
                                    <th width='37%'>Warranty Service Type</th>
                                    <td>Reach out to us at<br/>
                                    info@imaginemarketingindia.com/<br/>
                                    +912249461882<br/>
                                    Alternatively you can activate your warranty by giving us a missed call on 9223032222 </td>
                                </tr>
                                <tr>
                                    <th width='37%'>Covered in Warranty</th>
                                    <td>Manufacturing Defects</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Not Covered in Warranty</th>
                                    <td>Physical Damage</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </span>
            {/* <button onClick={handleReadMore} id="myBtn" className='specification-info__link'>More Specifications</button> */}
            <button onClick={handleReadMore}  id="myBtn" className='specification-info__link'>More Specifications</button>
        </>
    )
}

export default Specifications