import React from 'react';
import box1 from '../../Images/Product/box1.jpg';
import box2 from '../../Images/Product/box2.jpg';
import box3 from '../../Images/Product/box3.jpg';

const ProductBox = () => {
    const ProductBox = [
        {   
            Image: box1,
            Name: 'Headphone'
        }, {   
            Image: box2,
            Name: 'Speakers'
        }, {   
            Image: box3,
            Name: 'Headphone'
        }
    ];
    return (
        <>
            {ProductBox.map((ProductBox, idx) => {
                return (
                    <div className='buy-together__wrap' key={idx}>
                        <div className='buy-together__card'>
                            <div className='buy-together__media'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={ProductBox.Image}
                                    alt={ProductBox.Name}
                                    className='img img-fluid'
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default ProductBox