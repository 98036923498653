import React from 'react';
import PlayButton from '../../Images/Product/PlayButton.svg';



const Review = ({reviewData}) => {
    
    return (
        <>
            <div className='product-review-wrap'>
                <div className='review-section'>
                    <img src={reviewData.VideoImage} className='img img-fluid w-100' alt='' />
                    <div className='review-play-btn'>
                        <img src={PlayButton} className='img img-fluid' alt='' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Review