import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const GiftCart = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom items-removed__modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 gst-title-'>
                        <h2 className='notifyMe-heading mb-4'>Apply Gift card</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='login-textbox__wrapper'>
                        <div className='cart-gift__wrap'>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Company Name"
                                    className='error-state'
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Company Name' defaultValue="Parallel Private Limited" />
                                    <span className='mt-1'>Gift Card Number is invalid</span>
                                </FloatingLabel>
                            </div>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="GST Number"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='GST Number' defaultValue="24UDBBFJD326HDC" />
                                </FloatingLabel>
                            </div>
                        </div>
                        <p className='pt-3'>Bank wallets and offers may not be applicable when you use a gift card</p>  
                        <div className="continue-login_btn">
                            <button type='button' className='btn btn-dark-custom w-100'>Continue</button>
                        </div>  
                    </div>                  
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GiftCart