import React from 'react';
import Modal from 'react-bootstrap/Modal';
import NFM from '../../Images/Product/NFM.png';

const NotifyMe = (props) => {
    
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <h2 className='notifyMe-heading mb-4'>Notify Me</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='login-textbox__wrapper'>
                        <div className='text-left notify-me-text'>
                            <p className='mb-0'>Would you like us to let you know when this item is available ? </p>
                            <div className='notify-me__wrap'>
                                <div className='notify-me__media'>
                                    <img src={NFM} className='img-fluid' alt='' />
                                </div>
                                <div className="notify-me__content">
                                    <h6 className="notify-me__head mb-0">Samsung Galaxy Z Fold 2 5G</h6>
                                    <p className="notify-me__text mb-0">Awesome Violet, 6GB +128GB</p>
                                </div>
                            </div>
                        </div>
                        <div className='loginIn_wrap'>
                            <div className='cart-address__wrap'>
                                <input  type='number' className='form-control form-control-cart' placeholder='Mobile Number'  maxLength="10" />
                                <span className='absolute-login-input'>+91</span> 
                            </div>
                            {/* <span className='login-error'>Info text</span> */}
                        </div>
                        <div className='mar-spacer h-4' />
                            
                        <div className="continue-login_btn mt-4">
                            <button type='button' className='btn btn-dark-custom w-100'>Notify Me</button>
                        </div>  
                    </div>                  
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NotifyMe