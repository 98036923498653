import React, { useState } from 'react';
import SortFilter from '../Components/ProductList/SortFilter';
import SearchedFilter from '../Components/ProductList/SearchedFilter';
import CategoryFilter from '../Components/ProductList/ProductCategoryList/CategoryFilter'
import HeadphoneProductLists from '../Components/ProductList/HeadphoneProductLists';
import Sale from '../Components/Home/Sale';

import '../Components/ProductList/productList.css';
import { HeadphoneListData } from '../Data';

const HeadphoneProductList = () => {
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    }
    return (
        <>
            <div className='product-list__wrapper'>
                <div className='container'>
                    <div className='sort-by-filter__wrapper'>
                        <div className='search-recommend__wrap'>
                            <div className='search-recommend__title mb-1'>
                                Results for <span>“Headphones”</span>
                            </div>
                            <div className='search-recommend__label'>
                                <span>1 - 4 of{' '}
                                    <span>{HeadphoneListData.length}</span>{' '}
                                    results
                                </span>
                            </div>
                        </div>
                        <SortFilter />
                    </div>
                    <SearchedFilter />
                    <div className='product-list__wrap'>
                        <CategoryFilter />
                        <HeadphoneProductLists />
                    </div>
                    <div className='mar-spacer-lg' />
                    <section className="section__wrapper padding__wrapper">
                        <div className='container'>
                            <div className='spacing-block position-relative'>
                                <div className='top-head__wrap'>
                                    <h4 className="heading__wrap mb-0">Accessories that complement your Apple product</h4>
                                </div>
                                <Sale wishlistActive={wishlistActive} handleToggle={handleToggle} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default HeadphoneProductList