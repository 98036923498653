import React from 'react';
import StoreListAccordion from './StoreListAccordion';
import ReqCallbackBanner from './ReqCallbackBanner';

const StoreList = () => {
    return (
        <>
            <div className='container'>
                <div className='store-locator__container'>
                    <div className='mar-spacer h-2 mb-2' />
                    <div className='top-head__wrap'>
                        <h4 className='brand-heading'>240 stores in India</h4>
                    </div>                
                    <div className='store_accordion'>
                        <StoreListAccordion />
                    </div>
                </div>
                <ReqCallbackBanner />
            </div>
        </>
    )
}

export default StoreList