import React, { useState } from 'react';
import CartEmptyState from '../Components/Cart/CartEmptyState';
import CartSteps from '../Components/Cart/CartSteps';
import CartOffers from '../Components/Cart/CartOffers';
import CartOfferAdded from '../Components/Cart/CartOfferAdded';
import CartItems from '../Components/Cart/CartItems';
import CartPriceDetails from '../Components/Cart/CartPriceDetails';
import SaveForLater from '../Components/Cart/SaveForLater';
import Sale from '../Components/Home/Sale';
import '../Components/Cart/Cart.css';

const Cart = () => {
    const [ user ] = useState(true);
    const [ isCartEmpty ] = useState(false);
    const [ isOfferApplied ] = useState(false);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    }
    return (
        <>
            {isCartEmpty ? 
                <CartEmptyState />
                : 
                <>
                    <div className='cart__wrapper'>
                        <div className='container'>
                            <CartSteps />
                        </div>
                        <div className='step_cart'>
                            <div className='container'>
                                <div className='cart-head__wrap d-flex align-items-center justify-content-between'>
                                    <h3 className='cart-page_heading mb-0'>Cart</h3>
                                    <div className='step-location__content'>
                                        {user ? 
                                            <>
                                                <div className='cart-page_location d-flex align-items-center justify-content-between'>
                                                    <span className='d-flex'>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.3033 14.4698L10 19.7731L4.69667 14.4698C3.64779 13.4209 2.93349 12.0845 2.64411 10.6296C2.35473 9.17479 2.50326 7.6668 3.07092 6.29636C3.63858 4.92592 4.59987 3.75458 5.83324 2.93048C7.0666 2.10637 8.51665 1.6665 10 1.6665C11.4834 1.6665 12.9334 2.10637 14.1668 2.93048C15.4001 3.75458 16.3614 4.92592 16.9291 6.29636C17.4968 7.6668 17.6453 9.17479 17.3559 10.6296C17.0665 12.0845 16.3522 13.4209 15.3033 14.4698ZM10 10.8331C10.442 10.8331 10.866 10.6575 11.1785 10.3449C11.4911 10.0324 11.6667 9.60847 11.6667 9.16644C11.6667 8.72441 11.4911 8.30049 11.1785 7.98793C10.866 7.67537 10.442 7.49977 10 7.49977C9.55798 7.49977 9.13405 7.67537 8.82149 7.98793C8.50893 8.30049 8.33334 8.72441 8.33334 9.16644C8.33334 9.60847 8.50893 10.0324 8.82149 10.3449C9.13405 10.6575 9.55798 10.8331 10 10.8331Z" fill="#121415"/>
                                                        </svg>
                                                    </span>
                                                    <h6 className='mb-0 ml-1'>Rohan Nanda, 560103</h6>
                                                    <div className='change-location-cart ml-3'>Change</div>
                                                </div>
                                            </> : 
                                            <div className='step-location__content-loggedOut'>
                                                <label htmlFor="label">Deliver to :</label>
                                                <span>462026</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='content_cart'>
                            <div className='container'>
                                <div className='h-1_2 mar-spacer' />
                                <div className='h-1_2 mar-spacer' />
                                <div className='cart-bank__offers spacing-block'>
                                    <div className='p-0'>
                                        {isOfferApplied ?
                                            <div className='p-x-16'>
                                                <CartOfferAdded /> 
                                            </div>: 
                                            <div className='ml--16'>
                                                <CartOffers />
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='h-1_2 mar-spacer' />
                                <div className='h-1_2 mar-spacer' />
                                <div className='cart-container__wrap spacing-block'>
                                    <div className='cart-items__wrap'>
                                        <div className='cart-heading__wrapper'>
                                            <h4 className='d-flex align-items-center'>
                                                <span className='d-flex'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.33268 13.3332V3.33317H1.66602V1.6665H4.16602C4.38703 1.6665 4.59899 1.7543 4.75527 1.91058C4.91155 2.06686 4.99935 2.27882 4.99935 2.49984V12.4998H15.3643L17.031 5.83317H6.66602V4.1665H18.0993C18.226 4.16651 18.351 4.19539 18.4649 4.25095C18.5787 4.30651 18.6784 4.3873 18.7563 4.48716C18.8342 4.58702 18.8884 4.70334 18.9146 4.82726C18.9408 4.95119 18.9385 5.07946 18.9077 5.20234L16.8243 13.5357C16.7792 13.7159 16.6751 13.8758 16.5287 13.9901C16.3822 14.1044 16.2018 14.1665 16.016 14.1665H4.16602C3.945 14.1665 3.73304 14.0787 3.57676 13.9224C3.42048 13.7661 3.33268 13.5542 3.33268 13.3332ZM4.99935 19.1665C4.55732 19.1665 4.1334 18.9909 3.82084 18.6783C3.50828 18.3658 3.33268 17.9419 3.33268 17.4998C3.33268 17.0578 3.50828 16.6339 3.82084 16.3213C4.1334 16.0088 4.55732 15.8332 4.99935 15.8332C5.44138 15.8332 5.8653 16.0088 6.17786 16.3213C6.49042 16.6339 6.66602 17.0578 6.66602 17.4998C6.66602 17.9419 6.49042 18.3658 6.17786 18.6783C5.8653 18.9909 5.44138 19.1665 4.99935 19.1665ZM14.9993 19.1665C14.5573 19.1665 14.1334 18.9909 13.8208 18.6783C13.5083 18.3658 13.3327 17.9419 13.3327 17.4998C13.3327 17.0578 13.5083 16.6339 13.8208 16.3213C14.1334 16.0088 14.5573 15.8332 14.9993 15.8332C15.4414 15.8332 15.8653 16.0088 16.1779 16.3213C16.4904 16.6339 16.666 17.0578 16.666 17.4998C16.666 17.9419 16.4904 18.3658 16.1779 18.6783C15.8653 18.9909 15.4414 19.1665 14.9993 19.1665Z" fill="#121415"/>
                                                    </svg>
                                                </span>
                                                <span className='ml-2'>
                                                    <span>2</span> Item(s) in Cart
                                                </span>
                                            </h4>
                                        </div>
                                        <div className='h-1_2 mar-spacer' />
                                        <div className='h-1_2 mar-spacer' />
                                        <CartItems />
                                        <div className='cart-heading__wrapper'>
                                            <h4 className='d-flex align-items-center'>
                                                <span>
                                                Saved for later (<span>2</span>)
                                                </span>
                                            </h4>
                                        </div>
                                        <div className='h-1_2 mar-spacer' />
                                        <div className='h-1_2 mar-spacer' />
                                        <SaveForLater />
                                    </div>
                                    <div className='cart-price__wrap'>
                                        <div className='cart-heading__wrapper mb-3'>
                                            <h4 className='d-flex align-items-center'>
                                                <span className='d-flex'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.6667 18.3332H3.33333C3.11232 18.3332 2.90036 18.2454 2.74408 18.0891C2.5878 17.9328 2.5 17.7209 2.5 17.4998V2.49984C2.5 2.27882 2.5878 2.06686 2.74408 1.91058C2.90036 1.7543 3.11232 1.6665 3.33333 1.6665H16.6667C16.8877 1.6665 17.0996 1.7543 17.2559 1.91058C17.4122 2.06686 17.5 2.27882 17.5 2.49984V17.4998C17.5 17.7209 17.4122 17.9328 17.2559 18.0891C17.0996 18.2454 16.8877 18.3332 16.6667 18.3332ZM15.8333 16.6665V3.33317H4.16667V16.6665H15.8333ZM6.66667 7.49984H13.3333V9.1665H6.66667V7.49984ZM6.66667 10.8332H13.3333V12.4998H6.66667V10.8332Z" fill="#121415"/>
                                                    </svg>
                                                </span>
                                                <span className='ml-2'>Price Details</span>
                                            </h4>
                                        </div>
                                        <CartPriceDetails />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cart-accessories'>
                        <div className='container'>     
                            <div className='cart-accessories__carousel spacing-block position-relative'>
                                <div className='h-1_2 mar-spacer' />
                                <div className='h-1_2 mar-spacer' />
                                <div className='top-head__wrap'>
                                    <h4 className='cart-heading__wrap mb-2'>Accessories for you</h4>
                                </div>
                                <div className='btn-suggestion__wrap btn-cart-suggestion__wrap mt-0 justify-content-start mb-3'>
                                    <button type='button' className='btn-suggestion btn-right-space active'>All</button>
                                    <button type='button' className='btn-suggestion btn-right-space'>Accessories</button>
                                    <button type='button' className='btn-suggestion btn-right-space'>Earphones</button>
                                    <button type='button' className='btn-suggestion btn-right-space'>Chargers</button>
                                    <button type='button' className='btn-suggestion btn-right-space'>Cleaning Material</button>
                                    <button type='button' className='btn-suggestion btn-right-space'>Cleaning Material</button>
                                    <button type='button' className='btn-suggestion btn-right-space'>Accessories</button>
                                    <button type='button' className='btn-suggestion btn-right-space'>Accessories</button>   
                                </div>
                                <Sale wishlistActive={wishlistActive} handleToggle={handleToggle} />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Cart