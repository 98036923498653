import React from 'react';
import Highlights1 from '../../../Images/Product/Highlights13.png';
import Highlights2 from '../../../Images/Product/Highlights14.png';
import Highlights3 from '../../../Images/Product/Highlights15.png';
import Highlights4 from '../../../Images/Product/Highlights16.png';

const TVHighlights = () => {
    return (
        <>
            <div className='highlight-grid'>
                <div className='highlight-col'>
                    <img src={Highlights1} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights2} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights3} className='img-fluid' alt='' />
                </div>
                <div className='highlight-col'>
                    <img src={Highlights4} className='img-fluid' alt='' />
                </div>
            </div>
        </>
    )
}

export default TVHighlights