import React from 'react';

const CustomCarouselDots = ({ onClick, active, ImageGallery }) => {
    //console.log('ImageGallery', ImageGallery[0].Image);
    return (
        <>
            <li
                className={active ? "active" : "inactive"}
                onClick={() => onClick()}
                aria-hidden="true"
            >
                {ImageGallery.slice(0, 1).map((Data, Index) => (
                    <div className='product-img-small' key={Index}>
                        <img src={Data.Image_Btn} className='img-fluid' width='46' height='46' alt=''  />
                    </div>
                ))}
            </li>  
        </>
    )
}

export default CustomCarouselDots