import { Link } from '@reach/router'
import React from 'react'

const ProductReviewList = () => {
    return (
        <>
            <div className='review-main__wrapper'>
                <div className='review-head__wrap d-flex align-items-center justify-content-between'>
                    <h6>Reviews and Ratings
                        <span className='ml-3'>(3799 Ratings)</span>
                    </h6>
                    <button type='button' className='btn btn-outline'>Label</button>
                </div>
                <div className='mar-spacer h-2 mb-2' />
                <div className="product-review__wrap">
                    <div className='rating-stars'>
                        <div className='rating-counts mb-2'>4.2</div>
                                        
                        <div className='review-stars'>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.1564 16.744L12 16.6485L11.8436 16.744L6.58113 19.9581L8.0118 13.9596L8.05432 13.7814L7.91516 13.6622L3.23299 9.65143L9.37895 9.15904L9.56162 9.14441L9.63199 8.9752L12 3.28126L14.368 8.9752L14.4384 9.14441L14.621 9.15904L20.7679 9.65143L16.0848 13.6621L15.9457 13.7813L15.9882 13.9596L17.4189 19.9581L12.1564 16.744Z" stroke="#121415" strokeWidth="0.6"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className='mar-spacer h-1_2' />
                    <div className='mar-spacer h-2' />
                    <div className="review-comments">
                        <h6 className="reviewer-name">Very nice
                            <div className='d-flex ml-3'>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.26067 12.3233L9.00005 12.1641L8.73944 12.3233L5.35679 14.3893L6.27641 10.5335L6.34726 10.2364L6.11533 10.0378L3.10589 7.45989L7.05623 7.1434L7.36069 7.11901L7.47797 6.837L9.00005 3.17709L10.5221 6.837L10.6394 7.11901L10.9439 7.1434L14.8948 7.45989L11.8848 10.0377L11.6528 10.2364L11.7237 10.5335L12.6433 14.3893L9.26067 12.3233Z" stroke="#121415"/>
                                    </svg>
                                </span>
                            </div>
                        </h6>
                        <div className="review-text">Finally received this product! Thank you for these stylish and functional, head turners!</div>
                        <div className="review-info d-flex align-items-center justify-content-between">
                            <div className="reviewer-info">
                                <span>Tina Munim</span>
                                <span>10 Jun, 2022</span>
                            </div>
                            <div className="reviewer-likes_dislikes d-flex align-items-center justify-content-start">
                                <div className="reviewer-likes">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1667 6.66699H17.5C17.9421 6.66699 18.366 6.84259 18.6786 7.15515C18.9911 7.46771 19.1667 7.89163 19.1667 8.33366V10.087C19.1669 10.3048 19.1245 10.5205 19.0417 10.722L16.4625 16.9845C16.3996 17.1372 16.2928 17.2677 16.1555 17.3596C16.0183 17.4514 15.8568 17.5004 15.6917 17.5003H1.66671C1.44569 17.5003 1.23373 17.4125 1.07745 17.2562C0.921171 17.1 0.833374 16.888 0.833374 16.667V8.33366C0.833374 8.11265 0.921171 7.90068 1.07745 7.7444C1.23373 7.58812 1.44569 7.50033 1.66671 7.50033H4.56837C4.70179 7.50036 4.83326 7.46836 4.95174 7.40702C5.07021 7.34568 5.17223 7.25679 5.24921 7.14783L9.79337 0.70866C9.85082 0.627254 9.93553 0.569127 10.0322 0.544819C10.1288 0.52051 10.2309 0.531629 10.32 0.57616L11.8317 1.33199C12.2571 1.54463 12.597 1.89642 12.7948 2.32892C12.9926 2.76142 13.0365 3.24859 12.9192 3.70949L12.1667 6.66699ZM5.83337 8.82366V15.8337H15.1334L17.5 10.087V8.33366H12.1667C11.9129 8.33362 11.6624 8.27561 11.4344 8.16404C11.2064 8.05247 11.0069 7.8903 10.8511 7.6899C10.6953 7.48949 10.5874 7.25615 10.5355 7.00768C10.4836 6.7592 10.4891 6.50216 10.5517 6.25616L11.3042 3.29949C11.3278 3.20727 11.319 3.10976 11.2795 3.02318C11.2399 2.93661 11.1719 2.8662 11.0867 2.82366L10.5359 2.54866L6.61087 8.10866C6.40254 8.40366 6.13587 8.64533 5.83337 8.82366ZM4.16671 9.16699H2.50004V15.8337H4.16671V9.16699Z" fill="#6B7586"/>
                                        </svg>
                                    </span>
                                    <span className='ml-2'>273</span>
                                </div>
                                <div className="reviewer-dislikes ml-3">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.83337 13.3333H2.50004C2.05801 13.3333 1.63409 13.1577 1.32153 12.8452C1.00897 12.5326 0.833375 12.1087 0.833375 11.6667V9.91333C0.83315 9.69553 0.875617 9.4798 0.958375 9.27833L3.53837 3.01667C3.60111 2.86396 3.70779 2.73333 3.84488 2.64134C3.98196 2.54936 4.14329 2.50016 4.30838 2.5H18.3334C18.5544 2.5 18.7664 2.5878 18.9226 2.74408C19.0789 2.90036 19.1667 3.11232 19.1667 3.33333V11.6667C19.1667 11.8877 19.0789 12.0996 18.9226 12.2559C18.7664 12.4122 18.5544 12.5 18.3334 12.5H15.4317C15.2983 12.5 15.1668 12.532 15.0483 12.5933C14.9299 12.6546 14.8279 12.7435 14.7509 12.8525L10.2067 19.2908C10.1493 19.3722 10.0646 19.4304 9.96793 19.4547C9.87131 19.479 9.76917 19.4679 9.68004 19.4233L8.16838 18.6667C7.74296 18.454 7.4031 18.1022 7.20527 17.6697C7.00743 17.2372 6.96354 16.7501 7.08087 16.2892L7.83337 13.3333ZM14.1667 11.1767V4.16667H4.86671L2.50004 9.91333V11.6667H7.83337C8.08721 11.6667 8.33768 11.7247 8.56568 11.8363C8.79369 11.9479 8.99319 12.11 9.14898 12.3104C9.30477 12.5108 9.41273 12.7442 9.46461 12.9926C9.5165 13.2411 9.51095 13.4982 9.44838 13.7442L8.69588 16.7008C8.67232 16.7931 8.68106 16.8906 8.72063 16.9771C8.7602 17.0637 8.82822 17.1341 8.91337 17.1767L9.46421 17.4517L13.3892 11.8917C13.5975 11.5967 13.8642 11.355 14.1667 11.1767ZM15.8334 10.8333H17.5V4.16667H15.8334V10.8333Z" fill="#6B7586"/>
                                        </svg>
                                    </span>
                                    <span className='ml-2'>75</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-comments">
                        <h6 className="reviewer-name">Very nice
                            <div className='d-flex ml-3'>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.26067 12.3233L9.00005 12.1641L8.73944 12.3233L5.35679 14.3893L6.27641 10.5335L6.34726 10.2364L6.11533 10.0378L3.10589 7.45989L7.05623 7.1434L7.36069 7.11901L7.47797 6.837L9.00005 3.17709L10.5221 6.837L10.6394 7.11901L10.9439 7.1434L14.8948 7.45989L11.8848 10.0377L11.6528 10.2364L11.7237 10.5335L12.6433 14.3893L9.26067 12.3233Z" stroke="#121415"/>
                                    </svg>
                                </span>
                            </div>
                        </h6>
                        <div className="review-text">Finally received this product! Thank you for these stylish and functional, head turners!</div>
                        <div className="review-info d-flex align-items-center justify-content-between">
                            <div className="reviewer-info">
                                <span>Tina Munim</span>
                                <span>10 Jun, 2022</span>
                            </div>
                            <div className="reviewer-likes_dislikes d-flex align-items-center justify-content-start">
                                <div className="reviewer-likes">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1667 6.66699H17.5C17.9421 6.66699 18.366 6.84259 18.6786 7.15515C18.9911 7.46771 19.1667 7.89163 19.1667 8.33366V10.087C19.1669 10.3048 19.1245 10.5205 19.0417 10.722L16.4625 16.9845C16.3996 17.1372 16.2928 17.2677 16.1555 17.3596C16.0183 17.4514 15.8568 17.5004 15.6917 17.5003H1.66671C1.44569 17.5003 1.23373 17.4125 1.07745 17.2562C0.921171 17.1 0.833374 16.888 0.833374 16.667V8.33366C0.833374 8.11265 0.921171 7.90068 1.07745 7.7444C1.23373 7.58812 1.44569 7.50033 1.66671 7.50033H4.56837C4.70179 7.50036 4.83326 7.46836 4.95174 7.40702C5.07021 7.34568 5.17223 7.25679 5.24921 7.14783L9.79337 0.70866C9.85082 0.627254 9.93553 0.569127 10.0322 0.544819C10.1288 0.52051 10.2309 0.531629 10.32 0.57616L11.8317 1.33199C12.2571 1.54463 12.597 1.89642 12.7948 2.32892C12.9926 2.76142 13.0365 3.24859 12.9192 3.70949L12.1667 6.66699ZM5.83337 8.82366V15.8337H15.1334L17.5 10.087V8.33366H12.1667C11.9129 8.33362 11.6624 8.27561 11.4344 8.16404C11.2064 8.05247 11.0069 7.8903 10.8511 7.6899C10.6953 7.48949 10.5874 7.25615 10.5355 7.00768C10.4836 6.7592 10.4891 6.50216 10.5517 6.25616L11.3042 3.29949C11.3278 3.20727 11.319 3.10976 11.2795 3.02318C11.2399 2.93661 11.1719 2.8662 11.0867 2.82366L10.5359 2.54866L6.61087 8.10866C6.40254 8.40366 6.13587 8.64533 5.83337 8.82366ZM4.16671 9.16699H2.50004V15.8337H4.16671V9.16699Z" fill="#6B7586"/>
                                        </svg>
                                    </span>
                                    <span className='ml-2'>273</span>
                                </div>
                                <div className="reviewer-dislikes ml-3">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.83337 13.3333H2.50004C2.05801 13.3333 1.63409 13.1577 1.32153 12.8452C1.00897 12.5326 0.833375 12.1087 0.833375 11.6667V9.91333C0.83315 9.69553 0.875617 9.4798 0.958375 9.27833L3.53837 3.01667C3.60111 2.86396 3.70779 2.73333 3.84488 2.64134C3.98196 2.54936 4.14329 2.50016 4.30838 2.5H18.3334C18.5544 2.5 18.7664 2.5878 18.9226 2.74408C19.0789 2.90036 19.1667 3.11232 19.1667 3.33333V11.6667C19.1667 11.8877 19.0789 12.0996 18.9226 12.2559C18.7664 12.4122 18.5544 12.5 18.3334 12.5H15.4317C15.2983 12.5 15.1668 12.532 15.0483 12.5933C14.9299 12.6546 14.8279 12.7435 14.7509 12.8525L10.2067 19.2908C10.1493 19.3722 10.0646 19.4304 9.96793 19.4547C9.87131 19.479 9.76917 19.4679 9.68004 19.4233L8.16838 18.6667C7.74296 18.454 7.4031 18.1022 7.20527 17.6697C7.00743 17.2372 6.96354 16.7501 7.08087 16.2892L7.83337 13.3333ZM14.1667 11.1767V4.16667H4.86671L2.50004 9.91333V11.6667H7.83337C8.08721 11.6667 8.33768 11.7247 8.56568 11.8363C8.79369 11.9479 8.99319 12.11 9.14898 12.3104C9.30477 12.5108 9.41273 12.7442 9.46461 12.9926C9.5165 13.2411 9.51095 13.4982 9.44838 13.7442L8.69588 16.7008C8.67232 16.7931 8.68106 16.8906 8.72063 16.9771C8.7602 17.0637 8.82822 17.1341 8.91337 17.1767L9.46421 17.4517L13.3892 11.8917C13.5975 11.5967 13.8642 11.355 14.1667 11.1767ZM15.8334 10.8333H17.5V4.16667H15.8334V10.8333Z" fill="#6B7586"/>
                                        </svg>
                                    </span>
                                    <span className='ml-2'>75</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="review-comments">
                        <h6 className="reviewer-name">Very nice
                            <div className='d-flex ml-3'>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.00005 12.75L4.59155 15.4425L5.79005 10.4175L1.86755 7.0575L7.0163 6.645L9.00005 1.875L10.9838 6.645L16.1333 7.0575L12.2101 10.4175L13.4086 15.4425L9.00005 12.75Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.26067 12.3233L9.00005 12.1641L8.73944 12.3233L5.35679 14.3893L6.27641 10.5335L6.34726 10.2364L6.11533 10.0378L3.10589 7.45989L7.05623 7.1434L7.36069 7.11901L7.47797 6.837L9.00005 3.17709L10.5221 6.837L10.6394 7.11901L10.9439 7.1434L14.8948 7.45989L11.8848 10.0377L11.6528 10.2364L11.7237 10.5335L12.6433 14.3893L9.26067 12.3233Z" stroke="#121415"/>
                                    </svg>
                                </span>
                            </div>
                        </h6>
                        <div className="review-text">Finally received this product! Thank you for these stylish and functional, head turners!</div>
                        <div className="review-info d-flex align-items-center justify-content-between">
                            <div className="reviewer-info">
                                <span>Tina Munim</span>
                                <span>10 Jun, 2022</span>
                            </div>
                            <div className="reviewer-likes_dislikes d-flex align-items-center justify-content-start">
                                <div className="reviewer-likes">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1667 6.66699H17.5C17.9421 6.66699 18.366 6.84259 18.6786 7.15515C18.9911 7.46771 19.1667 7.89163 19.1667 8.33366V10.087C19.1669 10.3048 19.1245 10.5205 19.0417 10.722L16.4625 16.9845C16.3996 17.1372 16.2928 17.2677 16.1555 17.3596C16.0183 17.4514 15.8568 17.5004 15.6917 17.5003H1.66671C1.44569 17.5003 1.23373 17.4125 1.07745 17.2562C0.921171 17.1 0.833374 16.888 0.833374 16.667V8.33366C0.833374 8.11265 0.921171 7.90068 1.07745 7.7444C1.23373 7.58812 1.44569 7.50033 1.66671 7.50033H4.56837C4.70179 7.50036 4.83326 7.46836 4.95174 7.40702C5.07021 7.34568 5.17223 7.25679 5.24921 7.14783L9.79337 0.70866C9.85082 0.627254 9.93553 0.569127 10.0322 0.544819C10.1288 0.52051 10.2309 0.531629 10.32 0.57616L11.8317 1.33199C12.2571 1.54463 12.597 1.89642 12.7948 2.32892C12.9926 2.76142 13.0365 3.24859 12.9192 3.70949L12.1667 6.66699ZM5.83337 8.82366V15.8337H15.1334L17.5 10.087V8.33366H12.1667C11.9129 8.33362 11.6624 8.27561 11.4344 8.16404C11.2064 8.05247 11.0069 7.8903 10.8511 7.6899C10.6953 7.48949 10.5874 7.25615 10.5355 7.00768C10.4836 6.7592 10.4891 6.50216 10.5517 6.25616L11.3042 3.29949C11.3278 3.20727 11.319 3.10976 11.2795 3.02318C11.2399 2.93661 11.1719 2.8662 11.0867 2.82366L10.5359 2.54866L6.61087 8.10866C6.40254 8.40366 6.13587 8.64533 5.83337 8.82366ZM4.16671 9.16699H2.50004V15.8337H4.16671V9.16699Z" fill="#6B7586"/>
                                        </svg>
                                    </span>
                                    <span className='ml-2'>273</span>
                                </div>
                                <div className="reviewer-dislikes ml-3">
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.83337 13.3333H2.50004C2.05801 13.3333 1.63409 13.1577 1.32153 12.8452C1.00897 12.5326 0.833375 12.1087 0.833375 11.6667V9.91333C0.83315 9.69553 0.875617 9.4798 0.958375 9.27833L3.53837 3.01667C3.60111 2.86396 3.70779 2.73333 3.84488 2.64134C3.98196 2.54936 4.14329 2.50016 4.30838 2.5H18.3334C18.5544 2.5 18.7664 2.5878 18.9226 2.74408C19.0789 2.90036 19.1667 3.11232 19.1667 3.33333V11.6667C19.1667 11.8877 19.0789 12.0996 18.9226 12.2559C18.7664 12.4122 18.5544 12.5 18.3334 12.5H15.4317C15.2983 12.5 15.1668 12.532 15.0483 12.5933C14.9299 12.6546 14.8279 12.7435 14.7509 12.8525L10.2067 19.2908C10.1493 19.3722 10.0646 19.4304 9.96793 19.4547C9.87131 19.479 9.76917 19.4679 9.68004 19.4233L8.16838 18.6667C7.74296 18.454 7.4031 18.1022 7.20527 17.6697C7.00743 17.2372 6.96354 16.7501 7.08087 16.2892L7.83337 13.3333ZM14.1667 11.1767V4.16667H4.86671L2.50004 9.91333V11.6667H7.83337C8.08721 11.6667 8.33768 11.7247 8.56568 11.8363C8.79369 11.9479 8.99319 12.11 9.14898 12.3104C9.30477 12.5108 9.41273 12.7442 9.46461 12.9926C9.5165 13.2411 9.51095 13.4982 9.44838 13.7442L8.69588 16.7008C8.67232 16.7931 8.68106 16.8906 8.72063 16.9771C8.7602 17.0637 8.82822 17.1341 8.91337 17.1767L9.46421 17.4517L13.3892 11.8917C13.5975 11.5967 13.8642 11.355 14.1667 11.1767ZM15.8334 10.8333H17.5V4.16667H15.8334V10.8333Z" fill="#6B7586"/>
                                        </svg>
                                    </span>
                                    <span className='ml-2'>75</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mar-spacer h-1_1' />
                    <Link to='#' className='view-all__review'>View More</Link>
                    <div className='mar-spacer h-2' />
                </div>
            </div>
        </>
    )
}

export default ProductReviewList