import React from 'react';
import BrandOne from '../../Images/Home/brand/1.png';
import BrandTwo from '../../Images/Home/brand/2.png';
import BrandThree from '../../Images/Home/brand/3.png';
import BrandFour from '../../Images/Home/brand/4.png';
import BrandFive from '../../Images/Home/brand/5.png';
import BrandSix from '../../Images/Home/brand/6.png';
import BrandSeven from '../../Images/Home/brand/7.png';
import BrandEight from '../../Images/Home/brand/8.png';
 
const TopBrands = () => {
    const TopBrandsData = [
        {   
            _Id: 1,
            Image: BrandOne
        }, {   
            _Id: 2,
            Image: BrandTwo
        }, {   
            _Id: 3,
            Image: BrandThree
        }, {   
            _Id: 4,
            Image: BrandFour
        }, {   
            _Id: 5,
            Image: BrandFive
        }, {   
            _Id: 6,
            Image: BrandSix
        }, {   
            _Id: 7,
            Image: BrandSeven
        }, {   
            _Id: 8,
            Image: BrandEight
        }  
    ];
    return (
        <>       
            <div className='brand-items-wrapper'>
                {TopBrandsData.map((Data) => {
                    return (
                        <div className='brand-content-wrapper' key={Data._Id}>
                            <div className="brand-img">
                                <img src={Data.Image} className="img-fluid brand-image-style" alt="Apple"/>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default TopBrands
