import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import Location from '../Components/Location/Location';
import CartState from '../Components/Home/CartState';
import OrderState from '../Components/Home/OrderState';
import HomeGallery from '../Components/Home/HomeGallery';
import ShopByCatg from '../Components/Home/ShopByCatg';
import CategoryOffer from '../Components/Home/CategoryOffer';
import Exclusive from '../Components/Home/Exclusive';
import WhySangeetha from '../Components/Home/WhySangeetha';
import ProtectionPlans from '../Components/Home/ProtectionPlans';
import TopBrands from '../Components/Home/TopBrands';
import MobileOffer from '../Components/Home/MobileOffer';
import BankOffers from '../Components/Home/BankOffers';
import Accessories from '../Components/Home/Accessories';
import HottestDeals from '../Components/Home/HottestDeals';
import SmartGadgets from '../Components/Home/SmartGadgets';
import ProductFooter from '../Components/Footer/ProductFooter';
import Sale from '../Components/Home/Sale';
import phoneBanner from '../Images/Home/phoneBanner.png';

import {useDispatch, useSelector} from 'react-redux';
import { getHome } from '../store/actions/homeAction';

// import axios from 'axios'

const data = {
    type: "mobile",
    user_location: "Bengaluru",
    user_id: "",
    offset: "0",
    limit: "10"
};
// const headers = {
//     'Content-Type': 'application/json',
//     'Token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJsdW1lbi1qd3QiLCJzdWIiOjIxMiwiaWF0IjoxNTc0MTUwMTYzLCJleHAiOjE2MDU2ODYxNjN9.0b7PxovIcFiWayJduIklNs_0lidod2EkD0FcYEKaF5Q'
// };

// Helper.getUserAPI()
const Home = () => {

   
    navigator.geolocation.getCurrentPosition(function(position) {
        console.log(position);
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
    });
   
    
    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(getHome(data))
    // }, [dispatch])

    const homeDetailsData = useSelector(state => state.homeDetails);
    console.log(homeDetailsData);
    // const {homeDetails} = homeDetailsData;

    // console.log(homeDetails);
    // const [errorMessage, setErrorMessage] = useState('');
    // const [showErrMsg, setShowErrMsg] = useState(false);
    // const[ homeBanner,setHomeBanner] = useState(); 
    // const[ homeCategory,setHomeCategory] = useState(); 
    // if(homeDetails.block_1[0]){
    //     setHomeBanner(homeDetails.block_1[0]);
    // }

    // if(homeDetails.block_2[0]){
    //     setHomeBanner(homeCategory.block_2[0]);
    // }


    const [ HomeState ] = useState(true);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    };
    
    
   
    // useEffect(() =>{
    //     const getHomeDetails = async () => {
    //         const response =  await axios.post('https://smpl-t-api.izoleap.com/pims/HomePage/', data, {headers: headers}).catch((error) => {
    //             console.log(error);
    //         })
    //         // return response;
    //         if(response){
    //             console.log(response.data.data.block_1[0].content)
    //             dispatch(getHomeData(response.data.data))
    //             if(response.data.data.block_1){
    //                 // setHomeBanner(response.data.data.block_1[0].content);
    //                 dispatch(getBanner(response.data.data.block_1[0].content))
    //             }
    //             if(response.data.data.block_2){
    //                 dispatch()
    //             }
    //             if(response.data.data.block_3){
    //                 dispatch()
    //             }
    //         }
    //     }
    //     getHomeDetails()
    // },[])

    useEffect(() => {
        dispatch(getHome(data))
    }, [dispatch])
    
    
    return (
        <>
            {HomeState ?
                <CartState /> : 
                <OrderState />
            }
            <div className='home__wrapper'>
                <div className='header__wrap'>
                    <h1 className='header-title__wrap'>
                        <span className='welcome-text'>ನಿಮ್ಮ</span>
                        <span className='ml-1'>Smart Gadget Store</span>
                    </h1>
                    <div className='header-home__search'>
                        <form className="form">
                            <div className="form-group">
                                <div className="input-icon">
                                    <input type="text" className="bannerInput-search" placeholder="Search for smartphones, laptops, and more..."/>
                                    <span className="bannerSearch-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.0262 12.8473L17.5953 16.4157L16.4162 17.5948L12.8478 14.0257C11.5201 15.09 9.86866 15.6689 8.16699 15.6665C4.02699 15.6665 0.666992 12.3065 0.666992 8.1665C0.666992 4.0265 4.02699 0.666504 8.16699 0.666504C12.307 0.666504 15.667 4.0265 15.667 8.1665C15.6694 9.86817 15.0905 11.5196 14.0262 12.8473ZM12.3545 12.229C13.4121 11.1414 14.0027 9.68353 14.0003 8.1665C14.0003 4.94317 11.3895 2.33317 8.16699 2.33317C4.94366 2.33317 2.33366 4.94317 2.33366 8.1665C2.33366 11.389 4.94366 13.9998 8.16699 13.9998C9.68402 14.0022 11.1419 13.4116 12.2295 12.354L12.3545 12.229Z" fill="#999999"/>
                                        </svg>                                                        
                                    </span>
                                </div>                                            
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Location />
            <div className='home-banner-container'>
                <div className='home-gallery'>
                    {/* homeBanner={homeBanner} */}
                    <HomeGallery   />
                </div>
            </div>
            <section className="category-wrapper">
                {/* homeCategory={homeCategory} */}
                <ShopByCatg  />
            </section>
            <section className="brands-wrapper exclusive-section border-0">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">Complete your Samsung Experience</h4>
                </div>
                <CategoryOffer />
            </section>
            <section className="brands-wrapper exclusive-section">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">Exclusive at Sangeetha</h4>
                </div>
                <Exclusive />
            </section>
            <WhySangeetha />
            <ProtectionPlans />
            <section className="brands-wrapper exclusive-section">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading d-flex align-items-center justify-content-between">
                        <span>Brand Store</span>
                        <div className="view-all-link">
                            <Link to="/Brands" className="link-text">View All</Link>
                        </div>    
                    </h4>
                </div>
                <TopBrands />
            </section>
            <section className="brands-wrapper exclusive-section">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">Best Offers on Phones</h4>
                </div>
                <MobileOffer />
            </section>
            <section className="brands-wrapper exclusive-section">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">Bank Offers</h4>
                </div>
                <BankOffers />
            </section>
            <section className="brands-wrapper exclusive-section p-x-16">
                <div 
                    className='phome-banner__wrap'
                    style={{ 
                        backgroundImage:`url(${phoneBanner})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom right',
                        backgroundSize: 'inherit',
                        backgroundColor: '#000',
                        padding: '24px',
                        color: '#fff'
                    }}    
                >
                    <div className='phome-banner__info exclusive-text'>
                        <h5>Upgrade your phone every year!</h5>
                        <p>Join our subscription program and get the best deals</p>
                        <Link to='/' className='btn btn-dark-custom'>Know More</Link>
                    </div>
                </div>
            </section>
            <section className="brands-wrapper exclusive-section border-0">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">Phone Accessories</h4>
                </div>
                <Accessories />
            </section>
            <section className="brands-wrapper exclusive-section light-grey-bg border-0">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">Smart Living for you</h4>
                </div>
                <SmartGadgets />
            </section>
            <section className="brands-wrapper exclusive-section">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">🔥Hottest Deals</h4>
                </div>
                <HottestDeals />
            </section>
            <section className="brands-wrapper exclusive-section border-0">
                <div className='top-head__wrap'>
                    <h4 className="brand-heading">Work From Home Effectively</h4>
                </div>
                <Sale wishlistActive={wishlistActive} handleToggle={handleToggle} />
            </section>
            <div className='mar-spacer h-0_5' />
            <section className="brands-wrapper suggestion-section light-grey-bg border-0">
                <h3 className='suggestion-heading'>Didn’t find what you are looking for?</h3>
                <p className='suggestion-info'>Let us help you</p>
                <div className='btn-suggestion__wrap'>
                    <Link to='/' className='btn-suggestion btn-right-space mb-3'>Buy Now</Link>
                    <Link to='/' className='btn-suggestion btn-right-space mb-3'>Smart Watch</Link>
                    <Link to='/' className='btn-suggestion btn-right-space mb-3'>Earphones</Link><br/>
                    <Link to='/' className='btn-suggestion btn-right-space mb-3'>Speakers</Link>
                    <Link to='/' className='btn-suggestion btn-right-space mb-3'>Security Cameras</Link><br/>
                    <Link to='/' className='btn-suggestion btn-right-space mb-3'>Lights</Link>
                </div>
            </section>
            <ProductFooter />
        </>
    );
}

export default Home;