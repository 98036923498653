import React from 'react'
import '../Components/Profile/profile.css'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ProfileOrderTab from '../Components/Profile/profile-element-left/ProfileOrderTab'
import PersonalDetails from '../Components/Profile/profile-element-left/PersonalDetails';
import PersonalDetailsright from '../Components/Profile/ProfileElementRight/PersonalDetailsright';

import SaveAddress from '../Components/Profile/profile-element-left/SaveAddress';
import SavePayment from '../Components/Profile/profile-element-left/SavePayment';
import MyCoupns from '../Components/Profile/profile-element-left/MyCoupns';
import HelpSuport from '../Components/Profile/profile-element-left/HelpSuport';
import ChangeLanguage from '../Components/Profile/profile-element-left/ChangeLanguage';
import TrackOrder from '../Components/Profile/ProfileElementRight/TrackOrder';
import Savearressright from '../Components/Profile/ProfileElementRight/Savearressright';
import SavePaymentright from '../Components/Profile/ProfileElementRight/SavePaymentright';
import Mycouponsright from '../Components/Profile/ProfileElementRight/Mycouponsright';
import HelpSupportright from '../Components/Profile/ProfileElementRight/HelpSupportright';
import ChangeLanguageright from '../Components/Profile/ProfileElementRight/ChangeLanguageright';

const Profile = () => {
    return (
        <>
            <div className="h-2"/>
            <div className="profile-main_wrapper">
                <div className="profile_userName">
                    <h3 className='userName'>Rohan Nanda</h3>
                    <span className='User-no'>+91 7839391109</span>                    
                </div>
                <div className="h-2"/>
                <div className="profile-container">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='policymaintabe-wrap'>
                            <div className="profile-container-left ">
                                <Nav variant="pills" className="flex-column border-add">                                   
                                    <Nav.Item className='Profile-Order-tab'>
                                        <Nav.Link eventKey="first">
                                            <ProfileOrderTab/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='Profile-Order-tab'>
                                        <Nav.Link eventKey="second">
                                            <PersonalDetails/>
                                            
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className='Profile-Order-tab'>
                                        <Nav.Link eventKey="third">
                                            <SaveAddress/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='Profile-Order-tab'>
                                        <Nav.Link eventKey="fourth">                                            
                                            <SavePayment/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='Profile-Order-tab'>
                                        <Nav.Link eventKey="fifth">                                            
                                            <MyCoupns/>
                                        </Nav.Link>
                                    </Nav.Item> 
                                    < div className="profileleft-bg-12"/>
                                    <Nav.Item className='Profile-Order-tab'>
                                        <Nav.Link eventKey="sixth">                                            
                                            <HelpSuport/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='Profile-Order-tab'>
                                        <Nav.Link eventKey="seventh">                                            
                                            <ChangeLanguage/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <div className="logoutsection">
                                        <button className='Logoutbtn'>Log Out</button>
                                    </div>
                                </Nav>
                              

                            </div>
                            <div className='profile-container-right'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <TrackOrder/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <PersonalDetailsright/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Savearressright/>                               
                                    </Tab.Pane>  
                                    <Tab.Pane eventKey="fourth">
                                        <SavePaymentright/>                             
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        <Mycouponsright/>                               
                                    </Tab.Pane> 
                                    <Tab.Pane eventKey="sixth">
                                        <HelpSupportright/>                             
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="seventh">
                                        <ChangeLanguageright/>                                
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                    
                </div>
            </div>

            

        </>
    )
}

export default Profile;