import React from 'react';
import VASbg from '../../../Images/vas/vas1.svg';
import VASbg2 from '../../../Images/vas/vas.png';
import VAS1 from '../../../Images/vas/1.svg';
import VAS2 from '../../../Images/vas/2.svg';
import VAS3 from '../../../Images/vas/3.svg';
import VAS4 from '../../../Images/vas/4.svg';
import VAS5 from '../../../Images/vas/5.svg';
import VAS6 from '../../../Images/vas/6.svg';
import VAS7 from '../../../Images/vas/7.svg';
import VAS8 from '../../../Images/vas/8.svg';
import { Link } from '@reach/router';

const WhySangeetha = () => {
    return (
        <>
            <div 
                className='why-buy'
                style={{ 
                    backgroundImage:`url(${VASbg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom right',
                    backgroundSize: '',
                }}
            >
                <div className='card-vas__wrap w-100'>
                    <h6 className='mt-1 offer-box-info'>Protect Your Phone Against</h6>
                    <ul>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS1} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Damage</div>
                        </li>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS2} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Theft</div>
                        </li>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS3} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Fire</div>
                        </li>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS4} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Price Drop</div>
                        </li>
                    </ul>
                    <div className='flexible' />
                    <Link to='/WhySangeethaExplore' className='btn btn-outline-dark w-100'>Explore</Link>
                </div>
            </div>
            <div 
                className='why-buy'
                style={{ 
                    backgroundImage:`url(${VASbg2})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom right',
                    backgroundSize: '',
                }}
            >
                <div className='card-vas__wrap w-100'>
                    <h6 className='mt-1 offer-box-info'>Free Doorstep Services</h6>
                    <ul>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS5} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Data Transfer</div>
                        </li>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS6} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Tempered Glass Installation</div>
                        </li>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS7} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Wide Choice of Accessories</div>
                        </li>
                        <li>
                            <div className='vas_icon'>
                                <img src={VAS8} className='img-fluid' alt='' />
                            </div>
                            <div className='ml-1_5'>Multiple Payment Options</div>
                        </li>
                    </ul>
                    <div className='flexible' />
                </div>
            </div>
        </>
    )
}

export default WhySangeetha