import React, { useState } from 'react';
import StickyBanner from '../Components/Common/StickyBanner';
import BrandBanner from '../Components/BrandList/BrandBanner';
import Brands from '../Components/BrandList/Brands';
import BrandCarousel from '../Components/BrandList/BrandCarousel';
import BrandProduct from '../Components/BrandList/BrandProduct';
import BrandProductList from '../Components/BrandList/BrandProductList';

import '../Components/BrandList/Brand.css';
import offerBanner from '../Images/Brand/Banner.jpg'
import { Link } from '@reach/router';


const BrandList = () => {
    const [isWishlist] = useState(true);
    return (
        <>
            <StickyBanner />
            <BrandBanner />
            <section className="category__wrapper category__padding">
                <div className='container'>
                    <div className='category-head__wrap'>
                        <h4 className="category-heading__wrap mb-1">Products for you</h4>
                        <p className='category-head__info mb-0'>Upgrade your home with the latest tech, and save on the go!</p>
                    </div>
                    <Brands />
                </div>
            </section>
            <section className="brand__gallery category__padding">
                <div className='container'>
                    <BrandCarousel />
                </div>
            </section>
            <section className="Model__wrapper category__padding">
                <div className='container'>
                    <div className='Model-head__wrap'>
                        <h4 className="Model-heading__wrap mb-0">New Launches</h4>
                    </div>
                    <BrandProduct />
                </div>
            </section>
            <section className="section__wrapper category__padding">
                <div className='container d-flex'>
                    <Link to='/ProductDetails' className='offer-banner spacing-block position-relative'>
                        <img src={offerBanner} className='img-fluid w-100' alt='' />
                    </Link>
                </div>
            </section>
            <section className="Model-list__wrapper category__padding">
                <div className='container'>
                    <div className='ModelList-head__wrap'>
                        <h4 className="ModelList-heading__wrap mb-0">Curated for you</h4>
                    </div>
                    <BrandProductList isWishlist={isWishlist} />
                </div>
            </section>
        </>
    )
}

export default BrandList