import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import StoreListItem from './StoreListItem';

const StoreListAccordion = () => {
    const [activeId, setActiveId] = useState('0');

    const toggleActive = (id) => {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <>
            <Accordion className='cart-payment__accordion categories__accordion' defaultActiveKey={activeId}>
                <Accordion.Item eventKey="0" className={activeId === '0' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('0')}>
                        <div className='payment-card__head m-0'>
                            <span>Andhra Pradesh</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <StoreListItem />
                            <StoreListItem />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('1')} >
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Delhi</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <StoreListItem />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className={activeId === '2' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('2')} >
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Goa</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <StoreListItem />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className={activeId === '3' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('3')} >
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Gujarat</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <StoreListItem />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className={activeId === '4' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('4')} >
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Karnataka</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <StoreListItem />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className={activeId === '5' ? 'panel-wrap active-panel' : 'panel-wrap'}>
                    <Accordion.Header onClick={() => toggleActive('5')} >
                        <span>
                            <img src='' className='img img-fluid' alt='' />
                        </span>
                        <div className='payment-card__head m-0'>
                            <span>Kerala</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='item-item__category'>
                            <StoreListItem />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default StoreListAccordion