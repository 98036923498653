import React, { useState } from 'react';
import PlansTermsCondition from './PlansTermsCondition';
import plansImg from '../../Images/Product/plans-img.png';
import UserImg1 from '../../Images/users/1.png';
import UserImg2 from '../../Images/users/2.png';

const ProtectionPlans = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='mar-spacer h-3' />
            <div className='protection-plans__wrapper'>
                <div className='container'>
                    <div className='protection-plans__container'>
                        <div className='protection-plans__media'>
                            <div className='sticky-container'>
                                <div className='media-img__plans d-flex align-items-center justify-content-center'>
                                    <img src={plansImg} className='img-fluid' alt='' />
                                </div>
                                <div className='plans-review__details'>
                                    <div className='review-stars__wrappers d-flex align-items-center'>
                                        <div className='review-stars'>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                                </svg>
                                            </span>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                                </svg>
                                            </span>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                                </svg>
                                            </span>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 17L6.12199 20.59L7.71999 13.89L2.48999 9.41L9.35499 8.86L12 2.5L14.645 8.86L21.511 9.41L16.28 13.89L17.878 20.59L12 17Z" fill="#121415"/>
                                                </svg>
                                            </span>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.1564 16.744L12 16.6485L11.8436 16.744L6.58113 19.9581L8.0118 13.9596L8.05432 13.7814L7.91516 13.6622L3.23299 9.65143L9.37895 9.15904L9.56162 9.14441L9.63199 8.9752L12 3.28126L14.368 8.9752L14.4384 9.14441L14.621 9.15904L20.7679 9.65143L16.0848 13.6621L15.9457 13.7813L15.9882 13.9596L17.4189 19.9581L12.1564 16.744Z" stroke="#121415" strokeWidth="0.6"/>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className='review-wrapper__plans'>
                                            <span className='ml-1'>4.2</span>
                                            <span className='review__plans ml-2'>(190 reviews)</span>
                                        </div>
                                    </div>
                                    <div className='product-info'>
                                        <div className='brand-name'>Samsung</div>
                                        <div className='product-name mb-0'>Galaxy Z Fold2 5G</div>
                                        <div className="product-price my-0">
                                            <div className="new-price">₹43,659</div>
                                            <div className="old-price">₹45,659</div>
                                        </div>
                                        <div className='price-info-text'>(Price Includes GST)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='protection-plans__content'>
                            <h3 className='protection-plans__heading'>Protection Plans for your Device</h3>
                            <div className='addToCart__wrap'>
                                <div className='addToCart__section'>
                                    <div className='addToCart__list'>
                                        <div className='product-label-tag addToCart-label-tag'>Most Popular</div>
                                        <div className='addToCart__head d-flex align-items-center justify-content-between'>
                                            <div className=''>
                                                <span>
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.4135 3.29733L14 1.16699L23.5865 3.29733C23.8456 3.35492 24.0773 3.49912 24.2433 3.70613C24.4094 3.91314 24.5 4.17059 24.5 4.43599V16.0875C24.4999 17.2398 24.2153 18.3744 23.6715 19.3903C23.1277 20.4063 22.3415 21.2723 21.3827 21.9115L14 26.8337L6.61733 21.9115C5.65865 21.2724 4.87254 20.4066 4.32874 19.3909C3.78494 18.3751 3.50028 17.2408 3.5 16.0887V4.43599C3.50005 4.17059 3.59058 3.91314 3.75665 3.70613C3.92273 3.49912 4.15443 3.35492 4.4135 3.29733ZM15.1667 11.667V5.83366L9.33333 14.0003H12.8333V19.8337L18.6667 11.667H15.1667Z" fill="#121415"/>
                                                    </svg>
                                                </span>
                                                <span className='ml-3'>Money and Damage Protection (MAD) </span>
                                            </div>
                                            <button type='button' className='btn btn-add-device'>Label</button>
                                        </div>
                                        <ul className='addToCart__li pl-0'>
                                            <li>
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66668 10.115L12.7947 3.98633L13.738 4.92899L6.66668 12.0003L2.42401 7.75766L3.36668 6.81499L6.66668 10.115Z" fill="#121415"/>
                                                    </svg>
                                                </span> Free repairs - just walk in at any Sangeetha store!</li>
                                            <li>
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66668 10.115L12.7947 3.98633L13.738 4.92899L6.66668 12.0003L2.42401 7.75766L3.36668 6.81499L6.66668 10.115Z" fill="#121415"/>
                                                    </svg>
                                                </span> Replacement of damaged SKU at 50% cost</li>
                                            <li>
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66668 10.115L12.7947 3.98633L13.738 4.92899L6.66668 12.0003L2.42401 7.75766L3.36668 6.81499L6.66668 10.115Z" fill="#121415"/>
                                                    </svg>
                                                </span> Full refund in case of no claims</li>
                                        </ul>
                                        <button type='button' onClick={() => setModalShow(true)} className='addToCart__link d-flex px-0'>View Terms and Conditions</button>
                                        <div className='symbol-group__wrapper'>
                                            <div className='symbol-group symbol-hover'>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle symbol-light'>
                                                    <span className='symbol-label font-weight-bold'>+30k</span>
                                                </div>
                                            </div>
                                            <div className='symbol-group__label ml-1'>Rated this 4.5 Stars</div>
                                        </div>
                                        <div className='cart-horizontal-line m-0'></div>
                                        <div className='addToCart-footer pb-0'>
                                            <div className="addToCart-price">₹999</div>
                                            <div className='vas__validity'><span>Validity</span> 12 Months</div>
                                        </div>
                                    </div>

                                    <div className='addToCart__list'>
                                        <div className='addToCart__head d-flex align-items-center justify-content-between'>
                                            <div className=''>
                                                <span>
                                                    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M22.6784 2.20587L12.1784 0.018375C12.0608 -0.006125 11.9392 -0.006125 11.8216 0.018375L1.32155 2.20587C0.915773 2.29042 0.625 2.64802 0.625 3.0625V15.1066C0.625 16.9199 1.08963 18.7167 1.96867 20.3027C2.84772 21.8887 4.12511 23.2351 5.66281 24.1961L11.5363 27.867C11.6781 27.9557 11.8391 28 12 28C12.1609 28 12.3219 27.9556 12.4638 27.867L18.3372 24.1961C19.8749 23.2351 21.1523 21.8888 22.0313 20.3027C22.9104 18.7167 23.375 16.9199 23.375 15.1066V3.0625C23.375 2.64802 23.0842 2.29042 22.6784 2.20587ZM12.875 19.3443V19.6628C12.875 20.1339 12.5129 20.5393 12.0423 20.5615C11.5398 20.5851 11.125 20.1848 11.125 19.6875V19.3455C11.125 19.2255 11.0462 19.1208 10.9318 19.0845C9.72341 18.7006 8.79143 17.6799 8.55633 16.3762C8.47003 15.8978 8.76737 15.421 9.24473 15.3286C9.72981 15.2347 10.1936 15.559 10.2746 16.0431C10.4226 16.9272 11.2115 17.5575 12.1227 17.4959C12.9835 17.4377 13.6821 16.7417 13.7452 15.8812C13.8202 14.8572 13.0083 14 12 14C10.0701 14 8.5 12.4299 8.5 10.5C8.5 8.94201 9.52326 7.61852 10.933 7.1663C11.0469 7.12977 11.125 7.02527 11.125 6.90572V6.58722C11.125 6.11609 11.4871 5.71069 11.9577 5.68848C12.4601 5.6648 12.875 6.06523 12.875 6.5625V6.90446C12.875 7.0245 12.9538 7.12917 13.0682 7.16554C14.2902 7.55371 15.2296 8.59321 15.4513 9.91807C15.5311 10.3947 15.2094 10.8457 14.7328 10.9255C14.256 11.0055 13.8052 10.6836 13.7254 10.207C13.5774 9.32285 12.7885 8.69258 11.8773 8.75416C11.0165 8.81234 10.3179 9.50835 10.2548 10.3689C10.1798 11.3928 10.9917 12.25 12 12.25C13.9299 12.25 15.5 13.8201 15.5 15.75C15.5 17.308 14.4767 18.6315 13.067 19.0837C12.9531 19.1202 12.875 19.2247 12.875 19.3443Z" fill="black"/>
                                                    </svg>
                                                </span>
                                                <span className='ml-3'>Price Protection </span>
                                            </div>
                                            <button type='button' className='btn btn-add-device'>Label</button>
                                        </div>
                                        <ul className='addToCart__li pl-0'>
                                            <li>
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66668 10.115L12.7947 3.98633L13.738 4.92899L6.66668 12.0003L2.42401 7.75766L3.36668 6.81499L6.66668 10.115Z" fill="#121415"/>
                                                    </svg>
                                                </span>Pay a One Time fee to accomodate for price drop for 90 days after purchase.</li>
                                            <li>
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66668 10.115L12.7947 3.98633L13.738 4.92899L6.66668 12.0003L2.42401 7.75766L3.36668 6.81499L6.66668 10.115Z" fill="#121415"/>
                                                    </svg>
                                                </span>Sangeetha refunds you the difference between the price drop and the price at which you bought your phone.</li>
                                        </ul>
                                        <button type='button' onClick={() => setModalShow(true)} className='addToCart__link d-flex px-0'>View Terms and Conditions</button>
                                        <div className='symbol-group__wrapper'>
                                            <div className='symbol-group symbol-hover'>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle symbol-light'>
                                                    <span className='symbol-label font-weight-bold'>+30k</span>
                                                </div>
                                            </div>
                                            <div className='symbol-group__label ml-1'>Rated this 4.5 Stars</div>
                                        </div>
                                        <div className='cart-horizontal-line m-0'></div>
                                        <div className='addToCart-footer pb-0'>
                                            <div className="addToCart-price">₹999</div>
                                            <div className='vas__validity'><span>Validity</span> 6 Months</div>
                                        </div>
                                    </div>

                                    <div className='addToCart__list'>
                                        <div className='addToCart__head d-flex align-items-center justify-content-between'>
                                            <div className=''>
                                                <span>
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_121_25467)">
                                                            <path d="M13.8367 11.585H13.8483L19.565 9.63666C19.5884 9.53166 19.5884 9.42666 19.5884 9.33333C19.5884 9.22833 19.5767 9.135 19.5417 9.04166C19.4484 8.77333 19.2734 8.48166 19.0284 8.27166V5.71666C19.0284 3.82667 18.3517 3.08 17.6517 2.64833C17.29 1.55167 15.785 0 12.8333 0C9.33335 0 6.69668 3.465 6.69668 5.71666C6.69668 6.65 6.66168 7.385 6.62668 7.945C6.62668 8.06166 6.61501 8.16666 6.61501 8.26C6.35835 8.49333 6.18335 8.80833 6.10168 9.11166C6.09001 9.18166 6.07835 9.25166 6.07835 9.33333C6.07835 10.2433 6.59168 11.5617 6.66168 11.7133C6.73168 11.9117 6.88335 12.075 7.08168 12.1683C7.09335 12.215 7.10501 12.285 7.10501 12.425C7.10501 13.6617 8.16668 14.8283 8.75001 15.3883C8.69168 16.6717 8.33001 17.5583 7.81668 17.78L3.24334 19.2967C1.94834 19.7283 0.98001 20.7783 0.641676 22.1083L0.0233425 24.5817C-0.0349908 24.8383 0.0233425 25.1183 0.186676 25.3283C0.350009 25.5383 0.606676 25.6667 0.87501 25.6667H13.9533C13.6033 25.2233 13.2767 24.7333 12.9733 24.2083C12.2033 22.8433 11.6667 21.1867 11.6667 19.3083V14.63C11.6667 13.2533 12.5417 12.04 13.8367 11.585Z" fill="black"/>
                                                            <path d="M27.4062 13.7969L21.2812 11.7133C21.0992 11.6503 20.9008 11.6503 20.7188 11.7133L14.5938 13.7969C14.238 13.9171 14 14.2496 14 14.6253V19.3129C14 25.0343 20.3863 27.8156 20.6582 27.9311C20.8775 28.0233 21.1225 28.0233 21.3418 27.9311C21.6137 27.8144 28 25.0343 28 19.3129V14.6253C28 14.2496 27.762 13.9171 27.4062 13.7969ZM24.8337 18.2209L21.6253 22.3043C21.217 22.8258 20.4517 22.9016 19.9488 22.4699L17.9072 20.7199C17.4172 20.3011 17.36 19.5638 17.7812 19.0749C18.2 18.5861 18.9373 18.5301 19.425 18.9489L20.5427 19.9068L22.9985 16.7813C23.3963 16.2749 24.129 16.1851 24.6365 16.5853C25.144 16.9808 25.2327 17.7146 24.8337 18.2209Z" fill="black"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_121_25467">
                                                                <rect width="28" height="28" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                                <span className='ml-3'>Theft Protection</span>
                                            </div>
                                            <button type='button' className='btn btn-add-device'>Label</button>
                                        </div>
                                        <ul className='addToCart__li pl-0'>
                                            <li>
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66668 10.115L12.7947 3.98633L13.738 4.92899L6.66668 12.0003L2.42401 7.75766L3.36668 6.81499L6.66668 10.115Z" fill="#121415"/>
                                                    </svg>
                                                </span> Pay a One Time fee, provide an FIR and install the Sangeetha App on your phone to get a brand new phone upon losing your older phone.  </li>
                                            
                                        </ul>
                                        <button type='button' onClick={() => setModalShow(true)} className='addToCart__link d-flex px-0'>View Terms and Conditions</button>
                                        <div className='symbol-group__wrapper'>
                                            <div className='symbol-group symbol-hover'>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle symbol-light'>
                                                    <span className='symbol-label font-weight-bold'>+30k</span>
                                                </div>
                                            </div>
                                            <div className='symbol-group__label ml-1'>Rated this 4.5 Stars</div>
                                        </div>
                                        <div className='cart-horizontal-line m-0'></div>
                                        <div className='addToCart-footer pb-0'>
                                            <div className="addToCart-price">₹999</div>
                                            <div className='vas__validity'><span>Validity</span> 6 Months</div>
                                        </div>
                                    </div>

                                    <div className='addToCart__list'>
                                        <div className='addToCart__head d-flex align-items-center justify-content-between'>
                                            <div className=''>
                                                <span>
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_121_25492)">
                                                            <path d="M13.8367 11.585H13.8483L19.565 9.63666C19.5884 9.53166 19.5884 9.42666 19.5884 9.33333C19.5884 9.22833 19.5767 9.135 19.5417 9.04166C19.4484 8.77333 19.2734 8.48166 19.0284 8.27166V5.71666C19.0284 3.82667 18.3517 3.08 17.6517 2.64833C17.29 1.55167 15.785 0 12.8333 0C9.33335 0 6.69668 3.465 6.69668 5.71666C6.69668 6.65 6.66168 7.385 6.62668 7.945C6.62668 8.06166 6.61501 8.16666 6.61501 8.26C6.35835 8.49333 6.18335 8.80833 6.10168 9.11166C6.09001 9.18166 6.07835 9.25166 6.07835 9.33333C6.07835 10.2433 6.59168 11.5617 6.66168 11.7133C6.73168 11.9117 6.88335 12.075 7.08168 12.1683C7.09335 12.215 7.10501 12.285 7.10501 12.425C7.10501 13.6617 8.16668 14.8283 8.75001 15.3883C8.69168 16.6717 8.33001 17.5583 7.81668 17.78L3.24334 19.2967C1.94834 19.7283 0.98001 20.7783 0.641676 22.1083L0.0233425 24.5817C-0.0349908 24.8383 0.0233425 25.1183 0.186676 25.3283C0.350009 25.5383 0.606676 25.6667 0.87501 25.6667H13.9533C13.6033 25.2233 13.2767 24.7333 12.9733 24.2083C12.2033 22.8433 11.6667 21.1867 11.6667 19.3083V14.63C11.6667 13.2533 12.5417 12.04 13.8367 11.585Z" fill="black"/>
                                                            <path d="M27.4062 13.7969L21.2812 11.7133C21.0992 11.6503 20.9008 11.6503 20.7188 11.7133L14.5938 13.7969C14.238 13.9171 14 14.2496 14 14.6253V19.3129C14 25.0343 20.3863 27.8156 20.6582 27.9311C20.8775 28.0233 21.1225 28.0233 21.3418 27.9311C21.6137 27.8144 28 25.0343 28 19.3129V14.6253C28 14.2496 27.762 13.9171 27.4062 13.7969ZM24.8337 18.2209L21.6253 22.3043C21.217 22.8258 20.4517 22.9016 19.9488 22.4699L17.9072 20.7199C17.4172 20.3011 17.36 19.5638 17.7812 19.0749C18.2 18.5861 18.9373 18.5301 19.425 18.9489L20.5427 19.9068L22.9985 16.7813C23.3963 16.2749 24.129 16.1851 24.6365 16.5853C25.144 16.9808 25.2327 17.7146 24.8337 18.2209Z" fill="black"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_121_25492">
                                                                <rect width="28" height="28" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </span>
                                                <span className='ml-3'>Assured Buyback</span>
                                            </div>
                                            <button type='button' className='btn btn-add-device'>Label</button>
                                        </div>
                                        <ul className='addToCart__li pl-0'>
                                            <li>
                                                <span>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66668 10.115L12.7947 3.98633L13.738 4.92899L6.66668 12.0003L2.42401 7.75766L3.36668 6.81499L6.66668 10.115Z" fill="#121415"/>
                                                    </svg>
                                                </span> Pay a One Time fee to sell your phone back to Sangeetha at a price based on the purchase.</li>
                                        </ul>
                                        <button type='button' onClick={() => setModalShow(true)} className='addToCart__link d-flex px-0'>View Terms and Conditions</button>
                                        <div className='symbol-group__wrapper'>
                                            <div className='symbol-group symbol-hover'>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg1} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle'>
                                                    <img src={UserImg2} className='img-fluid' alt='' />
                                                </div>
                                                <div className='symbol symbol-30 symbol-circle symbol-light'>
                                                    <span className='symbol-label font-weight-bold'>+30k</span>
                                                </div>
                                            </div>
                                            <div className='symbol-group__label ml-1'>Rated this 4.5 Stars</div>
                                        </div>
                                        <div className='cart-horizontal-line m-0'></div>
                                        <div className='addToCart-footer pb-0'>
                                            <div className="addToCart-price">₹999</div>
                                            <div className='vas__validity'><span>Validity</span> 6 Months</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PlansTermsCondition
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default ProtectionPlans