import React from 'react';
import coupon from '../../Images/coupon.png';

const OrderDelayed = () => {
    return (
        <>
            <div className='delayed--info col'>
                <h6 className='mb-2'>We apologise for the delay</h6>
                <p className='mb-0'>To make it up to you, here’s a ₹1000 coupon on us! Apply it in your cart on your next order.</p>
            </div>
            <div className='delayed--coupon col ml-4 d-flex justify-content-center'>
                <img src={coupon} className='img-fluid' alt='' />
            </div>
        </>
    )
}

export default OrderDelayed