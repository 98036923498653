import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import CartBankOfferList from './CartBankOfferList';
//import CartEMIOfferList from './CartEMIOfferList';
import Discount from '../../Images/Cart/Discount.svg';
import Card from '../../Images/Cart/Card.svg';
import hdfc from '../../Images/Cart/BankLogo.png';
import Zestpay from '../../Images/Cart/EMILogo.png';

const CartOffers = () => {
    const [modalShow, setModalShow] = useState(false);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 2,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 2,
            partialVisibilityGutter: 0
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={false}
                arrows={false}
                itemClass="cart-offer-carousel"
                responsive={responsive}
            >   
                <div className='offer-box'>
                    <div className='card__wrap'>
                        <h5 className='mb-3'>
                            <img src={Discount} className='img img-fluid' alt='' />
                            <span>Bank Offers</span>
                        </h5>
                        <div className='mb-0 offer-box-info d-flex align-items-start justify-content-between'>
                            <div className='d-flex align-items-start'>
                                <img src={hdfc} className='img img-fluid' alt='' />
                                <span className='ml-2'>10% Instant Savings on Citi Credit and Debit Cards on a min of ₹2,999 TCA</span>
                            </div>
                            <div className='link-text'  onClick={() => setModalShow(true)} role='button' aria-hidden="true">6 More Offers</div>
                        </div>
                    </div>
                </div>
                <div className='offer-box'>
                    <div className='card__wrap'>
                        <h5 className='mb-3'>
                            <img src={Card} className='img img-fluid' alt='' />
                            <span>Cardless EMI</span>
                        </h5>
                        <div className='mb-0 offer-box-info d-flex align-items-start justify-content-between'>
                            <div className='d-flex align-items-start'>
                                <img src={Zestpay} className='img img-fluid' alt='' />
                                <span className='ml-2'>Buy your new phone @ ₹2515/month and Save ₹2,999 on interest</span>
                            </div>
                            <div className='link-text'  onClick={() => setModalShow(true)} role='button' aria-hidden="true">1 More Offer</div>
                        </div>
                    </div>
                </div>
            </Carousel>
            <CartBankOfferList 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />  
        </>
    )
}

export default CartOffers