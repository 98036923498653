import productImg from './Images/Product/Home/1.png';
import productImg2 from './Images/Product/Home/2.png';
import productImg3 from './Images/Product/Home/3.png';
import iPhone from './Images/Product/iPhone.png';
import airpods from './Images/Product/airpods.png';
import Fitbit from './Images/Product/Fitbit.png';
import productImg11 from './Images/Home/Offers/product1.jpg';
import productImg22 from './Images/Home/Offers/product2.jpg';
import product1_1 from './Images/Category/product/iPhone.png';
import product2_1 from './Images/Category/product/Xaomi.png';
import product3_1 from './Images/Category/product/Oppo.png';
import product4_1 from './Images/Category/product/Samsung.png';
import watch1 from './Images/Category/Watch.jpg';
import Earphone1 from './Images/Category/Earphone.jpg';
import Accessories1 from './Images/Category/Accessories.jpg';

/* Product Gallery */
import HomeBanner1 from './Images/Product/ProductGallery.png';
import Img1 from './Images/Product/ProductGallery1.png';
import Img2 from './Images/Product/ProductGallery2.png';
import Img3 from './Images/Product/ProductGallery3.png';
import Img4 from './Images/Product/ProductGallery4.png';
/* Specification */
import ram from './Images/Product/Specification/1.svg';
import storage from './Images/Product/Specification/2.svg';
import camera from './Images/Product/Specification/3.svg';
import display from './Images/Product/Specification/4.svg';
import cpu from './Images/Product/Specification/5.svg';
import battery from './Images/Product/Specification/6.svg';
import VideoThumbnail from './Images/Product/VideoThumbnail.jpg'
/* Earphone */
import EarphoneBanner1 from './Images/Product/ProductCatogory/1_1.png';
import EarphoneImg1 from './Images/Product/ProductCatogory/1_11.png';
import EarphoneImg2 from './Images/Product/ProductCatogory/1_22.png';
import EarphoneImg3 from './Images/Product/ProductCatogory/1_33.png';
import EarphoneImg4 from './Images/Product/ProductCatogory/1_44.png';
import EarphoneImg5 from './Images/Product/ProductCatogory/1_55.png';
import VideoThumbnail2 from './Images/Product/VideoThumbnail2.png'

/* Accessories */
import AccessoriesBanner1 from './Images/Product/ProductCatogory/2_2.png';
import AccessoriesImg1 from './Images/Product/ProductCatogory/2_11.png';
import AccessoriesImg2 from './Images/Product/ProductCatogory/2_22.png';
import VideoThumbnail3 from './Images/Product/VideoThumbnail3.png';
/* Watch */
import WatchBanner1 from './Images/Product/ProductCatogory/3_3.png';
import WatchImg1 from './Images/Product/ProductCatogory/3_11.png';
import VideoThumbnail4 from './Images/Product/VideoThumbnail4.png'
/* TV */
import TVBanner1 from './Images/Product/ProductCatogory/4_4.png';
import TVImg1 from './Images/Product/ProductCatogory/4_11.png';
import VideoThumbnail5 from './Images/Product/VideoThumbnail5.png'

//Bank Icons
import HDFCIcon from './Images/Product/offers/bankIcon.png';
import AxisIcon from './Images/Product/offers/AxisIcon.png';
import SBIIcon from './Images/Product/offers/SBIIcon.png';
import paytmIcon from './Images/Product/offers/paytmIcon.png';
import SimpleIcon from './Images/Product/offers/SimpleIcon.png';

import HDFCIcon1 from './Images/Product/emi/1.png';
import AxisIcon1 from './Images/Product/emi/2.png';
import SBIIcon1 from './Images/Product/emi/3.png';
import paytmIcon1 from './Images/Product/emi/4.png';
import SimpleIcon1 from './Images/Product/emi/5.png';

import NFMiPhone from './Images/Product/NFMiPhone.png';

//CardlessEMI
import Zestpay from './Images/Product/emi/Zestpay.png';
import loantap from './Images/Product/emi/loantap.png'
import Simpl from './Images/Product/emi/Simpl.png'
import Zebpay from './Images/Product/emi/Zebpay.png'

export const productsInfo = [
    {
        "_id": "1",
        "ProductTag": "Top Selling",
        "ProductBrand": "Samsung",
        "ProductName": "Galaxy Z Fold2 5G",
        "ProductNewPrice": "43658",
        "ProductOldPrice": "45659",
        "stock_status": "In Stock",
        "stock_type": ""
    }
]

export const productAccessoriesInfo = [
    {
        "_id": "1",
        "ProductTag": "Sponsored",
        "ProductBrand": "GiGZA",
        "ProductName": "Essentials G11 Earphone Carrying Case",
        "ProductNewPrice": "659",
        "ProductOldPrice": "759",
        "stock_status": "In Stock",
        "stock_type": ""
    }
]

export const productsEarphoneInfo = [
    {
        "_id": "1",
        "ProductTag": "Deals of the Day",
        "ProductBrand": "BOAT",
        "ProductName": "airdrop's 441 Bluetooth Truly Wireless in Ear Earbuds with Mic",
        "ProductNewPrice": "9790",
        "ProductOldPrice": "10000",
        "stock_status": "In Stock",
        "stock_type": ""
    }
]

export const productsTVInfo = [
    {
        "_id": "1",
        "ProductTag": "Sponsored",
        "ProductBrand": "GIGZA",
        "ProductName": "Essentials G11 Earphone Carrying Case",
        "ProductNewPrice": "89658",
        "ProductOldPrice": "145659",
        "stock_status": "In Stock",
        "stock_type": ""
    }
]

export const productsWatchInfo = [
    {
        "_id": "1",
        "ProductTag": "Sponsored",
        "ProductBrand": "Apple",
        "ProductName": "Watch Series 3 GPS Space Grey Aluminium Case With Sport Band",
        "ProductNewPrice": "39790",
        "ProductOldPrice": "42000",
        "stock_status": "In Stock",
        "stock_type": ""
    }
]

export const outOfStockInfo = [
    {
        "_id": "1",
        "ProductTag": "Deals of the Day",
        "ProductBrand": "Samsung",
        "ProductName": "Galaxy Z Fold2 5G",
        "ProductNewPrice": "43658",
        "ProductOldPrice": "45659",
        "stock_status": "Out Of Stock",
        "stock_type": ""
    }
]

export const prebookInfo = [
    {
        "_id": "1",
        "ProductTag": "Deals of the Day",
        "ProductBrand": "Samsung",
        "ProductName": "Galaxy Z Fold2 5G",
        "ProductNewPrice": "43658",
        "ProductOldPrice": "45659",
        "stock_status": "",
        //"stock_type": "PreLaunch"
        "stock_type": "PreLaunchStart"
        //"stock_type": "PreBook"
    }
]

export const productsData = [
    {
        "_id": "1",
        "Image": productImg,
        "productName": "iPhone 13 Pro",
        "prductBrand": "Apple",
        "prductDesc": "Every emotion in portrait",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "16GB",
            "Storage": "128GB"
        },
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }, {
        "_id": "2",
        "Image": productImg2,
        "productName": "Logitech Type C to USB 3.5mm convertor",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "3",
        "Image": productImg3,
        "productName": "LUZWE USB C to 3.5mm Female Headphone Jack Adapter Connector,",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "Boat",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "12% Off",
        "label": ""
    }, {
        "_id": "4",
        "Image": productImg,
        "productName": "Logitech Type C to USB 3.5mm convertor",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "5",
        "Image": productImg3,
        "productName": "Logitech Type C to USB 3.5mm convertor",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "6",
        "Image": productImg,
        "productName": "Logitech Type C to USB 3.5mm convertor",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }
]

export const productCategoryData = [
    {
        "_id": "1",
        "Image": productImg11,
        "productName": "iPhone 13 Pro",
        "prductBrand": "Apple",
        "prductDesc": "Every emotion in portrait",
        "productColor": "Awesome Violet",
        "productStorage": {
            "ram": "16GB",
            "Storage": "128GB"
        },
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }, {
        "_id": "2",
        "Image": productImg22,
        "productName": "Logitech Type C to USB 3.5mm convertor",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "3",
        "Image": productImg11,
        "productName": "LUZWE USB C to 3.5mm Female Headphone Jack Adapter Connector,",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "Boat",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "4",
        "Image": productImg22,
        "productName": "Logitech Type C to USB 3.5mm convertor",
        "productInfo": 'Black, 128TB + 8GB',
        "prductDesc": "Every emotion in portrait",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }
]

export const CategoryListData = [
    {
        "_id": "1",
        "Image": product1_1,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }, {
        "_id": "2",
        "Image": product2_1,
        "productName": "Apple Airpods",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "3",
        "Image": product3_1,
        "productName": "LUZWE USB C to 3.5mm Female Headphone Jack Adapter Connector,",
        "prductBrand": "Boat",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "4",
        "Image": product4_1,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }
]

export const WatchListData = [
    {
        "_id": "1",
        "Image": watch1,
        "productName": "Fireboltt Talk bluetooth camping ",
        "prductBrand": "Apple",
        "productColor": "Teal Strap, 46 mm ",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '/',
        "productNewPrice": 2999,
        "productOldPrice": 6559,
        "productOffer": "52% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": watch1,
        "productName": "Noise Color fit Brio",
        "prductBrand": "",
        "productColor": "Green Strap, Free Size",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '/',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Top Seller"
    }, {
        "_id": "3",
        "Image": watch1,
        "productName": "Fireboltt Talk bluetooth camping ",
        "prductBrand": "Boat",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '/',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": watch1,
        "productName": "Fireboltt Talk bluetooth camping ",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '/',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }
]

export const HeadphoneListData = [
    {
        "_id": "1",
        "Image": Earphone1,
        "productName": "boAt Bassheads 900 Wired On Ear Headphones with Mic (Carbon Black)",
        "prductBrand": "Apple",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '',
        "productNewPrice": 2999,
        "productOldPrice": 6559,
        "productOffer": "52% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": Earphone1,
        "productName": "boAt Bassheads 900 Wired On Ear Headphones with Mic (Carbon Black)",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '',
        "productNewPrice": 2999,
        "productOldPrice": 6599,
        "productOffer": "15% Off",
        "label": "Top Seller"
    }, {
        "_id": "3",
        "Image": Earphone1,
        "productName": "boAt Bassheads 900 Wired On Ear Headphones with Mic (Carbon Black)",
        "prductBrand": "Boat",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": Earphone1,
        "productName": "boAt Bassheads 900 Wired On Ear Headphones with Mic (Carbon Black)",
        "prductBrand": "Apple",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "",
            "List2": "Upto ₹11,680 Off on Exchange"
        },
        "CompareLink": '',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }
]

export const AccessoriesListData = [
    {
        "_id": "1",
        "Image": Accessories1,
        "productName": "Wellpoint Back Cover for Apple iPhone 12 Pro, 6.1 INCH ONLY  (Transparent, Grip Case)",
        "prductBrand": "Apple",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": ""
        },
        "CompareLink": '',
        "productNewPrice": 2999,
        "productOldPrice": 6559,
        "productOffer": "52% Off",
        "label": "Sponsored"
    }, {
        "_id": "2",
        "Image": Accessories1,
        "productName": "Wellpoint Back Cover for Apple iPhone 12 Pro, 6.1 INCH ONLY  (Transparent, Grip Case)",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": ""
        },
        "CompareLink": '',
        "productNewPrice": 2999,
        "productOldPrice": 6599,
        "productOffer": "15% Off",
        "label": "Top Seller"
    }, {
        "_id": "3",
        "Image": Accessories1,
        "productName": "Wellpoint Back Cover for Apple iPhone 12 Pro, 6.1 INCH ONLY  (Transparent, Grip Case)",
        "prductBrand": "Boat",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "",
            "List2": ""
        },
        "CompareLink": '',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": "Sponsored"
    }, {
        "_id": "4",
        "Image": Accessories1,
        "productName": "Wellpoint Back Cover for Apple iPhone 12 Pro, 6.1 INCH ONLY  (Transparent, Grip Case)",
        "prductBrand": "Apple",
        "productColor": "",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "",
            "List2": ""
        },
        "CompareLink": '',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }
]

export const BrandListData = [
    {
        "_id": "1",
        "Image": iPhone,
        "productName": "boAt Bassheads 900 Wired On Ear Headphones with Mic (Carbon Black)",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }, {
        "_id": "2",
        "Image": airpods,
        "productName": "Apple Airpods",
        "prductBrand": "",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": ""
        },
        "CompareLink": '',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "3",
        "Image": Fitbit,
        "productName": "LUZWE USB C to 3.5mm Female Headphone Jack Adapter Connector,",
        "prductBrand": "Boat",
        "productColor": "",
        "productStorage": {
            "ram": "",
            "Storage": ""
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": ""
        },
        "CompareLink": '',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "4",
        "Image": iPhone,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }
]

export const BankOfferData = [
    {   
        _id: 1,
        Title: 'Bank Offers',
        OfferDetails: '5% Cashback on SANGEETHA AXIS Bank Credit Card on orders above ₹1800 '
    }, {   
        _id: 2,
        Title: 'No Cost EMI',
        OfferDetails: 'Upto ₹5,599 EMI interest savings on select Credit cards'
    }, {   
        _id: 3,
        Title: 'No Cost EMI',
        OfferDetails: 'Upto ₹5,599 EMI interest savings on select Credit cards'
    }
];

export const ImageGallery = [
    {
        "_id": "1",
        "Image": HomeBanner1,
        "Image_Btn": Img1
    }, {
        "_id": "2",
        "Image": HomeBanner1,
        "Image_Btn": Img2
    }, {
        "_id": "3",
        "Image": HomeBanner1,
        "Image_Btn": Img3
    }, {
        "_id": "4",
        "Image": HomeBanner1,
        "Image_Btn": Img4
    }
];

export const EarphoneImageGallery = [
    {
        "_id": "1",
        "Image": EarphoneBanner1,
        "Image_Btn": EarphoneImg1
    }, {
        "_id": "2",
        "Image": EarphoneBanner1,
        "Image_Btn": EarphoneImg2
    }, {
        "_id": "3",
        "Image": EarphoneBanner1,
        "Image_Btn": EarphoneImg3
    }, {
        "_id": "4",
        "Image": EarphoneBanner1,
        "Image_Btn": EarphoneImg4
    }, {
        "_id": "5",
        "Image": EarphoneBanner1,
        "Image_Btn": EarphoneImg5
    }
];

export const AccessoriesImageGallery = [
    {
        "_id": "1",
        "Image": AccessoriesBanner1,
        "Image_Btn": AccessoriesImg2
    }, {
        "_id": "2",
        "Image": AccessoriesBanner1,
        "Image_Btn": AccessoriesImg2
    }, {
        "_id": "3",
        "Image": AccessoriesBanner1,
        "Image_Btn": AccessoriesImg1
    }, {
        "_id": "4",
        "Image": AccessoriesBanner1,
        "Image_Btn": AccessoriesImg2
    }, {
        "_id": "5",
        "Image": AccessoriesBanner1,
        "Image_Btn": AccessoriesImg1
    }
];

export const SmartTvImageGallery = [
    {
        "_id": "1",
        "Image": TVBanner1,
        "Image_Btn": TVImg1
    }, {
        "_id": "2",
        "Image": TVBanner1,
        "Image_Btn": TVImg1
    }, {
        "_id": "3",
        "Image": TVBanner1,
        "Image_Btn": TVImg1
    }, {
        "_id": "4",
        "Image": TVBanner1,
        "Image_Btn": TVImg1
    }, {
        "_id": "5",
        "Image": TVBanner1,
        "Image_Btn": TVImg1
    }
];

export const SmartWatchImageGallery = [
    {
        "_id": "1",
        "Image": WatchBanner1,
        "Image_Btn": WatchImg1
    }, {
        "_id": "2",
        "Image": WatchBanner1,
        "Image_Btn": WatchImg1
    }, {
        "_id": "3",
        "Image": WatchBanner1,
        "Image_Btn": WatchImg1
    }, {
        "_id": "4",
        "Image": WatchBanner1,
        "Image_Btn": WatchImg1
    }, {
        "_id": "5",
        "Image": WatchBanner1,
        "Image_Btn": WatchImg1
    }
];
export const ProductSpecifications = [
    {
        Specific: [
            {   
                _id: 1,
                Image: ram,
                label: 'RAM',
                Name: '8GB'
            }, {   
                _id: 2,
                Image: storage,
                label: 'Battery',
                Name: '4300 mAh'
            }, {   
                _id: 3,
                Image: camera,
                label: 'CPU',
                Name: 'Octa Core 2.4 GHz'
            }, {
                _id: 4,
                Image: display,
                label: 'Display',
                Name: '6.43” Full HD + AMOLED DISPLAY'
            }, {
                _id: 5,
                Image: cpu,
                label: 'Storage',
                Name: '128GB'
            }, {
                _id: 6,
                Image: battery,
                label: 'Camera',
                Name: '32 MP + 8MP + 2MP'
            }
        ]
    }, {
        General: {
            InBox: "Airdopes 131,Charging Case, Type-c Charging, User Manual, Warranty Card",
            ModelNumber: "IRHSY74384648",
            Color: "Midnight Blue",
            designedFor: "Designed For",
            WaterResistant: "Yes",
            SweatResistant: "Yes",
            OtherFeatures: "Upto 12 hours playback with a charging case (Up-to 4x Charge), Type-C Charging Port, Insta wake N’ Pair ",
            OS: "Android 11.0"
        }
    } 
];

export const EarphoneSpecifications = [
    {
        Specific: [
            {   
                _id: 1,
                Image: ram,
                label: 'Type',
                Name: 'Wireless'
            }, {   
                _id: 2,
                Image: storage,
                label: 'Connectivity',
                Name: 'Bluetooth'
            }, {   
                _id: 3,
                Image: camera,
                label: 'Size',
                Name: '3.8 x 3.05 x 7 cm'
            }, {
                _id: 4,
                Image: display,
                label: 'Weight',
                Name: '48.5 gms'
            }, {
                _id: 5,
                Image: cpu,
                label: 'Microphones',
                Name: 'With Microphone'
            }, {
                _id: 6,
                Image: battery,
                label: 'Warranty',
                Name: '1 Year'
            }
        ]
    }, {
        General: {
            InBox: "Airdopes 131,Charging Case, Type-c Charging, User Manual, Warranty Card",
            ModelNumber: "IRHSY74384648",
            Color: "Midnight Blue",
            designedFor: "Designed For",
            WaterResistant: "Yes",
            SweatResistant: "Yes",
            OtherFeatures: "Upto 12 hours playback with a charging case (Up-to 4x Charge), Type-C Charging Port, Insta wake N’ Pair ",
            OS: "Android 11.0"
        }
    } 
];

export const SmartTvSpecifications = [
    {
        Specific: [
            {   
                _id: 1,
                Image: ram,
                label: 'Display',
                Name: 'Ultra HD (4K) 3840 x 2160 Pixels'
            }, {   
                _id: 2,
                Image: storage,
                label: 'Sound Output',
                Name: '20 W'
            }, {   
                _id: 3,
                Image: camera,
                label: 'Screen Type',
                Name: 'Backlit LED'
            }, {
                _id: 4,
                Image: display,
                label: 'Operating System',
                Name: 'Tizen'
            }, {
                _id: 5,
                Image: cpu,
                label: 'Size',
                Name: '1449.4 mm x 59.9 mm'
            }, {
                _id: 6,
                Image: battery,
                label: 'Weight (with Stand)',
                Name: '20.9kg'
            }
        ]
    }, {
        General: {
            InBox: "Airdopes 131,Charging Case, Type-c Charging, User Manual, Warranty Card",
            ModelNumber: "IRHSY74384648",
            Color: "Midnight Blue",
            designedFor: "Designed For",
            WaterResistant: "Yes",
            SweatResistant: "Yes",
            OtherFeatures: "Upto 12 hours playback with a charging case (Up-to 4x Charge), Type-C Charging Port, Insta wake N’ Pair ",
            OS: "Android 11.0"
        }
    } 
];

export const AccessoriesSpecifications = [
    {
        Specific: []
    }, {
        General: {
            InBox: "Airdopes 131,Charging Case, Type-c Charging, User Manual, Warranty Card",
            ModelNumber: "IRHSY74384648",
            Color: "Midnight Blue",
            designedFor: "Designed For",
            WaterResistant: "Yes",
            SweatResistant: "Yes",
            OtherFeatures: "Upto 12 hours playback with a charging case (Up-to 4x Charge), Type-C Charging Port, Insta wake N’ Pair ",
            OS: "Android 11.0"
        }
    } 
];

export const WatchSpecifications = [
    {
        Specific: [
            {   
                _id: 1,
                Image: ram,
                label: 'Internal Memory',
                Name: '8 GB'
            }, {   
                _id: 2,
                Image: storage,
                label: 'Battery Life',
                Name: 'Upto 18 hours'
            }, {   
                _id: 3,
                Image: camera,
                label: 'Operating System',
                Name: 'Watch OS4'
            }, {
                _id: 4,
                Image: display,
                label: 'Notification',
                Name: 'Messages, Calendar, Mail'
            }, {
                _id: 5,
                Image: cpu,
                label: 'Resolution',
                Name: '256 GB'
            }, {
                _id: 6,
                Image: battery,
                label: 'Warranty ',
                Name: '1 year'
            }
        ]
    }, {
        General: {
            InBox: "Airdopes 131,Charging Case, Type-c Charging, User Manual, Warranty Card",
            ModelNumber: "IRHSY74384648",
            Color: "Midnight Blue",
            designedFor: "Designed For",
            WaterResistant: "Yes",
            SweatResistant: "Yes",
            OtherFeatures: "Upto 12 hours playback with a charging case (Up-to 4x Charge), Type-C Charging Port, Insta wake N’ Pair ",
            OS: "Android 11.0"
        }
    } 
];

export const ProductReview = [
    {
        VideoImage: VideoThumbnail
    }
]
export const EarphoneReview = [
    {
        VideoImage: VideoThumbnail2
    }
]
export const AccessoriesReview = [
    {
        VideoImage: VideoThumbnail3
    }
]
export const WatchReview = [
    {
        VideoImage: VideoThumbnail4
    }
]
export const TVReview = [
    {
        VideoImage: VideoThumbnail5
    }
]

export const OffersList = [
    {   
        Image: HDFCIcon,
        Text: '10% Instant Savings on Citi Credit and Debit Cards on a min of ₹2,999 TCA'
    }, {   
        Image: AxisIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: SBIIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: paytmIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: SimpleIcon,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }
];

export const OffersList1 = [
    {   
        Image: HDFCIcon1,
        Text: '10% Instant Savings on Citi Credit and Debit Cards on a min of ₹2,999 TCA'
    }, {   
        Image: AxisIcon1,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: SBIIcon1,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: paytmIcon1,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }, {   
        Image: SimpleIcon1,
        Text: 'Use code TRYNEW & get 30% off on orders above ₹480. Maximum discount: ₹48'
    }
];

export const CardlessEMIData = [
    {   
        _id: 1,
        Img: Zestpay,
        Title: 'Zestpay',
        EMIInfo: 'Save upto ₹2,450 on interest'
    }, {   
        _id: 2,
        Img: loantap,
        Title: 'Loantap',
        EMIInfo: 'Save upto ₹2,450 on interest'
    }, {   
        _id: 3,
        Img: Simpl,
        Title: 'Simpl',
        EMIInfo: 'Save upto ₹2,450 on interest'
    }, {   
        _id: 4,
        Img: Zebpay,
        Title: 'Zebpay',
        EMIInfo: 'Save upto ₹1,450 on interest'
    }
];

export const NFMListData = [
    {
        "_id": "1",
        "Image": NFMiPhone,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }, {
        "_id": "2",
        "Image": NFMiPhone,
        "productName": "Apple Airpods",
        "prductBrand": "",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "3",
        "Image": NFMiPhone,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Boat",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 89999,
        "productOldPrice": 93559,
        "productOffer": "15% Off",
        "label": ""
    }, {
        "_id": "4",
        "Image": NFMiPhone,
        "productName": "Apple iPhone 13 Pro",
        "prductBrand": "Apple",
        "productColor": "Purple",
        "productStorage": {
            "ram": "8GB",
            "Storage": "1TB"
        },
        "BrandOfferList": {
            "List1": "Upto ₹11,680 Off on Exchange",
            "List2": "10% Off on Axis Bank Credit Cards"
        },
        "CompareLink": '/',
        "productNewPrice": 70559,
        "productOldPrice": 75559,
        "productOffer": "15% Off",
        "label": "12% Off"
    }
]








