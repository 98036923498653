import React from 'react'
import Carousel from "react-multi-carousel";
import CategoryButtonGroup from '../Common/CategoryButtonGroup';
import img1 from '../../Images/Compare/img1.jpg';
import img2 from '../../Images/Compare/img2.jpg';
import img3 from '../../Images/Compare/img3.jpg';
import img4 from '../../Images/Compare/img4.jpg';

const ComparisonCarousel = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 0
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="image-item best-offer-phone__wrap"
                responsive={responsive}
                renderButtonGroupOutside={true}
                customButtonGroup={<CategoryButtonGroup />}
                swipeable
            >   
                <div className='popular-comparison'>
                    <div className='popular-comparison__row'>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img1} className='img-fluid' alt='' />
                            </div>
                            <div className='flexible' />
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>Apple iPhone 13 Pro</h6>
                                    <div className='compare_title'>Blue Void, 128GB, 8GB RAM</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹89,999</div>
                                </div>
                            </div>
                        </div>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                            <div className='flexible' />
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>Xiaomi 11T Pro 5G Hyperphone</h6>
                                    <div className='compare_title'>Purple, 1TB + 8GB</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹81,999</div>
                                    <div className='compare-old_price'>₹83,559</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-1_1 mar-spacer' />
                    <div className='flex-ble' />
                    <button type='button' className='btn btn-dark-custom-outline space-padding w-100'>View Comparison</button>
                    <div className='mar-spacer h-1' />
                </div>
                <div className='popular-comparison'>                            
                    <div className='popular-comparison__row'>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img3} className='img-fluid' alt='' />
                            </div>
                            <div className='flexible' />
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>OnePlus Nord 2 5G</h6>
                                    <div className='compare_title'>Blue Void, 128GB, 8GB RAM</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹89,999</div>
                                </div>
                            </div>
                        </div>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img4} className='img-fluid' alt='' />
                            </div>
                            <div className='flexible' />
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>Xiaomi 11T Pro 5G Hyperphone</h6>
                                    <div className='compare_title'>Purple, 1TB + 8GB</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹81,999</div>
                                    <div className='compare-old_price'>₹83,559</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-1_1 mar-spacer' />
                    <div className='flex-ble' />
                    <button type='button' className='btn btn-dark-custom-outline space-padding w-100'>View Comparison</button>                            
                </div>
                <div className='popular-comparison'>
                    <div className='popular-comparison__row'>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img1} className='img-fluid' alt='' />
                            </div>
                            <div className='flexible' />
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>Apple iPhone 13 Pro</h6>
                                    <div className='compare_title'>Blue Void, 128GB, 8GB RAM</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹89,999</div>
                                </div>
                            </div>
                        </div>
                        <div className='popular-comparison__col'>
                            <div className='popular-comparison_media'>
                                <img src={img2} className='img-fluid' alt='' />
                            </div>
                            <div className='flexible' />
                            <div className='popular-comparison_details'>
                                <div>
                                    <h6>Xiaomi 11T Pro 5G Hyperphone</h6>
                                    <div className='compare_title'>Purple, 1TB + 8GB</div>
                                </div>
                                <div className='flexible' />
                                <div className='compare_price'>
                                    <div className='compare-new_price'>₹81,999</div>
                                    <div className='compare-old_price'>₹83,559</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-1_1 mar-spacer' />
                    <div className='flex-ble' />
                    <button type='button' className='btn btn-dark-custom-outline space-padding w-100'>View Comparison</button>
                </div>
            </Carousel>
        </>
    )
}

export default ComparisonCarousel