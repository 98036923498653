import React from 'react';
import coupon2 from '../../../Images/Profile/coupon1.png';
import coupon3 from '../../../Images/Profile/coupon3.png';
import coupon4 from '../../../Images/Profile/coupon4.png'
import coupon5 from '../../../Images/Profile/coupon5.png'
import coupon6 from '../../../Images/Profile/coupon6.png'
import ApplyCoupncanvas from './ApplyCoupncanvas';
import coupon1 from '../../../Images/Profile/coupon2.png';








const Mycouponsright = () => {
    return (
        <>
            <div className='order-success__wrapper'>
                <div className='container'>
                    <div className='order-confirmation m-0'>
                        <div className="lagnguage-main_wrapper">
                            <div className="exparicoupns-container">
                                <div className="expari-coupon-text info">
                                    <span>Expiring Soon</span>                                  
                                </div>
                                <div className="h-1_5"></div>
                                <div className="coupon-area">
                                    <div className="coupon-one">
                                        {['end'].map((placement, idx) => (
                                            <ApplyCoupncanvas key={idx} placement={placement} name={placement} />
                                        ))}
                                    </div>
                                    <div className="coupon-one">
                                        <img src={coupon2} alt="coupon-img" />
                                    </div> 
                                    <div className="coupon-one">
                                        <img src={coupon3} alt="coupon-img" />
                                    </div>
                                    <div className="coupon-one">
                                        <img src={coupon4} alt="coupon-img" />
                                    </div>
                                </div>
                            </div>  
                            <div className="h-1_5"/>

                            <div className="exparicoupns-container">
                                <div className="expari-coupon-text">
                                    <span>Recents</span>                                  
                                </div>
                                <div className="h-1_5"/>

                              
                                <div className="coupon-area">
                                    <div className="coupon-one">
                                        <img src={coupon1} alt="coupon-img" />
                                    </div>
                                    <div className="coupon-one">
                                        <img src={coupon2} alt="coupon-img" />
                                    </div> 
                                    <div className="coupon-one">
                                        <img src={coupon5} alt="coupon-img" />
                                    </div>
                                    <div className="coupon-one">
                                        <img src={coupon6} alt="coupon-img" />
                                    </div>
                                </div>
                              
                           
                                  
                             
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default Mycouponsright