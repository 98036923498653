import React,{useState} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import coupon1 from '../../../Images/Profile/coupon2.png';
import couonLogo from '../../../Images/Profile/canvaslogo.png' 





const ApplyCoupncanvas = ({...props }) => {
    const [expary ,setExpary] =useState(true)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
        <>
            <button className='coupn-btn' onClick={handleShow}>
                <img src={coupon1} alt="coupon-img" />
            </button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="cart-pdp-offcanvas coupon-canvas">
                <Offcanvas.Header closeButton className='coupon-canva-head border-0' >
                    <div className="coupnLogo-side">
                        <img src={couonLogo} alt="" />
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="h-1_5"></div>
                    <div className="coupon-canvabody-wrapper">
                        <p>You Avail ₹3000 as Sangeetha Exchange bonus</p>

                        {expary?
                            <div className="coupon-input-body">
                                <input type="text"  className='coupon-holder' defaultValue="HABCC-FDFHD-DFJDS"/> <button className='btninput-dark'>Copy</button>
                            </div>:''
                        }
                        <div className="h-1_5"></div>

                        <div className="expairdate-coupon">
                            <p> Expiry date</p>
                            <span>15 May, 2022</span>{setExpary? <span className='expary-danger-text'>Expired</span>:""}
                        </div>
                        <div className="h-1_5"></div>

                        <div className="coupon-rules-details">
                            <h4>Details</h4>
                            <ul className='coupon-rules-ul'>
                                <li>This programs is designed to reward the loyalty of the people who have bought their previous phones from Sangeetha Store.</li>
                                <div className="h-1_5"></div>
                                <li>Both Offline and online orders and applicable for the discount.</li>
                            </ul>
                        </div>



                    </div>
             
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ApplyCoupncanvas