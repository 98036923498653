import { Link } from '@reach/router'
import React from 'react'

import map from '../../../Images/storeLocator/map.png';

const NoResult = () => {
    return (
        <>
            <div className='empty-state__store'>
                <div className='store-map__icon mb-3'>
                    <img src={map} className='img-fluid' alt='' />
                </div>
                <div className='store-details__view'>
                    <div className='store-empty_head'>Sorry, no results found!</div>
                    <div className='store-empty_info'>Looks like we don’t have a store in this area yet</div>
                    <Link to='/StoreLocator' className='btn btn-store-locator w-auto'>View all store</Link>
                </div>
            </div>
        </>
    )
}

export default NoResult