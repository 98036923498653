import React from 'react';
import { Link } from "@reach/router";
import img1 from '../../Images/Brand/BrandList/1.png';
import img2 from '../../Images/Brand/BrandList/2.png';
import img3 from '../../Images/Brand/BrandList/3.png';
import img4 from '../../Images/Brand/BrandList/4.png';
import img5 from '../../Images/Brand/BrandList/5.png';
import img6 from '../../Images/Brand/BrandList/6.png';
import img7 from '../../Images/Brand/BrandList/7.png';

const Brands = () => {
    return (
        <>
            <div className="category__wrap phone-accessories__wrap brands__wrap">
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img1} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Phone Covers</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img2} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Pop Sockets</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img3} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Gimbals</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img4} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Wireless Charger</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img5} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Phone Covers</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img6} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Pop Sockets</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img7} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Gimbals</span>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Brands