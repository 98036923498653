import React, { useState } from 'react';
import buy1 from '../../../Images/Product/ProductCatogory/buy3.png';
import buy2 from '../../../Images/Product/ProductCatogory/buy2.png';
import buy3 from '../../../Images/Product/ProductCatogory/buy1.png';

const WatchBuyTogether = () => {
    const [isChecked, setIsChecked] = useState(true);
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };
    const handleOnChange1 = () => {
        setIsChecked1(!isChecked1);
    };
    const handleOnChange2 = () => {
        setIsChecked2(!isChecked2);
    };
    return (
        <>
            <div className='buy-together__wrap'>
                <div className='buy-together__card'>
                    <div className="buy-together__checkBox">
                        {/* <Form.Check 
                            aria-label="option 1" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                        /> */}
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="1" 
                            checked={isChecked} 
                            onChange={handleOnChange}
                            id="1" />
                        <div className='buy-together__media'>
                            <img src={buy1} className='img img-fluid' alt='' />
                        </div>
                        <div className='buy-together__details'>
                            <h6 className='buy-together__heading'>Watch Series 3 GPS Space Grey.. </h6>
                            <div className="price-offer">
                                <div className="new-price">₹28,000</div>
                                <div className="old-price ml-2">₹30,000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="2" 
                            checked={isChecked1} 
                            onChange={handleOnChange1}
                            id="2" />
                    </div>
                    <div className='buy-together__media'>
                        <img src={buy2} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>Airdopes 441 Bluetooth Truly...</h6>
                        <div className="price-offer">
                            <div className="new-price">₹5,000</div>
                            <div className="old-price ml-2">₹6,000</div>
                        </div>
                    </div>
                </div>
                <div className='buy-together__card'>
                    <div className="form-check- buy-together__checkBox">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="3" 
                            checked={isChecked2} 
                            onChange={handleOnChange2}
                            id="3" />
                    </div>
                    <div className='buy-together__media' htmlFor="apple">
                        <img src={buy3} className='img img-fluid' alt='' />
                    </div>
                    <div className='buy-together__details'>
                        <h6 className='buy-together__heading'>UniGear Polyester Zipper Headpho..</h6>
                        <div className="price-offer">
                            <div className="new-price">₹28,000</div>
                            <div className="old-price ml-2">₹30,000</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='buy-together__savings'>
                <p className='mb-0'>Saving additional ₹3,000 on 3 items</p>
            </div>
            <div className='mar-spacer h-1_5' />
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-column align-items-start'>
                    <div className="price-offer-total justify-content-start">
                        <div className="new-price">₹28,000</div>
                        <div className="old-price ml-2">₹30,000</div>
                    </div>
                    <span className='total-amount-pdp'>Total Price</span>
                </div>
                <div className='d-flex'>
                    <button type='button' className='btn btn-block btn-dark-custom mb-2'>Add 3 items to Cart</button>
                </div>
            </div>
        </>
    )
}

export default WatchBuyTogether