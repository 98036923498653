import React from 'react'

const RefurbishmentFlow = () => {
    return (
        <>
            <div className='work_structure__wrapper'>
                <div className='work_structure'>
                    <h6 className='work-head_structure'>How Refurbishment works?</h6>
                    <div className='work-structure_flex'>
                        <div className='work-structure_wrap'>
                            <div className='work-structure_media'>
                                <span>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.5088 15.9872L31.785 12.8954C32.3142 11.6128 31.84 10.1534 30.6571 9.42744L27.3902 7.47281L26.6541 4.42594C26.3273 3.07462 25.0757 2.17375 23.7033 2.28175L19.9652 2.57106L17.8238 0.674875C17.2962 0.224563 16.6483 -0.000249792 16.0001 2.08275e-07C15.3528 0.000250208 14.7052 0.22475 14.1763 0.673938L12.0349 2.57106L8.29677 2.28175C6.93996 2.18656 5.67196 3.0755 5.37989 4.31512C5.45083 4.09469 4.35908 7.48812 4.29864 7.676L1.34352 9.4265C0.159767 10.1534 -0.314483 11.6127 0.215579 12.8964L1.49089 15.9872L0.216579 19.0771C-0.314421 20.3616 0.160704 21.82 1.34358 22.546L4.60721 24.4869L5.34602 27.5476C5.67102 28.8979 6.91708 29.7942 8.29677 29.6917L11.9946 29.4061L14.1763 31.3244C14.7046 31.7747 15.3519 32 16.0001 32C16.6473 32 17.2956 31.7747 17.8238 31.3243L20.0055 29.4061L23.7033 29.6917C25.1005 29.8024 26.3291 28.8988 26.6541 27.5475L27.3902 24.5006L30.6571 22.546C31.839 21.82 32.3133 20.3616 31.7841 19.0771L30.5088 15.9872Z" fill="#EDF5FF"/>
                                        <path d="M20.0054 29.4061L23.7032 29.6917C25.1004 29.8024 26.329 28.8988 26.654 27.5475L27.39 24.5006L30.657 22.546C31.8389 21.82 32.3132 20.3616 31.784 19.0771L30.5087 15.9872L31.7849 12.8954C32.3141 11.6128 31.8399 10.1534 30.657 9.42744L27.39 7.47281L26.654 4.42594C26.3272 3.07462 25.0756 2.17375 23.7032 2.28175L19.9651 2.57106L17.8237 0.674875C17.2961 0.224563 16.6482 -0.000249792 16 2.08275e-07V32C16.6472 32 17.2955 31.7747 17.8237 31.3243L20.0054 29.4061Z" fill="#D5E8FE"/>
                                        <path d="M16 26.3125C21.6954 26.3125 26.3125 21.6954 26.3125 16C26.3125 10.3046 21.6954 5.6875 16 5.6875C10.3046 5.6875 5.6875 10.3046 5.6875 16C5.6875 21.6954 10.3046 26.3125 16 26.3125Z" fill="#278C4C"/>
                                        <path d="M21.3503 12.5246C20.9841 12.1585 20.3908 12.1585 20.0246 12.5246L16 16.5493L15.0625 17.4868L11.9753 14.3996C11.6091 14.0334 11.0158 14.0334 10.6496 14.3996C10.2835 14.7658 10.2835 15.3591 10.6496 15.7253L14.3996 19.4753C14.5828 19.6584 14.8226 19.7499 15.0625 19.7499C15.3023 19.7499 15.5422 19.6584 15.7253 19.4753L16 19.2006L21.3503 13.8503C21.7166 13.4841 21.7166 12.8908 21.3503 12.5246Z" fill="#EDF5FF"/>
                                        <path d="M21.3503 12.5246C20.9841 12.1585 20.3908 12.1585 20.0246 12.5246L16 16.5493V19.2006L21.3503 13.8503C21.7165 13.4841 21.7165 12.8908 21.3503 12.5246Z" fill="#D5E8FE"/>
                                    </svg>
                                </span>
                            </div>
                            <div className='work-structure_details'>
                                <div className='work-structure_heading'>Assured Warranty</div>
                                <div className='work-structure_title'>All the products are carefully examined, tested and inspected by the Sangeetha store to fully working condition</div>
                            </div>
                        </div>
                        <div className='work-structure_wrap'>
                            <div className='work-structure_media'>
                                <div className='group-img'>
                                    <span>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M30.5088 15.9872L31.785 12.8954C32.3142 11.6128 31.84 10.1534 30.6571 9.42744L27.3902 7.47281L26.6541 4.42594C26.3273 3.07462 25.0757 2.17375 23.7033 2.28175L19.9652 2.57106L17.8238 0.674875C17.2962 0.224563 16.6483 -0.000249792 16.0001 2.08275e-07C15.3528 0.000250208 14.7052 0.22475 14.1763 0.673938L12.0349 2.57106L8.29677 2.28175C6.93996 2.18656 5.67196 3.0755 5.37989 4.31512C5.45083 4.09469 4.35908 7.48812 4.29864 7.676L1.34352 9.4265C0.159767 10.1534 -0.314483 11.6127 0.215579 12.8964L1.49089 15.9872L0.216579 19.0771C-0.314421 20.3616 0.160704 21.82 1.34358 22.546L4.60721 24.4869L5.34602 27.5476C5.67102 28.8979 6.91708 29.7942 8.29677 29.6917L11.9946 29.4061L14.1763 31.3244C14.7046 31.7747 15.3519 32 16.0001 32C16.6473 32 17.2956 31.7747 17.8238 31.3243L20.0055 29.4061L23.7033 29.6917C25.1005 29.8024 26.3291 28.8988 26.6541 27.5475L27.3902 24.5006L30.6571 22.546C31.839 21.82 32.3133 20.3616 31.7841 19.0771L30.5088 15.9872Z" fill="#EDF5FF"/>
                                        </svg>
                                    </span>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.0492 15.6914C19.4811 17.294 18.6218 18.6875 17.4951 19.8327C16.2125 21.1362 14.5331 22.172 12.5035 22.9109C12.4369 22.935 12.3674 22.9548 12.2975 22.9695C12.2053 22.9884 12.1114 22.9987 12.0184 23H12.0002C11.901 23 11.8013 22.9896 11.7025 22.9695C11.6326 22.9548 11.5641 22.935 11.4978 22.9114C9.46573 22.1737 7.78423 21.1384 6.5004 19.8349C5.37316 18.6897 4.51411 17.2971 3.94678 15.6945C2.91517 12.7806 2.97387 9.57075 3.02112 6.99128L3.02193 6.95167C3.03144 6.73867 3.03757 6.51493 3.04096 6.26786C3.05821 5.05483 3.98484 4.05127 5.15045 3.98363C7.58071 3.84247 9.46073 3.01751 11.0671 1.38788L11.0811 1.37445C11.3478 1.11983 11.6839 0.994953 12.0184 1.00016C12.3409 1.00452 12.6622 1.12923 12.9194 1.37445L12.9331 1.38788C14.5398 3.01751 16.4198 3.84247 18.85 3.98363C20.0156 4.05127 20.9423 5.05483 20.9595 6.26786C20.9629 6.51661 20.969 6.74001 20.9786 6.95167L20.979 6.96845C21.0261 9.55279 21.0845 12.7691 20.0492 15.6914Z" fill="#278C4C"/>
                                            <path d="M17.9753 9.27464C17.6091 8.90845 17.0158 8.90845 16.6496 9.27464L12.625 13.2993L11.6875 14.2368L8.60033 11.1496C8.23414 10.7834 7.64083 10.7834 7.27464 11.1496C6.90845 11.5158 6.90845 12.1091 7.27464 12.4753L11.0246 16.2253C11.2078 16.4084 11.4476 16.4999 11.6875 16.4999C11.9273 16.4999 12.1672 16.4084 12.3503 16.2253L12.625 15.9506L17.9753 10.6003C18.3416 10.2341 18.3416 9.64083 17.9753 9.27464Z" fill="#EDF5FF"/>
                                            <path d="M17.9753 9.27464C17.6091 8.90845 17.0158 8.90845 16.6496 9.27464L12.625 13.2993V15.9506L17.9753 10.6003C18.3415 10.2341 18.3415 9.64083 17.9753 9.27464Z" fill="#D5E8FE"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className='work-structure_details'>
                                <div className='work-structure_heading'>Easy Replacement</div>
                                <div className='work-structure_title'>The refurbished phones comes with a 1 year warranty</div>
                            </div>
                        </div>
                        <div className='work-structure_wrap'>
                            <div className='work-structure_media'>
                                <div className='group-img'>
                                    <span>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M30.5088 15.9872L31.785 12.8954C32.3142 11.6128 31.84 10.1534 30.6571 9.42744L27.3902 7.47281L26.6541 4.42594C26.3273 3.07462 25.0757 2.17375 23.7033 2.28175L19.9652 2.57106L17.8238 0.674875C17.2962 0.224563 16.6483 -0.000249792 16.0001 2.08275e-07C15.3528 0.000250208 14.7052 0.22475 14.1763 0.673938L12.0349 2.57106L8.29677 2.28175C6.93996 2.18656 5.67196 3.0755 5.37989 4.31512C5.45083 4.09469 4.35908 7.48812 4.29864 7.676L1.34352 9.4265C0.159767 10.1534 -0.314483 11.6127 0.215579 12.8964L1.49089 15.9872L0.216579 19.0771C-0.314421 20.3616 0.160704 21.82 1.34358 22.546L4.60721 24.4869L5.34602 27.5476C5.67102 28.8979 6.91708 29.7942 8.29677 29.6917L11.9946 29.4061L14.1763 31.3244C14.7046 31.7747 15.3519 32 16.0001 32C16.6473 32 17.2956 31.7747 17.8238 31.3243L20.0055 29.4061L23.7033 29.6917C25.1005 29.8024 26.3291 28.8988 26.6541 27.5475L27.3902 24.5006L30.6571 22.546C31.839 21.82 32.3133 20.3616 31.7841 19.0771L30.5088 15.9872Z" fill="#EDF5FF"/>
                                        </svg>
                                    </span>
                                    <span>
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.7465 13.3978C18.6355 13.558 18.6279 13.7603 18.7234 13.9257L19.2938 14.9137C14.5471 17.9539 12.9766 24.2431 15.8214 29.1704C16.2082 29.8404 16.6647 30.4645 17.176 31.0174C17.3286 31.1837 17.5833 31.2233 17.7784 31.1106L17.7826 31.1082C17.9794 30.9889 18.0707 30.7553 18.0005 30.5357C16.68 26.4075 18.3305 21.8531 21.9477 19.5103L22.5059 20.4771C22.6013 20.6425 22.7828 20.7413 22.9746 20.721C23.1664 20.7007 23.3256 20.5749 23.3916 20.3954L25.2961 14.9198C25.3429 14.7853 25.3319 14.639 25.2584 14.5118C25.1874 14.3888 25.0662 14.3062 24.9264 14.2795L19.237 13.1994C19.0486 13.1669 18.8557 13.2443 18.7465 13.3978V13.3978Z" fill="#278C4C"/>
                                            <path d="M24.7372 26.9954C24.5496 27.0133 24.3862 27.1415 24.3226 27.3252L22.4157 32.7967C22.3689 32.9311 22.3866 33.0792 22.4576 33.2021C22.5286 33.3251 22.648 33.4145 22.7878 33.4412L28.4791 34.5145C28.5946 34.5383 28.7116 34.5159 28.8134 34.4571C28.8728 34.4229 28.9248 34.3759 28.9653 34.3186C29.0787 34.1626 29.0881 33.9537 28.9926 33.7883L28.4222 32.8003C33.1714 29.7643 34.7376 23.4776 31.8904 18.546C31.5035 17.876 31.0495 17.2561 30.5424 16.7009C30.3831 16.5327 30.1351 16.495 29.9358 16.61C29.7366 16.7251 29.6435 16.9654 29.7137 17.1849C31.0384 21.3107 29.3813 25.8633 25.7684 28.2037L25.2102 27.2369C25.1147 27.0715 24.9315 26.9793 24.7372 26.9954V26.9954Z" fill="#278C4C"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className='work-structure_details'>
                                <div className='work-structure_heading'>Easy Replacement</div>
                                <div className='work-structure_title'>Get hassle free replacement</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RefurbishmentFlow