import {
    GET_HOME_INFO, 
    ERROR_HOME_INFO,
    GET_HOME_BANNER,
    GET_HOME_CATEGORY,
    GET_HOME_PRODUCT_SLIDER_1,
} from '../types'

const initialState = {
    homeBanner:[],
    homeCategory:[],
    productLayoutSlider_1:[],
}

export default function reducer(state = initialState, action) {

    switch(action.type){

    case GET_HOME_BANNER:
        return {
            ...state,
            homeBanner:action.payload,
        }

    case GET_HOME_CATEGORY:
        return {
            ...state,
            homeCategory:action.payload,
        }

    case GET_HOME_PRODUCT_SLIDER_1:
        return {
            ...state,
            productLayoutSlider_1:action.payload,
        }

    case GET_HOME_INFO:
        return {
            ...state,
            homeDetails:action.payload,
        }
    case ERROR_HOME_INFO:
        return{
            error: action.payload 
        }
    default: return state
    }

}