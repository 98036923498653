import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import Sale from '../Components/Home/Sale';
import RelatedCategory from '../Components/ProductDetails/RelatedCategory';
import OrderSuccessModal from '../Components/Order/OrderSuccessModal';

import '../Components/Order/Order.css';
import img1 from '../Images/Order/1.png';
import img2 from '../Images/Order/2.png';
import img3 from '../Images/Order/3.png';
import img4 from '../Images/Order/4.png';
import Package from '../Images/Order/Package.svg';

const OrderConfirmation = () => {
    const [modalShow, setModalShow] = useState(false);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    }

    useEffect(() => {
        setTimeout(() => {
            setModalShow({ show: true });
        }, 5000);
    }, [])
    return (
        <>
            <div className='order-success__wrapper'>
                <div className='container'>
                    <div className='order-confirmation'>
                        <div className='mar-spacer h-2 mb-2' />
                        <div className='order-banner d-flex align-items-center'>
                            <span className='d-flex'>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 16C0 24.8368 7.1632 32 16 32C24.8368 32 32 24.8368 32 16C32 7.1632 24.8368 0 16 0C7.1632 0 0 7.1632 0 16Z" fill="#278C4C"/>
                                    <path d="M22.803 10.521L22.6659 10.392L22.5289 10.521L13.9999 18.5493L9.47017 14.286L9.3331 14.157L9.19602 14.286L7.86293 15.5407L7.70819 15.6863L7.86293 15.832L13.8628 21.479L13.9999 21.608L14.137 21.479L24.1371 12.067L24.2919 11.9213L24.137 11.7757L22.803 10.521Z" fill="white" stroke="white" strokeWidth="0.4"/>
                                </svg>
                            </span>
                            <span className='ml-3'>Order Successful</span>
                        </div>
                        <div className='order-location'>
                            <div className='order-location-flex d-flex align-items-center mb-2'>
                                <span className='d-flex'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 23.728L5.636 17.364C4.37734 16.1053 3.52019 14.5017 3.17293 12.7558C2.82567 11.01 3.00391 9.20043 3.6851 7.5559C4.36629 5.91137 5.51984 4.50577 6.99988 3.51684C8.47992 2.52791 10.22 2.00008 12 2.00008C13.78 2.00008 15.5201 2.52791 17.0001 3.51684C18.4802 4.50577 19.6337 5.91137 20.3149 7.5559C20.9961 9.20043 21.1743 11.01 20.8271 12.7558C20.4798 14.5017 19.6227 16.1053 18.364 17.364L12 23.728ZM16.95 15.95C17.9289 14.971 18.5955 13.7237 18.8656 12.3659C19.1356 11.008 18.9969 9.6006 18.4671 8.32155C17.9373 7.04251 17.04 5.9493 15.8889 5.18017C14.7378 4.41103 13.3844 4.0005 12 4.0005C10.6156 4.0005 9.26222 4.41103 8.11109 5.18017C6.95996 5.9493 6.06275 7.04251 5.53292 8.32155C5.00308 9.6006 4.86442 11.008 5.13445 12.3659C5.40449 13.7237 6.07111 14.971 7.05 15.95L12 20.9L16.95 15.95ZM12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 10.4696 10.2107 9.96086 10.5858 9.58578C10.9609 9.21071 11.4696 9 12 9C12.5304 9 13.0391 9.21071 13.4142 9.58578C13.7893 9.96086 14 10.4696 14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className='ml-1_5'>Delivery Address</span>
                            </div>
                            <p className='order-name mb-2'>Rohan Nanda, 560103</p>
                            <p className='order-address m-0'>103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103</p>
                        </div>
                        <div className='bg-theme pb-1' />
                        <div className='order-track__wrap'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Arriving by 4:20 pm, Today</h6>
                                    <p className='order-placed__info mb-0'>ORDER ID : 983729203491</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='lookout_delivery'>
                            <div className='lookout-box_wrap justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <span className='d-flex'>
                                        <img src={Package} className='img-fluid' alt='' />
                                    </span>
                                    <div className='mb-0 offer-box-info_msg'>
									Look out for {' '}
                                        <span className='offer-box-info_msg-free'>Free</span>
                                        {' '}services by Sangeetha on your Delivery
                                    </div>
                                </div>
                                <span className='d-flex'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.172 12L8.222 7.05L9.636 5.636L16 12L9.636 18.364L8.222 16.95L13.172 12Z" fill="white"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
					
                        <div className='order-track__wrap order-track__wrap2 mt-3'>
                            <div className='order-track__head mb-3 d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <h6 className='order-placer__heading mb-1'>Arriving by 4:20 pm, Today</h6>
                                    <p className='order-placed__info mb-0'>ORDER ID : 983729203491</p>
                                </div>
                                <Link to='/' className='order-placed__link'>Track Order</Link>
                            </div>
                            <div className='order-track__content'>
                                <div className='orders_wrap'>
                                    <div className='orders-list_wrap d-flex align-items-start'>
                                        <div className='orders-media__wrap'>
                                            <img src={img1} className='img-fluid' alt='' />
                                            <div className='orders__tag'>
                                                <div className='orders_content'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.8915 1.413L6 0.5L10.1085 1.413C10.2195 1.43768 10.3188 1.49948 10.39 1.5882C10.4612 1.67692 10.5 1.78726 10.5 1.901V6.8945C10.5 7.38837 10.378 7.87459 10.1449 8.31001C9.91188 8.74542 9.57493 9.11657 9.164 9.3905L6 11.5L2.836 9.3905C2.42514 9.11662 2.08823 8.74555 1.85517 8.31023C1.62212 7.8749 1.50012 7.38878 1.5 6.895V1.901C1.50002 1.78726 1.53882 1.67692 1.60999 1.5882C1.68117 1.49948 1.78047 1.43768 1.8915 1.413ZM6.5 5V2.5L4 6H5.5V8.5L8 5H6.5Z" fill="white"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img2} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img3} className='img-fluid' alt='' />
                                        </div>
                                        <div className='orders-media__wrap'>
                                            <img src={img4} className='img-fluid' alt='' />
                                        </div>
                                    </div>
                                    <Link to='/OrderDetails' className='d-flex'>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5627 16L10.9627 9.4L12.848 7.51467L21.3334 16L12.848 24.4853L10.9627 22.6L17.5627 16Z" fill="#98A0AE"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cart-accessories'>
                <div className='container'>     
                    <div className='cart-accessories__carousel spacing-block position-relative border-0'>
                        <div className='h-1_2 mar-spacer' />
                        <div className='h-1_2 mar-spacer' />
                        <div className='top-head__wrap'>
                            <h4 className='cart-heading__wrap mb-2'>Accessories for you</h4>
                        </div>
                        <div className='btn-suggestion__wrap btn-cart-suggestion__wrap mt-0 justify-content-start mb-3'>
                            <button type='button' className='btn-suggestion btn-right-space active'>All</button>
                            <button type='button' className='btn-suggestion btn-right-space'>Accessories</button>
                            <button type='button' className='btn-suggestion btn-right-space'>Earphones</button>
                            <button type='button' className='btn-suggestion btn-right-space'>Chargers</button>
                            <button type='button' className='btn-suggestion btn-right-space'>Cleaning Material</button>
                            <button type='button' className='btn-suggestion btn-right-space'>Cleaning Material</button>
                            <button type='button' className='btn-suggestion btn-right-space'>Accessories</button>
                            <button type='button' className='btn-suggestion btn-right-space'>Accessories</button>   
                        </div>
                        <Sale wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                </div>
            </div>
            <div className='pdp-related-category'>
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <h4 className='pdp-catg-heading'>Related Categories</h4>
                        <RelatedCategory />
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-2 mb-2' />
            <OrderSuccessModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default OrderConfirmation