import React from 'react';
import { OffersList1 } from '../../Data'; 

const BankList = () => {
    return (
        <>
            <div className='bank-offer-wrapper emi-tab-content'>
                <div className='product-payment-offers'>  
                    {OffersList1.map((OffersItems, idx) => {                      
                        return(
                            <div to='/BankOffersDetails' className='payment-offer-options' key={idx}>
                                <div className='payment-offer-content  w-100'>
                                    <span className='d-flex'>
                                        <img src={OffersItems.Image} className='img im-fluid' alt='' />
                                    </span>
                                    <div className='payment-offer-info'>
                                        <p className='payment-offer-details'>{OffersItems.Text}</p>
                                    </div>
                                </div>
                                <div className='highlight-body__link ml-2'>Apply</div>
                            </div>
                        );
                    })}
                </div>
            </div> 
        </>
    )
}

export default BankList
