import React, { useState } from 'react';
import img1 from '../../Images/Search/Oneplus.png';
import img2 from '../../Images/Search/Oppo.png';
import img3 from '../../Images/Search/Samsung.png';

const HeaderSearch = () => {
    const [ showSearch, setShowSearch ] = useState(false);
    return (
        <>
            <div className='nav-search ml-4'>
                <input type='text' className='search__home' placeholder='Search for gadgets & brands' onClick={() => setShowSearch(true)}/>
                <span className='nav-search-icon'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z" fill="#98A0AE"/>
                    </svg>
                </span>
                <span className='remove-searched' style={{ display: showSearch ? 'flex' : 'none' }} onClick={() => setShowSearch(false)} role='button' aria-hidden="true">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z" fill="white"/>
                    </svg>
                </span>
                <div className='header-filtered__backdrop' style={{ display: showSearch ? 'block' : 'none' }} />
                <div className='header-filtered__result' style={{ display: showSearch ? 'block' : 'none' }}>
                    {/* <div className='searched-history'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h6 className='search-head__wrap'>Recent Searches</h6>
                            <button type='button' className='btn clear-btn'>Clear All</button>
                        </div>
                        <div className='recentSearch d-flex justify-content-start align-items-center flex-wrap'>
                            <div className='search_span d-flex align-items-center'>iPhone 13 
                                <span className='d-flex ml-1'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <div className='search_span d-flex align-items-center'>Mobiles
                                <span className='d-flex ml-1'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <div className='search_span d-flex align-items-center'>Samsung Phones
                                <span className='d-flex ml-1'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <div className='search_span d-flex align-items-center'>Earphones
                                <span className='d-flex ml-1'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                            <div className='search_span d-flex align-items-center'>Smart Phones
                                <span className='d-flex ml-1'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div> */}
                    <div className='searched-suggestions'>
                        <h6 className='search-head__suggestions'>Top Suggestions</h6>
                        <div className='history-lists__wrap'>
                            <div className='searched-history-list mb-3'>
                                <span className='d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0001 1.66669C14.6026 1.66669 18.3334 5.39752 18.3334 10C18.3334 14.6025 14.6026 18.3334 10.0001 18.3334C5.39758 18.3334 1.66675 14.6025 1.66675 10H3.33341C3.33341 13.6817 6.31841 16.6667 10.0001 16.6667C13.6817 16.6667 16.6667 13.6817 16.6667 10C16.6667 6.31835 13.6817 3.33335 10.0001 3.33335C7.94675 3.33335 6.11008 4.26169 4.88758 5.72085L6.66675 7.50002H1.66675V2.50002L3.70591 4.53835C5.23341 2.78002 7.48675 1.66669 10.0001 1.66669ZM10.8334 5.83335V9.65419L13.5359 12.3567L12.3567 13.5359L9.16675 10.3442V5.83335H10.8334Z" fill="#C4CAD0"/>
                                    </svg>
                                    <span className='ml-1'>Mobile Phones</span>
                                </span>
                                <span className='clear-search--heder'>Clear</span>
                            </div>
                        </div>
                        <div className='suggestion-lists__wrap'>
                            <div className='searched-suggestion-list mb-3'>
                                <span className='d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0001 1.66669C14.6026 1.66669 18.3334 5.39752 18.3334 10C18.3334 14.6025 14.6026 18.3334 10.0001 18.3334C5.39758 18.3334 1.66675 14.6025 1.66675 10H3.33341C3.33341 13.6817 6.31841 16.6667 10.0001 16.6667C13.6817 16.6667 16.6667 13.6817 16.6667 10C16.6667 6.31835 13.6817 3.33335 10.0001 3.33335C7.94675 3.33335 6.11008 4.26169 4.88758 5.72085L6.66675 7.50002H1.66675V2.50002L3.70591 4.53835C5.23341 2.78002 7.48675 1.66669 10.0001 1.66669ZM10.8334 5.83335V9.65419L13.5359 12.3567L12.3567 13.5359L9.16675 10.3442V5.83335H10.8334Z" fill="#C4CAD0"/>
                                    </svg>
                                    <span className='ml-1'>Mobile Phones</span>
                                </span>
                            </div>
                            <div className='searched-suggestion-list mb-3'>
                                <span className='d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0001 1.66669C14.6026 1.66669 18.3334 5.39752 18.3334 10C18.3334 14.6025 14.6026 18.3334 10.0001 18.3334C5.39758 18.3334 1.66675 14.6025 1.66675 10H3.33341C3.33341 13.6817 6.31841 16.6667 10.0001 16.6667C13.6817 16.6667 16.6667 13.6817 16.6667 10C16.6667 6.31835 13.6817 3.33335 10.0001 3.33335C7.94675 3.33335 6.11008 4.26169 4.88758 5.72085L6.66675 7.50002H1.66675V2.50002L3.70591 4.53835C5.23341 2.78002 7.48675 1.66669 10.0001 1.66669ZM10.8334 5.83335V9.65419L13.5359 12.3567L12.3567 13.5359L9.16675 10.3442V5.83335H10.8334Z" fill="#C4CAD0"/>
                                    </svg>
                                    <span className='ml-1'>Mobile Watch</span>
                                </span>
                            </div>
                            <div className='searched-suggestion-list mb-3'>
                                <span className='d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0001 1.66669C14.6026 1.66669 18.3334 5.39752 18.3334 10C18.3334 14.6025 14.6026 18.3334 10.0001 18.3334C5.39758 18.3334 1.66675 14.6025 1.66675 10H3.33341C3.33341 13.6817 6.31841 16.6667 10.0001 16.6667C13.6817 16.6667 16.6667 13.6817 16.6667 10C16.6667 6.31835 13.6817 3.33335 10.0001 3.33335C7.94675 3.33335 6.11008 4.26169 4.88758 5.72085L6.66675 7.50002H1.66675V2.50002L3.70591 4.53835C5.23341 2.78002 7.48675 1.66669 10.0001 1.66669ZM10.8334 5.83335V9.65419L13.5359 12.3567L12.3567 13.5359L9.16675 10.3442V5.83335H10.8334Z" fill="#C4CAD0"/>
                                    </svg>
                                    <span className='ml-1'>Mobile Stand</span>
                                </span>
                            </div>
                            <div className='searched-suggestion-list mb-3'>
                                <span className='d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0001 1.66669C14.6026 1.66669 18.3334 5.39752 18.3334 10C18.3334 14.6025 14.6026 18.3334 10.0001 18.3334C5.39758 18.3334 1.66675 14.6025 1.66675 10H3.33341C3.33341 13.6817 6.31841 16.6667 10.0001 16.6667C13.6817 16.6667 16.6667 13.6817 16.6667 10C16.6667 6.31835 13.6817 3.33335 10.0001 3.33335C7.94675 3.33335 6.11008 4.26169 4.88758 5.72085L6.66675 7.50002H1.66675V2.50002L3.70591 4.53835C5.23341 2.78002 7.48675 1.66669 10.0001 1.66669ZM10.8334 5.83335V9.65419L13.5359 12.3567L12.3567 13.5359L9.16675 10.3442V5.83335H10.8334Z" fill="#C4CAD0"/>
                                    </svg>
                                    <span className='ml-1'>Mobile Cover</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='searched-lists'>
                        <div className='mar-spacer h-1_5' />
                        <h6 className='search-head__wrap'>Trending Top 3</h6>
                        <div className='searched-lists__wrap'>
                            <div className='searched-list'>
                                <div className='searched-media'>
                                    <img src={img1} className='mg-fluid' alt='' />
                                </div>
                                <div className='searched-content'>
                                    <h6 className='mb-0'>OnePlus Nord CE 5G</h6>
                                    <p>BLue Void, 128GB, 8GB RAM</p>
                                </div>
                            </div>
                            <div className='searched-list'>
                                <div className='searched-media'>
                                    <img src={img2} className='mg-fluid' alt='' />
                                </div>
                                <div className='searched-content'>
                                    <div className='search-tag'>Trending</div>
                                    <h6 className='mb-0'>OnePlus Nord CE 5G</h6>
                                    <p>BLue Void, 128GB, 8GB RAM</p>
                                </div>
                            </div>
                            <div className='searched-list'>
                                <div className='searched-media'>
                                    <img src={img2} className='mg-fluid' alt='' />
                                </div>
                                <div className='searched-content'>
                                    <h6 className='mb-0'>OnePlus Nord CE 5G</h6>
                                    <p>BLue Void, 128GB, 8GB RAM</p>
                                </div>
                            </div>
                            <div className='searched-list'>
                                <div className='searched-media'>
                                    <img src={img3} className='mg-fluid' alt='' />
                                </div>
                                <div className='searched-content'>
                                    <h6 className='mb-0'>OnePlus Nord CE 5G</h6>
                                    <p>BLue Void, 128GB, 8GB RAM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderSearch