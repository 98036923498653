import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AddressList from '../Components/Address/AddressList';
import DeliveryEstimates from '../Components/Address/DeliveryEstimates';
import CouponPanel from '../Components/Cart/CouponPanel';
import '../Components/Address/Address.css';

import AddressEmpty from '../Images/Address/empty.svg'

const Address = () => {
    const [ isAddress ] = useState(true);
    const [show, setShow] = useState(false);
    //const [modalShow, setModalShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='cart__wrapper'>
                <div className='container'>
                    <div className='cart-steps'>
                        <ul className='form-steps'>
                            <li className='steps active-page valid'>
                                <span>Cart</span>
                            </li>
                            <li className='steps active-page'>
                                <span>Address</span>
                            </li>
                            <li className='steps'>
                                <span>Payment</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='step_cart'>
                    <div className='container'>
                        <div className='cart-head__wrap d-flex align-items-center justify-content-between'>
                            <h3 className='cart-page_heading mb-0'>Address</h3>
                        </div>
                    </div>
                </div>

                <div className='content_cart'>
                    <div className='container'>
                        <div className='h-3 mar-spacer' />
                        <div className='content-address spacing-block position-relative'>
                            <div className='address-items__wrap'>
                                <div className='cart-heading__wrapper mb-4'>
                                    <h4 className='d-flex align-items-center'>
                                        <span className='d-flex'>
                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5 23.728L6.136 17.364C4.87734 16.1054 4.02019 14.5017 3.67293 12.7559C3.32567 11.0101 3.50391 9.20047 4.1851 7.55595C4.86629 5.91142 6.01984 4.50582 7.49988 3.51689C8.97992 2.52796 10.72 2.00012 12.5 2.00012C14.28 2.00012 16.0201 2.52796 17.5001 3.51689C18.9802 4.50582 20.1337 5.91142 20.8149 7.55595C21.4961 9.20047 21.6743 11.0101 21.3271 12.7559C20.9798 14.5017 20.1227 16.1054 18.864 17.364L12.5 23.728ZM17.45 15.95C18.4289 14.971 19.0955 13.7238 19.3656 12.3659C19.6356 11.0081 19.4969 9.60064 18.9671 8.3216C18.4373 7.04256 17.54 5.94935 16.3889 5.18021C15.2378 4.41107 13.8844 4.00055 12.5 4.00055C11.1156 4.00055 9.76222 4.41107 8.61109 5.18021C7.45996 5.94935 6.56275 7.04256 6.03292 8.3216C5.50308 9.60064 5.36442 11.0081 5.63445 12.3659C5.90449 13.7238 6.57111 14.971 7.55 15.95L12.5 20.9L17.45 15.95ZM12.5 13C11.9696 13 11.4609 12.7893 11.0858 12.4143C10.7107 12.0392 10.5 11.5305 10.5 11C10.5 10.4696 10.7107 9.9609 11.0858 9.58583C11.4609 9.21076 11.9696 9.00004 12.5 9.00004C13.0304 9.00004 13.5391 9.21076 13.9142 9.58583C14.2893 9.9609 14.5 10.4696 14.5 11C14.5 11.5305 14.2893 12.0392 13.9142 12.4143C13.5391 12.7893 13.0304 13 12.5 13Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='ml-2'>Deliver to</span>
                                    </h4>
                                </div>
                                {isAddress ?
                                    <AddressList /> :
                                    <div className='address-empty'>
                                        <img src={AddressEmpty} className='img-fluid m-auto d-block' alt='' />
                                        <p className='text-center'>You haven’t added any address yet</p>

                                        <button type='button' onClick={handleShow} className='btn btn-dark-custom btn-dark-custom-address py-2 w-100'>Add Address</button>
                                    </div>
                                }

                            </div>
                            <div className='address-estimates__wrap'>
                                <div className='cart-heading__wrapper mb-3'>
                                    <h4 className='d-flex align-items-center'>
                                        <span>Delivery Estimates</span>
                                    </h4>
                                </div>
                                <DeliveryEstimates />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose} className='cart-pdp-offcanvas coupon-offcanvas'>
                <CouponPanel  />
            </Offcanvas>
        </>
    )
}

export default Address