import { Link } from '@reach/router';
import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
//import CompareSearch from './CompareSearch';
import { productsData } from '../../../Data';

import Iphone from '../../Images/Compare/Iphone.png'
import Samsung from '../../Images/Compare/Samsung.png';

const Compare = ({ ...props }) => { 
    const [ compareSearch, setCompareSearch ] = useState(true);
    const [show, setShow] = useState(false);    
 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='product-compare' onClick={handleShow} aria-hidden="true">+ Compare</div>

            <Offcanvas show={show} onHide={handleClose} {...props} className='compare__offCanvas'>
                <Offcanvas.Header closeButton>
                    {compareSearch ?
                        <Offcanvas.Title className='cart-heading'>Compare</Offcanvas.Title> :
                        <Offcanvas.Title className='cart-heading d-flex align-items-center'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.625 6.25058L7.80333 7.42891L5.69 9.54225H16.6667V11.2089H5.69L7.80333 13.3222L6.625 14.5006L2.5 10.3756L6.625 6.25058Z" fill="#121415"/>
                            </svg>
                            <span className='ml-2'>Search</span>
                        </Offcanvas.Title>
                    }
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='' style={{ display: compareSearch ? "block" : "none" }}>
                        <div className='compare__wrap'>
                            <div className='compare__row'>
                                <div className='compare__col'>
                                    <div className='compare__bg ml-auto'>
                                        <div className='compare__media'>
                                            <img src={Iphone} className='img-fluid' alt='' />
                                        </div>
                                        <div className='compare__content'>
                                            <div className='compare__title'>Apple iPhone 13 Pro</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='compare__col flex_auto'>
                                    <div className='compare_vs'>VS</div>
                                </div>
                                <div className='compare__col'>
                                    <div className='compare__bg mr-auto'>
                                        <div className='compare__media'>
                                            <img src={Samsung} className='img-fluid' alt='' />
                                        </div>
                                        <div className='compare__content'>
                                            <div className='compare__title'>Samsung Galaxy FoldZ 5G</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='compare__search mt-4'>
                                <h4 className='compare-title'>Select another product</h4>
                                <div className='header-home__search'>
                                    <form className="form">
                                        <div className="form-group">
                                            <div className="input-icon">
                                                <input type="text" className="bannerInput-search" placeholder="Search for mobiles and brands" onClick={() => setCompareSearch(false)}/>
                                                <span className="bannerSearch-icon d-flex">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.0258 13.8475L18.595 17.4158L17.4158 18.595L13.8475 15.0258C12.5197 16.0902 10.8683 16.6691 9.16663 16.6667C5.02663 16.6667 1.66663 13.3067 1.66663 9.16666C1.66663 5.02666 5.02663 1.66666 9.16663 1.66666C13.3066 1.66666 16.6666 5.02666 16.6666 9.16666C16.669 10.8683 16.0901 12.5198 15.0258 13.8475ZM13.3541 13.2292C14.4117 12.1416 15.0023 10.6837 15 9.16666C15 5.94333 12.3891 3.33333 9.16663 3.33333C5.94329 3.33333 3.33329 5.94333 3.33329 9.16666C3.33329 12.3892 5.94329 15 9.16663 15C10.6837 15.0024 12.1415 14.4118 13.2291 13.3542L13.3541 13.2292Z" fill="#98A0AE"/>
                                                    </svg>                                                       
                                                </span>
                                            </div>                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>	
                        <div className='product-wrapper'>
                            {productsData.slice(0, 2).map(product => (
                                <div className='product-list' key={product._id}>
                                    {product.label ?
                                        <div className='product-label-tag m-h-1'>{product.label}</div> : ''
                                    }
                                    <div className='product-wrapper-flex w-100'>
                                        <div className='product-thumb'>
                                            <img src={Samsung} className='img-fluid img w-100' alt={product.productName} />
                                        </div>
                                        <div className='product-details d-flex align-items-start justify-content-center flex-column'>
                                            <h2>
                                                <Link to='/ProductDetails'>{product.productName}</Link>
                                            </h2>
                                            <p className='product-muted'>
                                                {product.productColor},
                                                {product.productStorage.Storage} +
                                                {product.productStorage.Storage}
                                            </p>
                                            <div className='product-price'>
                                                <div className='new-price'>₹{product.productNewPrice}</div>
                                                <div className='old-price'>₹{product.productOldPrice}</div>
                                                <div className='price-discount'>{product.productOffer}</div>
                                            </div>                                        
                                        </div>
                                        <div className='d-flex align-items-start justify-content-center flex-column'>
                                            <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                        </div>
                                    </div>

                                    <div className='product-tags mt-0'>
                                        <div className='tag-label'>PhotoGraphy</div>
                                        <div className='tag-label'>Wireless Charge</div>
                                        <div className='tag-label'>Retina Display</div>
                                        <div className='tag-label'>Gaming</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='check-EMI__offers' style={{ display: compareSearch ? "none" : "block" }}>
                        {/* <CompareSearch /> */}
                    </div>
                </Offcanvas.Body>
                {compareSearch ? 
                    <div className='sticky-btn'>
                        <button type='button' className='btn btn-dark-custom w-100' disabled>Compare Now</button>
                    </div> : null
                }   
            </Offcanvas>
        </>
    )
}

export default Compare