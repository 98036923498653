import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import AddAddressCanvas from './AddAddressCanvas';


const Savearressright = () => {
    const [eligible]=useState(false)
    return (
        <>
            <div className='order-success__wrapper'>
                <div className='container'>
                    <div className='order-confirmation m-0'>
                        <div className="address-wrap address-wrap-padding">
                            <div className="addnewcard-btn">
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 9V5H10.3333V9H14.3333V10.3333H10.3333V14.3333H9V10.3333H5V9H9Z" fill="#121415"/></svg>
                                </span>
                                {['end'].map((placement, idx) => (
                                    <AddAddressCanvas key={idx} placement={placement} name={placement} />
                                ))}
                            </div>
                            <div className="mar-spacer"/>
                            <div className="savecart-txt">
                            saved address
                            </div>
                            <div className="h-1_5"/>
                            <div className="addres-mainn-wrapper">
                                <div className="address-card">
                                    <div className='coupon-list__input mt-1 address-radio'>
                                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"  />
                                    </div>
                                    <div className='logged-default__list border-0 mb-4 ml-4'>
                                        <div className='logged-default__label mb-2'>
                                            <span>Rohan Nanda <span className='default__address'>(Default)</span></span>
                                            <span className="btn btn-tag">Home</span>
                                        </div>
                                        <div className='cart-address__section'>
                                            <div className='loggedIn-address__label mb-2'>
                                                    103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                            </div>
                                            <div className='loggedIn-address__contact'>
                                                <label htmlFor="label">Mobile: </label>
                                                <span className='contact-number'>7382901648</span>
                                            </div>
                                        </div>
                                        <div className='h-1_1'/>
                                        <div className="address-edit-remove-btn-area">
                                            <div className="card-remove_button"><button className="remove-btn-border">Edit</button></div>                                            
                                            <div className="card-remove_button"><button className="remove-btn-border">Remove</button></div>

                                        </div>
                                    </div>
                                </div>
                                <div className='devider-address'/>
                                <div className='h-1_5'/>
                                <div className="address-card">
                                    <div className='coupon-list__input mt-1 address-radio'>
                                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"  />
                                    </div>
                                    <div className='logged-default__list border-0 mb-4 ml-4'>
                                        <div className='logged-default__label mb-2'>
                                            <span>Kartik Gupta </span>
                                         
                                        </div>
                                        <div className='cart-address__section'>
                                            <div className='loggedIn-address__label mb-2'>
                                                    103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                            </div>
                                            <div className='loggedIn-address__contact'>
                                                <label htmlFor="label">Mobile: </label>
                                                <span className='contact-number'>7382901648</span>
                                            </div>
                                        </div>
                                        <div className='h-1_1'/>
                                        {eligible?
                                            <div className="address-edit-remove-btn-area">
                                                <div className="card-remove_button"><button className="remove-btn-border">Edit</button></div>                                            
                                                <div className="card-remove_button"><button className="remove-btn-border">Remove</button></div>

                                            </div>:
                                            <div className="address-check-alert-beadge">
                                                <span className='d-flex'>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 8C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8Z" fill="#278C4C"/><path d="M11.47 5.1883L11.3329 5.05937L11.1959 5.18835L6.99993 9.13797L4.80362 7.07083L4.66655 6.94182L4.52948 7.07083L3.86293 7.69817L3.70819 7.84381L3.86293 7.98945L6.86287 10.813L6.99994 10.942L7.13701 10.813L12.1371 6.10697L12.2919 5.96128L12.137 5.81564L11.47 5.1883Z" fill="white" stroke="white"/></svg>
                                                </span>
                                                <div className="address-success-txt">
                                                This address is eligible for our Free Delivery Services
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='h-1_5'/>
                                <div className='devider-address'/>
                                <div className='h-1_5'/>

                                <div className="address-card">
                                    <div className='coupon-list__input mt-1 address-radio'>
                                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1"  />
                                    </div>  
                                    <div className='logged-default__list border-0 mb-4 ml-4'>
                                        <div className='logged-default__label mb-2'>
                                            <span>Shiuli Chandra </span>
                                         
                                        </div>
                                        <div className='cart-address__section'>
                                            <div className='loggedIn-address__label mb-2'>
                                                    103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                            </div>
                                            <div className='loggedIn-address__contact'>
                                                <label htmlFor="label">Mobile: </label>
                                                <span className='contact-number'>7382901648</span>
                                            </div>
                                        </div>
                                        <div className='h-1_1'/>
                                        {eligible?
                                            <div className="address-edit-remove-btn-area">
                                                <div className="card-remove_button"><button className="remove-btn-border">Edit</button></div>                                            
                                                <div className="card-remove_button"><button className="remove-btn-border">Remove</button></div>

                                            </div>:
                                            <div className="address-check-alert-beadge">
                                                <span className='d-flex'>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 8C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8Z" fill="#278C4C"/><path d="M11.47 5.1883L11.3329 5.05937L11.1959 5.18835L6.99993 9.13797L4.80362 7.07083L4.66655 6.94182L4.52948 7.07083L3.86293 7.69817L3.70819 7.84381L3.86293 7.98945L6.86287 10.813L6.99994 10.942L7.13701 10.813L12.1371 6.10697L12.2919 5.96128L12.137 5.81564L11.47 5.1883Z" fill="white" stroke="white"/></svg>
                                                </span>
                                                <div className="address-success-txt">
                                                This address is eligible for our Free Delivery Services
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                

                            </div>

                          

                          
                      

                          

                        </div>
                        
                      

                    </div>
                </div>
            </div>

        </>
    )
}

export default Savearressright