import React,{useState} from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';
import map from '../../../Images/Profile/map.png'
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


const AddAddressCanvas = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button className='btn-addcard' onClick={handleShow}>Add New Address</button>           
            <Offcanvas show={show} onHide={handleClose} {...props} className="cart-pdp-offcanvas address-padding">
                <Offcanvas.Header closeButton>
                    <div>
                        <div className="offcanvastitel-address">
                        Add Address
                        </div>
                        <div className="h-1_5"/>                   
                    </div>
                
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                    <div className="address-map-wrap">
                        <img src={map} alt="map" className='img-fluid' />
                    </div>
                  
                    <div className="location-wrapper">
                        <div className="location-head">
                            <span className='d-flex'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2427 11.576L8 15.8187L3.75734 11.576C2.91823 10.7369 2.34679 9.66777 2.11529 8.50389C1.88378 7.34 2.0026 6.13361 2.45673 5.03726C2.91086 3.9409 3.6799 3.00384 4.66659 2.34455C5.65328 1.68527 6.81332 1.33337 8 1.33337C9.18669 1.33337 10.3467 1.68527 11.3334 2.34455C12.3201 3.00384 13.0891 3.9409 13.5433 5.03726C13.9974 6.13361 14.1162 7.34 13.8847 8.50389C13.6532 9.66777 13.0818 10.7369 12.2427 11.576ZM8 9.99999C8.70725 9.99999 9.38552 9.71904 9.88562 9.21894C10.3857 8.71884 10.6667 8.04057 10.6667 7.33332C10.6667 6.62608 10.3857 5.9478 9.88562 5.4477C9.38552 4.94761 8.70725 4.66666 8 4.66666C7.29276 4.66666 6.61448 4.94761 6.11438 5.4477C5.61429 5.9478 5.33334 6.62608 5.33334 7.33332C5.33334 8.04057 5.61429 8.71884 6.11438 9.21894C6.61448 9.71904 7.29276 9.99999 8 9.99999ZM8 8.66665C7.64638 8.66665 7.30724 8.52618 7.05719 8.27613C6.80715 8.02608 6.66667 7.68694 6.66667 7.33332C6.66667 6.9797 6.80715 6.64056 7.05719 6.39051C7.30724 6.14046 7.64638 5.99999 8 5.99999C8.35362 5.99999 8.69276 6.14046 8.94281 6.39051C9.19286 6.64056 9.33334 6.9797 9.33334 7.33332C9.33334 7.68694 9.19286 8.02608 8.94281 8.27613C8.69276 8.52618 8.35362 8.66665 8 8.66665Z" fill="#121415"/></svg>
                            </span>
                            <h5 className='location-head-h4'>Royal Orchard</h5>

                        </div>
                        <div className="location-body-sum">
                        Royal Orchard, Major district 40, Manikaran Road, Bengaluru, Karnataka 560017
                        </div>
                    </div>
                    <div className='mar-spacer'/>
                    <div className="contact-details-head">
                    Contact Details*
                    </div>
                    <div className="h-1_5"/>  

                  
                    <div className='login-textbox__wrapper address-offcanvas-wrap'>
                        <div className='cart-gift__wrap'>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your name"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Your name' defaultValue="Siddharth Sharma" />
                                </FloatingLabel>
                            </div>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Mobile number"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Mobile number' defaultValue="9836644212" />
                                </FloatingLabel>
                            </div>
                        </div>
                            
                      
                    </div> 
                    <div className="bg-theme"/>
                    <div className="h-1_5"/>  
                    <div className="contact-details-head">
                    Address*
                    </div>
                    <div className="h-1_5"/>  
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Pincode"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='Pincode' defaultValue="123305" />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Address (House no, Building, Street, Area)"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='Address (House no, Building, Street, Area)' defaultValue="Address (House no, Building, Street, Area)" />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Locality"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='Locality' defaultValue="Koramangala" />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="City"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='City' defaultValue="Bengaluru" />
                        </FloatingLabel>
                    </div>
                    <div className='form-group-custom'>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="State"
                        >
                            <Form.Control type="text" className='form-control form-control-custom' placeholder='State' defaultValue="Karnataka" />
                        </FloatingLabel>
                    </div>
                    <div className="bg-theme"/>
                    <div className="h-1_5"/>  
                    <div className="contact-details-head">
                    Save Address as*
                    </div>
                    <div className="h-1_5"/>  
                    <div className='cart-address-btn__wrap mt-3'>
                        <button type='button' className='btn btn-home-address px-4'>
                            <span className='home-icon-add'>
                                <svg width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 16.6666C16.6666 16.8877 16.5788 17.0996 16.4226 17.2559C16.2663 17.4122 16.0543 17.5 15.8333 17.5H4.16665C3.94563 17.5 3.73367 17.4122 3.57739 17.2559C3.42111 17.0996 3.33331 16.8877 3.33331 16.6666V9.16664H0.833313L9.43915 1.34331C9.59257 1.20371 9.79255 1.12634 9.99998 1.12634C10.2074 1.12634 10.4074 1.20371 10.5608 1.34331L19.1666 9.16664H16.6666V16.6666ZM9.16665 10.8333V15.8333H10.8333V10.8333H9.16665Z" fill="#121415"/></svg>
                            </span>
                            Home</button>
                        <button type='button' className='btn btn-home-address ml-3 px-4'>
                            <span className='home-icon-add'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.83329 4.16671V1.66671C5.83329 1.44569 5.92109 1.23373 6.07737 1.07745C6.23365 0.921171 6.44561 0.833374 6.66663 0.833374H13.3333C13.5543 0.833374 13.7663 0.921171 13.9225 1.07745C14.0788 1.23373 14.1666 1.44569 14.1666 1.66671V4.16671H17.5C17.721 4.16671 17.9329 4.2545 18.0892 4.41079C18.2455 4.56707 18.3333 4.77903 18.3333 5.00004V16.6667C18.3333 16.8877 18.2455 17.0997 18.0892 17.256C17.9329 17.4122 17.721 17.5 17.5 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.256C1.75442 17.0997 1.66663 16.8877 1.66663 16.6667V5.00004C1.66663 4.77903 1.75442 4.56707 1.9107 4.41079C2.06698 4.2545 2.27895 4.16671 2.49996 4.16671H5.83329ZM14.1666 5.83337V10H16.6666V5.83337H14.1666ZM12.5 5.83337H7.49996V10H12.5V5.83337ZM5.83329 5.83337H3.33329V10H5.83329V5.83337ZM7.49996 2.50004V4.16671H12.5V2.50004H7.49996Z" fill="#121415"/></svg>
                            </span>
                            
                            Work</button>
                    </div>
                    <div className="h-1_5"/>  

                    <div className="bg-theme"/>
                    <div className="h-2"/>  
                    <div className="Makedefault-addres-check">
                        <div className='cart-check__input'>
                            <Form.Check 
                                type='checkbox'
                                id='default-checkbox'
                                role="button" aria-hidden="true"
                                className='d-flex align-items-start'
                            />
                        </div>
                        <div className="defaultaddress-txt">
                        Make this my default address
                        </div>

                    </div>
                    <div className="h-2"/>  
                    <button type="button" className="btn btn-dark-custom btn-dark-custom-cart w-100">Add Address</button>


                             


               
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default AddAddressCanvas