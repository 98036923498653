import { Link } from "@reach/router";
import React, { useState } from "react";
import Step1 from "../Components/DIYHeadphone/Step1";
import Step2 from "../Components/DIYHeadphone/Step2";
import Step3 from "../Components/DIYHeadphone/Step3";
import '../Components/DIY/DIY.css'

const DIYHeadphone = () => {
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        username: "",
        nationality: "",
        other: "",
    });

    const stepsTitle = ["1/3", "2/3", "3/3"];

    const PageDisplay = () => {
        if (page === 0) {
            return <Step1 formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
            return <Step2 formData={formData} setFormData={setFormData} />;
        } else {
            return <Step3 formData={formData} setFormData={setFormData} />;
        }
    };
    
    return (
        <>
            <div className="diy__container">
                <div className="container">
                    <div className="spacing-block position-relative">
                        <div className="mar-spacer h-2 mb-2" />
                        <div className="wizard-steps">
                            <div className="form">
                                <h3 className="diy__heading">DIY</h3>
                                <div className="progress-bar">
                                    <div
                                        className="progress-bar__line"
                                        style={{ width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%" }}
                                    />
                                </div>
                                <div className="form-container">
                                    <div className="header">
                                        <div className="steps-bar">Step <span>{stepsTitle[page]}</span></div>
                                    </div>
                                    <div className="body">{PageDisplay()}</div>
                                    <div className="steps-footer">                                        
                                        <button
                                            className={page === 0 ? 'btn btn-dark-custom mr-2_5 btn-diy' : 'btn btn-dark-custom-outline col-flex mr-2_5 btn-diy'}
                                            disabled={page === 0}
                                            onClick={() => {
                                                setPage((currPage) => currPage - 1);
                                            }}
                                        >
							Previous
                                        </button>
                                        <Link
                                            className='btn btn-dark-custom col-flex btn-diy'
                                            to={page === stepsTitle.length - 1 ? '/ResultList' : '' }
                                            onClick={() => {
                                                if (page === stepsTitle.length - 1) {
                                                    console.log(formData);
                                                } else {
                                                    setPage((currPage) => currPage + 1);
                                                }
                                            }}
                                        >
                                            {/* {page === stepsTitle.length - 1 ? "Submit" : "Continue"} */}
											Next
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DIYHeadphone
