import React from 'react';
import Form from 'react-bootstrap/Form'

const ColorFilter = () => {
    return (
        <>
            <div className='input__wrapper'>
                <input type='text' className='form-control form-control-cart' placeholder='Search by Color'/>
                <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0208 11.078L14.8762 13.9327L13.9328 14.876L11.0782 12.0207C10.016 12.8722 8.69483 13.3353 7.3335 13.3334C4.0215 13.3334 1.3335 10.6454 1.3335 7.33337C1.3335 4.02137 4.0215 1.33337 7.3335 1.33337C10.6455 1.33337 13.3335 4.02137 13.3335 7.33337C13.3354 8.69471 12.8723 10.0159 12.0208 11.078ZM10.6835 10.5834C11.5296 9.7133 12.0021 8.54699 12.0002 7.33337C12.0002 4.75471 9.9115 2.66671 7.3335 2.66671C4.75483 2.66671 2.66683 4.75471 2.66683 7.33337C2.66683 9.91137 4.75483 12 7.3335 12C8.54712 12.002 9.71342 11.5294 10.5835 10.6834L10.6835 10.5834Z" fill="#98A0AE"/>
                    </svg>
                </span>
            </div>
            <div className='filter-tab__heading'>
                <h4>Popular Choices</h4>
            </div>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#D6EEFF'}}></span>
                        </div>
                        <label htmlFor="label">Pastel Blue</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#000000'}}></span>
                        </div>
                        <label htmlFor="label">Black</label>
                    </div>
                    <span className='filter-tab__count'>14</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#ffffff', 'border': '1px solid #EAECF0'}}></span>
                        </div>
                        <label htmlFor="label">White</label>
                    </div>
                    <span className='filter-tab__count'>20</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#ECECEC'}}></span>
                        </div>
                        <label htmlFor="label">Grey</label>
                    </div>
                    <span className='filter-tab__count'>12</span>
                </div>
            </div>
            <div className='filter-tab__heading'>
                <h4>All Colors</h4>
            </div>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#EECCEE'}}></span>
                        </div>
                        <label htmlFor="label">Purple</label>
                    </div>
                    <span className='filter-tab__count'>15</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#606060'}}></span>
                        </div>
                        <label htmlFor="label">Charcoal</label>
                    </div>
                    <span className='filter-tab__count'>2</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#78E29F'}}></span>
                        </div>
                        <label htmlFor="label">Green</label>
                    </div>
                    <span className='filter-tab__count'>55</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#ECECCE'}}></span>
                        </div>
                        <label htmlFor="label">Beige</label>
                    </div>
                    <span className='filter-tab__count'>8</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#FFB7B7'}}></span>
                        </div>
                        <label htmlFor="label">Pink</label>
                    </div>
                    <span className='filter-tab__count'>3</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#000000'}}></span>
                        </div>
                        <label htmlFor="label">Black</label>
                    </div>
                    <span className='filter-tab__count'>10</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#03436F'}}></span>
                        </div>
                        <label htmlFor="label">Navy Blue</label>
                    </div>
                    <span className='filter-tab__count'>1</span>
                </div>
                <div className='filter-tab__list'>
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox' aria-label="option 1" id='label' />
                        <div className='filter-color__wrap'>
                            <span className='filter-color__code' style={{'background': '#000000'}}></span>
                        </div>
                        <label htmlFor="label">Black</label>
                    </div>
                    <span className='filter-tab__count'>8</span>
                </div>
            </div>
        </>
    )
}

export default ColorFilter
