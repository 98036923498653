import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BankListTab from './BankListTab';

const CartBankOfferList = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                    
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <h2 className='modal-heading mb-4'>Offers</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BankListTab />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CartBankOfferList
