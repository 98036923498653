import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import icon from '../../Images/Product/address-login.png'


const Address = (props) => {
    const [ user ] = useState(true);
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <h2 className='modal-heading m-0'>Select Delivery Address</h2>
                        <div className='search-address-wrapper'>
                            <div className='search-address'>
                                <input type="number" className='form-control form-control-cart' placeholder='Enter coupon code here'/>
                                <button type='button' className='btn btn-check-custom absolute-cart-input'>Check</button>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='address-login-state'>
                        {user ?
                            <div className='address-state'>
                                <div className='address-loggedIn-section'>
                                    <h5 className='address-loggedIn__head'>Saved Address</h5>
                                    <div className='loggedIn__wrap'>
                                        <div className='loggedIn__lists'>
                                            <div className='loggedIn__list'>
                                                <div className='loggedIn__checkbox'>
                                                    <div className='coupon-list__input mt-1'>
                                                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                                    </div>
                                                </div>
                                                <div className='loggedIn__addr'>
                                                    <h6 className='loggedIn__label mb-1'>Rohan Nanda</h6>
                                                    <p className='loggedIn__info'>103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103</p>
                                                </div>
                                                <div className='loggedIn__tag'>
                                                    <div className='btn btn-tag'>Home</div>
                                                </div>
                                            </div>
                                            <div className='loggedIn__list'>
                                                <div className='loggedIn__checkbox'>
                                                    <div className='coupon-list__input mt-1'>
                                                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                                    </div>
                                                </div>
                                                <div className='loggedIn__addr'>
                                                    <h6 className='loggedIn__label mb-1'>Rohan Nanda</h6>
                                                    <p className='loggedIn__info'>103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103</p>
                                                </div>
                                                <div className='loggedIn__tag'>
                                                    <div className='btn btn-tag'>Home</div>
                                                </div>
                                            </div>
                                            <div className='loggedIn__list'>
                                                <div className='loggedIn__checkbox'>
                                                    <div className='coupon-list__input mt-1'>
                                                        <Form.Check type="radio" name='radioCoupon' aria-label="radio 1" />
                                                    </div>
                                                </div>
                                                <div className='loggedIn__addr'>
                                                    <h6 className='loggedIn__label mb-1'>Rohan Nanda</h6>
                                                    <p className='loggedIn__info'>103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103</p>
                                                </div>
                                                <div className='loggedIn__tag'>
                                                    <div className='btn btn-tag'>Other</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className='login-state'>
                                <div className='login-icon'>
                                    <img src={icon} className='img-fluid' alt='' />
                                </div>
                                <p className='text-center'>Login to view saved addresses</p>
                                <div className='d-flex justify-content-center'>
                                    <button type='button' className='btn btn-outline btn-outline-custom btn__spacer-60'>Login Now</button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Address
