import { Link } from "@reach/router";
import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
//import Step6 from "./Step6";
import Step7 from "./Step7";

import '../Exchange.css';

const ExchangeSteps = ({ setShow }) => {
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        username: "",
        nationality: "",
        other: "",
    });
    const [ istoggle, setToggle ] = useState(false);
    const stepsTitle = ["1/6", "2/6", "3/6", "4/6", "5/6", ""];

    const PageDisplay = () => {
        if (page === 0) {
            return <Step1 formData={formData} setFormData={setFormData} istoggle={istoggle} setToggle={setToggle} />;
        } else if (page === 1) {
            return <Step2 formData={formData} setFormData={setFormData} />;
        } else if (page === 2) {
            return <Step3 formData={formData} setFormData={setFormData} />;
        } else if (page === 3) {
            return <Step4 formData={formData} setFormData={setFormData} />;
        } else if (page === 4) {
            return <Step5 formData={formData} setFormData={setFormData} />;
        } else {
            return <Step7 formData={formData} setFormData={setFormData} />;
        }
    };
    return (
        <>
            <div className="wizard-steps wizard-steps__exchange mt-0 p-0">
                <div className="form">
                    {page === stepsTitle.length - 1 ? '' : 
                        <div className="sticky-progress">
                            <div className="progress-bar">
                                <div
                                    className="progress-bar__line"
                                    style={{ width: page === 0 ? "16.66%" : page === 1 ? "33.32%" : page === 2 ? "49.99%" : page === 3 ? "66.65%" : page === 4 ? "83.31%" : page === 5 ? "100%" : "0%" }}
                                /> 
                            </div>
                            <div className="header">
                                <div className="steps-bar">Step <span>{stepsTitle[page]}</span></div>
                            </div>
                        </div>
                    }
                    <div className="form-container">
                        <div className="body">{PageDisplay()}</div>
						
                        <div className="steps-footer d-flex align-items-center justify-content-between">
                            {page === stepsTitle.length - 1 ?
                                '' :
                                <button
                                    className={page === 0 ? 'btn btn-place-order btn-dark-custom not-selected d-none' : 'btn btn-dark-custom-outline col-flex mr-2_5'}
                                    disabled={page === 0}
                                    onClick={() => {
                                        setPage((currPage) => currPage - 1);
                                    }}
                                >
								Previous
                                </button>
                            }
                            <Link
                                className={page >= 1 ? 'btn btn-place-order btn-dark-custom col-flex' : 'btn btn-place-order btn-dark-custom w-100'}
                                to={page === stepsTitle.length - 1 ? '/ProductDetails' : '' }
                                onClick={() => {
                                    if (page === stepsTitle.length - 1) {
                                        console.log(formData);
                                    } else if(istoggle) {
                                        setShow(false)
                                    } else {
                                        setPage((currPage) => currPage + 1);
                                    }
                                }}
                            >
                                {page === stepsTitle.length - 1 ? 'Confirm Exchange' :
                                    <>
                                        {!istoggle === true ? 'Next' : "Close"}
                                    </>
                                }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExchangeSteps