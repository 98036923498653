import React, { useState } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import TabContent from 'react-bootstrap/TabContent';
import BankOffer from "./BankOffer";

const OfferListTab = () => {
    const [key, setKey] = useState('creditEmi');

    return (
        <>  
            <div className='phone-offers__wrap'>
                <div className="product-details__tabs">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="d-flex justify-content-center flex-nowrap emi-tabs"
                    >
                        <Tab eventKey="creditEmi" title="Bank Offers">
                            <TabContent>
                                <div className="tab-specific px-0 mt-1 pb-0">
                                    <div className='mar-spacer h-1_5' />
                                    <BankOffer />
                                </div>
                            </TabContent>
                        </Tab>
                        <Tab eventKey="debitEmi" title="Brand Offers">
                            <TabContent>
                                <div className="tab-specific px-0 mt-1 pb-0">
                                    <div className='mar-spacer h-1_5' />
                                    <BankOffer />
                                </div>
                            </TabContent >
                        </Tab>
                        <Tab eventKey="CardlessEmi" title="EMI Offers">
                            <TabContent>
                                <div className="tab-specific px-0 mt-1 pb-0">
                                    <div className='mar-spacer h-1_5' />
                                    <BankOffer />
                                </div>
                            </TabContent>
                        </Tab>
                    </Tabs>  
                </div> 
            </div>     
        </>
    )
}

export default OfferListTab