import React from 'react';
import Carousel from 'react-multi-carousel';
import Category1 from '../../Images/Product/Category/Catg1.png';
import Category2 from '../../Images/Product/Category/Catg2.png';
import Category3 from '../../Images/Product/Category/Catg3.png';
import Category4 from '../../Images/Product/Category/Catg4.png';

const RelatedCategory = () => {
    const ProductCategory = [
        {   
            Image: Category1,
            Name: 'Headphone'
        }, {   
            Image: Category2,
            Name: 'Speakers'
        }, {   
            Image: Category3,
            Name: 'Smart Watch'
        }, {   
            Image: Category4,
            Name: 'Computer'
        }

    ];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 4,
            partialVisibilityGutter: 0
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 3,
            partialVisibilityGutter: 20
        }
    };

    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="related-category"
                responsive={responsive}
            >   
                {ProductCategory.map((ProductCategory, idx) => {
                    return (
                        <div className='product-carousel-wrapper' key={idx}>
                            <div className='carousel-media-wrapper carousel-catg'>
                                <img
                                    draggable={true}
                                    style={{ width: "100%", height: "100%" }}
                                    src={ProductCategory.Image}
                                    alt={ProductCategory.Name}
                                    className='img'
                                />
                            </div>
                            <div className='category-overlay-text'>
                                {ProductCategory.Name}
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default RelatedCategory