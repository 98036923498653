import React from 'react'
import Carousel from "react-multi-carousel";
import CategoryButtonGroup from '../Common/CategoryButtonGroup';

const KeyFeatures = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            partialVisibilityGutter: 10
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 2,
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 1,
            partialVisibilityGutter: 20
        }
    };
    return (
        <>
            <div className='key-feature-wrapper'>
                <Carousel                
                    partialVisible={true}
                    arrows={false}
                    itemClass="key-feature-slider"
                    responsive={responsive}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<CategoryButtonGroup />}
                    swipeable
                >   
                    <div className='feature__list'>
                        <div className='list-media__wrap '>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.66664 5.0013C1.66664 4.5413 2.04581 4.16797 2.49331 4.16797H17.5066C17.9633 4.16797 18.3333 4.5388 18.3333 5.0013V16.668C18.3333 17.128 17.9541 17.5013 17.5066 17.5013H2.49331C2.38425 17.5011 2.2763 17.4793 2.17567 17.4373C2.07504 17.3953 1.9837 17.3338 1.90689 17.2563C1.83008 17.1789 1.76932 17.0871 1.7281 16.9861C1.68687 16.8851 1.66598 16.777 1.66664 16.668V5.0013ZM3.33331 5.83464V15.8346H16.6666V5.83464H3.33331ZM11.6666 13.3346C12.3297 13.3346 12.9656 13.0712 13.4344 12.6024C13.9033 12.1336 14.1666 11.4977 14.1666 10.8346C14.1666 10.1716 13.9033 9.53571 13.4344 9.06687C12.9656 8.59803 12.3297 8.33464 11.6666 8.33464C11.0036 8.33464 10.3677 8.59803 9.89888 9.06687C9.43003 9.53571 9.16664 10.1716 9.16664 10.8346C9.16664 11.4977 9.43003 12.1336 9.89888 12.6024C10.3677 13.0712 11.0036 13.3346 11.6666 13.3346ZM11.6666 15.0013C10.5616 15.0013 9.50177 14.5623 8.72036 13.7809C7.93896 12.9995 7.49998 11.9397 7.49998 10.8346C7.49998 9.72957 7.93896 8.66976 8.72036 7.88836C9.50177 7.10696 10.5616 6.66797 11.6666 6.66797C12.7717 6.66797 13.8315 7.10696 14.6129 7.88836C15.3943 8.66976 15.8333 9.72957 15.8333 10.8346C15.8333 11.9397 15.3943 12.9995 14.6129 13.7809C13.8315 14.5623 12.7717 15.0013 11.6666 15.0013ZM3.33331 1.66797H8.33331V3.33464H3.33331V1.66797Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span className="feature__text ml-2">23 Mp Triple Camera</span>
                        </div>
                        <div className='list-text__wrap'>Photography</div>
                    </div>
                    <div className='feature__list'>
                        <div className='list-media__wrap '>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.83329 3.33464V16.668H14.1666V3.33464H5.83329ZM4.99996 1.66797H15C15.221 1.66797 15.4329 1.75577 15.5892 1.91205C15.7455 2.06833 15.8333 2.28029 15.8333 2.5013V17.5013C15.8333 17.7223 15.7455 17.9343 15.5892 18.0906C15.4329 18.2468 15.221 18.3346 15 18.3346H4.99996C4.77895 18.3346 4.56698 18.2468 4.4107 18.0906C4.25442 17.9343 4.16663 17.7223 4.16663 17.5013V2.5013C4.16663 2.28029 4.25442 2.06833 4.4107 1.91205C4.56698 1.75577 4.77895 1.66797 4.99996 1.66797ZM9.99996 14.168C10.221 14.168 10.4329 14.2558 10.5892 14.412C10.7455 14.5683 10.8333 14.7803 10.8333 15.0013C10.8333 15.2223 10.7455 15.4343 10.5892 15.5906C10.4329 15.7468 10.221 15.8346 9.99996 15.8346C9.77895 15.8346 9.56698 15.7468 9.4107 15.5906C9.25442 15.4343 9.16663 15.2223 9.16663 15.0013C9.16663 14.7803 9.25442 14.5683 9.4107 14.412C9.56698 14.2558 9.77895 14.168 9.99996 14.168Z" fill="#121415"/>
                                </svg>
                            </span>
                            <span className="feature__text ml-2">3k AMOLED Display</span>
                        </div>
                        <div className='list-text__wrap'>Clarity of Display, Panda Glass</div>
                    </div>
                    <div className='feature__list'>
                        <div className='list-media__wrap '>
                            <span>
                                <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.58268 7.0013H9.33268L6.41602 11.0846V8.16797H4.66602L7.58268 4.08464V7.0013ZM6.41602 3.5013H4.08268V11.668H9.91602V3.5013H7.58268V2.33464H6.41602V3.5013ZM5.24935 2.33464V1.7513C5.24935 1.59659 5.31081 1.44822 5.4202 1.33882C5.5296 1.22943 5.67797 1.16797 5.83268 1.16797H8.16602C8.32073 1.16797 8.4691 1.22943 8.5785 1.33882C8.68789 1.44822 8.74935 1.59659 8.74935 1.7513V2.33464H10.4994C10.6541 2.33464 10.8024 2.39609 10.9118 2.50549C11.0212 2.61489 11.0827 2.76326 11.0827 2.91797V12.2513C11.0827 12.406 11.0212 12.5544 10.9118 12.6638C10.8024 12.7732 10.6541 12.8346 10.4994 12.8346H3.49935C3.34464 12.8346 3.19627 12.7732 3.08687 12.6638C2.97747 12.5544 2.91602 12.406 2.91602 12.2513V2.91797C2.91602 2.76326 2.97747 2.61489 3.08687 2.50549C3.19627 2.39609 3.34464 2.33464 3.49935 2.33464H5.24935Z" fill="#121415"></path>
                                </svg>
                            </span>
                            <span className="feature__text ml-2">7000 mAh</span>
                        </div>
                        <div className='list-text__wrap'>Long Battery Life, Warp Charge</div>
                    </div>
                    <div className='feature__list'>
                        <div className='list-media__wrap '>
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.50065 4.56764V11.668H10.5007V2.33464H5.73365L3.50065 4.56764ZM2.5049 3.9143L5.25065 1.16797H11.084C11.2387 1.16797 11.3871 1.22943 11.4965 1.33882C11.6059 1.44822 11.6673 1.59659 11.6673 1.7513V12.2513C11.6673 12.406 11.6059 12.5544 11.4965 12.6638C11.3871 12.7732 11.2387 12.8346 11.084 12.8346H2.91732C2.76261 12.8346 2.61424 12.7732 2.50484 12.6638C2.39544 12.5544 2.33398 12.406 2.33398 12.2513V4.32614C2.33402 4.17144 2.3955 4.02309 2.5049 3.91372V3.9143ZM8.75065 2.91797H9.91732V5.2513H8.75065V2.91797ZM7.00065 2.91797H8.16732V5.2513H7.00065V2.91797ZM5.25065 3.5013H6.41732V5.2513H5.25065V3.5013Z" fill="#121415"></path>
                                </svg>
                            </span>
                            <span className="feature__text ml-2">Upto 512GB</span>
                        </div>
                        <div className='list-text__wrap'>Photography</div>
                    </div>
                </Carousel>
            </div>
        </>
    )
}

export default KeyFeatures