import { Link } from '@reach/router';
import React, { useState } from 'react';
import { HeadphoneListData } from '../../Data';

const HeadphoneProductLists = () => {
    const [isWishlist] = useState(true);
    return (
        <>
            <div className='search-rightContainer column-base'>
                {/* Product List for Watch */}
                <div className='product-wrapper'>
                    {HeadphoneListData.map(data => (
                        <div className='product-list' key={data._id}>
                            {data.label ?
                                <div className='product-label-tag'>{data.label}</div> : ''}
                            <div className='product__icons'>
                                <button type='button' className='product-share'>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.0762 17.271L8.9662 14.484C8.47569 14.9709 7.85201 15.3017 7.17379 15.4346C6.49557 15.5676 5.79316 15.4968 5.15513 15.2311C4.51709 14.9654 3.97199 14.5168 3.58855 13.9418C3.20511 13.3668 3.00049 12.6911 3.00049 12C3.00049 11.3089 3.20511 10.6332 3.58855 10.0582C3.97199 9.48317 4.51709 9.03455 5.15513 8.76889C5.79316 8.50322 6.49557 8.43241 7.17379 8.56536C7.85201 8.69832 8.47569 9.02909 8.9662 9.51599L14.0762 6.72899C13.9009 5.90672 14.0275 5.04885 14.4328 4.31224C14.8381 3.57563 15.4949 3.00952 16.2833 2.71742C17.0717 2.42532 17.9388 2.42676 18.7262 2.72147C19.5136 3.01619 20.1686 3.58448 20.5714 4.32243C20.9742 5.06037 21.098 5.91866 20.92 6.74034C20.742 7.56202 20.2742 8.29218 19.6021 8.79729C18.93 9.3024 18.0986 9.54871 17.2598 9.49119C16.4211 9.43366 15.631 9.07613 15.0342 8.48399L9.9242 11.271C10.0261 11.7516 10.0261 12.2483 9.9242 12.729L15.0342 15.516C15.631 14.9238 16.4211 14.5663 17.2598 14.5088C18.0986 14.4513 18.93 14.6976 19.6021 15.2027C20.2742 15.7078 20.742 16.438 20.92 17.2596C21.098 18.0813 20.9742 18.9396 20.5714 19.6776C20.1686 20.4155 19.5136 20.9838 18.7262 21.2785C17.9388 21.5732 17.0717 21.5747 16.2833 21.2826C15.4949 20.9905 14.8381 20.4243 14.4328 19.6877C14.0275 18.9511 13.9009 18.0933 14.0762 17.271Z" fill="#121415"/>
                                    </svg>
                                </button>
                                <button type='button' className='product-wishlist'>
                                    {isWishlist ?
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.6792 12.794L21.6922 12.808L12.5002 22L3.30821 12.808L3.32121 12.794C2.25378 11.5495 1.69605 9.94767 1.7598 8.30938C1.82355 6.67108 2.50406 5.11739 3.66497 3.95965C4.82587 2.80191 6.38141 2.12564 8.01987 2.06636C9.65834 2.00708 11.2587 2.56917 12.5002 3.63999C13.7417 2.56917 15.3421 2.00708 16.9806 2.06636C18.619 2.12564 20.1746 2.80191 21.3355 3.95965C22.4964 5.11739 23.1769 6.67108 23.2406 8.30938C23.3044 9.94767 22.7466 11.5495 21.6792 12.794ZM5.07521 5.38299C4.65734 5.80086 4.32587 6.29694 4.09972 6.8429C3.87357 7.38887 3.75717 7.97404 3.75717 8.56499C3.75717 9.15594 3.87357 9.74111 4.09972 10.2871C4.32587 10.833 4.65734 11.3291 5.07521 11.747L12.5002 19.172L19.9252 11.747C20.7691 10.9031 21.2432 9.75847 21.2432 8.56499C21.2432 7.37151 20.7691 6.22691 19.9252 5.38299C19.0813 4.53907 17.9367 4.06496 16.7432 4.06496C15.5497 4.06496 14.4051 4.53907 13.5612 5.38299L9.31821 9.62599L7.90421 8.20999L11.0662 5.04799C10.2014 4.35733 9.11246 4.00957 8.00743 4.07118C6.90241 4.1328 5.85885 4.59947 5.07621 5.38199L5.07521 5.38299Z" fill="#121415"/>
                                        </svg> :
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.8685 3.96415C18.7535 5.85415 18.8185 8.86415 17.0652 10.8275L9.99852 17.9042L2.93352 10.8275C1.18019 8.86415 1.24602 5.84915 3.13019 3.96415C5.01769 2.07748 8.03685 2.01415 10.0002 3.77415C11.9577 2.01665 14.9827 2.07498 16.8685 3.96415ZM4.30935 5.14332C3.06769 6.38498 3.00519 8.37248 4.14935 9.68582L9.99935 15.545L15.8494 9.68665C16.9944 8.37248 16.9319 6.38748 15.6885 5.14165C14.4494 3.89998 12.4544 3.83998 11.1444 4.98665L7.64269 8.48915L6.46352 7.31082L8.81769 4.95498L8.74935 4.89748C7.43685 3.84332 5.51852 3.93332 4.30935 5.14332Z" fill="#121415" />
                                        </svg>}
                                </button>
                            </div>
                            <div className='product-thumb'>
                                <img src={data.Image} className='img-fluid' alt={data.productName} />
                            </div>
                            <Link className='product-details' to='/ProductDetails'>
                                <h2>{data.productName}</h2>
                                {/* {data.productStorage.productColor ||  data.productStorage.ram || data.productStorage.ram ? */}
                                <p className='product-muted'>
                                    {data.productColor ?
                                        <>{data.productColor} {' '}</> : null
                                    }
                                    {data.Storage ?
                                        <>, {data.Storage} + {' '}</> : null
                                    }
                                    {data.ram ?
                                        <>{data.ram}</> : null
                                    }
                                </p>
                                
                                {data.productNewPrice || data.productOldPrice || data.productOffer ?
                                    <div className='product-price'>
                                        {data.productNewPrice ?
                                            <div className='new-price'>₹{data.productNewPrice}</div> : null
                                        }
                                        {data.productNewPrice ?
                                            <div className='old-price ml-1'>₹{data.productOldPrice}</div> : null
                                        }
                                        {data.productNewPrice ?
                                            <div className='price-discount'>{data.productOffer}</div> : null
                                        }
                                    </div> : null
                                }
                                <div className='product-offer'>
                                    {data.BrandOfferList.List1.length > 0 ?
                                        <div className='product-offer-list'>
                                            <div className='offer-icon'>
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.9994 2.5H10.6427L2.16602 10.9767L9.52268 18.3333L17.9994 9.85667V2.5ZM15.0827 4.16667C15.7727 4.16667 16.3327 4.72667 16.3327 5.41667C16.3327 6.10667 15.7727 6.66667 15.0827 6.66667C14.3927 6.66667 13.8327 6.10667 13.8327 5.41667C13.8327 4.72667 14.3927 4.16667 15.0827 4.16667Z" fill="#121415"/>
                                                </svg>
                                            </div>
                                            <div className='offer-label'>{data.BrandOfferList.List1}</div>
                                        </div> : null
                                    }	
                                    {data.BrandOfferList.List2.length > 0 ?
                                        <div className='product-offer-list'>
                                            <div className='offer-icon'>
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_444_12766)">
                                                        <path d="M12.9158 7.64296L18.7495 7.64296L18.7495 13.4766L17.0831 13.4766L17.0825 10.4873L9.32138 18.2496L8.14287 17.071L15.9045 9.30937L12.9158 9.30937L12.9158 7.64296ZM2.25031 6.52337L3.91673 6.52337L3.91673 9.51207L11.6784 1.7504L12.8569 2.92891L5.09524 10.6906L8.08394 10.6906L8.08394 12.357L2.25031 12.357L2.25031 6.52337Z" fill="#121415"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_444_12766">
                                                            <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                            <div className='offer-label'>{data.BrandOfferList.List2}</div>
                                        </div> : null
                                    }
                                </div>
                                {data.CompareLink.length > 0 ?
                                    <div className='product-compare'>+ Compare</div> : null
                                }
                            </Link>
                        </div>
                    ))}
                </div>
                <div className='mar-spacer h-1' />
                <div className='d-flex align-items-center justify-content-center'>
                    <Link to='/ProductList' className='btn btn-dark-custom-outline btn__spacer-60'>Show 24 More</Link>
                    <Link to='/ProductList' className='btn btn-dark-custom btn__spacer-60 ml-3'>Show All (2,129)</Link>
                </div>
            </div>
        </>
    )
}

export default HeadphoneProductLists