import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Link } from '@reach/router';
import loginlogo from '../../Images/logo-icon.png';


const ReqCallback = () => {
    const [modalShow, setModalShow] = useState(false);
    const [ callback, setCallback ] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const [number, setNumber] = useState();
    
    return (
        <>
            <div className={`store-btn__info ${callback ? '' : 'callback'}`} onClick={() => setModalShow(true)} role='button' aria-hidden='true'>Request Callback</div>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100' />
                </Modal.Header>
                <Modal.Body>
                    <div className='Login-wrapper' style={{ display: callback ? "block" : "none" }}>
                        <div className="Login-logo mb-4">
                            <img src={loginlogo} alt="" className='first_logo' />
                        </div>
                        <div className='login-textbox__wrapper' style={{ display: callback ? 'block' : 'none' }}>
                            <h5 className='login-heading mb-4'>Welcome to Sangeetha Mobiles</h5>
                            <p className='Login-info mb-4'>Please enter your mobile number</p>
                            <div className='loginIn_wrap'>
                                <div className='cart-address__wrap'>
                                    <input  type='number' className='form-control form-control-cart' placeholder='Mobile Number'  maxLength="10" />
                                    <span className='absolute-login-input'>+91</span> 
                                </div>
                                <span className='login-error'>Please enter a valid mobile number (10 digits)</span>
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className="login-footer">
                                <div className="footer-links mb-0 login-footerlink">
                                    <span className='fontcolor-light'>By continuing,</span>{' '}
                                    <span className='fontcolor-light'>I agree to the{' '}
                                        <Link to='/' className='Linkpolicy'>Privacy Policy</Link>  
                                    </span>
                                </div>
                                <div className="footer-links login-footerlink"> 
                                    <span className='fontcolor-light'>and{' '}
                                        <Link to='/' className='Linkpolicy'>Terms & Conditions</Link>
                                    </span>
                                </div>
                            </div>
                            <div className="continue-login_btn mt-4">
                                <button type='button' className='btn btn-dark-custom w-100'  onClick={() => setCallback(false)}>Login</button>
                            </div>  
                        </div>                  
                    </div>
                    <div className='requested-callback py-1' style={{ display: callback ? "none" : "block" }}>
                        <div className='callback_media'>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40 19.9995C40 21.9781 36.4551 23.3721 35.8755 25.1575C35.2742 27.0081 37.302 30.2156 36.1807 31.7553C35.0489 33.3111 31.3644 32.3681 29.8086 33.5005C28.2683 34.6212 28.0331 38.4223 26.1832 39.0212C24.3978 39.6009 21.9809 36.6826 20 36.6826C18.019 36.6826 15.6043 39.6009 13.8167 39.0212C11.9662 38.4199 11.731 34.619 10.1907 33.5005C8.63549 32.3687 4.95097 33.3117 3.81862 31.7559C2.69793 30.2156 4.72512 27.0081 4.12444 25.1581C3.54488 23.3727 0 21.9787 0 20.0001C0 18.0215 3.54488 16.6275 4.12456 14.8421C4.72524 12.9921 2.69804 9.78451 3.81874 8.24432C4.95109 6.68855 8.6356 7.63152 10.1914 6.49975C11.7311 5.37905 11.9663 1.5774 13.8168 0.979024C15.6022 0.399347 18.0191 3.31758 20.0001 3.31758C21.9809 3.31758 24.3957 0.399347 26.1833 0.979024C28.0334 1.5797 28.269 5.38125 29.8088 6.49975C31.3645 7.63209 35.049 6.68913 36.1814 8.24432C37.3021 9.78462 35.2743 12.9922 35.8756 14.8421C36.4551 16.6268 40 18.0208 40 19.9995Z" fill="#278C4C"/>
                                <path d="M18.2533 26.7995C17.6676 26.8003 17.1057 26.5677 16.692 26.153L11.7686 21.2296C10.906 20.3673 10.9058 18.9691 11.768 18.1066C12.6303 17.244 14.0285 17.2438 14.8911 18.106L18.2532 21.4676L25.1127 14.6086C25.9751 13.7461 27.3735 13.746 28.236 14.6084C29.0985 15.4708 29.0987 16.8692 28.2362 17.7317L19.8149 26.153C19.4012 26.5678 18.8391 26.8004 18.2533 26.7995Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='callback-head'>Callback Requested</div>
                        <div className='callback-info'>We will call you shortly on 9825577834 in next 5 minutes</div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReqCallback;
