import React from 'react'

const CategoryButtonGroup = ({ next, previous }) => {
    return (
        <>
            <div className="carousel-button-group carousel-group-btn">
                <button className='arrow-btn prev-btn' onClick={() => previous()}>   
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.02329 10.0001L13.1483 14.1251L11.97 15.3034L6.66663 10.0001L11.97 4.69678L13.1483 5.87511L9.02329 10.0001Z" fill="#121415"/>
                    </svg>						
                </button>
                <button className='arrow-btn nxt-btn' onClick={() => next()}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9766 9.99989L6.85159 5.87489L8.02992 4.69656L13.3333 9.99989L8.02992 15.3032L6.85159 14.1249L10.9766 9.99989Z" fill="#121415"/>
                    </svg>
                </button>
            </div>
        </>
    )
}

export default CategoryButtonGroup