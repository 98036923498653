import React from 'react';
import hdfc from '../../../Images/product/emi/hdfc.png';

const CartOfferAdded = () => {
    return (
        <>
            <div className='cart-coupon cart__offerApplied'>
                <div className='cart-coupon__head d-flex align-items-center justify-content-between'>
                    <div className='coupon-tag d-flex align-items-center'>
                        <span className='d-flex'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.75195 2L7.05664 5.19531L3.49414 5.82031L4 9.40039L1.48438 12L4 14.5996L3.49219 18.1797L7.05469 18.8047L8.75195 22L12 20.4121L15.248 22L16.9434 18.8047L20.5059 18.1797L20 14.5996L22.5156 12L20 9.40039L20.5078 5.82031L16.9453 5.19531L15.248 2L12 3.58789L8.75195 2ZM9 7C10.105 7 11 7.895 11 9C11 10.105 10.105 11 9 11C7.895 11 7 10.105 7 9C7 7.895 7.895 7 9 7ZM14.4004 7.55078L15.5996 8.44922L9.59961 16.4492L8.40039 15.5508L14.4004 7.55078ZM9 8C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10C9.26522 10 9.51957 9.89464 9.70711 9.70711C9.89464 9.51957 10 9.26522 10 9C10 8.73478 9.89464 8.48043 9.70711 8.29289C9.51957 8.10536 9.26522 8 9 8ZM15 13C16.105 13 17 13.895 17 15C17 16.105 16.105 17 15 17C13.895 17 13 16.105 13 15C13 13.895 13.895 13 15 13ZM15 14C14.7348 14 14.4804 14.1054 14.2929 14.2929C14.1054 14.4804 14 14.7348 14 15C14 15.2652 14.1054 15.5196 14.2929 15.7071C14.4804 15.8946 14.7348 16 15 16C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15C16 14.7348 15.8946 14.4804 15.7071 14.2929C15.5196 14.1054 15.2652 14 15 14Z" fill="#121415"/>
                            </svg>
                        </span>
                        <span className='ml-2'>Bank Offers</span>
                    </div>
                    <span className='coupon-link d-flex align-items-center'>
                        <span>View All Offers</span>
                        <span className='d-flex'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.3332 5L7.1582 6.175L10.9749 10L7.1582 13.825L8.3332 15L13.3332 10L8.3332 5Z" fill="#121415"/>
                            </svg>
                        </span>
                    </span>
                </div>
                <div className='mt-2'>
                    <div className='shopping-cart__vas m-0'>
                        <div className='shopping-vas__info'>
                            <div className='shopping-vas__media'>
                                <img src={hdfc} className='img img-fluid' alt='' />
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='shopping-vas__head'>HDFC Credit and Debit Cards</div>
                                <div className='shopping-vas__label offer-applied'>Saving ₹2,999</div>
                            </div>
                        </div>
                        <div className='shopping-vas__close'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z" fill="#98A0AE"/>
                                <path d="M6.00036 5.29336L8.47536 2.81836L9.18236 3.52536L6.70736 6.00036L9.18236 8.47536L8.47536 9.18236L6.00036 6.70736L3.52536 9.18236L2.81836 8.47536L5.29336 6.00036L2.81836 3.52536L3.52536 2.81836L6.00036 5.29336Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CartOfferAdded