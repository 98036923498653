import React, { useState } from 'react';
//import MyCouponDetails from '../../../Account/MyCouponDetails';

import Banner2 from '../../../Images/Product/Exchange/banner2.png';
import coupon1 from '../../../Images/Product/Exchange/coupon1.png';

const Step5 = () => { 
    const [ inputState ] = useState(true);
    
    return (
        <>
            <div className="exchange-data- all-brands">
                <div className='px-3-'>
                    <h3 className="Exchange-head">Verify your phone&apos;s IMEI number</h3>
                    <div className={`input-group-address border-0 ${inputState === true ? 'valid-state' : inputState === false ? 'error-state' : null}`}>
                        {/* error-state  */}
                        <div className="cart-address__wrap pb-2 exchange-details">
                            <input
                                type="text"
                                className="form-control form-control-exchange"
                                placeholder="Enter IMEI number"
                            />
                            {inputState ? 
                                <div className='edit__coupon-verified d-flex'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0003 18.3334C5.39783 18.3334 1.66699 14.6025 1.66699 10C1.66699 5.39752 5.39783 1.66669 10.0003 1.66669C14.6028 1.66669 18.3337 5.39752 18.3337 10C18.3337 14.6025 14.6028 18.3334 10.0003 18.3334ZM9.16949 13.3334L15.0612 7.44085L13.8828 6.26252L9.16949 10.9767L6.81199 8.61919L5.63366 9.79752L9.16949 13.3334Z" fill="#34B171"/>
                                    </svg>
                                </div> :
                                <div className='edit__coupon'>Verify</div>
                            }
                        </div>
                        <span className="">
                            {inputState ? 
                                <>
                                    <div className='imei-info px-3'>Dial *#06# to check your 15 digit IMEI number. Incase of 2 IMEIs enter the first IMEI number(IMEI1).</div>
                                    <div className='mar-spacer h-2 mb-2' />
                                </> : 
                                inputState === false ?
                                    (<label className="exchange-error px-3 mt-2" htmlFor="text">Please enter valid IMEI & verify</label>) : null
                            }
                        </span>
                    </div>
                    {inputState ?
                        (<div className='d-flex flex-column align-items-start'>

                            <div className='coupon-card__wrapper'>
                                <img src={Banner2} className='img-fluid w-100 coupon-img' alt='' />
                                <div className='coupon-card__bg'>
                                    <div className='coupon-card__content'>
                                        <h5>Yay! ₹3000 Off on Sangeetha <br/>Exchange Bonus</h5>
                                        <hr className='hr-coupon' />
                                        <div className='coupon-card__info'>VALIDITY: only on current purchase</div>
                                        <button type='button' className='btn coupon-btn btn-dark-custom'>Learn More</button>
                                    </div>
                                    <div className='coupon-card__media'>
                                        <img src={coupon1} className='img img-fluid' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>) : null
                    }
                </div>
            </div>
            <div className='mar-spacer h-2 mb-2' />
            <div className='px-3-'>
                <div className='exchange-bg__storage'>
                    <div className='font-500 mb-2'>Why do I need to enter my IMEI number?</div>
                    <p className='mb-0'>IMEI number helps us to verify your details on your current phone’s purchase history. It also helps us to detect if this device was reported as stolen.</p>
                </div>
            </div>
            <div className='mar-spacer h-2 mb-2' />
        </>
    )
}

export default Step5