import React, { useState } from 'react';
import HomeGallery from '../Components/Home/HomeGallery'
import ShopByCatg from '../Components/Home/ShopByCatg';
import WhySangeetha from '../Components/Home/WhySangeetha';
import Exclusive from '../Components/Home/Exclusive';
import TopBrands from '../Components/Home/TopBrands';
import MobileOffer from '../Components/Home/MobileOffer';
import PhoneAccessories from '../Components/Home/PhoneAccessories';
import SmartGadgets from '../Components/Home/SmartGadgets';
import HottestDeals from '../Components/Home/HottestDeals';
import Sale from '../Components/Home/Sale';
import HomeDIY from '../Components/Home/HomeDIY';
// import Loader from '../Components/Loader'

import offerBanner from '../Images/Home/offerBanner.jpg';
import { Link } from '@reach/router';

const Home = () => {
    //const [loading, setLoading] = useState(true);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    };
    return (
        <>
            {/* <Loader />  */}
            <div className='home-banner-container'>
                <div className='home-gallery'>
                    <HomeGallery   />
                </div>
            </div>
            <section className="category-wrapper">
                <div className='container'>
                    <div className='spacing-block'>
                        <ShopByCatg  />
                    </div>
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <WhySangeetha  />
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <div className='top-head__wrap'>
                        <h4 className="heading__wrap mb-0">Exclusive at Sangeetha</h4>
                    </div>
                    <Exclusive />
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <TopBrands />
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <div className='top-head__wrap'>
                            <h4 className="heading__wrap">Best Offers on Phones</h4>
                        </div>
                        <MobileOffer />
                    </div>
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <Link to='/ProductDetails' className='offer-banner'>
                        <img src={offerBanner} className='img-fluid w-100' alt='' />
                    </Link>
                </div>
            </section>
            <section className="category-wrapper pt-0">
                <div className='container'>
                    <div className='spacing-block'>
                        <div className='top-head__wrap'>
                            <h4 className="heading__wrap mb-0">Phone Accessories</h4>
                        </div>
                        <PhoneAccessories  />
                    </div>
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <div className='top-head__wrap'>
                        <h4 className="heading__wrap mb-0">Smart Living for you</h4>
                    </div>
                    <SmartGadgets />
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <div className='top-head__wrap'>
                            <h4 className="brand-heading">🔥Hottest Deals</h4>
                        </div>
                        <HottestDeals />
                    </div>
                </div>
            </section>
            <section className="section__wrapper padding__wrapper">
                <div className='container'>
                    <div className='spacing-block position-relative'>
                        <div className='top-head__wrap'>
                            <h4 className="heading__wrap">Work From Home Effectively</h4>
                        </div>
                        <Sale wishlistActive={wishlistActive} handleToggle={handleToggle} />
                    </div>
                </div>
            </section>
            <section className="diy-home__wrapper">
                <div className='container'>
                    <h3 className='suggestion-heading'>Didn’t find what you are looking for?</h3>
                    <p className='suggestion-info'>Let us help you</p>
                    <HomeDIY />
                </div>
            </section>
        </>
    )
}

export default Home