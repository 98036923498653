import React from 'react'
import { Link } from "@reach/router";
import catg1 from '../../Images/Home/Category/1.png';
import catg2 from '../../Images/Home/Category/2.png';
import catg3 from '../../Images/Home/Category/3.png';
import catg4 from '../../Images/Home/Category/4.png';
import catg5 from '../../Images/Home/Category/5.png';
import catg6 from '../../Images/Home/Category/6.png';
import catg7 from '../../Images/Home/Category/7.png';
import catg8 from '../../Images/Home/Category/8.png';

const ShopByCatg = () => { 

    return (
        <>
            <div className="category__wrap category__grid">  
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg1} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>20% Sale</span>
                        </div>
                    </Link>
                </div>              
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg2} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Phone</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg3} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Laptops</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg4} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Earphone</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg5} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Watches</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg6} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Accessories</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg7} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Cameras</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/Categories' className="catg-item-wrapper">
                        <div className="catg-img-wrapper">
                            <img src={catg8} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>TVs</span>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ShopByCatg;
