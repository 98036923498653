import React, { useState } from 'react';
import CartOffers from '../Components/Cart/CartOffers';
import CartOfferAdded from '../Components/Cart/CartOfferAdded';
import PaymentMethods from '../Components/Payment/PaymentMethods';
import PaymentAccordion from '../Components/Payment/PaymentAccordion';
import GiftCart from '../Components/Payment/GiftCart';

import '../Components/Payment/Payment.css';

const Payment = () => {
    const [ isOfferApplied ] = useState(false);
    const [ isGiftApplied ] = useState(false); 
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <div className='cart__wrapper'>
                <div className='container'>
                    <div className='cart-steps'>
                        <ul className='form-steps'>
                            <li className='steps active-page valid'>
                                <span>Cart</span>
                            </li>
                            <li className='steps active-page valid'>
                                <span>Address</span>
                            </li>
                            <li className='steps active-page'>
                                <span>Payment</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='step_cart'>
                    <div className='container'>
                        <div className='cart-head__wrap d-flex align-items-center justify-content-between'>
                            <h3 className='cart-page_heading mb-0'>Payment</h3>
                        </div>
                    </div>
                </div>

                <div className='content_cart'>
                    <div className='container'>
                        <div className='h-1_2 mar-spacer' />
                        <div className='h-1_2 mar-spacer' />
                        <div className='cart-bank__offers spacing-block'>
                            <div className='p-0'>
                                {isOfferApplied ?
                                    <div className='p-x-16'>
                                        <CartOfferAdded /> 
                                    </div>: 
                                    <div className='ml--16'>
                                        <CartOffers />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='h-1_2 mar-spacer' />
                        <div className='h-1_2 mar-spacer' />

                        <div className='payment-container__wrap spacing-block'>
                            <div className='payment-items__wrap'>
                                <div className='cart-heading__wrapper border-bottom-0'>
                                    <h4 className='d-flex align-items-center mb-4'>
                                        <span>Choose Payment Method</span>
                                    </h4>
                                </div>
                                <div className='h-1_2 mar-spacer' />
                                <div className='h-1_2 mar-spacer' />
                                <div className='cart-payment__wrapper'>
                                    <p className='cart-payment__heading mb-0'>Saved Payment Methods</p>
                                    <div className='cart-payment__wrap'>
                                        <PaymentMethods />
                                    </div>
                                    <div className='h-2 mb-2 mar-spacer' />
                                    <div className='bg-theme' />
                                    <div className='h-2 mb-2 mar-spacer' />
                                    <p className='cart-payment__heading mb-0'>More Ways to Pay</p>
                                    <div className='cart-payment__wrap'>
                                        <PaymentAccordion />
                                    </div>
                                    <div className='h-2 mb-2 mar-spacer' />
                                    <div className='bg-theme' />
                                    <div className='h-2 mb-2 mar-spacer' />
                                    <div className='gift-payment__card'>
                                        {isGiftApplied ?
                                            (<div className='gift-payment__wrap'>
                                                <div className='gift-payment__content'>
                                                    <span className='gift-payment__media d-flex'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.5 1.99952C15.0906 1.99931 15.6717 2.14859 16.1892 2.43345C16.7066 2.7183 17.1435 3.12946 17.4592 3.62865C17.7749 4.12783 17.9592 4.6988 17.9948 5.28837C18.0305 5.87794 17.9163 6.46694 17.663 7.00052L21 6.99952C21.2652 6.99952 21.5196 7.10487 21.7071 7.29241C21.8946 7.47995 22 7.7343 22 7.99952V11.9995C22 12.2647 21.8946 12.5191 21.7071 12.7066C21.5196 12.8942 21.2652 12.9995 21 12.9995H20V20.9995C20 21.2647 19.8946 21.5191 19.7071 21.7066C19.5196 21.8942 19.2652 21.9995 19 21.9995H5C4.73478 21.9995 4.48043 21.8942 4.29289 21.7066C4.10536 21.5191 4 21.2647 4 20.9995V12.9995H3C2.73478 12.9995 2.48043 12.8942 2.29289 12.7066C2.10536 12.5191 2 12.2647 2 11.9995V7.99952C2 7.7343 2.10536 7.47995 2.29289 7.29241C2.48043 7.10487 2.73478 6.99952 3 6.99952L6.337 7.00052C5.98076 6.25219 5.90163 5.40172 6.1137 4.60052C6.32577 3.79931 6.81529 3.09936 7.4951 2.62527C8.17491 2.15118 9.0009 1.93371 9.82603 2.01158C10.6512 2.08946 11.4219 2.45761 12.001 3.05052C12.326 2.71704 12.7147 2.45219 13.1439 2.27166C13.5732 2.09113 14.0343 1.99859 14.5 1.99952ZM18 12.9995H6V19.9995H18V12.9995ZM20 8.99952H4V10.9995H20V8.99952ZM9.5 3.99952C9.11478 3.99971 8.74441 4.14809 8.46561 4.41392C8.18682 4.67976 8.02099 5.04266 8.00248 5.42743C7.98396 5.8122 8.11419 6.18935 8.36618 6.48072C8.61816 6.77209 8.97258 6.95534 9.356 6.99252L9.5 6.99952H11V5.49952C11 5.12663 10.8611 4.76712 10.6104 4.4911C10.3597 4.21507 10.0152 4.04231 9.644 4.00652L9.5 3.99952ZM14.5 3.99952L14.356 4.00652C14.0094 4.03972 13.6851 4.19249 13.4388 4.43863C13.1925 4.68476 13.0395 5.00892 13.006 5.35552L13 5.49952V6.99952H14.5L14.644 6.99252C15.015 6.95654 15.3594 6.78371 15.6099 6.5077C15.8605 6.23169 15.9993 5.87228 15.9993 5.49952C15.9993 5.12675 15.8605 4.76734 15.6099 4.49133C15.3594 4.21532 15.015 4.04249 14.644 4.00652L14.5 3.99952Z" fill="#121415"/>
                                                        </svg>
                                                    </span> 
                                                    <span className='gift-payment__heading ml-1_5'>Gift Card Applied</span>
                                                    <span>

                                                    </span>
                                                </div>
                                                <button type='button' className='text-dark btn m-0 p-0 btn-link d-flex align-items-center'>
                                                    <span className='d-flex'>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.99992 14.6673C4.31792 14.6673 1.33325 11.6827 1.33325 8.00065C1.33325 4.31865 4.31792 1.33398 7.99992 1.33398C11.6819 1.33398 14.6666 4.31865 14.6666 8.00065C14.6666 11.6827 11.6819 14.6673 7.99992 14.6673ZM7.33525 10.6673L12.0486 5.95332L11.1059 5.01065L7.33525 8.78198L5.44925 6.89598L4.50659 7.83865L7.33525 10.6673Z" fill="#278C4C"/>
                                                        </svg>
                                                    </span>
                                                    <span className='ml-1_5'>Remove</span>
                                                </button>
                                            </div>) :
                                            (<div className='gift-payment__wrap'>
                                                <div className='gift-payment__content'>
                                                    <span className='gift-payment__media d-flex'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.5 1.99952C15.0906 1.99931 15.6717 2.14859 16.1892 2.43345C16.7066 2.7183 17.1435 3.12946 17.4592 3.62865C17.7749 4.12783 17.9592 4.6988 17.9948 5.28837C18.0305 5.87794 17.9163 6.46694 17.663 7.00052L21 6.99952C21.2652 6.99952 21.5196 7.10487 21.7071 7.29241C21.8946 7.47995 22 7.7343 22 7.99952V11.9995C22 12.2647 21.8946 12.5191 21.7071 12.7066C21.5196 12.8942 21.2652 12.9995 21 12.9995H20V20.9995C20 21.2647 19.8946 21.5191 19.7071 21.7066C19.5196 21.8942 19.2652 21.9995 19 21.9995H5C4.73478 21.9995 4.48043 21.8942 4.29289 21.7066C4.10536 21.5191 4 21.2647 4 20.9995V12.9995H3C2.73478 12.9995 2.48043 12.8942 2.29289 12.7066C2.10536 12.5191 2 12.2647 2 11.9995V7.99952C2 7.7343 2.10536 7.47995 2.29289 7.29241C2.48043 7.10487 2.73478 6.99952 3 6.99952L6.337 7.00052C5.98076 6.25219 5.90163 5.40172 6.1137 4.60052C6.32577 3.79931 6.81529 3.09936 7.4951 2.62527C8.17491 2.15118 9.0009 1.93371 9.82603 2.01158C10.6512 2.08946 11.4219 2.45761 12.001 3.05052C12.326 2.71704 12.7147 2.45219 13.1439 2.27166C13.5732 2.09113 14.0343 1.99859 14.5 1.99952ZM18 12.9995H6V19.9995H18V12.9995ZM20 8.99952H4V10.9995H20V8.99952ZM9.5 3.99952C9.11478 3.99971 8.74441 4.14809 8.46561 4.41392C8.18682 4.67976 8.02099 5.04266 8.00248 5.42743C7.98396 5.8122 8.11419 6.18935 8.36618 6.48072C8.61816 6.77209 8.97258 6.95534 9.356 6.99252L9.5 6.99952H11V5.49952C11 5.12663 10.8611 4.76712 10.6104 4.4911C10.3597 4.21507 10.0152 4.04231 9.644 4.00652L9.5 3.99952ZM14.5 3.99952L14.356 4.00652C14.0094 4.03972 13.6851 4.19249 13.4388 4.43863C13.1925 4.68476 13.0395 5.00892 13.006 5.35552L13 5.49952V6.99952H14.5L14.644 6.99252C15.015 6.95654 15.3594 6.78371 15.6099 6.5077C15.8605 6.23169 15.9993 5.87228 15.9993 5.49952C15.9993 5.12675 15.8605 4.76734 15.6099 4.49133C15.3594 4.21532 15.015 4.04249 14.644 4.00652L14.5 3.99952Z" fill="#121415"/>
                                                        </svg>
                                                    </span>
                                                    <span className='gift-payment__heading ml-1_5'>Gift Card</span>
                                                </div>
                                                <button type='button' className='text-dark btn m-0 p-0 btn-link' onClick={() => setModalShow(true)} >Apply</button>
                                                {/* {['bottom'].map((placement, idx) => (
                                                    <GiftCardModal key={idx} placement={placement} name={placement} />
                                                ))} */}
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='payment-price__wrap'>
                                <div className='cart-heading__wrapper border-bottom-0'>
                                    <h4 className='d-flex align-items-center mb-4'>
                                        <span className='d-flex'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.6667 18.3332H3.33333C3.11232 18.3332 2.90036 18.2454 2.74408 18.0891C2.5878 17.9328 2.5 17.7209 2.5 17.4998V2.49984C2.5 2.27882 2.5878 2.06686 2.74408 1.91058C2.90036 1.7543 3.11232 1.6665 3.33333 1.6665H16.6667C16.8877 1.6665 17.0996 1.7543 17.2559 1.91058C17.4122 2.06686 17.5 2.27882 17.5 2.49984V17.4998C17.5 17.7209 17.4122 17.9328 17.2559 18.0891C17.0996 18.2454 16.8877 18.3332 16.6667 18.3332ZM15.8333 16.6665V3.33317H4.16667V16.6665H15.8333ZM6.66667 7.49984H13.3333V9.1665H6.66667V7.49984ZM6.66667 10.8332H13.3333V12.4998H6.66667V10.8332Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='ml-2'>Price Details</span>
                                    </h4>
                                </div>

                                <div className='payment-order__summary'>
                                    <div className='cart-order__summary'>
                                        <h4 className='heading-order__summary'>Order Summary</h4>
                                        <div className='order-summary__table'>
                                            <div className='order-summary__body'>
                                                <div className='order-summary__row'>
                                                    <div className='order-summary__col'>
                                                        <label htmlFor="label">Items Total</label>
                                                    </div>
                                                    <div className='order-summary__col'>
                                                        <span>₹19,999</span>
                                                    </div>
                                                </div>
                                                <div className='order-summary__row'>
                                                    <div className='order-summary__col'>
                                                        <label htmlFor="label">Delivery Charges</label>
                                                    </div>
                                                    <div className='order-summary__col details-text'>
                                                        <span className='text-uppercase'>Free</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='order-summary__footer'>
                                                <div className='order-summary__row'>
                                                    <div className='order-summary__col flex-column d-flex'>
                                                        <span>Total Amount</span>
                                                        <span className='excl-txt'>(Inclusive of all taxes)</span>
                                                    </div>
                                                    <div className='order-summary__col'>
                                                        <span>₹22,097</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type='button' className='btn btn-dark-custom btn-dark-custom-cart w-100 mt-4'>Pay Now</button>
                            </div>
                        </div>
                        <div className='h-5 mar-spacer' />
                    </div>
                </div>
            </div>
            <GiftCart 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Payment