import React, { useEffect, useState } from 'react';
import ProductGallery from '../../Components/ProductDetails/ProductGallery';
import KeyFeatures from '../../Components/ProductDetails/KeyFeatures';
import ProductColors from '../../Components/ProductDetails/ProductColors';
import ProductVariants from '../../Components/ProductDetails/ProductVariants';
import RenewedInfo from '../../Components/ProductDetails/RenewedInfo';
import Offers from '../../Components/ProductDetails/Offers';
import Location from '../../Components/ProductDetails/Location';
import EmiOptions from '../../Components/ProductDetails/EmiOptions';
import Exchange from '../../Components/ProductDetails/Exchange';
import Specifications from '../../Components/ProductDetails/Specifications';
import CancellationPolicy from '../../Components/ProductDetails/CancellationPolicy';
//import ProductReviewComponent from '../../Components/ProductDetails/ProductReview';
import BuyTogether from '../../Components/ProductDetails/BuyTogether';
import RefurbishmentFlow from '../../Components/ProductDetails/RefurbishmentFlow';
import WhySangeetha from '../../Components/ProductDetails/WhySangeetha';
import ProductBox from '../../Components/ProductDetails/ProductBox';
import Highlights from '../../Components/ProductDetails/Highlights';
import ReviewVideo from '../../Components/ProductDetails/Review';
//import ProductReviewList from '../../Components/ProductDetails/ProductReviewList';
import Sale from '../../Components/Home/Sale';
//import RelatedCategory from '../../Components/ProductDetails/RelatedCategory';
import { ImageGallery, ProductSpecifications, ProductReview, productsInfo } from '../../Data';

import '../../Components/ProductDetails/ProductDetails.css';

const ProductRenewed = () => {
    const [ productData, setProductData ] = useState([]);
    const [ specificData, setData ] = useState([]);
    const [ genaralData, setgenaralData ] = useState([]);
    const [ reviewData, setReviewData ] = useState([]);

    useEffect(() => {
        setProductData(productsInfo[0])
        setData(ProductSpecifications[0].Specific)
        setgenaralData(ProductSpecifications[1].General)
        setReviewData(ProductReview[0])
    }, []);
    return (
        <>
            <div className='product-details-wrapper'>
                <div className='container'>
                    <div className='pdp-container'>
                        <div className='product-gallery-container'>
                            <div className='sticky-container'>
                                <ProductGallery DataList={ImageGallery} productData={productData} />                            
                            </div>
                        </div>
                        <div className='product-details-container'>
                            <div className='pdp-info'>
                                <div className='pdp-info__wrap'>
                                    <div className='d-flex'>
                                        {/* {productData.stock_status >= 0 ?
                                            <span className='position-static out-of-stock'>Out Of Stock</span> :
                                            <span className='product-label-tag px-3 position-static'>{productData.ProductTag}</span>
                                        } */}
                                        <div className='product-label-tag tag-flex-light position-static'>
                                            <span className='tag-flex_light d-flex align-items-center'>
                                                <span className='d-flex'>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.19925 1.16617C9.08227 1.21086 9.01081 1.30288 9.01081 1.40541V2.018C5.27467 2.15733 2.28577 4.66022 2.28577 7.71523C2.28577 8.13063 2.34102 8.54339 2.45146 8.93774C2.48395 9.05607 2.61716 9.14282 2.76662 9.14282H2.76986C2.92254 9.14018 3.0525 9.05607 3.085 8.93512C3.69576 6.66096 6.15511 4.98623 9.01081 4.86792V5.46736C9.01081 5.56989 9.08227 5.66455 9.19925 5.7066C9.31619 5.74867 9.4494 5.73289 9.54687 5.66717L12.4578 3.63225C12.5293 3.58231 12.5715 3.51132 12.5715 3.43244C12.5715 3.3562 12.5293 3.28521 12.4578 3.23527L9.54687 1.20559C9.4494 1.13987 9.31293 1.12409 9.19925 1.16617Z" fill="white"/>
                                                        <path d="M6.79865 10.2918C6.68491 10.2497 6.54842 10.2655 6.45092 10.3338L3.53906 12.3667C3.46758 12.4167 3.42859 12.4903 3.42859 12.5666C3.42859 12.6429 3.46758 12.7165 3.53906 12.7664L6.45419 14.7941C6.51268 14.8361 6.58741 14.8572 6.6654 14.8572C6.7109 14.8572 6.7564 14.8493 6.79865 14.8335C6.91566 14.7914 6.99039 14.6968 6.99039 14.5942V13.9814C10.7277 13.8447 13.7143 11.3411 13.7143 8.28256C13.7143 7.86704 13.6591 7.45679 13.5518 7.06232C13.5161 6.94135 13.3861 6.85718 13.2333 6.85718C13.0806 6.85718 12.9473 6.94397 12.9148 7.06494C12.3071 9.33976 9.84375 11.0123 6.99039 11.1307V10.5311C6.99039 10.4285 6.91566 10.3365 6.79865 10.2918Z" fill="white"/>
                                                    </svg>
                                                </span>
                                                <span className='ml-1'>Refurbished</span>
                                            </span>
                                            <span className='tag-flex_dark'>Superb</span>
                                        </div>
                                    </div>
                                    <div className='product-info'>
                                        <div className='brand-name'>{productData.ProductBrand}</div>
                                        <h2 className='product-name mb-0'>{productData.ProductName}</h2>
                                        <div className="product-price mb-0">
                                            <div className="new-price">₹{productData.ProductNewPrice}</div>
                                            <div className="old-price">₹{productData.ProductOldPrice}</div>
                                        </div>
                                        <div className='price-info-text'>(Price Includes GST)</div>
                                    </div>
                                </div>
                            </div>
                            <div className='pdp-key-features'>
                                <KeyFeatures />
                            </div>
                            <div className='mar-spacer h-2' />
                            <div className='pdp-colors'>
                                <h4 className='pdp-heading'>Colors</h4>
                                <ProductColors />
                            </div>
                            <div className='border-divider' />
                            <div className='pdp-variants'>
                                <h4 className='pdp-heading'>RAM + Storage</h4>
                                <ProductVariants />
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='product-policies'>
                                <div className='days-policies border-r1'>
                                    <span className='policies-icon'>
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.50065 14.6654C4.81865 14.6654 1.83398 11.6807 1.83398 7.9987C1.83398 4.3167 4.81865 1.33203 8.50065 1.33203C12.1827 1.33203 15.1673 4.3167 15.1673 7.9987C15.1673 11.6807 12.1827 14.6654 8.50065 14.6654ZM8.50065 13.332C9.91514 13.332 11.2717 12.7701 12.2719 11.7699C13.2721 10.7697 13.834 9.41319 13.834 7.9987C13.834 6.58421 13.2721 5.22766 12.2719 4.22746C11.2717 3.22727 9.91514 2.66536 8.50065 2.66536C7.08616 2.66536 5.72961 3.22727 4.72941 4.22746C3.72922 5.22766 3.16732 6.58421 3.16732 7.9987C3.16732 9.41319 3.72922 10.7697 4.72941 11.7699C5.72961 12.7701 7.08616 13.332 8.50065 13.332ZM5.16732 8.66536H11.1673V9.9987H8.50065V11.9987L5.16732 8.66536ZM8.50065 5.9987V3.9987L11.834 7.33203H5.83398V5.9987H8.50065Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span className='policies-label'>7 days Return</span>
                                </div>
                                <div className='days-policies'>
                                    <span className='policies-icon'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00065 2H14.0007C14.1775 2 14.347 2.07024 14.4721 2.19526C14.5971 2.32029 14.6673 2.48986 14.6673 2.66667V13.3333C14.6673 13.5101 14.5971 13.6797 14.4721 13.8047C14.347 13.9298 14.1775 14 14.0007 14H2.00065C1.82384 14 1.65427 13.9298 1.52925 13.8047C1.40422 13.6797 1.33398 13.5101 1.33398 13.3333V2.66667C1.33398 2.48986 1.40422 2.32029 1.52925 2.19526C1.65427 2.07024 1.82384 2 2.00065 2ZM13.334 7.33333H2.66732V12.6667H13.334V7.33333ZM13.334 6V3.33333H2.66732V6H13.334ZM9.33398 10H12.0007V11.3333H9.33398V10Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span className='policies-label'>Doorstep EMI</span>
                                </div>
                            </div>
                            <div className='mar-spacer h-4' />
                            <RenewedInfo />
                            <div className='mar-spacer h-4' />
                            <div className='pdp-Offers'>
                                <h4 className='pdp-heading'>Offers</h4>
                                <Offers />
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='product-payment-offers'>
                                <div className='pdp-location'>
                                    <Location />
                                </div>
                                <div className='pdp-emi'>
                                    <EmiOptions />
                                </div>
                                <div className='pdp-exchange'>
                                    <Exchange />
                                </div>
                            </div>
                            <div className='mar-spacer h-4' />
                            <div className='pdp-specifications'>
                                <h4 className='pdp-heading'>Specifications</h4>
                                <Specifications specificData={specificData} genaralData={genaralData} />
                            </div>
                            <div className='mar-spacer h-4' />
                            <CancellationPolicy />
                            <div className='product-buy-together specification-head'>
                                <h4 className='pdp-heading'>Buy together, save more</h4>
                                <BuyTogether />
                            </div>
                            <div className='mar-spacer h-2_5' />
                        </div>
                    </div>
                </div>
                <div className='pdp-Refurbishment__container'>
                    <div className='container'>
                        <RefurbishmentFlow />
                    </div>
                </div>
                <div className='pdp-details__container'>
                    <div className='container'>
                        <div className='why-sangeetha__bg'>
                            <h4 className='pdp-info-heading text-center'>Why Buy From Sangeetha?</h4>
                            <div className='h-1_1 mar-spacer' />
                            <div className='why-sangeetha__flex'>
                                <WhySangeetha />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pdp-product-box space-top-160'>
                    <div className='container'>
                        <h4 className='pdp-info-heading text-center'>What’s In The Box?</h4>
                        <p className='pdp-info-text text-center'>Samsung Galaxy Fold, Type C Cable, Pin</p>
                        <div className='product-box__wrap d-flex align-items-center justify-content-center'>
                            <ProductBox />
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-5'></div>
                <section className='pdp-highlights'>
                    <h4 className='pdp-info-heading text-center'>Highlights</h4>
                    <Highlights />
                </section>
                <div className='mar-spacer h-5'></div>
                <section className='pdp-review'>
                    <div className='container'>
                        <h4 className='pdp-heading text-center'>Watch Galaxy Fold’s Expert Review</h4>
                        <div className='mar-spacer h-1_1'></div>
                        <ReviewVideo reviewData={reviewData} />
                    </div>
                </section>
                <div className='space-top-160 pdp-product-recommend'>
                    <div className='container'>
                        <h4 className='pdp-heading'>Sangeetha Recommends</h4>
                        <div className='spacing-block position-relative'>
                            <Sale />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductRenewed