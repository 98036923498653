import React from 'react';
import Form from 'react-bootstrap/Form';

const UPIPayment = () => {
    
    return (
        <>
            <div className='upi-payment__list'>
                <div className='upi-payment__add pt-0'>
                    <div className="cart-address__wrap form-group-custom pl-0 error-state pb-0">{/* class::valid-state || error-state */}
                        <input type="number" className="form-control form-control-cart" placeholder="Enter UPI ID" />
                        <button type="button" className="btn btn-dark-button absolute-cart-input btn-space">Verify</button>
                        <span>Please enter a valid UPI ID</span>
                    </div>                    
                    <div className='filter-tab__list border-0 p-0'>
                        <div className='filter-tab__flex'>
                            <Form.Check className='filter__checkbox' aria-label="option 1" defaultChecked />
                            <label htmlFor="label" className='filter_price'>Save this UPI ID for faster payments</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UPIPayment