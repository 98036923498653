import React from 'react';
import COD from '../../../Images/product/COD.png';

const CODPayment = () => {
    return (
        <>
            <div className='cod__wrap d-flex flex-column align-items-start'>
                <img src={COD} className='img img-fluid' alt='' />
                <button type="button" className="text-dark btn mt-1 p-0 btn-link">Change Image</button>
            </div>
            <div className='mar-spacer h-1' />
            <div className="cart-address__wrap pl-0 error-state mb-2">{/* class::valid-state || error-state */}
                <input type="number" className="form-control form-control-cart" placeholder="Enter UPI ID" />
                <button type="button" className="btn btn-dark-button absolute-cart-input btn-space">Check</button>
                <span>Please enter a valid UPI ID</span>
            </div>   
            <div className='address-form__details p-0'>
                <p className='cod-info__wrap'>You can pay via Card/Cash or UPI enabled app at the time of delivery. Ask your delivery executive for these options.</p>
            </div>
        </>
    )
}

export default CODPayment