import {
    GET_HOME_INFO,
    ERROR_HOME_INFO,
    GET_HOME_BANNER,
    GET_HOME_CATEGORY,
    GET_HOME_PRODUCT_SLIDER_1,
} from '../types'

// import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios'

const apiUrl = (`https://smpl-t-api.izoleap.com/pims/HomePage`);
// const method = 'POST';
const headers = {
    'Content-Type': 'application/json',
    'Token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJsdW1lbi1qd3QiLCJzdWIiOjIxMiwiaWF0IjoxNTc0MTUwMTYzLCJleHAiOjE2MDU2ODYxNjN9.0b7PxovIcFiWayJduIklNs_0lidod2EkD0FcYEKaF5Q'
};

export const getHome = (payLoad) => async (dispatch) => {
    try {
        const response =  await axios.post(apiUrl, JSON.stringify(payLoad), {headers: headers}).catch((error) => {
            console.log(error);
        })
        // const res = await axios({
        //     method,
        //     url: apiUrl,
        //     data: JSON.stringify(payLoad),
        //     headers,
        // });
        console.log(response);
        // dispatch({
        //     type: GET_HOME_INFO,
        //     payload: response.data.data
        // })

        dispatch(getBanner(response.data.data.block_1[0].content));
        dispatch(getCategory(response.data.data.block_2[0]));
    } catch (error) {
        dispatch({
            type: ERROR_HOME_INFO,
            payload: error,
        })
    }
}

export const getHomeData = (payload) => async(dispatch) => {
    try {
        dispatch({
            type: GET_HOME_INFO,
            payload: payload
        })
    } catch (error) {
        dispatch({
            type: ERROR_HOME_INFO,
            payload: error,
        })
    }
}   


export const getBanner = (payload)  => async (dispatch) =>  {
    try {
        dispatch({
            type: GET_HOME_BANNER,
            payload: payload
        })
    } catch (error) {
        dispatch({
            type: GET_HOME_BANNER,
            payload: error,
        })
    }

}

export const getCategory = (payload)  => async (dispatch) =>  {
    dispatch({
        type: GET_HOME_CATEGORY,
        payload: payload
    })
}

export const getProductSlider_1 = (payload)  => async (dispatch) =>  {
    dispatch({
        type: GET_HOME_PRODUCT_SLIDER_1,
        payload: payload
    })
}



