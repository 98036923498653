import React from 'react'

const ButtonGroup = ({ next, previous, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group carousel-group-btn">
            <button className={`arrow-btn ${currentSlide === 0 ? 'disable' : 'prev-btn'}`} onClick={() => previous()}>
                {currentSlide === 0 ?
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.656 24.0005L31.556 33.9005L28.728 36.7285L16 24.0005L28.728 11.2725L31.556 14.1005L21.656 24.0005Z" fill="#C4CAD0"/>
                    </svg> : 
                    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.656 24.7144L31.556 14.8144L28.728 11.9864L16 24.7144L28.728 37.4424L31.556 34.6144L21.656 24.7144Z" fill="#121415"/>
                    </svg>
                }
            </button>
            <button className='arrow-btn nxt-btn' onClick={() => next()}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.344 23.9995L16.444 14.0995L19.272 11.2715L32 23.9995L19.272 36.7275L16.444 33.8995L26.344 23.9995Z" fill="#121415"/>
                </svg>
            </button>
        </div>
    );
}

export default ButtonGroup