import React,{ useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from '@reach/router';

const OrderCanceledStatus = ({...props}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [refundStatus]=useState(true)
    return (
        <>
            <button type='button' className='btn btn-dark-button btn-space bnt-h'onClick={handleShow}>Track</button>
            <Offcanvas show={show} onHide={handleClose} {...props} className="address-offcanvas">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='cart-heading'>Track Shipment</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {refundStatus?    
                        <div className="trackshipment_canva-Model expected">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para expected-para'>Expected delivery by</p>
                                <h4 className='trackshipment_canva-msg expected-para'>Wed, 6th Jan  </h4>
                            </div>
                        </div>
                        : 
                        <div className="trackshipment_canva-Model">
                            <div className="ordersuccesful_message">
                                <p className='trackshipment_canva-para refund-para'>Refund Successful</p>
                                <h4 className='trackshipment_canva-msg refundpara'>Refund of ₹19,999 has been processed successfully on Wed, 1 Jan - 3:50pm  </h4>
                            </div>
                        </div>                                      
                    }

                    <div className="mar-spacer h-1"></div>                    
                    <div className="order_tracking-shipiment-strapper">
                        <ul className="StepProgress">
                            <li className="StepProgress-item is-done line-going">
                                <strong className='orderplace going_on'>Order Placed</strong>
                                <span className='tracking_timing'>Wed, 1 Jan, 2022 - 3:45pm</span>
                            </li>
                            
                            <li className='StepProgress-item is-done line-going'>
                                <strong className='orderplace'>Order is on the way</strong>
                                <span className='tracking_timing'>Thursday, 2 Jan, 2022</span>                                    
                                <Link to="/" className='StaperShow-details'>Show details</Link>
                            </li>                                
                            <li className='StepProgress-item is-done line-going'>
                                <strong className='orderplace '>Order Cancelled</strong>
                                <span className='tracking_timing'>Wed, 1 Jan - 3:50pm </span>                                    
                                <Link to="/" className='StaperShow-details'>Show details</Link>                                       
                            </li>
                            
                         
                            <li className='StepProgress-item is-done'>
                                <strong>Refund Successful</strong>
                                <span className='tracking_timing'>Refund of ₹19,999 has been processed successfully on Wed, 1 Jan - 3:50pm  </span>
                            </li>
                            <li className='StepProgress-item '>
                                <strong className='stapertxr'>Delivery Pending</strong>
                                <span className='tracking_timing'>Delhivery : 3477KL67</span>
                            </li>
                        </ul>
                        <div className="mar-spacer h-1_2"></div>

                    </div>                                     
                     

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default OrderCanceledStatus;