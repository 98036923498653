import React from 'react';
import ComparisonCarousel from './ComparisonCarousel';

const Overview = () => {
    return (
        <>
            <div className='compare__overview position-relative spacing-block'>
                <div className='compare__head'>
                    <div className='compare__text'>Overview</div>
                </div>
                <div className='overview__content'>
                    <div className='specification-info__table w-100'>
                        <table className='table specification-striped__table mb-0'>
                            <tbody>
                                <tr>
                                    <th width='37%'>Camera</th>
                                    <td>48MP + 8MP + 2MP</td>
                                    <td>48MP + 8MP + 2MP</td>
                                </tr>
                                <tr>
                                    <th width='37%'>CPU</th>
                                    <td>Octa Core 2.4 GHz</td>
                                    <td>Octa Core 2.4 GHz</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Storage</th>
                                    <td>256GB</td>
                                    <td>256GB</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Display</th>
                                    <td>6.43” Full HD + AMOLED Display</td>
                                    <td>6.43” Full HD + AMOLED Display</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Battery</th>
                                    <td>4300 mAh</td>
                                    <td>4300 mAh</td>
                                </tr>

                                <tr>
                                    <th width='37%'>RAM</th>
                                    <td>8 GB</td>
                                    <td>8 GB</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='mar-spacer h-5' />
                    </div>
                </div>
                <div className='compare__head'>
                    <div className='compare__text'>Offers and Deals</div>
                </div>
                <div className='overview__content'>
                    <div className='specification-info__table w-100'>
                        <table className='table specification-striped__table mb-0'>
                            <tbody>
                                <tr>
                                    <th width='37%'>Exchange</th>
                                    <td>Upto ₹11,680 Off on Exchange</td>
                                    <td>Upto ₹11,680 Off on Exchange</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Deals</th>
                                    <td>10% Off on Axis Bank Credit Cards</td>
                                    <td>10% Off on Axis Bank Credit Cards</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='mar-spacer h-5' />
                    </div>
                </div>

                <div className='compare__head'>
                    <div className='compare__text'>Features</div>
                </div>
                <div className='overview__content'>
                    <div className='specification-info__table w-100'>
                        <table className='table specification-striped__table mb-0'>
                            <tbody>
                                <tr>
                                    <th width='37%'>Charge</th>
                                    <td>Fast Charge </td>
                                    <td>Upto ₹11,680 Off on Exchange</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Deals</th>
                                    <td>Fast Charge </td>
                                    <td>Upto ₹11,680 Off on Exchange</td>
                                </tr>
                                <tr>
                                    <th width='37%'>Exchange</th>
                                    <td>Fast Charge </td>
                                    <td>Upto ₹11,680 Off on Exchange</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='mar-spacer h-5' />
                    </div>
                </div>

                <div className='compare__head'>
                    <div className='compare__text'>Display</div>
                </div>
                <div className='overview__content'>
                    <div className='specification-info__table w-100'>
                        <table className='table specification-striped__table mb-0'>
                            <tbody>
                                <tr>
                                    <th width='37%'>Resolution</th>
                                    <td>6.1 inch (15.5 cm diagonal) Super XDR Retina with ProMotion</td>
                                    <td>6.1 inch (15.5 cm diagonal) Super XDR Retina with ProMotion</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='mar-spacer h-5' />
                    </div>
                </div>
                
                <div className='popular-comparison__wrap'>
                    <h6 className='popular-comparison__title'>Popular Comparisons</h6>
                    <div className='spacing-block position-relative'>
                        <ComparisonCarousel />
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-5' />
        </>
    )
}

export default Overview
