import React from 'react';
import CdTimerComp from './Countdown/CdTimerComp';

const ProductInfo = ({ productData }) => { 

    return (
        <>
            <div className='pdp-info__wrap'>
                {productData.stock_type >= 0 &&
                    <div className='d-flex'>
                        {productData.stock_status === 'Out Of Stock' ?
                            <span className='position-static out-of-stock'>Out Of Stock</span> :
                            <span className='product-label-tag px-3 position-static'>{productData.ProductTag}</span>
                        }
                    </div>
                }
                {productData.stock_type === 'PreLaunch' || productData.stock_type === 'PreLaunchStart' ?
                    <div className='d-flex'>
                        <span className='position-static out-of-stock'>coming soon</span>
                    </div>: null
                }
                {productData.stock_type === 'PreBook' ?
                    <div className='d-flex'>
                        <span className='position-static out-of-stock'>booking open</span>
                    </div>: null
                }
                <div className='product-info'>
                    <div className='brand-name'>{productData.ProductBrand}</div>
                    <h2 className='product-name mb-0'>{productData.ProductName}</h2>
                    {productData.stock_type === 'PreLaunch' &&
                        <div className='pre-launch-1 pt-1'>
                            <span>March 31, 7:30pm IST</span>
                        </div>
                    }
                    {productData.stock_type === 'PreLaunchStart' &&
                        <CdTimerComp /> 
                    }
                    {productData.stock_type === 'PreBook' && 
                        <div className='pre-book-1 pt-1'>
                            <span>Pre book now at</span> {' '}
                            <span className='pre-book-price'>₹500</span>
                        </div>
                    }
                    <div className="product-price mb-0">
                        <div className="new-price">₹{productData.ProductNewPrice}</div>
                        <div className="old-price">₹{productData.ProductOldPrice}</div>
                    </div>
                    <div className='price-info-text'>(Price Includes GST)</div>
                </div> 
            </div>
        </>
    )
}

export default ProductInfo