import React from 'react'

const MyCoupns = () => {
    return (
        <>
            <div className="Profileordertab_wrapper">
                <div className="profileorder-div">
                    <div className='order-divleft-box'>
                        <div className="order-l-img">
                            <span>
                                <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg"><g><g><path d="M2.05664 8.41667V3.83333C2.05664 3.61232 2.14691 3.40036 2.3076 3.24408C2.46829 3.0878 2.68624 3 2.91348 3H18.3367C18.5639 3 18.7819 3.0878 18.9426 3.24408C19.1033 3.40036 19.1935 3.61232 19.1935 3.83333V8.41667C18.6254 8.41667 18.0805 8.63616 17.6788 9.02686C17.2771 9.41756 17.0514 9.94747 17.0514 10.5C17.0514 11.0525 17.2771 11.5824 17.6788 11.9731C18.0805 12.3638 18.6254 12.5833 19.1935 12.5833V17.1667C19.1935 17.3877 19.1033 17.5996 18.9426 17.7559C18.7819 17.9122 18.5639 18 18.3367 18H2.91348C2.68624 18 2.46829 17.9122 2.3076 17.7559C2.14691 17.5996 2.05664 17.3877 2.05664 17.1667V12.5833C2.62476 12.5833 3.16962 12.3638 3.57134 11.9731C3.97307 11.5824 4.19875 11.0525 4.19875 10.5C4.19875 9.94747 3.97307 9.41756 3.57134 9.02686C3.16962 8.63616 2.62476 8.41667 2.05664 8.41667ZM12.3388 4.66667H3.77033V7.14C4.414 7.45025 4.95575 7.92921 5.3345 8.52288C5.71326 9.11656 5.91395 9.80133 5.91395 10.5C5.91395 11.1987 5.71326 11.8834 5.3345 12.4771C4.95575 13.0708 4.414 13.5498 3.77033 13.86V16.3333H12.3388V4.66667ZM14.0525 4.66667V16.3333H17.4798V13.86C16.8362 13.5498 16.2944 13.0708 15.9157 12.4771C15.5369 11.8834 15.3362 11.1987 15.3362 10.5C15.3362 9.80133 15.5369 9.11656 15.9157 8.52288C16.2944 7.92921 16.8362 7.45025 17.4798 7.14V4.66667H14.0525Z" fill="#6B7586"/></g></g><defs><clipPath id="clip0_1353_36429"><rect width="20.5643" height="20" fill="white"/></clipPath><clipPath id="clip1_1353_36429"><rect width="20.5643" height="20" fill="white"/></clipPath></defs></svg>
                            </span>
                        </div>
                        <div className="order-profilemid-container">
                            <h4>My Coupons</h4>
                            <span>Manage coupons for additional discounts </span>
                        </div>

                    </div>
                    
                    <div className="profileorder-arrow">
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.172 12.0002L8.222 7.05023L9.636 5.63623L16 12.0002L9.636 18.3642L8.222 16.9502L13.172 12.0002Z" fill="#fff"/></svg>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyCoupns