import React from 'react'

const CartSteps = () => {
    return (
        <>
            <div className='cart-steps'>
                <ul className='form-steps'>
                    <li className='steps active-page valid-'>
                        <span>Cart</span>
                    </li>
                    <li className='steps'>
                        <span>Address</span>
                    </li>
                    <li className='steps'>
                        <span>Payment</span>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default CartSteps