import React from 'react'

const SearchedFilter = () => {
    return (
        <>
            <div className='product-filter__apply_wrap'>
                <div className='product-filter__lists'>
                    <div className='product-filter__item'>
                        <span>₹80,000  -  ₹1,10,000</span>
                    </div>
                    <div className='product-filter__item'>
                        <span>₹80,000  -  ₹1,10,000</span>
                    </div>
                    <div className='product-filter__item'>
                        <span style={{ backgroundColor: '#D6EEFF'}} className='filtered-color'></span>
                        <span>Pastel Blue</span>
                        <span className='clear_filter_btn cursor' role="button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                    <div className='product-filter__item'>
                        <span style={{ backgroundColor: '#EECCEE'}} className='filtered-color'></span>
                        <span>Purple</span>
                        <span className='clear_filter_btn cursor' role="button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                    <div className='product-filter__item'>
                        <span style={{ backgroundColor: '#ECECCE'}} className='filtered-color'></span>
                        <span>Beige</span>
                        <span className='clear_filter_btn cursor' role="button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.99999 7.05732L11.3 3.75732L12.2427 4.69999L8.94266 7.99999L12.2427 11.3L11.3 12.2427L7.99999 8.94266L4.69999 12.2427L3.75732 11.3L7.05732 7.99999L3.75732 4.69999L4.69999 3.75732L7.99999 7.05732Z" fill="#121415"/>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className='clear__all' role="button">Clear All</div>
            </div>
        </>
    )
}

export default SearchedFilter