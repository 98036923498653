import React from 'react';
import img1 from '../../Images/Product/colors/1.png';
import img2 from '../../Images/Product/colors/2.png';
import img3 from '../../Images/Product/colors/3.png';
import img4 from '../../Images/Product/colors/4.png'; 

const ProductColors = () => {
    return (
        <>
            <div className='product-colors-box'>
                <div className='product-colorFrame active'>
                    <div className='product-colorFrame-media mb-2'>
                        <img src={img1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Midnight Blue</div>
                </div>  
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media mb-2'>
                        <img src={img2} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Special :Iron Man</div>
                </div>  
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media mb-2'>
                        <img src={img3} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Awesome Black</div>
                </div>  
                <div className='product-colorFrame'>
                    <div className='product-colorFrame-media mb-2'>
                        <img src={img4} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Crimson Cream</div>
                </div>
                <div className='product-colorFrame out-of-stock'>
                    <div className='product-colorFrame-media mb-2'>
                        <img src={img1} className='img-fluid img d-block m-auto' alt='' />
                    </div>
                    <div className='colorFrame-name'>Special : Wakanda</div>
                </div>   
            </div>
        </>
    )
}

export default ProductColors