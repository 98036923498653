import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { useNavigate } from 'react-router-dom';
//import Topbar from './Topbar';
import NavHamburger from './NavHamburger';
import NavWishlist from './NavWishlist';
import NavAddToCart from './NavAddToCart';
import sangeethaLogo from '../../Images/sangeethaLogo.svg'

const Header = () => {
    const [isHomeScreen] = useState(true);
    const [ stickyTab, setStickyTab] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);      
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); 

    const handleScroll = (e) => {
        setStickyTab(e.target.scrollTop > 31);
    };

    return (
        <>
            <header 
                className={`header-wrapper navbar-sticky-header ${stickyTab ? 'shadow-sm nav-sticky-top' : ''}`}
            >
                {/* {isHomeScreen ?
                    <Topbar /> : ''
                } */}
                <div 
                    className="navbar-sticky bg-dark navbar-dark"
                >
                    <div className="navbar navbar-expand-lg navbar-light">
                        <div className="container px-0">
                            <div className="header-logo-wrapper navbar-brand flex-shrink-0">
                                {isHomeScreen ?
                                    <>
                                        <NavHamburger />
                                        <a href="/" className="d-flex align-items-center ml-2_5">
                                            <img src={sangeethaLogo} className='img img-fluid' alt='Sangeetha Logo' />
                                        </a>
                                    </> :
                                    <>  
                                        <div onClick={() => navigate(-1)} className="d-flex align-items-center" aria-hidden="true">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.625 6.25055L7.80333 7.42888L5.69 9.54222H16.6667V11.2089H5.69L7.80333 13.3222L6.625 14.5005L2.5 10.3755L6.625 6.25055Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className='navigation-Label'>
                                            {/* <div className='navigationLabel-head'>Mobile Phones</div>
                                            <div className='navigationLabel-text'>865 Items</div> */}
                                            <div className='navigationMain-head'>Order Details</div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                                <Link to='/NavSearchBar'>
                                    <div className="navbar-tool">
                                        <div className="navbar-tool-icon">
                                            <svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.25 1.5C11.976 1.5 15 4.524 15 8.25C15 11.976 11.976 15 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5ZM8.25 13.5C11.1502 13.5 13.5 11.1502 13.5 8.25C13.5 5.349 11.1502 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1502 5.349 13.5 8.25 13.5ZM14.6137 13.5532L16.7355 15.6742L15.6742 16.7355L13.5532 14.6137L14.6137 13.5532Z" fill="white"/>
                                            </svg>
                                        </div>
                                    </div>    
                                </Link>        
                                <NavWishlist />
                                <NavAddToCart />
                                {/* {isHomeScreen ?                            
                                        <a href="#" className="navbar-tool-btn">
                                        <svg width="15" height="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 7.21V8.978C9.50006 9.10459 9.4521 9.22649 9.3658 9.3191C9.2795 9.41171 9.16128 9.46814 9.035 9.477C8.8165 9.492 8.638 9.5 8.5 9.5C4.0815 9.5 0.5 5.9185 0.5 1.5C0.5 1.362 0.5075 1.1835 0.523 0.965C0.531861 0.838722 0.588291 0.720505 0.680902 0.634204C0.773513 0.547903 0.895411 0.499943 1.022 0.5H2.79C2.85202 0.499937 2.91185 0.52293 2.95786 0.56451C3.00388 0.606091 3.0328 0.663291 3.039 0.725C3.0505 0.84 3.061 0.9315 3.071 1.001C3.17037 1.69446 3.374 2.36892 3.675 3.0015C3.7225 3.1015 3.6915 3.221 3.6015 3.285L2.5225 4.056C3.18223 5.59323 4.40727 6.81827 5.9445 7.478L6.7145 6.401C6.74597 6.357 6.79189 6.32544 6.84425 6.31183C6.8966 6.29821 6.95208 6.3034 7.001 6.3265C7.63351 6.62693 8.30779 6.83006 9.001 6.929C9.0705 6.939 9.162 6.95 9.276 6.961C9.33762 6.96732 9.3947 6.99629 9.43618 7.04229C9.47765 7.08829 9.50058 7.14806 9.5005 7.21H9.5Z" fill="black"/>
                                    </svg>
                                    <span className="ml-1">Get help</span>
                                </a> : ''
                                     } */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>        
           
        </>
    );
};

export default Header;
