import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import OfferListTab from './OfferListTab';
import BankOffers from './BankOffers';

const OfferModal = (props) => {
    const [ showBankOffers, setShowBankOffers ] = useState(false);
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom'
            >
                {showBankOffers ?
                    <>
                        <Modal.Header closeButton className='border-0' style={{style : showBankOffers ? 'block' : 'none'}}>
                            <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                                <h2 className='modal-heading mb-4'>Bank Offers (6)</h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <OfferListTab 
                                showBankOffers={showBankOffers}
                            />
                        </Modal.Body>
                    </>:
                    <>
                        <Modal.Header closeButton className='border-0' style={{style : showBankOffers ? 'none' : 'block'}}>
                            <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                                <h2 className='modal-heading mb-4'>
                                    <span onClick={() => setShowBankOffers(true)} role='button' aria-hidden="true">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.828 11.0007H20V13.0007H7.828L13.192 18.3647L11.778 19.7787L4 12.0007L11.778 4.22266L13.192 5.63666L7.828 11.0007Z" fill="#121415"/>
                                        </svg>
                                    </span>
                                    <span className='ml-1_5'>Bank Offers (6)</span>
                                </h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BankOffers />
                        </Modal.Body>
                    </>
                }
            </Modal>
        </>
    )
}

export default OfferModal