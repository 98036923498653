
import React from 'react';
import { Link } from "@reach/router";
import img1 from '../../Images/Category/brands/1.png';
import img2 from '../../Images/Category/brands/2.png';
import img3 from '../../Images/Category/brands/3.png';
import img4 from '../../Images/Category/brands/4.png';
import img5 from '../../Images/Category/brands/5.png';
import img6 from '../../Images/Category/brands/6.png';
import img7 from '../../Images/Category/brands/7.png';
import img8 from '../../Images/Category/brands/8.png';

const Categories = () => {
    return (
        <>
            <div className="category__wrap phone-accessories__wrap brands__wrap">
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img1} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Apple</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img2} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>OnePlus</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img3} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Samsung</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img4} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Vivo</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img5} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Oppo</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img6} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Xiaomi</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img7} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Gimbals</span>
                        </div>
                    </Link>
                </div>
                <div className="category-list__wrap">
                    <Link to='/' className="catg-item-wrapper">
                        <div className="catg-img-wrapper p-2">
                            <img src={img8} className="img-fluid" alt=""/>
                        </div>
                        <div className="catg-content-wrapper">
                            <span>Huawei</span>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Categories