import React from 'react';

import Photography from '../../Images/DIY/1.svg'
import Networking from '../../Images/DIY/2.svg'
import Gaming from '../../Images/DIY/3.svg'
import Music from '../../Images/DIY/4.svg'
import Travelling from '../../Images/DIY/5.svg'
import Office from '../../Images/DIY/6.svg'

const Step1 = ({ formData, setFormData }) => {
    const availableCategories = [
        {
            __id : 1,
            name: 'Photography',
            status: 'unchecked',
            Image: Photography
        }, {
            __id : 2,
            name: 'Social Networking',
            status: 'checked',
            Image: Networking
        }, {
            __id : 3,
            name: 'Gaming',
            status: 'unchecked',
            Image: Gaming
        }, {
            __id : 4,
            name: 'Music',
            status: 'checked',
            Image: Music
        }, {
            __id : 5,
            name: 'Travelling',
            status: 'unchecked',
            Image: Travelling
        }, {
            __id : 6,
            name: 'Office',
            status: 'unchecked',
            Image: Office
        }
    ];
    return (
        <>
            <div className="sign-up-container">
                <div className="exchange-data">
                    <div className="mar-spacer h-4" />
                    <h3 className='steps__head'>What activities would you use your phone for?</h3>
                    <p className='steps__info'>Select upto 3 options from below : </p>
                    <div className="mar-spacer h-4" />
                    <div className='filter-tab__lists'>     
                        {availableCategories.map((Data) => (
                            <div className={Data.status === 'checked' ? 'filter-tab__flex changeexchange-div active' : 'filter-tab__flex changeexchange-div'} key={Data.__id} style={{ height: '172px'}}>
                                <div className='steps__media'>
                                    <img src={Data.Image} className='img-fluid' alt={Data.name} />
                                </div>
                                <div className='flexible' />
                                <div className='steps__content'>
                                    <label htmlFor="label" className='filter_exchange ml-2'>{Data.name}</label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                
            </div>
        </>
    )
}

export default Step1
