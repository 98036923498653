import React from 'react'
import Carousel from "react-multi-carousel";
import CategoryButtonGroup from '../Common/CategoryButtonGroup';

import Banner1 from '../../Images/Category/1_1.jpg';
import Banner2 from '../../Images/Category/2_1.jpg';

const BestGaming = () => {
    const productsData = [
        {
            "_id": "1",
            "Image": Banner1,
        }, {
            "_id": "2",
            "Image": Banner2,
        }, {
            "_id": "3",
            "Image": Banner1
        }, {
            "_id": "4",
            "Image": Banner2
        },
    ];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
            partialVisibilityGutter: 100
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 2,
            partialVisibilityGutter: 100
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 3,
            partialVisibilityGutter: 0
        }
    };
    return (
        <>
            <Carousel                
                partialVisible={true}
                arrows={false}
                itemClass="models-carousel best-gaming"
                responsive={responsive}
                renderButtonGroupOutside={true}
                customButtonGroup={<CategoryButtonGroup />}
                swipeable
            >   
                {productsData.map((Data, idx) => {
                    return (
                        <div className='product-carousel-wrapper' key={idx}>
                            <div className='carousel-media-wrapper d-flex justify-content-center'>
                                <img
                                    draggable={true}
                                    src={Data.Image}
                                    alt=''
                                    className='img-fluid'
                                />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </>
    )
}

export default BestGaming