import { Link } from '@reach/router';
import React, { useState } from 'react';
import CategoryProductList from '../CategoryList/CategoryProductList';
//import SponsoredPhones from './SponsoredPhones';

const ProductLists = () => {
    const [isWishlist] = useState(true);
    return (
        <>
            <div className='search-rightContainer column-base'>
                <CategoryProductList isWishlist={isWishlist} />
                {/* <div className="Model__wrapper category__padding">
                    <div className='spacing-block position-relative'>
                        <div className='Model-head__wrap'>
                            <h4 className="Model-heading__wrap mb-0">Sponsored Phone</h4>
                        </div>
                        <SponsoredPhones />
                    </div>
                </div> */}
                <div className='mar-spacer h-1' />
                <div className='d-flex align-items-center justify-content-center'>
                    <Link to='/ProductList' className='btn btn-dark-custom-outline btn__spacer-60'>Show 24 More</Link>
                    <Link to='/ProductList' className='btn btn-dark-custom btn__spacer-60 ml-3'>Show All (2,129)</Link>
                </div>
            </div>
        </>
    )
}

export default ProductLists