import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert'

const StickyBanner = () => {
    const [show, setShow] = useState(true);

    return (
        <>
            <div className=''>
                <Alert variant="danger" className='sticky-banner mb-0' show={show} onClose={() => setShow(false)} dismissible>
                    <p className='alert-text mb-0'>Use <span>FLAT20</span> and get a Flat 20% Discount on your first purchase.</p>
                </Alert>
            </div>
        </>
    )
}

export default StickyBanner