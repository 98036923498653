import { Link } from '@reach/router';
import React, { useState } from 'react';
import { CategoryListData } from '../Data';
import WishlistEmptyState from '../Components/Wishlist/WishlistEmptyState';
import ModalSimilarProducts from '../Components/ProductDetails/ModalSimilarProducts';
import NotifyMe from '../Components/ProductDetails/NotifyMe';

import '../Components/Wishlist/Wishlist.css';

const Wishlist = () => {
    const [modalShow, setModalShow] = useState(false);
    const [ similarShow, setSimilarhow ] = useState(false);
    const [ isWishlistEmpty ] = useState(false);
    return (
        <>
            {isWishlistEmpty ? 
                <WishlistEmptyState />
                : 
                <>
                    <div className='wishlist__wrapper'>
                        <div className='container'>
                            <div className='wishlist__container'>
                                <div className='mar-spacer h-5' />
                                <h2 className='wishlist-heading'>Wishlist <span className='ml-2'>(2)</span> </h2>
                                <div className='mar-spacer h-4' />

                                <div className='product-wrapper'>
                                    {CategoryListData.map(data => (
                                        <div className='product-list' key={data._id}>
                                            {data.label ?
                                                <div className='product-label-tag'>{data.label}</div> : ''}
                                            <div className='product-thumb'>
                                                <img src={data.Image} className='img-fluid' alt={data.productName} />
                                            </div>
                                            <div className='remove-wishlist-item'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.25 12.75C2.25 6.95101 6.95101 2.25 12.75 2.25C18.549 2.25 23.25 6.95101 23.25 12.75C23.25 18.549 18.549 23.25 12.75 23.25C6.95101 23.25 2.25 18.549 2.25 12.75Z" fill="#98A0AE"/>
                                                    <path d="M12.75 11.5128L17.0813 7.18152L18.3185 8.41877L13.9873 12.75L18.3185 17.0813L17.0813 18.3185L12.75 13.9873L8.41875 18.3185L7.1815 17.0813L11.5128 12.75L7.1815 8.41877L8.41875 7.18152L12.75 11.5128Z" fill="white"/>
                                                </svg>
                                            </div>
                                            <Link className='product-details' to='/ProductDetails'>
                                                <h2>{data.productName}</h2>
                                                {data.productStorage.productColor ||  data.productStorage.ram || data.productStorage.ram ?
                                                    <p className='product-muted'>
                                                        {data.productColor}, {' '}
                                                        {data.productStorage.Storage} + {' '}
                                                        {data.productStorage.ram}
                                                    </p> : null
                                                }
                                                {data.productNewPrice || data.productOldPrice || data.productOffer ?
                                                    <div className='product-price'>
                                                        {data.productNewPrice ?
                                                            <div className='new-price'>₹{data.productNewPrice}</div> : null
                                                        }
                                                        {data.productNewPrice ?
                                                            <div className='old-price ml-1'>₹{data.productOldPrice}</div> : null
                                                        }
                                                        {data.productNewPrice ?
                                                            <div className='price-discount'>{data.productOffer}</div> : null
                                                        }
                                                    </div> : null
                                                }
                                                <div className='product-offer'>
                                                    {data.BrandOfferList.List1.length > 0 ?
                                                        <div className='product-offer-list'>
                                                            <div className='offer-icon'>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17.9994 2.5H10.6427L2.16602 10.9767L9.52268 18.3333L17.9994 9.85667V2.5ZM15.0827 4.16667C15.7727 4.16667 16.3327 4.72667 16.3327 5.41667C16.3327 6.10667 15.7727 6.66667 15.0827 6.66667C14.3927 6.66667 13.8327 6.10667 13.8327 5.41667C13.8327 4.72667 14.3927 4.16667 15.0827 4.16667Z" fill="#121415"/>
                                                                </svg>
                                                            </div>
                                                            <div className='offer-label'>{data.BrandOfferList.List1}</div>
                                                        </div> : null
                                                    }	
                                                    {data.BrandOfferList.List2.length > 0 ?
                                                        <div className='product-offer-list'>
                                                            <div className='offer-icon'>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_444_12766)">
                                                                        <path d="M12.9158 7.64296L18.7495 7.64296L18.7495 13.4766L17.0831 13.4766L17.0825 10.4873L9.32138 18.2496L8.14287 17.071L15.9045 9.30937L12.9158 9.30937L12.9158 7.64296ZM2.25031 6.52337L3.91673 6.52337L3.91673 9.51207L11.6784 1.7504L12.8569 2.92891L5.09524 10.6906L8.08394 10.6906L8.08394 12.357L2.25031 12.357L2.25031 6.52337Z" fill="#121415"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_444_12766">
                                                                            <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            </div>
                                                            <div className='offer-label'>{data.BrandOfferList.List2}</div>
                                                        </div> : null
                                                    }
                                                </div>
                                                {data.CompareLink.length > 0 ?
                                                    <div className='product-compare'>+ Compare</div> : null
                                                }
                                                <div className='product-tags'>
                                                    <div className='tag-label'>PhotoGraphy</div>
                                                    <div className='tag-label'>Wireless Charge</div>
                                                    <div className='tag-label'>Retina Display</div>
                                                    <div className='tag-label'>Gaming</div>
                                                    <div className='tag-label'>Wireless Charge</div>
                                                    <div className='tag-label'>Retina Display</div>
                                                    <div className='tag-label'>Gaming</div>
                                                </div>
                                            </Link>
                                            <div className='col-12'>
                                                <div className='wishlist-btn-wrap d-flex align-items-center'>
                                                    <button type='button' className='btn btn-outline w-100' onClick={() => setSimilarhow(true)}>Show Similar</button>
                                                    <Link to='/Cart' className='btn btn-outline ml-3 w-100 d-flex align-items-center justify-content-center'>
                                                        <span className='d-flex'>
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.9 11.1V3.89995H2.7V2.69995H4.5C4.65913 2.69995 4.81174 2.76317 4.92426 2.87569C5.03678 2.98821 5.1 3.14082 5.1 3.29995V10.5H12.5628L13.7628 5.69995H6.3V4.49995H14.532C14.6232 4.49995 14.7132 4.52075 14.7952 4.56075C14.8771 4.60076 14.9489 4.65892 15.005 4.73082C15.0611 4.80272 15.1001 4.88647 15.119 4.9757C15.1379 5.06492 15.1362 5.15728 15.114 5.24575L13.614 11.2458C13.5815 11.3755 13.5066 11.4907 13.4011 11.573C13.2957 11.6553 13.1658 11.6999 13.032 11.7H4.5C4.34087 11.7 4.18826 11.6367 4.07573 11.5242C3.96321 11.4117 3.9 11.2591 3.9 11.1ZM5.1 15.3C4.78174 15.3 4.47651 15.1735 4.25147 14.9485C4.02643 14.7234 3.9 14.4182 3.9 14.1C3.9 13.7817 4.02643 13.4765 4.25147 13.2514C4.47651 13.0264 4.78174 12.9 5.1 12.9C5.41826 12.9 5.72348 13.0264 5.94853 13.2514C6.17357 13.4765 6.3 13.7817 6.3 14.1C6.3 14.4182 6.17357 14.7234 5.94853 14.9485C5.72348 15.1735 5.41826 15.3 5.1 15.3ZM12.3 15.3C11.9817 15.3 11.6765 15.1735 11.4515 14.9485C11.2264 14.7234 11.1 14.4182 11.1 14.1C11.1 13.7817 11.2264 13.4765 11.4515 13.2514C11.6765 13.0264 11.9817 12.9 12.3 12.9C12.6183 12.9 12.9235 13.0264 13.1485 13.2514C13.3736 13.4765 13.5 13.7817 13.5 14.1C13.5 14.4182 13.3736 14.7234 13.1485 14.9485C12.9235 15.1735 12.6183 15.3 12.3 15.3Z" fill="#121415"/>
                                                            </svg>
                                                        </span>
                                                        <span className='ml-1'>Add to Cart</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='wishlist__wrapper wishlist-outOfStock__wrapper'>
                        <div className='container'>
                            <div className='wishlist__container'>
                                <div className='mar-spacer h-5' />
                                <h2 className='wishlist-heading'>Out of Stock <span className='ml-2'>(2)</span> </h2>
                                <div className='mar-spacer h-4' />

                                <div className='product-wrapper'>
                                    {CategoryListData.slice(0, 2).map(data => (
                                        <div className='product-list' key={data._id}>
                                            {data.label ?
                                                <div className='product-label-tag'>{data.label}</div> : ''}
                                            <div className='product-thumb'>
                                                <img src={data.Image} className='img-fluid' alt={data.productName} />
                                            </div>
                                            <Link className='product-details' to='/ProductDetails'>
                                                <h2>{data.productName}</h2>
                                                {data.productStorage.productColor ||  data.productStorage.ram || data.productStorage.ram ?
                                                    <p className='product-muted'>
                                                        {data.productColor}, {' '}
                                                        {data.productStorage.Storage} + {' '}
                                                        {data.productStorage.ram}
                                                    </p> : null
                                                }
                                                {data.productNewPrice || data.productOldPrice || data.productOffer ?
                                                    <div className='product-price'>
                                                        {data.productNewPrice ?
                                                            <div className='new-price'>₹{data.productNewPrice}</div> : null
                                                        }
                                                        {data.productNewPrice ?
                                                            <div className='old-price ml-1'>₹{data.productOldPrice}</div> : null
                                                        }
                                                        {data.productNewPrice ?
                                                            <div className='price-discount'>{data.productOffer}</div> : null
                                                        }
                                                    </div> : null
                                                }
                                                <div className='product-offer'>
                                                    {data.BrandOfferList.List1.length > 0 ?
                                                        <div className='product-offer-list'>
                                                            <div className='offer-icon'>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17.9994 2.5H10.6427L2.16602 10.9767L9.52268 18.3333L17.9994 9.85667V2.5ZM15.0827 4.16667C15.7727 4.16667 16.3327 4.72667 16.3327 5.41667C16.3327 6.10667 15.7727 6.66667 15.0827 6.66667C14.3927 6.66667 13.8327 6.10667 13.8327 5.41667C13.8327 4.72667 14.3927 4.16667 15.0827 4.16667Z" fill="#121415"/>
                                                                </svg>
                                                            </div>
                                                            <div className='offer-label'>{data.BrandOfferList.List1}</div>
                                                        </div> : null
                                                    }	
                                                    {data.BrandOfferList.List2.length > 0 ?
                                                        <div className='product-offer-list'>
                                                            <div className='offer-icon'>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_444_12766)">
                                                                        <path d="M12.9158 7.64296L18.7495 7.64296L18.7495 13.4766L17.0831 13.4766L17.0825 10.4873L9.32138 18.2496L8.14287 17.071L15.9045 9.30937L12.9158 9.30937L12.9158 7.64296ZM2.25031 6.52337L3.91673 6.52337L3.91673 9.51207L11.6784 1.7504L12.8569 2.92891L5.09524 10.6906L8.08394 10.6906L8.08394 12.357L2.25031 12.357L2.25031 6.52337Z" fill="#121415"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_444_12766">
                                                                            <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            </div>
                                                            <div className='offer-label'>{data.BrandOfferList.List2}</div>
                                                        </div> : null
                                                    }
                                                </div>
                                                {data.CompareLink.length > 0 ?
                                                    <div className='product-compare'>+ Compare</div> : null
                                                }
                                                <div className='product-tags'>
                                                    <div className='tag-label'>PhotoGraphy</div>
                                                    <div className='tag-label'>Wireless Charge</div>
                                                    <div className='tag-label'>Retina Display</div>
                                                    <div className='tag-label'>Gaming</div>
                                                    <div className='tag-label'>Wireless Charge</div>
                                                    <div className='tag-label'>Retina Display</div>
                                                    <div className='tag-label'>Gaming</div>
                                                </div>
                                            </Link>
                                            <div className='col-12'>
                                                <div className='wishlist-btn-wrap d-flex align-items-center'>
                                                    <button type='button' className='btn btn-outline w-100' onClick={() => setSimilarhow(true)}>Show Similar</button>
                                                    <button type='button' className='btn btn-outline ml-3 w-100' onClick={() => setModalShow(true)}>Notify Me</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <NotifyMe 
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </>
            }
            <ModalSimilarProducts
                show={similarShow}
                onHide={() => setSimilarhow(false)}
            /> 
        </>
    )
}

export default Wishlist