import React from 'react';
import Form from 'react-bootstrap/Form'

const ChangeLanguageright = () => {
    return (
        <>
            <div className='order-success__wrapper'>
                <div className='container'>
                    <div className='order-confirmation m-0'>
                        <div className="lagnguage-main_wrapper">
                            <div className="prefer-lang-text">
                               prefered language
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>English</label>
                            </div>
                            <div className='h-1_5'/>

                            <div className="prefer-lang-text">
                            More languages
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Tamil - தமிழ்</label>
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Kannada - ಕನ್ನಡ</label>
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Telugu - తెలుగు</label>
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Malayalam - മലയാളം</label>
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Hindi - हिन्दी</label>
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Bengali - বাংলা</label>
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Punjabi - ਅਸਾਮੀ</label>
                            </div>
                            <div className='h-1_5'/>  <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Gujarati - ગુજરાતી</label>
                            </div>
                            <div className='h-1_5'/>
                            <div className="Language-sort_filter-tab">
                                <Form.Check className='filter__checkbox sort__checkbox language-check' aria-label="option 1" type='radio' name='radio'/>
                                <label htmlFor="label" className='lang-filter_sort'>Odia - ଘୃଣା କରେ |</label>
                            </div>
                            <div className='h-1_5'/>

                        </div>

                            
            
                    </div>
                </div>
            </div>

        </>
    )
}

export default ChangeLanguageright