import React, {useState} from 'react';
import Carousel from 'react-multi-carousel';
import CustomCarouselDots from './CustomCarouselDots';
import CustomCarouselArrows from './CustomCarouselArrows';
import Wishlist from '../Common/Wishlist';
import CartOffcanvas from './CartOffcanvas'; 
import NotifyMe from './NotifyMe';


const ProductGallery = ({ DataList, productData }) => {
    const [modalShow, setModalShow] = useState(false);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    };

    console.log('kjkjjn', productData);
    return (
        <>
            <div className='product-gallery'>
                <Wishlist wishlistActive={wishlistActive} handleToggle={handleToggle} />
                <Carousel
                    partialVisible={false}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="carouser-container"
                    customDot={<CustomCarouselDots ImageGallery={DataList} />}
                    customButtonGroup={<CustomCarouselArrows />}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={true}
                    renderDotsOutside={true}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 1
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 1
                        }
                    }}
                    showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {DataList.map((Data) => {
                        return (
                            <div className='home-banner-slider' key={Data._id}>
                                <img src={Data.Image} className='img img-fluid w-100' alt='' />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
            <div className='d-flex w-100 btn-cart__wrapper'>
                {productData.stock_status ?
                    <>
                        <CartOffcanvas  />
                        <button type='button' className='btn btn-dark-custom-outline col ml-1_5'>Buy Now</button>
                    </> :
                    <button type='button' className='btn btn-outline w-100' onClick={() => setModalShow(true)}>Notify Me</button>
                }
            </div>
            <div className='mar-spacer h-3' />
            <NotifyMe 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default ProductGallery