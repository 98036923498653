import React from 'react';
import Form from 'react-bootstrap/Form';

const Step1 = () => {
    return (
        <>
            <h6 className='heading-group-banks'>Emi Plans</h6>
            <div className='bank-list__wrap exchange-list__wrap'>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹2,776 for 3 months</span>
                            <div className='emi-card-intrest'>₹2,776 for 3 months</div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹1461 for 6 months</span>
                            <div className='emi-card-intrest'>Total Interest Charged: ₹371 </div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹992 for 9 months</span>
                            <div className='emi-card-intrest'>Total Interest Charged: ₹534 </div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹992 for 12 months</span>
                            <div className='emi-card-intrest'>Total Interest Charged: ₹698 </div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>                    
                </div>
            </div>    

            <div className='mar-spacer h-13' />   
        </>
    )
}

export default Step1