import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import prebook from '../../Images/Product/prebook.png'

const ModalBookSuccessfull = () => {
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setModalShow({ show: true });
        }, 2000);
    }, [])
    return (
        <div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-lg-custom modal-nfm-custom'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                        <div className='notify-me__wrap mb-4'>
                            <div className='notify-me__media'>
                                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.0003 34.8334C10.2556 34.8334 3.16699 27.7448 3.16699 19.0001C3.16699 10.2553 10.2556 3.16675 19.0003 3.16675C27.7451 3.16675 34.8337 10.2553 34.8337 19.0001C34.8337 27.7448 27.7451 34.8334 19.0003 34.8334ZM17.4217 25.3334L28.6159 14.1377L26.3771 11.8988L17.4217 20.8557L12.9425 16.3765L10.7037 18.6153L17.4217 25.3334Z" fill="#278C4C"/>
                                </svg>
                            </div>
                            <div className='notify-me__content ml-1_5'>
                                <h4 className='prebook__head mb-2'>Booking Successful!</h4>
                                <p className='prebook__text mb-0'>We will notify you once the product is out for sale</p>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='prebook-wrap__content'>
                        <p>You can find this item in your Order history</p>
                        <div className='prebook-wrap__media mb-3'>
                            <img src={prebook} className='img-fluid' alt='' />
                        </div>
                        <div className='prebook-wrap__flex text-center'>
                            <div className='prebook-wrap__info'>Samsung Galaxy Z Fold 2 5G</div>
                            <div className='prebook-wrap__variant'>Mystic Purple, 256GB + 8GB</div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mb-3'>
                        <button type='button' className='btn btn-dark-custom btn-dark-prebook-spacing'>View Details</button>
                        <button type='button' className='btn btn-dark-custom-outline btn-dark-prebook-spacing ml-3'>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalBookSuccessfull
