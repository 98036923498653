import React from 'react';
import BlogCategoryContent from '../BlogInnerContent/BlogCategoryContent';

const CategoaryTab = () => {
    return (
        <>
            <div className="blog-filter-tab_wrapper blog-mr-t">
                <div className="filter_heading">
                    Filter by topic
                </div>
                <div className="product-cat-tags">
                    <div className="product-tag_label">Sangeetha Mobiles</div>
                    <div className="product-tag_label ">Oppo 
                    </div>
                    <div className="product-tag_label">Vivo</div>
                    <div className="product-tag_label">Apple</div>
                    <div className="product-tag_label">Smartphones</div>
                    <div className="product-tag_label">Buying guide</div>
                    <div className="product-tag_label">Laptops</div>
                    <div className="product-tag_label">Camera</div>
                    <div className="product-tag_label">Fridge</div>
                    <div className="product-tag_label">Kitchen</div>
                    <div className="product-tag_label">TV</div>
                    <div className="product-tag_label">Camera</div>


                </div>
                <div className="popular-blog_contents">
                    <div className='mar-spacer h-1_2  mb-2' />
                    <BlogCategoryContent/>
                </div>
            </div>

        </>
    )
}

export default CategoaryTab