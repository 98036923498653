import React from 'react';
import Form from 'react-bootstrap/Form'
import screensize1 from '../../../Images/CategoryDetails/filter/screensize1.png';
import screensize2 from '../../../Images/CategoryDetails/filter/screensize2.png';
import screensize3 from '../../../Images/CategoryDetails/filter/screensize3.png';




const ScreenSize = () => {
    return (
        <>
            <div className='filter-tab__lists'>
                <div className='filter-tab__list screen-size-filter'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" type='radio' name='radio'/>               
                        <div className='filter-tab__flex'>
                            <div className='filter-screen-size'>
                                <img src={screensize1} alt="" />
                            </div>

                            <div className='filter-screen-size-text'>
                                <span className='Screensize-small'>Small</span>
                                <span className='Screensize-inch'>3.2” - 4.5”</span>
                            </div>
                        </div>
                    </div>
                    <span className='filter-tab__count'>180</span>
                </div>
                <div className='filter-tab__list screen-size-filter'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" type='radio' name='radio'/>               
                        <div className='filter-tab__flex'>
                            <div className='filter-screen-size'>
                                <img src={screensize2} alt="" />
                            </div>

                            <div className='filter-screen-size-text'>
                                <span className='Screensize-small'>Small</span>
                                <span className='Screensize-inch'>3.2” - 4.5”</span>
                            </div>
                        </div>
                    </div>
                    <span className='filter-tab__count'>210</span>
                </div>
                <div className='filter-tab__list screen-size-filter'>  
                    <div className='filter-tab__flex'>
                        <Form.Check className='filter__checkbox screensize_checkbox' aria-label="option 1" type='radio' name='radio'/>               
                        <div className='filter-tab__flex'>
                            <div className='filter-screen-size'>
                                <img src={screensize3} alt="" />
                            </div>

                            <div className='filter-screen-size-text'>
                                <span className='Screensize-small'>Small</span>
                                <span className='Screensize-inch'>3.2” - 4.5”</span>
                            </div>
                        </div>
                    </div>
                    <span className='filter-tab__count'>210</span>
                </div>   
            </div>

        </>
    )
}

export default ScreenSize