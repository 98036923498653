import React from 'react';

import shoppingCart from '../../../Images/product/shoppingCart.png';
import shoppingCart2 from '../../../Images/CategoryDetails/Cart/iphone13-pro.png'

const SaveForLater = () => {
    
    return (
        <>
            <div className='shopping-cart___wrap'>
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <img src={shoppingCart2} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>IPhone 13 Pro</h4>
                        <p>
                            <span>Purple </span>,
                            <span>256GB </span> + 
                            <span> 8GB</span>
                        </p>
                        
                        <div className='price-offer addToCart-offcanvas mt-3'>
                            <div className='new-price'>₹79,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3337 5.33337H13.3337L15.3337 8.03737V12H13.977C13.8977 12.5562 13.6205 13.0652 13.1961 13.4333C12.7718 13.8015 12.2288 14.0042 11.667 14.0042C11.1052 14.0042 10.5622 13.8015 10.1379 13.4333C9.71351 13.0652 9.43624 12.5562 9.35699 12H5.97699C5.89774 12.5562 5.62047 13.0652 5.19611 13.4333C4.77175 13.8015 4.2288 14.0042 3.66699 14.0042C3.10518 14.0042 2.56223 13.8015 2.13787 13.4333C1.71352 13.0652 1.43625 12.5562 1.35699 12H0.666992V4.00004C0.666992 3.82323 0.73723 3.65366 0.862254 3.52864C0.987279 3.40361 1.15685 3.33337 1.33366 3.33337H10.667C10.8438 3.33337 11.0134 3.40361 11.1384 3.52864C11.2634 3.65366 11.3337 3.82323 11.3337 4.00004V5.33337ZM11.3337 6.66671V8.66671H14.0003V8.47671L12.6617 6.66671H11.3337Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                            <span className="label__similarProd">Monday, 24 Jan.</span>
                        </div>
                    </div>
                </div>
                
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8 5V13.4C12.8 13.5591 12.7368 13.7117 12.6243 13.8243C12.5117 13.9368 12.3591 14 12.2 14H3.8C3.64087 14 3.48826 13.9368 3.37574 13.8243C3.26321 13.7117 3.2 13.5591 3.2 13.4V5H2V3.8H14V5H12.8ZM4.4 5V12.8H11.6V5H4.4ZM5 2H11V3.2H5V2ZM7.4 6.8H8.6V11H7.4V6.8Z" fill="#121415"/>
                            </svg>                                    
                        </span>
                        <span className='ml-1'>Remove</span>
                    </button>
                    <button type='button' className='btn btn-save-for-later btn-cart-h ml-2'>
                        <span className='d-flex'>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 12V3H2V1.5H4.25C4.44891 1.5 4.63968 1.57902 4.78033 1.71967C4.92098 1.86032 5 2.05109 5 2.25V11.25H14.3285L15.8285 5.25H6.5V3.75H16.79C16.904 3.75 17.0165 3.776 17.119 3.826C17.2214 3.87601 17.3111 3.94871 17.3813 4.03859C17.4514 4.12847 17.5001 4.23315 17.5237 4.34468C17.5473 4.45622 17.5452 4.57166 17.5175 4.68225L15.6425 12.1823C15.6019 12.3444 15.5082 12.4884 15.3764 12.5913C15.2446 12.6941 15.0822 12.75 14.915 12.75H4.25C4.05109 12.75 3.86032 12.671 3.71967 12.5303C3.57902 12.3897 3.5 12.1989 3.5 12ZM5 17.25C4.60217 17.25 4.22064 17.092 3.93934 16.8107C3.65804 16.5294 3.5 16.1478 3.5 15.75C3.5 15.3522 3.65804 14.9706 3.93934 14.6893C4.22064 14.408 4.60217 14.25 5 14.25C5.39782 14.25 5.77936 14.408 6.06066 14.6893C6.34196 14.9706 6.5 15.3522 6.5 15.75C6.5 16.1478 6.34196 16.5294 6.06066 16.8107C5.77936 17.092 5.39782 17.25 5 17.25ZM14 17.25C13.6022 17.25 13.2206 17.092 12.9393 16.8107C12.658 16.5294 12.5 16.1478 12.5 15.75C12.5 15.3522 12.658 14.9706 12.9393 14.6893C13.2206 14.408 13.6022 14.25 14 14.25C14.3978 14.25 14.7794 14.408 15.0607 14.6893C15.342 14.9706 15.5 15.3522 15.5 15.75C15.5 16.1478 15.342 16.5294 15.0607 16.8107C14.7794 17.092 14.3978 17.25 14 17.25Z" fill="#121415"/>
                            </svg>                                     
                        </span>
                        <span className='ml-1'>Add to Cart</span>
                    </button>
                </div>
                <div className='mar-spacer h-3' />
                <div className='shopping-cart__list'>
                    <div className='shopping-cart___media'>
                        <img src={shoppingCart} className='img img-fluid' alt='' />
                    </div>
                    <div className='shopping-cart__info'>
                        <h4>Samsung Galaxy Z Fold 2 5G</h4>
                        
                        <div className='price-offer addToCart-offcanvas mt-3'>
                            <div className='new-price'>₹19,999</div>
                            <div className='old-price'>₹24,559</div>
                        </div>
                        <div className="deliverTo__info">
                            <span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3337 5.33337H13.3337L15.3337 8.03737V12H13.977C13.8977 12.5562 13.6205 13.0652 13.1961 13.4333C12.7718 13.8015 12.2288 14.0042 11.667 14.0042C11.1052 14.0042 10.5622 13.8015 10.1379 13.4333C9.71351 13.0652 9.43624 12.5562 9.35699 12H5.97699C5.89774 12.5562 5.62047 13.0652 5.19611 13.4333C4.77175 13.8015 4.2288 14.0042 3.66699 14.0042C3.10518 14.0042 2.56223 13.8015 2.13787 13.4333C1.71352 13.0652 1.43625 12.5562 1.35699 12H0.666992V4.00004C0.666992 3.82323 0.73723 3.65366 0.862254 3.52864C0.987279 3.40361 1.15685 3.33337 1.33366 3.33337H10.667C10.8438 3.33337 11.0134 3.40361 11.1384 3.52864C11.2634 3.65366 11.3337 3.82323 11.3337 4.00004V5.33337ZM11.3337 6.66671V8.66671H14.0003V8.47671L12.6617 6.66671H11.3337Z" fill="#C4CAD0"/>
                                </svg>
                            </span>
                            <span className="info__similarProd ml-1">Delivery by &nbsp;</span>
                            <span className="label__similarProd">Monday, 24 Jan.</span>
                            <span className="deliver-type text-uppercase">Free</span>
                        </div>
                    </div>
                </div>
                <div className='mar-spacer h-1_2' />
                <div className='shopping-cart__btn'>
                    <button type='button' className='btn btn-save-for-later btn-cart-h'>
                        <span className='d-flex'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8 5V13.4C12.8 13.5591 12.7368 13.7117 12.6243 13.8243C12.5117 13.9368 12.3591 14 12.2 14H3.8C3.64087 14 3.48826 13.9368 3.37574 13.8243C3.26321 13.7117 3.2 13.5591 3.2 13.4V5H2V3.8H14V5H12.8ZM4.4 5V12.8H11.6V5H4.4ZM5 2H11V3.2H5V2ZM7.4 6.8H8.6V11H7.4V6.8Z" fill="#121415"/>
                            </svg>                                    
                        </span>
                        <span className='ml-1'>Remove</span>
                    </button>
                    <button type='button' className='btn btn-save-for-later btn-cart-h ml-2'>
                        <span className='d-flex'>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 12V3H2V1.5H4.25C4.44891 1.5 4.63968 1.57902 4.78033 1.71967C4.92098 1.86032 5 2.05109 5 2.25V11.25H14.3285L15.8285 5.25H6.5V3.75H16.79C16.904 3.75 17.0165 3.776 17.119 3.826C17.2214 3.87601 17.3111 3.94871 17.3813 4.03859C17.4514 4.12847 17.5001 4.23315 17.5237 4.34468C17.5473 4.45622 17.5452 4.57166 17.5175 4.68225L15.6425 12.1823C15.6019 12.3444 15.5082 12.4884 15.3764 12.5913C15.2446 12.6941 15.0822 12.75 14.915 12.75H4.25C4.05109 12.75 3.86032 12.671 3.71967 12.5303C3.57902 12.3897 3.5 12.1989 3.5 12ZM5 17.25C4.60217 17.25 4.22064 17.092 3.93934 16.8107C3.65804 16.5294 3.5 16.1478 3.5 15.75C3.5 15.3522 3.65804 14.9706 3.93934 14.6893C4.22064 14.408 4.60217 14.25 5 14.25C5.39782 14.25 5.77936 14.408 6.06066 14.6893C6.34196 14.9706 6.5 15.3522 6.5 15.75C6.5 16.1478 6.34196 16.5294 6.06066 16.8107C5.77936 17.092 5.39782 17.25 5 17.25ZM14 17.25C13.6022 17.25 13.2206 17.092 12.9393 16.8107C12.658 16.5294 12.5 16.1478 12.5 15.75C12.5 15.3522 12.658 14.9706 12.9393 14.6893C13.2206 14.408 13.6022 14.25 14 14.25C14.3978 14.25 14.7794 14.408 15.0607 14.6893C15.342 14.9706 15.5 15.3522 15.5 15.75C15.5 16.1478 15.342 16.5294 15.0607 16.8107C14.7794 17.092 14.3978 17.25 14 17.25Z" fill="#121415"/>
                            </svg>                                     
                        </span>
                        <span className='ml-1'>Add to Cart</span>
                    </button>
                </div>
                <div className='mar-spacer h-3' />
            </div>
        </>
    )
}

export default SaveForLater