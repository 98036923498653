import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SangeethaRecommends from '../Components/Order/SangeethaRecommends';
import TrackPanel from '../Components/Order/TrackPanel';
import OrderDelayed from '../Components/Order/OrderDelayed';
import img2 from '../Images/Order/1.png';
import { prebookInfo } from '../Data';

const OrderDetails = () => {
    const [ productData, setProductData ] = useState([]);

    useEffect(() => {
        setProductData(prebookInfo[0])
    }, []);
    const [ OrderStatus ] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [ wishlistActive, setWishlistState ] = useState(false);
    const handleToggle = () => {
        setWishlistState(!wishlistActive);
    }
    return (
        <>
            <div className='order-details__wrap'>
                <div className='container'>
                    <div className='order--container'>
                        <div className='order-detail__section'>
                            <div className='Order_Id'>
                                <div className='order-id-txt'>
                                    ORDER ID:
                                    <span> 983729203490</span>
                                </div>
                            </div>
                            {OrderStatus ?
                                <div className='order-este__delivery'>
                                    <div className='order-este__details d-flex align-items-center'>
                                        <div className='address-este___media'>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.40655 11.3465L20.5776 4.304L16.0001 2L2.41956 8.836L7.40655 11.3465Z" fill="#121415"/>
                                                <path d="M24.5775 6.31738L11.4065 13.3599L16 15.6724L29.5805 8.83588L24.5775 6.31738Z" fill="#121415"/>
                                                <path d="M15.625 16.3275L11 13.9995V19.1145L9 17.101H7V11.986L2 9.46948V23.1415L15.625 30V16.3275Z" fill="#121415"/>
                                                <path d="M16.375 16.3275V30L30 23.1415V9.46948L16.375 16.3275Z" fill="#121415"/>
                                            </svg>
                                        </div>
                                        <div className='address-este____content ml-3'>
                                            <h5 className='mb-0'>Order Placed</h5>
                                            <div className="order_deliverTo__info">
                                                <span className="order_info__similarProd">Expected delivery by Wed, 6th Jan</span>
                                            </div>
                                        </div>
                                    </div>
                                    <TrackPanel />
                                </div> : 
                                <div className='order-este__delivery'>
                                    <div className='order-este__details d-flex align-items-center'>
                                        <div className='address-este___media'>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 16C0 24.8368 7.1632 32 16 32C24.8368 32 32 24.8368 32 16C32 7.1632 24.8368 0 16 0C7.1632 0 0 7.1632 0 16Z" fill="#278C4C"/>
                                                <path d="M22.803 10.5211L22.6659 10.3921L22.5289 10.5211L13.9999 18.5494L9.47017 14.2861L9.3331 14.1571L9.19602 14.2861L7.86293 15.5408L7.70819 15.6864L7.86293 15.832L13.8628 21.4791L13.9999 21.6081L14.137 21.4791L24.1371 12.0671L24.2919 11.9214L24.137 11.7758L22.803 10.5211Z" fill="white" stroke="white" strokeWidth="0.4"/>
                                            </svg>
                                        </div>
                                        <div className='address-este____content ml-3'>
                                            <h5 className='mb-0'>Order Delivered</h5>
                                            <div className="order_deliverTo__info">
                                                <span className="order_info__similarProd">Expected delivery by Wed, 6th Jan</span>
                                            </div>
                                        </div>
                                    </div>
                                    <TrackPanel />
                                </div>
                            }
                            <div className='item-delayed--wrap'>
                                <OrderDelayed />
                            </div>
                            <div className='item-include'>
                                <h6 className='item-inc__heading'>items included</h6>
                                <div className='item-inc__wrap'>
                                    <div className='order-este__include d-flex align-items-center'>
                                        <div className='orderdetails-este____media'>
                                            <img src={img2} className='img img-fluid' alt='' />
                                        </div>
                                        <div className='order-include__details ml-3'>
                                            <h5 className='mb-0'>Samsung Galaxy Z Fold 2 5G</h5>
                                            <div className="order_deliverTo__info mb-2">
                                                <span className="order_info__similarProd">Mystic Purple  &nbsp;</span>
                                                <span className="order_info__similarProd">, 256GB + 8GB &nbsp;</span>
                                            </div>
                                            <div className='order-qty'>Qty : 1 </div>
                                        </div>
                                    </div>
                                    <div className='mad__wrap border-bottom-0'>
                                        <div className='mad__media'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.783 2.826L12 1L20.217 2.826C20.4391 2.87536 20.6377 2.99897 20.78 3.1764C20.9224 3.35384 21 3.57452 21 3.802V13.789C20.9999 14.7767 20.756 15.7492 20.2899 16.62C19.8238 17.4908 19.1499 18.2331 18.328 18.781L12 23L5.672 18.781C4.85027 18.2332 4.17646 17.4911 3.71035 16.6205C3.24424 15.7498 3.00024 14.7776 3 13.79V3.802C3.00004 3.57452 3.07764 3.35384 3.21999 3.1764C3.36234 2.99897 3.56094 2.87536 3.783 2.826ZM13 10V5L8 12H11V17L16 10H13Z" fill="#121415"/>
                                            </svg>
                                        </div>
                                        <div className='mad__content ml-3'>
                                            <div className='mad-head__content'>Money and Damage Protection (MAD) </div>
                                            <div className='mad-info__content'>1 Year Protection</div>
                                        </div>
                                    </div>
                                    <div className='mad__wrap'>
                                        <div className='mad__media'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.5963 9.46704L20.8654 9.46704L20.8654 15.7362L19.0746 15.7362L19.074 12.5237L10.7335 20.8655L9.46702 19.599L17.8081 11.2579L14.5963 11.2579V9.46704ZM3.13456 8.26387L4.92538 8.26387L4.92538 11.4757L13.2665 3.13459L14.533 4.40108L6.19187 12.7422L9.40369 12.7422L9.40369 14.533L3.13456 14.533L3.13456 8.26387Z" fill="#121415"/>
                                            </svg>
                                        </div>
                                        <div className='mad__content ml-3'>
                                            <div className='mad-head__content'>Exchange on Apple iPhone XR 128GBMAD) </div>
                                            <div className='mad-info__content'>Please keep your old phone ready for verification.</div>
                                        </div>
                                    </div>
                                    <div className='replacement__wrap justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <div className='replacement__media'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 22.5C6.201 22.5 1.5 17.799 1.5 12C1.5 6.201 6.201 1.5 12 1.5C17.799 1.5 22.5 6.201 22.5 12C22.5 17.799 17.799 22.5 12 22.5Z" fill="#278C4C"/>
                                                    <path d="M14.7825 4.71729L13.7175 5.78229L15.4425 7.49979H7.5C6.67158 7.49979 6 8.17136 6 8.99979V11.2498H7.5V8.99979H15.4425L13.725 10.7173L14.7825 11.7748L17.7825 8.77479C18.0733 8.48224 18.0733 8.00983 17.7825 7.71729L14.7825 4.71729Z" fill="#278C4C"/>
                                                    <path d="M16.5001 15.0001H8.55762L10.2751 13.2826L9.21762 12.2251L6.21762 15.2251C5.92681 15.5176 5.92681 15.9901 6.21762 16.2826L9.21762 19.2826L10.2751 18.2251L8.55762 16.5001H16.5001C17.3285 16.5001 18.0001 15.8285 18.0001 15.0001V12.7501H16.5001V15.0001Z" fill="#278C4C"/>
                                                </svg>
                                            </div>
                                            <div className='replacement__content ml-3'>
                                                <div className='replacement-head__content'>Requested Replacement </div>
                                                <div className='replacement-info__content'>In progress</div>
                                            </div>
                                        </div>

                                        <div className='d-fex'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.9763 10.0001L6.85134 5.87511L8.02968 4.69678L13.333 10.0001L8.02967 15.3034L6.85134 14.1251L10.9763 10.0001Z" fill="#278C4C"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className='item-inc__wrap'>
                                    <div className='order-este__include d-flex align-items-center mb-3'>
                                        <div className='orderdetails-este____media'>
                                            <img src={img2} className='img img-fluid' alt='' />
                                        </div>
                                        <div className='order-include__details ml-3'>
                                            <h5 className='mb-0'>Samsung Galaxy Z Fold 2 5G</h5>
                                            <div className="order_deliverTo__info mb-2">
                                                <span className="order_info__similarProd">Mystic Purple  &nbsp;</span>
                                                <span className="order_info__similarProd">, 256GB + 8GB &nbsp;</span>
                                            </div>
                                            <div className='order-qty'>Qty : 1 </div>
                                        </div>
                                    </div>
                                    <div className='review-rate__wrap d-flex align-items-start justify-content-between'>
                                        <div className='d-flex align-items-start flex-column'>
                                            <div className='d-flex align-items-center'>
                                                <label htmlFor='label' className='review-rate__label'>Rate Product</label>
                                                <div className='review__rate ml-3 d-flex align-items-center'>
                                                    <span className='d-flex'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.9999 18.26L4.94691 22.208L6.52191 14.28L0.586914 8.792L8.61391 7.84L11.9999 0.5L15.3859 7.84L23.4129 8.792L17.4779 14.28L19.0529 22.208L11.9999 18.26Z" fill="#121415"/>
                                                        </svg>
                                                    </span>
                                                    <span className='d-flex ml-2'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 18.26L4.94698 22.208L6.52198 14.28L0.586975 8.792L8.61398 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26ZM12 15.968L16.247 18.345L15.298 13.572L18.871 10.267L14.038 9.694L12 5.275L9.96198 9.695L5.12898 10.267L8.70197 13.572L7.75297 18.345L12 15.968Z" fill="#C4CAD0"/>
                                                        </svg>
                                                    </span>
                                                    <span className='d-flex ml-2'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 18.26L4.94698 22.208L6.52198 14.28L0.586975 8.792L8.61398 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26ZM12 15.968L16.247 18.345L15.298 13.572L18.871 10.267L14.038 9.694L12 5.275L9.96198 9.695L5.12898 10.267L8.70197 13.572L7.75297 18.345L12 15.968Z" fill="#C4CAD0"/>
                                                        </svg>
                                                    </span>
                                                    <span className='d-flex ml-2'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 18.26L4.94698 22.208L6.52198 14.28L0.586975 8.792L8.61398 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26ZM12 15.968L16.247 18.345L15.298 13.572L18.871 10.267L14.038 9.694L12 5.275L9.96198 9.695L5.12898 10.267L8.70197 13.572L7.75297 18.345L12 15.968Z" fill="#C4CAD0"/>
                                                        </svg>
                                                    </span>
                                                    <span className='d-flex ml-2'>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 18.26L4.94698 22.208L6.52198 14.28L0.586975 8.792L8.61398 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26ZM12 15.968L16.247 18.345L15.298 13.572L18.871 10.267L14.038 9.694L12 5.275L9.96198 9.695L5.12898 10.267L8.70197 13.572L7.75297 18.345L12 15.968Z" fill="#C4CAD0"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <p className='mt-3 mb-0'>Quality of the product is not very good but the look and feel is great</p>
                                        </div>
                                        <div className='review__link' onClick={() => setModalShow(true)} role='button' aria-hidden="true">Write Review</div>
                                    </div>
                                </div>
                            </div>
                            {productData.stock_type === 'prebookInfo' &&
                                <div className='d-flex align-items-center w-100 mb-3'>
                                    <button type='button' className='btn btn-dark-custom col-flex'>Buy Now</button>
                                    <button type='button' className='btn btn-dark-custom-outline col-flex ml-4 d-flex align-items-center justify-content-center'>
                                        <span className='d-flex'>
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.83268 13.3333V3.33329H2.16602V1.66663H4.66602C4.88703 1.66663 5.09899 1.75442 5.25527 1.9107C5.41155 2.06698 5.49935 2.27895 5.49935 2.49996V12.5H15.8643L17.531 5.83329H7.16602V4.16663H18.5993C18.726 4.16663 18.851 4.19551 18.9649 4.25107C19.0787 4.30664 19.1784 4.38742 19.2563 4.48728C19.3342 4.58714 19.3884 4.70346 19.4146 4.82738C19.4408 4.95131 19.4385 5.07958 19.4077 5.20246L17.3243 13.5358C17.2792 13.716 17.1751 13.876 17.0287 13.9902C16.8822 14.1045 16.7018 14.1666 16.516 14.1666H4.66602C4.445 14.1666 4.23304 14.0788 4.07676 13.9225C3.92048 13.7663 3.83268 13.5543 3.83268 13.3333ZM5.49935 19.1666C5.05732 19.1666 4.6334 18.991 4.32084 18.6785C4.00828 18.3659 3.83268 17.942 3.83268 17.5C3.83268 17.0579 4.00828 16.634 4.32084 16.3214C4.6334 16.0089 5.05732 15.8333 5.49935 15.8333C5.94138 15.8333 6.3653 16.0089 6.67786 16.3214C6.99042 16.634 7.16602 17.0579 7.16602 17.5C7.16602 17.942 6.99042 18.3659 6.67786 18.6785C6.3653 18.991 5.94138 19.1666 5.49935 19.1666ZM15.4993 19.1666C15.0573 19.1666 14.6334 18.991 14.3208 18.6785C14.0083 18.3659 13.8327 17.942 13.8327 17.5C13.8327 17.0579 14.0083 16.634 14.3208 16.3214C14.6334 16.0089 15.0573 15.8333 15.4993 15.8333C15.9414 15.8333 16.3653 16.0089 16.6779 16.3214C16.9904 16.634 17.166 17.0579 17.166 17.5C17.166 17.942 16.9904 18.3659 16.6779 18.6785C16.3653 18.991 15.9414 19.1666 15.4993 19.1666Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <span className='ml-2'>Add To Cart</span>
                                    </button>
                                </div>
                            }

                            <div className='bg-theme pb-1' />

                            <div className="order_details-Model">
                                <div className='d-flex flex-column'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.455 15L1 18.5V3C1 2.73478 1.10536 2.48043 1.29289 2.29289C1.48043 2.10536 1.73478 2 2 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V15H5.455ZM4.763 13H16V4H3V14.385L4.763 13ZM8 17H18.237L20 18.385V8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V22.5L17.545 19H9C8.73478 19 8.48043 18.8946 8.29289 18.7071C8.10536 18.5196 8 18.2652 8 18V17Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <h4 className='Order_Pending-msg mb-0 ml-1_5'>Need help with order?</h4>
                                    </div>
                                    <p className='order-cancellation-txt mb-0'>Order cancellation window open till the order is dispatched</p>
                                </div>
                                <button type="button" className="btn px-4 btn-dark-custom-outline btn-contact">Contact Support</button>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className="order_details-Model">
                                <div className='d-flex flex-column w-100'>
                                    <div className="need_help__img d-flex align-items-center mb-4">
                                        <h4 className='Order_Pending-msg mb-0'>Free Services</h4>
                                    </div>
                                    <div className='free-service__wrap'>
                                        <div className='free-service__lists'>
                                            <div className='free-service__media'>
                                                <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="47" height="48" rx="23.5" fill="#121415"/>
                                                    <path d="M24 26V28C22.4087 28 20.8826 28.6321 19.7574 29.7574C18.6321 30.8826 18 32.4087 18 34H16C16 31.8783 16.8429 29.8434 18.3431 28.3431C19.8434 26.8429 21.8783 26 24 26ZM24 25C20.685 25 18 22.315 18 19C18 15.685 20.685 13 24 13C27.315 13 30 15.685 30 19C30 22.315 27.315 25 24 25ZM24 23C26.21 23 28 21.21 28 19C28 16.79 26.21 15 24 15C21.79 15 20 16.79 20 19C20 21.21 21.79 23 24 23ZM30 33.5L27.061 35.045L27.622 31.773L25.245 29.455L28.531 28.977L30 26L31.47 28.977L34.755 29.455L32.378 31.773L32.938 35.045L30 33.5Z" fill="white"/>
                                                </svg>
                                            </div>
                                            <div className='free-service__info'>Delivery by expert</div>
                                        </div>
                                        <div className='free-service__lists'>
                                            <div className='free-service__media'>
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="48" height="48" rx="24" fill="#121415"/>
                                                    <path d="M33.547 15.8862L24.3595 12.0717C24.1293 11.9761 23.8706 11.9761 23.6405 12.0717L14.453 15.8862C14.1031 16.0315 13.875 16.3732 13.875 16.752V21.3892C13.875 27.7741 17.7342 33.5176 23.6457 35.9305C23.8728 36.0232 24.1272 36.0232 24.3543 35.9305C30.2657 33.5176 34.125 27.7742 34.125 21.3892V16.752C34.125 16.3732 33.897 16.0315 33.547 15.8862ZM32.25 21.3892C32.25 26.817 29.0625 31.8138 24 34.0442C19.0728 31.8734 15.75 26.9641 15.75 21.3892V17.3779L24 13.9526L32.25 17.3779V21.3892Z" fill="white"/>
                                                    <path d="M23.01 24.68L20.76 22.68L19.5 23.8L23.01 27L28.5 22.12L27.24 21L23.01 24.68Z" fill="white"/>
                                                </svg>
                                            </div>
                                            <div className='free-service__info'>Tempered Glass Installation at home</div>
                                        </div>
                                        <div className='free-service__lists'>
                                            <div className='free-service__media'>
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="48" height="48" rx="24" fill="#121415"/>
                                                    <g clipPath="url(#clip0_1231_56344)">
                                                        <path d="M36.2491 20.8224L30.249 14.8222C29.9142 14.4876 29.3715 14.4877 29.0368 14.8224C28.8761 14.9832 28.7858 15.201 28.7858 15.4283V17.9998H22.7857C22.3123 17.9998 21.9285 18.3835 21.9285 18.8569C21.9285 19.3304 22.3123 19.7141 22.7857 19.7141H29.643C30.1164 19.7141 30.5001 19.3304 30.5001 18.8569V17.4974L34.4311 21.4284L30.5001 25.3593V23.9998C30.5001 23.5264 30.1164 23.1427 29.643 23.1427H20.2142V20.5712C20.2141 20.0978 19.8303 19.7141 19.3569 19.7142C19.1297 19.7143 18.9118 19.8045 18.751 19.9652L12.751 25.9653C12.4163 26.3 12.4163 26.8426 12.751 27.1773L18.7511 33.1774C18.9118 33.3382 19.1298 33.4286 19.3571 33.4286C19.4697 33.429 19.5814 33.4068 19.6854 33.3635C20.0056 33.2307 20.2143 32.9181 20.2142 32.5714V30H26.2143C26.6877 30 27.0715 29.6162 27.0715 29.1428C27.0715 28.6694 26.6877 28.2856 26.2143 28.2856H19.3571C18.8837 28.2856 18.4999 28.6694 18.4999 29.1428V30.5022L14.569 26.5713L18.4999 22.6404V23.9998C18.4999 24.4732 18.8837 24.857 19.3571 24.857H28.7858V27.4285C28.7859 27.9019 29.1698 28.2856 29.6431 28.2855C29.8703 28.2854 30.0883 28.1952 30.249 28.0345L36.2491 22.0344C36.5837 21.6996 36.5837 21.1571 36.2491 20.8224Z" fill="white"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1231_56344">
                                                            <rect width="24" height="24" fill="white" transform="translate(12.5 12)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className='free-service__info'>Data Transfer at home</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className="order_details-Model">
                                <div className='d-flex flex-column'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.9998 22C6.47676 22 1.99976 17.523 1.99976 12C1.99976 6.477 6.47676 2 11.9998 2C17.5228 2 21.9998 6.477 21.9998 12C21.9998 17.523 17.5228 22 11.9998 22ZM11.9998 20C14.1215 20 16.1563 19.1571 17.6566 17.6569C19.1569 16.1566 19.9998 14.1217 19.9998 12C19.9998 9.87827 19.1569 7.84344 17.6566 6.34315C16.1563 4.84285 14.1215 4 11.9998 4C9.87802 4 7.84319 4.84285 6.3429 6.34315C4.84261 7.84344 3.99976 9.87827 3.99976 12C3.99976 14.1217 4.84261 16.1566 6.3429 17.6569C7.84319 19.1571 9.87802 20 11.9998 20Z" fill="#121415"/>
                                                <path d="M12.9653 10.5322C12.9005 11.2125 12.6667 11.7619 12.2639 12.1805C11.8611 12.5992 11.2569 12.8765 10.4514 13.0126L13.4583 17H12L9 13.0361L9.13889 11.8901H9.77778C10.0046 11.8901 10.2037 11.8796 10.375 11.8587C10.5463 11.8378 10.6944 11.809 10.8194 11.7724C10.9491 11.7305 11.0602 11.6808 11.1528 11.6232C11.2454 11.5604 11.331 11.4898 11.4097 11.4113C11.6134 11.1915 11.7454 10.8985 11.8056 10.5322H9V9.38619H11.7917C11.7546 9.1821 11.6944 9.00942 11.6111 8.86813C11.5324 8.72161 11.4282 8.59079 11.2986 8.47567C11.169 8.36054 10.9977 8.27682 10.7847 8.22449C10.5764 8.17216 10.2963 8.146 9.94444 8.146H9V7H15V8.146H12.3333C12.4954 8.27682 12.6296 8.4495 12.7361 8.66405C12.8426 8.8786 12.9167 9.11931 12.9583 9.38619H15V10.5322H12.9653Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <h4 className='Order_Pending-msg mb-0 ml-1_5'>Payment Method</h4>
                                    </div>
                                    <p className='order-cancellation-txt mb-0'>
                                        <span className='font-500'>Cash on Delivery: </span>
                                        You can pay via Card/Cash or UPI enabled app at the time of delivery. Ask your delivery executive for these options or view this page to receive a link for online payments.
                                    </p>
                                </div>
                                <button type="button" className="btn px-4 btn-dark-custom-outline btn-contact ml-4 px-4 text-nowrap">Pay Now</button>
                            </div>

                            <div className='bg-theme pb-1' />

                            <div className='cart-address__default order_details-Model'>
                                <div className='d-flex flex-column'>
                                    <div className="need_help__img d-flex align-items-center mb-2">
                                        <span className='d-flex'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 23.7279L5.636 17.3639C4.37734 16.1052 3.52019 14.5016 3.17293 12.7558C2.82567 11.0099 3.00391 9.20035 3.6851 7.55582C4.36629 5.91129 5.51984 4.50569 6.99988 3.51677C8.47992 2.52784 10.22 2 12 2C13.78 2 15.5201 2.52784 17.0001 3.51677C18.4802 4.50569 19.6337 5.91129 20.3149 7.55582C20.9961 9.20035 21.1743 11.0099 20.8271 12.7558C20.4798 14.5016 19.6227 16.1052 18.364 17.3639L12 23.7279ZM16.95 15.9499C17.9289 14.9709 18.5955 13.7236 18.8656 12.3658C19.1356 11.0079 18.9969 9.60052 18.4671 8.32148C17.9373 7.04244 17.04 5.94923 15.8889 5.18009C14.7378 4.41095 13.3844 4.00043 12 4.00043C10.6156 4.00043 9.26222 4.41095 8.11109 5.18009C6.95996 5.94923 6.06275 7.04244 5.53292 8.32148C5.00308 9.60052 4.86442 11.0079 5.13445 12.3658C5.40449 13.7236 6.07111 14.9709 7.05 15.9499L12 20.8999L16.95 15.9499ZM12 12.9999C11.4696 12.9999 10.9609 12.7892 10.5858 12.4141C10.2107 12.0391 10 11.5304 10 10.9999C10 10.4695 10.2107 9.96078 10.5858 9.58571C10.9609 9.21064 11.4696 8.99992 12 8.99992C12.5304 8.99992 13.0391 9.21064 13.4142 9.58571C13.7893 9.96078 14 10.4695 14 10.9999C14 11.5304 13.7893 12.0391 13.4142 12.4141C13.0391 12.7892 12.5304 12.9999 12 12.9999Z" fill="#121415"/>
                                            </svg>
                                        </span>
                                        <h4 className='Order_Pending-msg mb-0 ml-1_5'>Delivery Address</h4>
                                    </div>
                                    <div className='logged-default__list border-0'>
                                        <div className='logged-default__label mb-2'>
                                            <span>Rohan Nanda <span className='default__address'>(Default)</span></span>
                                            <span className="btn btn-tag">Home</span>
                                        </div>
                                        <div className='cart-address__section'>
                                            <div className='loggedIn-address__label mb-2'>
                                        103, Udgam Belvedere, Kaverappa Layout, Kadubeesanahalli, Outer Ring Road, Bangalore - 560103
                                            </div>
                                            <div className='loggedIn-address__contact'>
                                                <label htmlFor="label">Mobile: </label>{' '}
                                                <span className='contact-number'>7382901648</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='order-summary__section'>
                            <div className='payment-order__summary'>
                                <div className='cart-order__summary'>
                                    <h4 className='heading-order__summary'>Order Summary</h4>
                                    <div className='order-summary__table'>
                                        <div className='order-summary__body'>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Samsung Galaxy Z Fold 2 5G</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹19,999</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Money and Damage Protection</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹999</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Discount</label>
                                                </div>
                                                <div className='order-summary__col details-text'>
                                                    <span>- ₹400</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row order-summary--dashed-border'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Watercolor gradient Phone grip holder...</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹1,999</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Applied Coupon</label>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>- ₹500</span>
                                                </div>
                                            </div>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col'>
                                                    <label htmlFor="label">Delivery Charges</label>
                                                </div>
                                                <div className='order-summary__col details-text'>
                                                    <span className='text-uppercase'>Free</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-summary__footer'>
                                            <div className='order-summary__row'>
                                                <div className='order-summary__col flex-column d-flex'>
                                                    <span>Total Amount</span>
                                                    <span className='excl-txt'>(Inclusive of all taxes)</span>
                                                </div>
                                                <div className='order-summary__col'>
                                                    <span>₹22,097</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type='button' className='btn btn-dark-custom-outline btn-dark-custom-summary w-100 d-flex justify-content-center align-items-center mt-4'>
                                <span className='d-flex'>
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3333 8.33358H15.5L10.5 13.3336L5.50001 8.33358H9.66667V2.50024H11.3333V8.33358ZM3.83334 15.8336H17.1667V10.0002H18.8333V16.6669C18.8333 16.8879 18.7455 17.0999 18.5893 17.2562C18.433 17.4124 18.221 17.5002 18 17.5002H3.00001C2.77899 17.5002 2.56703 17.4124 2.41075 17.2562C2.25447 17.0999 2.16667 16.8879 2.16667 16.6669V10.0002H3.83334V15.8336Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className='ml-2'>Download Invoice</span>
                            </button>
                        </div>
                    </div>

                    <div className='cart-accessories'>
                        <div className='container'>     
                            <div className='cart-accessories__carousel mb-0'>
                                <div className='h-5 mar-spacer' />
                                <div className='top-head__wrap'>
                                    <h4 className='order-heading__wrap mb-0'>Sangeetha Recommends</h4>
                                </div>
                                
                                <SangeethaRecommends wishlistActive={wishlistActive} handleToggle={handleToggle} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom items-removed__modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 gst-title-'>
                        <div className='d-flex flex-column mb-4'>
                            <h2 className='review-heading mb-4'>Average</h2>
                            <div className='d-flex align-items-center justify-content-center'>
                                <span className='d-flex'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 17L6.12223 20.59L7.72023 13.89L2.49023 9.41L9.35523 8.86L12.0002 2.5L14.6452 8.86L21.5112 9.41L16.2802 13.89L17.8782 20.59L12.0002 17Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className='d-flex ml-1'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 17L6.12223 20.59L7.72023 13.89L2.49023 9.41L9.35523 8.86L12.0002 2.5L14.6452 8.86L21.5112 9.41L16.2802 13.89L17.8782 20.59L12.0002 17Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className='d-flex ml-1 nth-last-star'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 17L6.12223 20.59L7.72023 13.89L2.49023 9.41L9.35523 8.86L12.0002 2.5L14.6452 8.86L21.5112 9.41L16.2802 13.89L17.8782 20.59L12.0002 17Z" fill="#121415"/>
                                    </svg>
                                </span>
                                <span className='d-flex ml-1'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9999 18.26L4.94691 22.208L6.52191 14.28L0.586914 8.792L8.61391 7.84L11.9999 0.5L15.3859 7.84L23.4129 8.792L17.4779 14.28L19.0529 22.208L11.9999 18.26ZM11.9999 15.968L16.2469 18.345L15.2979 13.572L18.8709 10.267L14.0379 9.694L11.9999 5.275L9.96191 9.695L5.12891 10.267L8.70191 13.572L7.75291 18.345L11.9999 15.968Z" fill="#C4CAD0"/>
                                    </svg>
                                </span>
                                <span className='d-flex ml-1'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9999 18.26L4.94691 22.208L6.52191 14.28L0.586914 8.792L8.61391 7.84L11.9999 0.5L15.3859 7.84L23.4129 8.792L17.4779 14.28L19.0529 22.208L11.9999 18.26ZM11.9999 15.968L16.2469 18.345L15.2979 13.572L18.8709 10.267L14.0379 9.694L11.9999 5.275L9.96191 9.695L5.12891 10.267L8.70191 13.572L7.75291 18.345L11.9999 15.968Z" fill="#C4CAD0"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='login-textbox__wrapper'>
                        <div className='cart-gift__wrap'>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Add a title"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Add a title' />
                                </FloatingLabel>
                            </div>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Add a description"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Add a description' />
                                </FloatingLabel>
                            </div>
                        </div>
                            
                        <div className="continue-login_btn">
                            <button type='button' className='btn btn-dark-custom w-100'>Submit Review</button>
                        </div>  
                    </div>                  
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderDetails