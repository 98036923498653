import React, {useState} from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CouponPanel from './CouponPanel';

const CartPriceDetails = () => {
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='cart-coupon'>
                <div className='cart-coupon__head d-flex align-items-center justify-content-between mb-3'>
                    <div className='coupon-tag'>
                        <span>Apply Coupon</span>
                    </div>
                    <span className='coupon-link'>
                        <button type='button' className='btn' onClick={handleShow}>View All</button>
                    </span>
                </div>
                <div className='input-group-address'>
                    <div className='cart-address__wrap'>
                        <input type="number" className='form-control form-control-coupon' placeholder='Enter your coupon code here'/>
                        <button type='button' className='btn btn-coupon-absolute' disabled>Check</button>
                    </div>
                </div>
            </div>
            <div className='mar-spacer h-1_5' />
            <div className='payment-order__summary'>
                <div className='cart-order__summary'>
                    <h4 className='heading-order__summary'>Order Summary</h4>
                    <div className='order-summary__table'>
                        <div className='order-summary__body'>
                            <div className='order-summary__row'>
                                <div className='order-summary__col'>
                                    <label htmlFor="label">Samsung Galaxy Z Fold 2 5G</label>
                                </div>
                                <div className='order-summary__col'>
                                    <span>₹19,999</span>
                                </div>
                            </div>
                            <div className='order-summary__row'>
                                <div className='order-summary__col'>
                                    <label htmlFor="label">Money and Damage Protection</label>
                                </div>
                                <div className='order-summary__col'>
                                    <span>₹999</span>
                                </div>
                            </div>
                            <div className='order-summary__row'>
                                <div className='order-summary__col'>
                                    <label htmlFor="label">Discount</label>
                                </div>
                                <div className='order-summary__col details-text'>
                                    <span>- ₹400</span>
                                </div>
                            </div>
                            <div className='order-summary__row order-summary--dashed-border'>
                                <div className='order-summary__col'>
                                    <label htmlFor="label">Watercolor gradient Phone grip holder...</label>
                                </div>
                                <div className='order-summary__col'>
                                    <span>₹1,999</span>
                                </div>
                            </div>
                            <div className='order-summary__row'>
                                <div className='order-summary__col'>
                                    <label htmlFor="label">Applied Coupon</label>
                                </div>
                                <div className='order-summary__col'>
                                    <span>- ₹500</span>
                                </div>
                            </div>
                            <div className='order-summary__row'>
                                <div className='order-summary__col'>
                                    <label htmlFor="label">Delivery Charges</label>
                                </div>
                                <div className='order-summary__col details-text'>
                                    <span className='text-uppercase'>Free</span>
                                </div>
                            </div>
                        </div>
                        <div className='order-summary__footer'>
                            <div className='order-summary__row'>
                                <div className='order-summary__col flex-column d-flex'>
                                    <span>Total Amount</span>
                                    <span className='excl-txt'>(Inclusive of all taxes)</span>
                                </div>
                                <div className='order-summary__col'>
                                    <span>₹22,097</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cart-check__warp d-flex flex-column'>
                <div className='cart-check__list d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <span className='d-flex'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 2C15.0906 1.9998 15.6717 2.14908 16.1892 2.43393C16.7066 2.71879 17.1435 3.12995 17.4592 3.62914C17.7749 4.12832 17.9592 4.69929 17.9948 5.28886C18.0305 5.87843 17.9163 6.46743 17.663 7.001L21 7C21.2652 7 21.5196 7.10536 21.7071 7.2929C21.8946 7.48043 22 7.73479 22 8V12C22 12.2652 21.8946 12.5196 21.7071 12.7071C21.5196 12.8946 21.2652 13 21 13H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V13H3C2.73478 13 2.48043 12.8946 2.29289 12.7071C2.10536 12.5196 2 12.2652 2 12V8C2 7.73479 2.10536 7.48043 2.29289 7.2929C2.48043 7.10536 2.73478 7 3 7L6.337 7.001C5.98076 6.25268 5.90163 5.40221 6.1137 4.601C6.32577 3.7998 6.81529 3.09985 7.4951 2.62576C8.17491 2.15167 9.0009 1.9342 9.82603 2.01207C10.6512 2.08994 11.4219 2.4581 12.001 3.051C12.326 2.71753 12.7147 2.45268 13.1439 2.27215C13.5732 2.09162 14.0343 1.99908 14.5 2ZM18 13H6V20H18V13ZM20 9H4V11H20V9ZM9.5 4C9.11478 4.00019 8.74441 4.14858 8.46561 4.41441C8.18682 4.68025 8.02099 5.04315 8.00248 5.42792C7.98396 5.81269 8.11419 6.18984 8.36618 6.48121C8.61816 6.77257 8.97258 6.95583 9.356 6.993L9.5 7H11V5.5C11 5.12712 10.8611 4.76761 10.6104 4.49158C10.3597 4.21556 10.0152 4.0428 9.644 4.007L9.5 4ZM14.5 4L14.356 4.007C14.0094 4.04021 13.6851 4.19298 13.4388 4.43911C13.1925 4.68525 13.0395 5.0094 13.006 5.356L13 5.5V7H14.5L14.644 6.993C15.015 6.95703 15.3594 6.7842 15.6099 6.50819C15.8605 6.23218 15.9993 5.87277 15.9993 5.5C15.9993 5.12724 15.8605 4.76783 15.6099 4.49182C15.3594 4.21581 15.015 4.04297 14.644 4.007L14.5 4Z" fill="#121415"/>
                            </svg>
                        </span>
                        <span className='ml-2'>Send this as gift</span>
                    </div>
                    <div className='cart-check__input'>
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value="1" 
                            //checked={isChecked} 
                            //onChange={handleOnChange}
                            id="1" />
                    </div>
                </div>
                <div className='cart-check__list d-flex align-items-center justify-content-between flex-wrap'>
                    <div className='d-flex align-items-center'>
                        <span>Use GST Invoice</span>
                    </div>
                    <div className='cart-check__input'>
                        <Form.Check 
                            type='checkbox'
                            id='default-checkbox'
                            role="button" onClick={() => setModalShow(true)} aria-hidden="true"
                            className='d-flex align-items-start'
                        />
                    </div>
                    <div className='gst-applied__wrap d-flex align-items-center justify-content-between'>
                        <div className='gst-label__info d-flex flex-column'>
                            <span>Parallel</span>
                            <span>24UDBBFJD326HDC</span>
                        </div>
                        <div className='gst-change__link'>Change</div>
                    </div>
                </div>
            </div>
            <button type='button' className='btn btn-dark-custom btn-dark-custom-cart w-100'>Place Order</button>

            <Offcanvas show={show} onHide={handleClose} className='cart-pdp-offcanvas coupon-offcanvas'>
                <CouponPanel  />
            </Offcanvas>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-md-custom items-removed__modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 gst-title'>
                        <h2 className='notifyMe-heading mb-4'>Add GST details</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='login-textbox__wrapper'>
                        <div className='cart-gift__wrap'>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Company Name"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='Company Name' defaultValue="Parallel Private Limited" />
                                </FloatingLabel>
                            </div>
                            <div className='form-group-custom'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="GST Number"
                                >
                                    <Form.Control type="text" className='form-control form-control-custom' placeholder='GST Number' defaultValue="24UDBBFJD326HDC" />
                                </FloatingLabel>
                            </div>
                        </div>
                            
                        <div className="continue-login_btn">
                            <button type='button' className='btn btn-dark-custom w-100'>Continue</button>
                        </div>  
                    </div>                  
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CartPriceDetails