import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import HDFCLogo from '../../../../Images/product/emi/HDFCLogo.png';
import IciciLogo from '../../../../Images/product/emi/IciciLogo.png';
import SBI from '../../../../Images/product/emi/SBI.png';

const Step2 = () => {
    const [ BankOffers ] = useState([
        { code: 'HDFC', title: 'HDFC Bank (Credit Card)', ImgIco: HDFCLogo },
        { code: 'SBI', title: 'SBI Bank (Debit Card)', ImgIco: SBI},
        { code: 'ICICI', title: 'ICICI Bank (Credit Card)', ImgIco: IciciLogo}
    ]);
    const [toggleContents, setToggleContents] = useState('Select option');
    // eslint-disable-next-line no-unused-vars
    const [selectedCountry, setSelectedCountry] = useState();

    return (
        <>
            <div className='input-group-banks input-group-location mb-4 position-relative'>
                <Form>
                    <Dropdown
                        onSelect={eventKey => {
                            const { ImgIco, title } = BankOffers.find(({ code }) => eventKey === code);
        
                            setSelectedCountry(eventKey);
                            setToggleContents(
                                <div className='upi-payment__list'>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <img src={ImgIco} className='img-fluid' alt='' />
                                        </div>
                                        <span className='upi-payment__text ml-2 ml-2'>{title}</span>
                                    </div>                                    
                                </div>
                            );
                        }}
                        className="d-flex"
                    >
                        <Dropdown.Toggle id="dropdown-autoclose-true" className='dropdown-toggle-box'>
                            {toggleContents}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className=''>
                            {BankOffers.map((SelectData) => (
                                <Dropdown.Item key={SelectData.code} eventKey={SelectData.code} className='p-2'>
                                    <div className='upi-payment__list'>
                                        <div className='d-flex align-items-center'>
                                            <div className='upi-payment__media m-0'>
                                                <img src={SelectData.ImgIco} className='img-fluid' alt='' />
                                            </div>
                                            <span className='upi-payment__text ml-2 ml-2'>{SelectData.title}</span>
                                        </div>                                    
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form>
            </div>
            <h6 className='heading-group-banks'>Emi Plans</h6>
            <div className='bank-list__wrap exchange-list__wrap'>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹2,776 for 3 months</span>
                            <div className='emi-card-intrest'>₹2,776 for 3 months</div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹1461 for 6 months</span>
                            <div className='emi-card-intrest'>Total Interest Charged: ₹371 </div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹992 for 9 months</span>
                            <div className='emi-card-intrest'>Total Interest Charged: ₹534 </div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>
                </div>
                <div className='upi-payment__list justify-content-between flex-row align-items-center'>
                    <div className='upi-payment__wrap'>
                        <div className='d-flex flex-column align-items-start'>
                            <span className='upi-payment__text mb-1'>₹992 for 12 months</span>
                            <div className='emi-card-intrest'>Total Interest Charged: ₹698 </div>
                        </div>
                        <div className='upi-payment__btn'>
                            <Form.Check type="radio" name='upiCoupon' aria-label="radio 1" />
                        </div>
                    </div>                    
                </div>
            </div>    

            <div className='mar-spacer h-13' />   
        </>
    )
}

export default Step2